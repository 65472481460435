export interface LeadSearch {
  key: string;
  name: string;
  companyName: string;
  email: string;
  lastModifiedDate: string;
  lastModifiedDisplayValue: string;
  phone1: string;
  phone2: string;
  status: LeadStatus;
}

export enum LeadStatus {
  Unqualified = 0,
  Qualified = 1,
  Converted = 2,
  Archived = 3,
}
