import { ConfigurationMapper } from './configuration';
import {
  CaseItem,
  CaseSearch,
  CaseStatus,
  CaseFieldOption,
  StatusGroup,
  Field,
} from '../../../models';
import {
  Case as OctopusCase,
  CaseFieldOptionsResponse,
  StatusOptionsGroup,
  StatusAvailability,
} from '../models';
import { EntityMapper } from './entity';

export class CaseSearchMapper extends EntityMapper<OctopusCase, CaseSearch> {
  from(source: OctopusCase): CaseSearch {
    return {
      key: source.Key,
      subject: source.Subject,
      companyKey: source.AbEntry.Key ?? null,
      companyName: this.mapCompanyOrIndividual(source.AbEntry),
      contactName: source.Contact?.FullName,
      contactKey: source.Contact ? source.Contact.Key : null,
      lastModifiedDisplayValue: source.LastModifyDate?.DisplayValue,
      lastModifiedValue: source.LastModifyDate?.Value,
    };
  }
}

export class CaseStatusMapper extends ConfigurationMapper<
  CaseFieldOptionsResponse,
  CaseStatus[]
> {
  from(source: CaseFieldOptionsResponse): CaseStatus[] {
    const csCase = source.Case?.FieldOptions;
    const statusArray = csCase?.Status || [];

    const checkGroupStatus = (status: StatusOptionsGroup): StatusGroup => {
      if (!status.Group) {
        return 'unassigned';
      } else if (
        status.Group &&
        status.Group.length === 1 &&
        status.Group[0].Value === StatusAvailability.Assign
      ) {
        return 'assign';
      } else if (
        status.Group &&
        status.Group.length === 1 &&
        status.Group[0].Value === StatusAvailability.Escalate
      ) {
        return 'escalate';
      } else if (status.Group && status.Group.length > 1) {
        let hasResolve = false;

        for (const groupItem of status.Group) {
          if (groupItem.Value === StatusAvailability.Resolve) {
            hasResolve = true;
            break;
          }
        }

        if (hasResolve) {
          return 'resolved';
        } else {
          return 'escalate';
        }
      }
      return 'resolved';
    };

    return (
      statusArray
        .map((status) => ({
          id: status.Value,
          name: status.DisplayValue,
          group: checkGroupStatus(status),
        }))
        // Sort status array alphabetically
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }
}

export class CasesMapper extends EntityMapper<OctopusCase, CaseItem> {
  from(source: OctopusCase, fields: Field[] = []): CaseItem {
    return {
      abEntry: {
        value: source.AbEntry?.Key ?? '',
        display: this.mapCompanyOrIndividual(source.AbEntry),
      },
      caseId: source.Key,
      caseNumber: source.CaseNumber,
      subject: source.Subject,
      company: this.mapCompanyOrIndividual(source.AbEntry),
      companyId: source.AbEntry.Key ?? '',
      creationDateDisplayValue: source.CreationDate.DisplayValue,
      creationDateValue: source.CreationDate.Value,
      priority: source.Priority.DisplayValue,
      priorityValue: source.Priority.Value,
      severity: source.Severity.DisplayValue,
      severityValue: source.Severity.Value,
      status: {
        id: source.Status.Value,
        name: source.Status.DisplayValue,
      },
      assignedTo: source.AssignedTo?.Key?.Value ?? '',
      assignedToName: source.AssignedTo ? source.AssignedTo.DisplayValue : '',
      resolvedBy: source.ResolvedBy ? source.ResolvedBy.DisplayValue : '',
      overdue: source.Overdue,
      contact: {
        value: source.Contact?.Key ?? '',
        display: source.Contact
          ? `${source.Contact.FirstName} ${source.Contact.LastName}`.trim()
          : '',
      },
      ...this.mapFields(source, fields),
    };
  }
}

export class CaseFieldOptionsMapper extends ConfigurationMapper<
  CaseFieldOptionsResponse,
  CaseFieldOption
> {
  from(source: CaseFieldOptionsResponse): CaseFieldOption {
    const csCase = source.Case?.FieldOptions;

    return {
      priority: csCase?.Priority?.map(this.mapValueAndDisplay) ?? [],
      caseType: csCase?.Type?.map(this.mapValueAndDisplay) ?? [],
      caseQueue: csCase?.Queue?.map(this.mapValueAndDisplay) ?? [],
      assignedTo: csCase?.AssignedTo?.map(this.mapValueAndDisplay) ?? [],
    };
  }
}
