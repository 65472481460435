/* eslint-disable @angular-eslint/directive-selector */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  ViewContainerRef,
  Input,
} from '@angular/core';
import { delay, of, tap } from 'rxjs';
import { KendoComponentDirective } from './kendo-component.directive';

@Directive({
  selector:
    'kendo-textbox, kendo-multiselecttree, kendo-dropdownlist, kendo-autocomplete, kendo-combobox, kendo-dropdowntree, kendo-multicolumncombobox, kendo-multiselect',
})
export class KendoInputDirective
  extends KendoComponentDirective
  implements AfterViewInit
{
  @Input()
  focusOnViewInit = false;

  constructor(element: ElementRef, view: ViewContainerRef) {
    super(element, view);
  }

  ngAfterViewInit(): void {
    if (this.focusOnViewInit) {
      of(this.component)
        .pipe(
          delay(250),
          tap((control) => control?.focus())
        )
        .subscribe();
    }
  }
}
