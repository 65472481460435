 import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContextService } from './context.service';
import { Observable, map } from 'rxjs';
import { Octopus } from '@maximizer/core/shared/domain';

export interface EmailValidationDto {
  Email1: string;
  PreviousEmail1: string;
  Email2?: string;
  PreviousEmail2?: string;
  Email3?: string;
  PreviousEmail3?: string;
}

export interface EmailValidationResponseDto {
  Success: string;
  errorMessage?: string;
}

@Injectable()
export class EmailValidationService {

  private readonly ValidateUserEmails =
    '/mvc/EmailProperties/EmailProperties/ValidateUserEmails';

  constructor(
    private readonly _http: HttpClient,
    private readonly _context: ContextService
  ) {}

  getEmailValidationStatus(userKey: string): Observable<Octopus.EmailStatusGroup | undefined> {
    const readRequest: Octopus.EmailValidationReadRequest = {
      UserProfileValidation: {
        Criteria: {
          SearchQuery: {
            Key: {
              $EQ: userKey
            }
          }
        },
        Scope: {
          Fields: {
            Key: 1,
            DisplayValue: 1,
            Email1Status: {
              Key: 1,
              DisplayValue: 1
            },
            Email2Status: {
              Key: 1,
              DisplayValue: 1
            },
            Email3Status: {
              Key: 1,
              DisplayValue: 1
            }
          }
        }
      }
    };

    return this._http
      .post<Octopus.EmailValidationResponse>(
        `${this._context.api}${Octopus.Action.READ}`, 
        readRequest
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            return result.UserProfileValidation.Data.length > 0 ? result.UserProfileValidation.Data[0] : undefined;
          }
          return undefined;
        })
      );
  }

  validateUserEmails(dto: EmailValidationDto): Observable<EmailValidationResponseDto> {
    return this._http
      .post<EmailValidationResponseDto>(
        `${this._context.website}${this.ValidateUserEmails}`, dto
      );
  }
}
