/* eslint-disable @angular-eslint/directive-selector */
import { ElementRef, ViewContainerRef } from '@angular/core';
import {
  DatePickerComponent,
  DateTimePickerComponent,
  TimePickerComponent,
} from '@progress/kendo-angular-dateinputs';
import {
  AutoCompleteComponent,
  ComboBoxComponent,
  DropDownListComponent,
  DropDownTreeComponent,
  MultiColumnComboBoxComponent,
  MultiSelectComponent,
  MultiSelectTreeComponent,
} from '@progress/kendo-angular-dropdowns';
import {
  NumericTextBoxComponent,
  SwitchComponent,
  TextAreaComponent,
  TextBoxComponent,
} from '@progress/kendo-angular-inputs';
import { KendoComponent } from '../models/kendo-component';

export abstract class KendoComponentDirective {
  protected component?: KendoComponent;

  constructor(
    protected element: ElementRef,
    protected view: ViewContainerRef,
  ) {
    const tag = element.nativeElement.tagName.toLowerCase();
    this.component = this.getComponent(tag, view);
  }

  getComponent(
    tag: string,
    view: ViewContainerRef,
  ): KendoComponent | undefined {
    switch (tag) {
      case 'kendo-multiselecttree':
        return view.injector.get(MultiSelectTreeComponent);
      case 'kendo-dropdownlist':
        return view.injector.get(DropDownListComponent);
      case 'kendo-autocomplete':
        return view.injector.get(AutoCompleteComponent);
      case 'kendo-combobox':
        return view.injector.get(ComboBoxComponent);
      case 'kendo-dropdowntree':
        return view.injector.get(DropDownTreeComponent);
      case 'kendo-multicolumncombobox':
        return view.injector.get(MultiColumnComboBoxComponent);
      case 'kendo-multiselect':
        return view.injector.get(MultiSelectComponent);
      case 'kendo-switch':
        return view.injector.get(SwitchComponent);
      case 'kendo-textbox':
        return view.injector.get(TextBoxComponent);
      case 'kendo-textarea':
        return view.injector.get(TextAreaComponent);
      case 'kendo-numerictextbox':
        return view.injector.get(NumericTextBoxComponent);
      case 'kendo-datetimepicker':
        return view.injector.get(DateTimePickerComponent);
      case 'kendo-datepicker':
        return view.injector.get(DatePickerComponent);
      case 'kendo-timepicker':
        return view.injector.get(TimePickerComponent);
      default:
        return undefined;
    }
  }
}
