/* eslint-disable @angular-eslint/directive-selector */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { KendoComponentDirective } from '../../kendo/directives/kendo-component.directive';
import { LayoutFormControl, LayoutFormGroup } from '../classes';

@Directive({
  selector: '[formControlName]',
})
export class KendoComponentInjectorDirective
  extends KendoComponentDirective
  implements AfterViewInit, OnDestroy
{
  constructor(
    element: ElementRef,
    view: ViewContainerRef,
    private control: NgControl,
  ) {
    super(element, view);
  }

  ngAfterViewInit(): void {
    const control = this.control.control;
    if (this.component && control instanceof LayoutFormControl) {
      if (!this.component.formControl) {
        this.component.formControl = control;
      }

      if (control.parent instanceof LayoutFormGroup) {
        control.parent.components.push(this.component);
      }
    }
  }

  ngOnDestroy(): void {
    const control = this.control.control;
    if (this.component && control?.parent instanceof LayoutFormGroup) {
      const index = control.parent.components.indexOf(this.component);
      if (index > -1) {
        control.parent.components.splice(index, 1);
      }
    }
  }
}
