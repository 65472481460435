<div class="text-xs bg-neutral-10 p-4 h-full flex w-full">
  <maximizer-loader
    type="legacy"
    class="outlook-loader my-auto"
    *ngIf="loading"
  ></maximizer-loader>
  <div class="pb-8 space-y-2 w-full h-full">
    <div class="filter-panel" *ngIf="!loading">
      <div class="flex flex-col w-full">
        <div class="flex flex-row space-x-2 justify-between">
          <kendo-textbox
            (valueChange)="filterByText($event)"
            [placeholder]="'outlook.timeline.search' | translate"
            [clearButton]="true"
            class="w-2/3"
          >
            <ng-template kendoTextBoxPrefixTemplate>
              <kendo-svgicon [icon]="searchIcon"></kendo-svgicon>
              <kendo-textbox-separator></kendo-textbox-separator>
            </ng-template>  
          </kendo-textbox>

          <kendo-button
            #togglePopup
            (click)="toggleFilterPopup()"
            [classList]="'flex ml-auto px-0'"
            fillMode="flat" 
            rounded="medium"
          >
            <span class="flex ">
              <em
                class="material-icons icon-filter-alt icon-16 mx-1 my-auto font-bold"
              ></em>
              {{ 'outlook.timeline.filter.title' | translate }}
              <em
                class="fa-icons icon-solid icon-caret-down icon-16 mx-1 my-auto"
              ></em>
            </span>
          </kendo-button>
        </div>

        <div
          class="flex flex-row my-2"
          *ngIf="filtersByType > 0 || filtersByUser > 0"
        >
          <kendo-chip
            *ngIf="filtersByType > 0"
            size="medium"
            rounded="full"
            fillMode="solid"
            themeColor="base"
            [removable]="true"
            (remove)="onRemoveTypeFilter()"
            class=""
          >
            {{ filtersByType }}
            {{ 'outlook.timeline.filter.typeChip' | translate }}
          </kendo-chip>
          <kendo-chip
            *ngIf="filtersByUser > 0"
            size="medium"
            rounded="full"
            fillMode="solid"
            themeColor="base"
            [removable]="true"
            (remove)="onRemoveUserFilter()"
          >
            {{ filtersByUser }}
            {{ 'outlook.timeline.filter.userChip' | translate }}
          </kendo-chip>
        </div>

        <!-- Filter Popup -->
        <div id="timeline">
          <kendo-popup
          *ngIf="showFilterPopup"
            [anchor]="togglePopup.element"
            class="w-full px-2"
          >
            <form
              [formGroup]="form!"
              class="flex flex-col justify-between mt-1"
              (submit)="applyFilters($event)"
            >
              <!-- Filter Types -->

              <div class="flex flex-row my-2">
                <em
                  class="fa-icons icon-user icon-14 my-auto mx-2 font-bold"
                ></em>
                <span class="font-bold ml-1">{{
                  'outlook.timeline.filter.users' | translate
                }}</span>
              </div>
              <div class="m-2">
                <kendo-multiselect
                  kendoMultiSelectSummaryTag
                  [data]="users"
                  [formControlName]="'users'"
                  textField="displayName"
                  valueField="key"
                  [popupSettings]="{ popupClass: 'ml-1 w-full' }"
                >
                </kendo-multiselect>
              </div>
              <div class="flex flex-row my-2">
                <em
                  class="fa-icons icon-clock icon-14 my-auto mx-2 font-bold"
                ></em>
                <span class="font-bold ml-1">{{
                  'outlook.timeline.filter.timelineItems' | translate
                }}</span>
              </div>
              <div
                *ngFor="let filterType of types"
                class="hover:bg-inserv-10 cursor-pointer space-y-1"
                [class]="verifyCheckbox(filterType.key) ? 'bg-inserv-5' : ''"
                (click)="toggleCheckbox(filterType.key)"
              >
                <input
                  type="checkbox"
                  #filterType
                  kendoCheckBox
                  [formControlName]="filterType.key"
                  class="m-2"
                />
                <label class="k-checkbox-label cursor-pointer my-2">{{
                  filterType.name
                }}</label>
              </div>

              <!-- Filter Buttons -->
              <div
                class="bg-neutral-5 z-50 flex self-end space-x-2 p-2 w-full sticky bottom-0"
              >
                <button
                  kendoButton
                  rounded="large"
                  class="uppercase rounded"
                  themeColor="primary"
                  fillMode="flat"
                  (click)="toggleFilterPopup()"
                  [disabled]="loading"
                  fillMode="flat"
                  style="margin-left: auto"
                >
                  {{ 'outlook.buttons.cancel' | translate }}
                </button>
                <button
                  kendoButton
                  class="uppercase rounded ml-auto"
                  themeColor="primary"
                  fillMode="outline"
                  (click)="applyFilters($event)"
                >
                  <span>{{ 'outlook.buttons.apply' | translate }} </span>
                </button>
              </div>
            </form>

            <ng-template #checkboxTemplate let-filterType>
              <div
                class="flex flex-row m-1"
                (click)="toggleCheckbox(filterType.key)"
              >
                <input
                  type="checkbox"
                  [id]="filterType.key"
                  #filterType
                  kendoCheckBox
                />
                <kendo-label
                  class="k-checkbox-label"
                  [for]="filterType"
                  [text]="filterType.name"
                ></kendo-label>
              </div>
            </ng-template>
          </kendo-popup>
        </div>
      </div>
    </div>

    <!-- Notes -->

    <div
      *ngIf="!importantNotes && !groupedTimeline"
      class="flex flex-row"
      (click)="toggleFilterPopup(false)"
    >
      <div class="flex flex-col min-h-[120px] m-auto">
        <img
          class="mx-auto mt-auto max-h-[40px]"
          [src]="cdn + '/assets/images/outlook/timeline-emptystate.png'"
          alt="No items available"
        />
        <span class="mb-auto mt-2 text-xs text-center">{{
          'outlook.timeline.emptyState' | translate
        }}</span>
      </div>
    </div>

    <div
      class="rounded shadow-card flex flex-col divide-y divide-neutral-30 text-xs bg-white"
      *ngIf="importantNotes"
    >
      <div class="flex flex-row justify-center text-center">
        <span class="font-bold p-2"
          >{{ 'outlook.timeline.importantNotes' | translate }}
        </span>
        <span class="text-xs my-auto text-neutral-70 font-normal"
          >{{ importantNotes.length }}
          {{ 'outlook.timeline.items' | translate }}</span
        >
      </div>
      <ng-container class="flex flex-col" *ngFor="let event of importantNotes">
        <maximizer-outlook-timeline-card
          [event]="event"
        ></maximizer-outlook-timeline-card>
      </ng-container>
    </div>

    <!-- Events -->
    <div
      class="rounded shadow-card flex flex-col divide-y divide-neutral-30 text-xs bg-white"
      *ngFor="let item of groupedTimeline"
      (click)="toggleFilterPopup(false)"
    >
      <div class="flex flex-row justify-center text-center">
        <span class="font-bold p-2">{{ item.date | date: 'MMMM YYYY' }} </span>
        <span class="text-xs my-auto text-neutral-70 text-caption"
          >{{ item.events.length }}
          {{ 'outlook.timeline.items' | translate }}</span
        >
      </div>
      <ng-container class="flex flex-col" *ngFor="let event of item.events">
        <maximizer-outlook-timeline-card
          [event]="event"
        ></maximizer-outlook-timeline-card>
      </ng-container>
    </div>
  </div>
</div>



<maximizer-outlook-notification
  #notification
  entryId="timeline"
  [closable]="true"
  >
</maximizer-outlook-notification>
