import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ContextService } from '@maximizer/core/shared/data-access';

declare const LOAD_UID: string;

export function HttpLoaderFactory(http: HttpClient, context: ContextService) {
  const version = context.version ?? LOAD_UID;
  if (context.embedded) {
    const website = context.debug
      ? 'http://localhost:4200/i18n/'
      : `${context.website}/ClientScript/AngularElements/i18n/`;
    return new TranslateHttpLoader(http, website, `.json?v=${version}`);
  }
  return new TranslateHttpLoader(http, './i18n/', `.json?v=${version}`);
}

export function TestingHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

export function IntegrationHttpLoaderFactory(
  http: HttpClient,
  context: ContextService,
) {
  if (context.integration) {
    return new TranslateHttpLoader(
      http,
      `${context.integration.url}/i18n/`,
      `.json?v=${LOAD_UID}`,
    );
  }
  return new TranslateHttpLoader(http, './i18n/', `.json?v=${LOAD_UID}`);
}

export const defaultTranslateConfiguration: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient, ContextService],
  },
  defaultLanguage: 'en',
};

export const integrationTranslateConfiguration: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: IntegrationHttpLoaderFactory,
    deps: [HttpClient, ContextService],
  },
  defaultLanguage: 'en',
};

export const testingTranslateConfiguration: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: TestingHttpLoaderFactory,
    deps: [HttpClient],
  },
  defaultLanguage: 'en',
};
