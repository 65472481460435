import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateDocument, Octopus } from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import { ContextService } from './context.service';

@Injectable()
export class DocumentService {
  constructor(
    private context: ContextService,
    private httpClient: HttpClient,
  ) {}

  createAttachment(
    file: File,
    contentType: string | undefined = undefined,
  ): Observable<string | null> {
    const headers = new HttpHeaders();
    if (contentType) {
      headers.set('Content-Type', contentType);
    }

    return this.httpClient
      .post<Octopus.AttachmentWriteResponse>(
        `${this.context.api}${Octopus.Action.ATTACHMENTCREATE}`,
        file,
        {
          headers,
          responseType: 'json',
        },
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            return result.Attachment.Data.AttachmentId;
          }
          return null;
        }),
      );
  }

  createDocument(source: CreateDocument): Observable<string | null> {
    const mapper = new Octopus.CreateDocumentMapper();
    const request = mapper.from(source);
    return this.httpClient
      .post<Octopus.DocumentWriteResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            return result.Document.Data.Key ?? null;
          }
          return null;
        }),
      );
  }

  delete(documentKeys: string[]): Observable<boolean> {
    const request: { [name: string]: unknown } = {};

    if (documentKeys) {
      documentKeys.forEach(
        (key, index) => (request['Document.' + index] = { Data: { Key: key } }),
      );
    }

    return this.httpClient
      .post<Octopus.Response>(
        `${this.context.api}${Octopus.Action.DELETE}`,
        request,
        { headers: this.getRequestHeaders() },
      )
      .pipe(
        map((result) => {
          return result.Code === Octopus.ResponseStatusCode.Successful;
        }),
      );
  }
  private getRequestHeaders(): { [header: string]: string } {
    return {
      'mx-insights-entity': 'Document',
    };
  }
}
