import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ContextService } from './context.service';
import { Octopus } from '@maximizer/core/shared/domain';

@Injectable()
export class AppointmentService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  delete(appointmnetKeys: string[]): Observable<boolean> {
    const request: { [name: string]: unknown } = {};

    if (appointmnetKeys) {
      appointmnetKeys.forEach(
        (key, index) =>
          (request['Appointment.' + index] = { Data: { Key: key } }),
      );
    }

    return this.http
      .post<Octopus.Response>(
        `${this.context.api}${Octopus.Action.DELETE}`,
        request,
        { headers: this.getRequestHeaders() },
      )
      .pipe(
        map((result) => {
          return result.Code === Octopus.ResponseStatusCode.Successful;
        }),
      );
  }
  private getRequestHeaders(): { [header: string]: string } {
    return {
      'mx-insights-entity': 'Appointment',
    };
  }
}
