import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Octopus,
  PipelineListItem,
  Stage,
  Coaching,
  PipelineTemplate,
  LinksTemplate,
} from '@maximizer/core/shared/domain';
import {
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  mergeMap,
  from,
  toArray,
} from 'rxjs';
import { ContextService } from '@maximizer/core/shared/data-access';
import {
  PipelineTemplateA,
  PipelineTemplateB,
  PipelineTemplateC,
  PipelineTemplateD,
  PipelineTemplateE,
} from '../../mocks';

@Injectable()
export class PipelineService {
  private readonly stageMapper = new Octopus.SalesStageSetupMapper();
  private readonly linkMapper = new Octopus.CoachingLinkMapper();

  constructor(
    private http: HttpClient,
    private context: ContextService,
  ) {}

  getPipelines(): Observable<PipelineListItem[]> {
    const request: Octopus.OpportunityFieldOptionRequest = {
      Opportunity: {
        FieldOptions: {
          SalesProcessSetup: [
            {
              Key: 1,
              Description: 1,
            },
          ],
        },
      },
    };

    return this.http
      .post<Octopus.OpportunityFieldOptionResponse>(
        this.context.api + Octopus.Action.READ,
        request,
      )
      .pipe(
        switchMap((response) => {
          if (
            response.Code === Octopus.ResponseStatusCode.Successful &&
            response.Opportunity?.FieldOptions?.SalesProcessSetup
          ) {
            const pipelines =
              response.Opportunity.FieldOptions.SalesProcessSetup.map(
                (process) => this.getStages(process.Key, process.Description),
              );
            return forkJoin(pipelines);
          }
          return of([]);
        }),
      );
  }

  getPipelineNameById(processKey: string): Observable<string> {
    const request: Octopus.SalesProcessSetupReadRequest = {
      SalesProcessSetup: {
        Criteria: {
          SearchQuery: {
            Key: {
              $EQ: `${processKey}`,
            },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            Description: 1,
          },
        },
      },
    };

    return this.http
      .post<Octopus.SalesProcessSetupResponse>(
        this.context.api + Octopus.Action.READ,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === 0 && response.SalesProcessSetup?.Data) {
            return response.SalesProcessSetup.Data[0].Description || '';
          }
          return '';
        }),
      );
  }

  getStagesByPipelineId(id: string): Observable<Stage[]> {
    const request: Octopus.SalesStageSetupReadRequest = {
      SalesStageSetup: {
        Criteria: {
          SearchQuery: {
            SalesProcessSetupKey: { $EQ: id },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            SalesProcessSetupKey: 1,
            DisplayValue: 1,
            ProbabilityClose: 1,
            TargetAge: 1,
          },
        },
        OrderBy: {
          Fields: [
            {
              ProbabilityClose: 'ASC',
            },
          ],
        },
      },
    };
    let stages: Stage[] = [];

    return this.http
      .post<Octopus.SalesStageSetupResponse>(
        this.context.api + Octopus.Action.READ,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === 0 && response.SalesStageSetup?.Data) {
            stages = response.SalesStageSetup.Data.map(this.stageMapper.from);
          }
          return stages;
        }),
      );
  }

  createPipeline(name: string): Observable<string> {
    const request: Octopus.SalesProcessSetupWriteRequest = {
      SalesProcessSetup: {
        Data: {
          Key: null,
          Description: name,
        },
      },
    };

    return this.http
      .post<Octopus.SalesProcessSetupWriteResponse>(
        this.context.api + Octopus.Action.CREATE,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === 0 && response.SalesProcessSetup?.Data) {
            return response.SalesProcessSetup.Data.Key || '';
          }
          return '';
        }),
      );
  }

  updatePipeline(id: string, name: string): Observable<boolean> {
    const request: Octopus.SalesProcessSetupWriteRequest = {
      SalesProcessSetup: {
        Data: {
          Key: id,
          Description: name,
        },
      },
    };

    return this.http
      .post<Octopus.SalesProcessSetupWriteResponse>(
        this.context.api + Octopus.Action.UPDATE,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === 0 && response.SalesProcessSetup?.Data) {
            return true;
          }
          return false;
        }),
      );
  }

  deletePipeline(id: string): Observable<boolean> {
    const request: Octopus.SalesProcessSetupWriteRequest = {
      SalesProcessSetup: {
        Data: {
          Key: id,
        },
      },
    };

    return this.http
      .post<Octopus.SalesProcessSetupWriteResponse>(
        this.context.api + Octopus.Action.DELETE,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === 0) {
            return true;
          }
          return false;
        }),
      );
  }

  checkDuplicatedPipelineName(name: string): Observable<boolean> {
    const request: Octopus.SalesProcessSetupReadRequest = {
      SalesProcessSetup: {
        Scope: {
          Fields: {
            Key: 1,
          },
        },
        Criteria: {
          SearchQuery: {
            Description: {
              $EQ: name,
            },
          },
        },
      },
    };

    return this.http
      .post<Octopus.SalesProcessSetupResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.SalesProcessSetup?.Data
          ) {
            return result.SalesProcessSetup?.Data.length > 0;
          }
          return false;
        }),
      );
  }

  createPipelineTemplate(template: PipelineTemplate) {
    return this.createPipeline(template.processName).pipe(
      mergeMap((processId) => this.createPipelineStages(template, processId)),
      toArray(),
    );
  }

  private createPipelineStages(template: PipelineTemplate, processId: string) {
    return from(template.stages).pipe(
      mergeMap((stage) => {
        const createStageRequest = this.createStage(
          processId,
          stage.name,
          stage.probability,
          stage.targetAge,
        );

        return createStageRequest.pipe(
          mergeMap((stageId) =>
            this.createStageLinks(stage.coaching.links, processId, stageId),
          ),
        );
      }),
    );
  }

  private createStageLinks(
    links: LinksTemplate[],
    processId: string,
    stageId: string,
  ): Observable<string> {
    return from(links).pipe(
      mergeMap((link) => {
        return this.createLink(
          link.title,
          link.url,
          link.description,
          processId,
          stageId,
        );
      }),
    );
  }

  getPipelineTemplates(): Observable<PipelineTemplate[]> {
    const pipelineTemplates = of([
      PipelineTemplateA,
      PipelineTemplateB,
      PipelineTemplateC,
      PipelineTemplateD,
      PipelineTemplateE,
    ]);
    return pipelineTemplates;
  }

  private getStages(id: string, name: string): Observable<PipelineListItem> {
    const pipeline: PipelineListItem = {
      id,
      name,
      stages: [],
    };
    const request: Octopus.OpportunityFieldOptionRequest = {
      Opportunity: {
        FieldOptions: {
          SalesStageSetup: [
            {
              Key: 1,
              SalesProcessSetupKey: 1,
              DisplayValue: 1,
              ProbabilityClose: 1,
              TargetAge: 1,
            },
          ],
        },
        Data: {
          SalesProcessSetupKey: id,
        },
      },
    };
    return this.http
      .post<Octopus.OpportunityFieldOptionResponse>(
        this.context.api + Octopus.Action.READ,
        request,
      )
      .pipe(
        map((response) => {
          if (
            response.Code === Octopus.ResponseStatusCode.Successful &&
            response.Opportunity?.FieldOptions?.SalesStageSetup
          ) {
            pipeline.stages =
              response.Opportunity.FieldOptions.SalesStageSetup.map(
                this.stageMapper.from,
              );
          }
          return pipeline;
        }),
      );
  }

  createStage(
    processId: string,
    name: string,
    probabilityClose: number,
    targetAge: number,
  ): Observable<string> {
    const request: Octopus.SalesStageSetupWriteRequest = {
      SalesStageSetup: {
        Data: {
          Key: null,
          SalesProcessSetupKey: processId,
          Description: name,
          ProbabilityClose: probabilityClose,
          TargetAge: targetAge,
        },
      },
    };

    return this.http
      .post<Octopus.SalesStageSetupWriteResponse>(
        this.context.api + Octopus.Action.CREATE,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === 0 && response.SalesStageSetup?.Data) {
            return response.SalesStageSetup.Data.Key || '';
          }
          return '';
        }),
      );
  }

  updateStage(
    id: string,
    name: string,
    probabilityClose: number,
    targetAge: number,
  ): Observable<boolean> {
    const request: Octopus.SalesStageSetupWriteRequest = {
      SalesStageSetup: {
        Data: {
          Key: id,
          Description: name,
          ProbabilityClose: probabilityClose,
          TargetAge: targetAge,
        },
      },
    };

    return this.http
      .post<Octopus.SalesStageSetupWriteResponse>(
        this.context.api + Octopus.Action.UPDATE,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === 0 && response.SalesStageSetup?.Data) {
            return true;
          }
          return false;
        }),
      );
  }

  deleteStage(id: string): Observable<boolean> {
    const request: Octopus.SalesStageSetupWriteRequest = {
      SalesStageSetup: {
        Data: {
          Key: id,
        },
      },
    };

    return this.http
      .post<Octopus.SalesStageSetupWriteResponse>(
        this.context.api + Octopus.Action.DELETE,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === 0) {
            return true;
          }
          return false;
        }),
      );
  }

  checkDuplicatedStageName(
    name: string,
    processId?: string,
  ): Observable<boolean> {
    const request: Octopus.SalesStageSetupReadRequest = {
      SalesStageSetup: {
        Scope: {
          Fields: {
            Key: 1,
            SalesProcessSetupKey: 1,
          },
        },
        Criteria: {
          SearchQuery: {
            Description: {
              $EQ: name,
            },
            SalesProcessSetupKey: {
              $EQ: processId,
            },
          },
        },
      },
    };

    return this.http
      .post<Octopus.SalesStageSetupResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.SalesStageSetup?.Data
          ) {
            return result.SalesStageSetup?.Data.length > 0;
          }
          return false;
        }),
      );
  }

  getCoachingInfo(processKey: string): Observable<Stage[]> {
    const request: Octopus.CoachingDocumentSetupReadRequest = {
      SalesCoachingDocumentSetup: {
        Criteria: {
          SearchQuery: {
            SalesProcessSetupKey: {
              $EQ: `${processKey}`,
            },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            Name: 1,
            Description: 1,
            FileUrl: 1,
            SalesStageSetupKey: 1,
          },
        },
      },
    };

    return this.http
      .post<Octopus.CoachingDocumentSetupResponse>(
        this.context.api + Octopus.Action.READ,
        request,
      )
      .pipe(
        switchMap((response) => {
          if (
            response.Code === 0 &&
            response.SalesCoachingDocumentSetup?.Data
          ) {
            return this.getStages(processKey, 'temp').pipe(
              map((pipeline) => this.mapCoachingInfo(pipeline, response)),
            );
          }
          return of([]);
        }),
      );
  }

  private mapCoachingInfo(
    pipeline: PipelineListItem,
    response: Octopus.CoachingDocumentSetupResponse,
  ) {
    pipeline.stages.forEach((stage) => {
      const stageCoachingData = response.SalesCoachingDocumentSetup.Data.filter(
        (link) => link.SalesStageSetupKey === stage.id,
      );
      stage.coaching = {
        links: stageCoachingData.map(this.linkMapper.from),
      } as Coaching;
    });
    return pipeline.stages;
  }

  createLink(
    name: string,
    fileUrl: string,
    description: string,
    processId: string,
    stageId: string,
  ): Observable<string> {
    const request: Octopus.CoachingDocumentSetupWriteRequest = {
      SalesCoachingDocumentSetup: {
        Data: {
          Key: null,
          Name: name,
          Description: description,
          FileUrl: fileUrl,
          SalesProcessSetupKey: processId,
          SalesStageSetupKey: stageId,
        },
      },
    };

    return this.http
      .post<Octopus.CoachingDocumentSetupWriteResponse>(
        this.context.api + Octopus.Action.CREATE,
        request,
      )
      .pipe(
        map((response) => {
          if (
            response.Code === 0 &&
            response.SalesCoachingDocumentSetup?.Data
          ) {
            return response.SalesCoachingDocumentSetup.Data.Key || '';
          }
          return '';
        }),
      );
  }
}
