<maximizer-outlook-menu [pageTitle]="relatedTitle"></maximizer-outlook-menu>
<div class="h-[calc(100%-40px)] w-full flex flex-col scrollbar gap-2 p-2">
  @if (isLoading.skeleton) {
    <div class="flex flex-col gap-4">
      <maximizer-outlook-skeleton
        containerClasses="h-8 w-full rounded-md bg-neutral-20"
        fullWidth="full"
      ></maximizer-outlook-skeleton>
      <maximizer-outlook-skeleton
        containerClasses="h-32 w-full rounded-md bg-neutral-20"
        fullWidth="full"
      ></maximizer-outlook-skeleton>
      <maximizer-outlook-skeleton
        containerClasses="h-8 w-full rounded-md bg-neutral-20"
        fullWidth="full"
      ></maximizer-outlook-skeleton>
      <maximizer-outlook-skeleton
        containerClasses="h-8 w-full rounded-md bg-neutral-20"
        fullWidth="full"
      ></maximizer-outlook-skeleton>
      <maximizer-outlook-skeleton
        containerClasses="h-32 w-full rounded-md bg-neutral-20"
        fullWidth="full"
      ></maximizer-outlook-skeleton>
      <maximizer-outlook-skeleton
        containerClasses="h-8 w-full rounded-md bg-neutral-20"
        fullWidth="full"
      ></maximizer-outlook-skeleton>
    </div>
  } @else {
    @if (isLoading.spinner) {
      <maximizer-loader type="legacy"></maximizer-loader>
    }
    <!-- Opportunities -->
    @if (this.userRights?.opportunity?.read && this.opportunities.length) {
      <p class="subtitle-2 sticky">
        {{ 'outlook.main.related.opportunitiesSubtitle' | translate }}
      </p>
      @if (!showMore.opportunities) {
        <maximizer-outlook-opportunity-card
          [opportunity]="opportunities[0]"
          [rights]="userRights?.opportunity"
          (save)="saveEmail($event, 'opportunity')"
        ></maximizer-outlook-opportunity-card>
      } @else {
        @for (opportunity of opportunities; track $index) {
          <maximizer-outlook-opportunity-card
            [opportunity]="opportunity"
            [rights]="userRights?.opportunity"
            (save)="saveEmail($event, 'opportunity')"
          ></maximizer-outlook-opportunity-card>
        }
      }

      @if (this.opportunities.length > 1) {
        <div class="text-center">
          <button
            kendoButton
            fillMode="flat"
            class="text-inserv-100"
            (click)="showMore.opportunities = !showMore.opportunities"
          >
            {{
              showMore.opportunities
                ? ('outlook.buttons.showLess' | translate)
                : ('outlook.buttons.showMore' | translate)
            }}
          </button>
        </div>
      }
    }
    <!-- Cases -->
    @if (this.userRights?.customerService?.read && this.cases.length) {
      <p class="subtitle-2 pt-2 sticky">
        {{ 'outlook.main.related.casesSubtitle' | translate }}
      </p>
      @if (!showMore.cases) {
        <maximizer-outlook-case-card
          [case]="cases[0]"
          [rights]="userRights?.customerService"
          (save)="saveEmail($event, 'case')"
        ></maximizer-outlook-case-card>
      } @else {
        @for (case of cases; track $index) {
          <maximizer-outlook-case-card
            [case]="case"
            [rights]="userRights?.customerService"
            (save)="saveEmail($event, 'case')"
          ></maximizer-outlook-case-card>
        }
      }
      @if (this.cases.length > 1) {
        <div class="text-center">
          <button
            kendoButton
            fillMode="flat"
            class="text-inserv-100"
            (click)="showMore.cases = !showMore.cases"
          >
            {{
              showMore.cases
                ? ('outlook.buttons.showLess' | translate)
                : ('outlook.buttons.showMore' | translate)
            }}
          </button>
        </div>
      }
    }
  }
</div>

<maximizer-outlook-notification #notification></maximizer-outlook-notification>
