import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbEntrySearchService } from './services/ab-entry-search.service';
import { OpportunitiesSearchService } from './services/opportunities-search.service';
import { InteractionsSearchService } from './services/interactions-search.service';
import { CasesSearchService } from './services/cases-search.service';
import { LeadSearchService } from './services/lead-search.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    AbEntrySearchService,
    InteractionsSearchService,
    OpportunitiesSearchService,
    CasesSearchService,
    LeadSearchService
  ]
})
export class CoreGlobalSearchDataAccessModule {}
