import { Pipe, PipeTransform } from '@angular/core';

const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const FILE_SIZE_UNITS_LONG = [
  { Key: 'B', Value: 'Bytes' },
  { Key: 'KB', Value: 'Kilobytes' },
  { Key: 'MB', Value: 'Megabytes' },
  { Key: 'GB', Value: 'Gigabytes' },
  { Key: 'TB', Value: 'Terabyte' },
  { Key: 'PB', Value: 'Pettabytes' },
  { Key: 'EB', Value: 'Exabytes' },
  { Key: 'ZB', Value: 'Zettabytes' },
  { Key: 'YB', Value: 'Yottabytes' },
];

@Pipe({
  name: 'formatFileSize',
})
export class FormatFileSizePipe implements PipeTransform {
  transform(sizeInBytes: number, type?: string, longForm?: boolean): string {
    const units = longForm
      ? this.GetLongSizeUnits(type)
      : this.GetFileSizeUnits(type);
    let power = Math.round(Math.log(sizeInBytes) / Math.log(1024));
    power = Math.min(power, units.length - 1);

    const size = sizeInBytes / Math.pow(1024, power); // size in new units
    let formattedSize = Math.round(size * 100) / 100;
    let unit = units[power];
    if (size < 1) {
      formattedSize = Math.round(size * 1024 * 100) / 100;
      unit = units[power - 1];
    }
    return `${formattedSize} ${unit}`;
  }
  GetFileSizeUnits(types?: string): string[] {
    const fileSizeUnits: string[] = [];
    if (types) {
      let insert = false;
      FILE_SIZE_UNITS.forEach((element) => {
        if (types.toLowerCase() === element.toLowerCase()) insert = true;
        if (insert) fileSizeUnits.push(element);
      });
      return fileSizeUnits.length > 0 ? fileSizeUnits : FILE_SIZE_UNITS;
    }
    return FILE_SIZE_UNITS;
  }
  GetLongSizeUnits(types?: string): string[] {
    const fileSizeUnits: string[] = [];
    if (types) {
      let insert = false;
      FILE_SIZE_UNITS_LONG.forEach((element) => {
        if (types.toLowerCase() === element.Key.toLowerCase()) insert = true;
        if (insert) fileSizeUnits.push(element.Value);
      });
      return fileSizeUnits.length > 0
        ? fileSizeUnits
        : FILE_SIZE_UNITS_LONG.map((u) => u.Value);
    }
    return FILE_SIZE_UNITS_LONG.map((u) => u.Value);
  }
}
