import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AbEntrySearchService } from '@maximizer/core/global-search/data-access';
import { ListItem, Octopus } from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
} from '@maximizer/core/shared/ui';
import { CaseService } from '@maximizer/outlook/case/data-access';
import { AbEntryService } from '@maximizer/outlook/shared/data-access';
import {
  CaseForm,
  OutlookAbEntrySearch,
} from '@maximizer/outlook/shared/domain';
import {
  OutlookNotificationComponent,
  OutlookNotificationType,
} from '@maximizer/outlook/shared/ui';
import { TranslateService } from '@ngx-translate/core';
import {
  Subject,
  catchError,
  debounceTime,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
} from 'rxjs';

@Component({
  selector: 'maximizer-outlook-add-case',
  templateUrl: './add-case.component.html',
  styleUrl: './add-case.component.scss',
})
export class AddCaseComponent implements OnInit {
  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  private contactInputSubject = new Subject<string>();

  abEntryKey = '';
  form: FormGroupWrapper<CaseForm>;
  status: ListItem<string>[] = [];
  subject: ListItem<string>[] = [];
  product: ListItem<string>[] = [];
  category: ListItem<string>[] = [];
  contactList: ListItem<string>[] = [];

  isLoading = {
    spinner: false,
    autoComplete: false,
  };

  constructor(
    private translate: TranslateService,
    private caseService: CaseService,
    private router: Router,
    private route: ActivatedRoute,
    private abEntryService: AbEntryService,
    private abEntrySearchService: AbEntrySearchService,
  ) {
    this.abEntryKey = decodeURI(this.route.snapshot.paramMap.get('id') || '');

    this.form = FormWrapperBuilder.group<CaseForm>({
      key: null,
      subject: [null, [Validators.required, Validators.maxLength(127)]],
      abEntryKey: [null, [Validators.required, Validators.maxLength(9215)]],
      abEntryName: null,
      contactName: null,
      contactKey: null,
      description: [null, Validators.required],
      status: [null, Validators.required],
      contact: null,
      product: [[], Validators.required],
      category: [[]],
    });
  }

  ngOnInit(): void {
    this.isLoading.spinner = true;

    forkJoin({
      fieldOptions: this.caseService.getFieldOptions(),
      abEntry: this.abEntryService.getByKey(this.abEntryKey),
    })
      .pipe(
        map((result) => {
          this.status = [...result.fieldOptions.status].reverse();
          this.subject = result.fieldOptions.subject;
          this.product = result.fieldOptions.product;
          this.category = result.fieldOptions.category;

          this.contactList = [
            { id: this.abEntryKey, name: result.abEntry?.name ?? '' },
          ];

          this.patchFormValues(result.abEntry);
        }),
        catchError((error) => {
          console.error(error);

          return of(null);
        }),
        finalize(() => {
          this.isLoading.spinner = false;
        }),
      )
      .subscribe(() => {
        this.initContactInputSubject();
      });
  }

  private patchFormValues(abEntry: OutlookAbEntrySearch | null): void {
    if (!abEntry) {
      return;
    }
    const defaultStatusId = this.status[0]?.id;

    if (abEntry?.type !== Octopus.AbEntryType.Individual) {
      this.form.group.patchValue({
        status: defaultStatusId,
        abEntryKey: abEntry?.parentKey ?? this.abEntryKey,
        abEntryName: abEntry?.companyName ?? '',
        contactKey: this.abEntryKey,
        contactName: abEntry?.name ?? '',
      });
    } else {
      this.form.group.patchValue({
        status: defaultStatusId,
        abEntryKey: this.abEntryKey,
        abEntryName: abEntry?.name ?? '',
      });
    }
  }

  initContactInputSubject() {
    this.contactInputSubject
      .pipe(
        debounceTime(500),
        switchMap((input: string) => {
          if (input.length < 3) {
            this.isLoading.autoComplete = false;
            this.contactList = [];
            return of([]);
          }

          this.isLoading.autoComplete = true;

          return this.abEntrySearchService.search(input, true, 1).pipe(
            catchError((error) => {
              console.error('Autocomplete search failed', error);
              this.contactList = [];
              return of([]);
            }),
            finalize(() => (this.isLoading.autoComplete = false)),
          );
        }),
      )
      .subscribe((data) => {
        this.contactList = data.map((entry) => ({
          id: entry.key ?? '',
          name: entry.name,
        }));
      });
  }

  save(): void {
    if (this.form) {
      this.form.group.markAllAsTouched();
      this.form.group.markAsDirty();

      const validation = this.form.validate();
      if (validation.valid) {
        this.isLoading.spinner = true;
        this.caseService
          .save(this.form.value)
          .pipe(
            catchError((error) => {
              console.error(error);
              this.showNotificationMessage('error', 'outlook.case.error');
              return of(null);
            }),
            finalize(() => {
              this.isLoading.spinner = false;
            }),
          )
          .subscribe(() => {
            this.showNotificationMessage('success', 'outlook.case.success');
            this.router.navigate(['../'], { relativeTo: this.route });
          });
      }
    }
  }

  contactFilterChange(input: string) {
    this.contactList = [];
    this.contactInputSubject.next(input);
  }

  contactValueChange(input: string) {
    const contactData = this.contactList.find((entry) => entry.name === input);
    this.form.patch({
      contactKey: contactData?.id,
      contactName: contactData?.name,
    });
  }

  cancel(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  showNotificationMessage(
    type: OutlookNotificationType,
    message: string,
  ): void {
    const translatedMessage = this.translate.instant(message);
    this.notification?.showMessage(type, translatedMessage);
  }
}
