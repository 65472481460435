/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyValue } from '@angular/common';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Octopus } from '@maximizer/core/shared/domain';
import {
  IEventTelemetry,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { finalize, tap } from 'rxjs';
import { HeaderPrefix, insightsHeader } from '../events';
import { InsightsService } from '../services/insights.service';

@Injectable()
export class InsightsInterceptor implements HttpInterceptor {
  private readonly trackedActions = [
    'Create',
    'UserCreate',
    'Update',
    'UserUpdate',
    'Delete',
  ];
  private readonly verboseEntities = ['ConfigurationSetting'];

  constructor(private insights: InsightsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let event: IEventTelemetry | null = null;
    let level: SeverityLevel = SeverityLevel.Information;
    const started = Date.now();
    const insightsHeaders = this.getHeaders(request);
    const entityHeader = request.headers.get(insightsHeader('entity'));
    const ignoreHeader = request.headers.get(insightsHeader('ignore'));

    if (ignoreHeader) {
      return next.handle(request);
    }

    if (insightsHeaders.length) {
      let headers = request.headers;
      insightsHeaders.forEach((header) => {
        headers = headers.delete(header.key);
      });
      request = request.clone({
        headers,
      });
    }

    return next.handle(request).pipe(
      tap({
        next: (response) => {
          if (response instanceof HttpResponse) {
            ({ event, level } = this.handleSuccess(
              request,
              response,
              entityHeader,
            ));
          }
        },
        error: (error: HttpErrorResponse) => {
          event = this.handleError(request, error, entityHeader);
          level = SeverityLevel.Error;
        },
      }),
      finalize(() => {
        this.trackEvent(event, level, started, insightsHeaders);
      }),
    );
  }

  private handleSuccess(
    request: HttpRequest<any>,
    response: HttpResponse<any>,
    entityHeader: string | null,
  ): { event: IEventTelemetry | null; level: SeverityLevel } {
    if (request.url.includes('Data.svc')) {
      const action = this.getAction(request);

      if (response.body?.Code === Octopus.ResponseStatusCode.Successful) {
        if (this.trackedActions.includes(action)) {
          const entity = entityHeader ?? this.getEntity(request);

          return {
            event: {
              name: action,
              properties: {
                entity,
              },
            },
            level: this.verboseEntities.includes(entity)
              ? SeverityLevel.Verbose
              : SeverityLevel.Information,
          };
        }
      } else {
        const entity = entityHeader ?? this.getEntity(request);

        return {
          event: {
            name: 'ApiError',
            properties: {
              action,
              entity,
              request: request.body,
              response: response.body,
            },
          },
          level: SeverityLevel.Error,
        };
      }
    }

    return { event: null, level: SeverityLevel.Information };
  }

  private handleError(
    request: HttpRequest<any>,
    error: HttpErrorResponse,
    entityHeader: string | null,
  ): IEventTelemetry | null {
    const action = this.getAction(request);
    const entity = entityHeader ?? this.getEntity(request);

    return {
      name: 'HttpError',
      properties: {
        action,
        entity,
        request: request.body,
        error,
      },
    };
  }

  private getHeaders(
    request: HttpRequest<any>,
  ): KeyValue<string, string | null>[] {
    return request.headers
      .keys()
      .filter((key) => key.startsWith(HeaderPrefix))
      .map((key) => {
        return {
          key,
          value: request.headers.get(key),
        };
      });
  }

  private getAction(request: HttpRequest<any>): string {
    return request.url.substring(request.url.lastIndexOf('/') + 1);
  }

  private getEntity(request: HttpRequest<any>): string {
    return Object.entries(request.body ?? {}).reduce(
      (name, [key, value]: [string, any]) => {
        let entity = '';
        if (value?.Data || value?.Scope) {
          entity = key;
        } else if (value?.FieldOptions) {
          entity = `${key}FieldOptions`;
        }

        if (entity) {
          if (name.length > 0) {
            name += 'And';
          }
          name += entity;
        }

        return name;
      },
      '',
    );
  }

  private trackEvent(
    event: IEventTelemetry | null,
    level: SeverityLevel,
    started: number,
    headers: KeyValue<string, string | null>[],
  ): void {
    if (event) {
      if (event.properties) {
        for (const header of headers) {
          event.properties[header.key.substring(HeaderPrefix.length)] =
            header.value;
        }

        event.properties['level'] = SeverityLevel[level];

        const duration = Date.now() - started;
        event.properties['duration'] =
          duration < 1000 ? `${duration}ms` : `${duration / 1000}s`;
        event.properties['logGroup'] = 'api';
      }

      this.insights.trackEvent(event, level).flush();
    }
  }
}
