/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
  AsyncValidatorFn,
  FormControl,
} from '@angular/forms';

export class FormControlWrapper<T> {
  constructor(
    public control: AbstractControl<T>,
    public controlName: string,
  ) {
    (control as any).setNativeElement = (element: HTMLElement) => {
      (control as any).nativeElement = element;
      if (this.onInit) {
        this.onInit(this);
      }
    };
  }

  onInit?: (control: FormControlWrapper<T>) => void;

  get valid(): boolean {
    return this.control.valid;
  }

  get errorVisible(): boolean {
    return (
      !this.control.valid &&
      (this.control.dirty || this.control.touched) &&
      !this.control.pending
    );
  }

  get errors(): ValidationErrors | null {
    return this.control.errors;
  }

  get value(): T {
    return this.control.value;
  }

  set value(value: T) {
    this.control.setValue(value);
  }

  get touched(): unknown {
    return this.control.touched;
  }

  get nativeElement(): HTMLElement | undefined {
    return (this.control as any).nativeElement;
  }

  focusInput() {
    const nativeElement = this.nativeElement;
    if (nativeElement) {
      nativeElement.querySelector('input')?.focus();
    }
  }

  hasAnyError(...errors: string[]): boolean {
    return errors.some((error) => this.control.hasError(error));
  }

  hasError(error: string): boolean {
    return this.control.hasError(error);
  }

  addValidator(validator: ValidatorFn): void {
    if (validator == null) {
      return;
    }

    const currentValidator = this.control.validator;
    const validators = currentValidator
      ? [currentValidator, validator]
      : [validator];

    this.control.clearValidators();
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

  setValidators(
    validators: ValidatorFn | ValidatorFn[] | null,
    onlyIfDirty = false,
  ): void {
    if (!onlyIfDirty || this.control.dirty) {
      this.control.clearValidators();
      this.control.setValidators(validators);
      this.control.updateValueAndValidity();
    }
  }

  setAsyncValidators(
    validators: AsyncValidatorFn | AsyncValidatorFn[] | null,
    onlyIfDirty = false,
  ): void {
    if (!onlyIfDirty || this.control.dirty) {
      this.control.clearAsyncValidators();
      this.control.setAsyncValidators(validators);
      this.control.updateValueAndValidity();
    }
  }

  reset(): void {
    this.control.reset();
  }

  get(): FormControl<T> {
    return this.control as FormControl<T>;
  }
}
