import { Mapper } from '../../../mappers';
import {
  EntryType,
  InteractionType,
  ListItem,
  RelatedEntry,
  SecurityAccessListItem,
} from '../../../models';
import {
  Case,
  KeyAndDisplay,
  Opportunity,
  User,
  ValueAndName,
  ValueAndDisplay,
  InteractionType as OctopusInteractionType,
  FieldOption,
} from '../models';
import { SecurityAccessItem } from '../models/security-access';

export abstract class ConfigurationMapper<Source, Target> extends Mapper<
  Source,
  Target
> {
  mapInteractionType(source: OctopusInteractionType): InteractionType {
    return {
      type: source.Key,
      icon: source.Icon,
      display: source.DisplayValue,
      external: source.External,
    };
  }

  mapValueAndDisplay<T>(source: ValueAndDisplay<T>): ListItem<T> {
    return {
      id: source.Value,
      name: source.DisplayValue,
    };
  }

  mapValueAndName<T>(source: ValueAndName<T>): ListItem<T> {
    return {
      id: source.Value,
      name: source.Name,
    };
  }

  mapKeyAndDisplay(source: KeyAndDisplay): ListItem<string> {
    return {
      id: source.Key,
      name: source.DisplayValue,
    };
  }

  mapFieldOption(source: FieldOption): ListItem<string> {
    return {
      id: source.Key ?? '',
      name: source.DisplayValue,
    };
  }

  mapRelatedEntry(type: EntryType, source: KeyAndDisplay): RelatedEntry {
    return {
      type,
      id: source.Key,
      display: source.DisplayValue,
    };
  }

  mapRelatedCase(source: Case): RelatedEntry {
    return {
      type: 'case',
      id: source.Key,
      display: `${source.CaseNumber} ${source.Subject}`,
    };
  }

  mapRelatedUser(source: User): RelatedEntry {
    return {
      type: 'user',
      id: source.Key.Uid,
      display: source.DisplayValue,
    };
  }

  mapRelatedOpportunity(source: Opportunity): RelatedEntry {
    return {
      type: 'opportunity',
      id: source.Key,
      display: source.Objective,
    };
  }

  mapSecurityAccess(source: SecurityAccessItem): SecurityAccessListItem {
    return {
      key: source.Key.Value ?? '',
      id: source.Key.Uid,
      name: source.DisplayValue,
    };
  }
}
