import {
  Rule,
  RuleAction,
  RuleSet,
  SelectorField,
} from '@maximizer/core/shared/domain';
import { LayoutFormGroup } from '../classes/layout-form-group';
import { LayoutFormControl } from '../classes/layout-form-control';

export interface RuleSetHandler {
  applyRuleSet(): void;
}

export class OctopusRuleSetHandler implements RuleSetHandler {
  ruleHandlers: OctopusRuleHandler[] = [];

  constructor(
    private readonly form: LayoutFormGroup,
    private readonly ruleSet: RuleSet | null,
  ) {}

  applyRuleSet(): void {
    if (this.ruleSet) {
      for (const rule of this.ruleSet.rules) {
        const handler = new OctopusRuleHandler(this.form, rule);
        this.ruleHandlers.push(handler);
        handler.evaluate();
      }
    }
  }
}

class OctopusRuleHandler {
  constructor(
    private readonly form: LayoutFormGroup,
    private readonly rule: Rule,
  ) {
    for (const field of Object.keys(rule.test)) {
      const control = this.form.getControl(field);
      if (control) {
        control.valueChanges.subscribe(() => this.evaluate());
      }
    }
  }

  evaluate(): void {
    const test = Object.entries(this.rule.test).reduce(
      (valid, [field, conditions]) => {
        const control = this.form.getControl(field);
        if (control instanceof LayoutFormControl) {
          let controlValue = control.value ?? '';

          if (control.field.type === 'selector') {
            const selector = control.field as SelectorField<unknown>;
            const option = selector.options.find(
              (option) =>
                option.id === controlValue || option.name === controlValue,
            );
            controlValue = option?.name ?? option?.id ?? controlValue;
          }

          const isValid = Object.entries(conditions).every(
            ([operator, value]) => {
              return this.assertCondition(operator, controlValue, value);
            },
          );
          return valid && isValid;
        }
        return false;
      },
      true,
    );

    if (test) {
      this.doActions();
    }
  }

  private assertCondition(
    operator: string,
    currentValue: unknown,
    targetValue: unknown,
  ): boolean {
    switch (operator) {
      case '$EQ':
        return currentValue === targetValue;
      case '$NE':
        return currentValue !== targetValue;
      case '$IN':
      case '$NIN': {
        const current = Array.isArray(currentValue)
          ? currentValue.map((value) => value.name ?? value.id ?? value)
          : [currentValue];
        const target = Array.isArray(targetValue) ? targetValue : [targetValue];

        return operator === '$IN'
          ? target.every((value) => current.includes(value))
          : target.every((value) => !current.includes(value));
      }

      default:
        return false;
    }
  }

  private doActions(): void {
    for (const action of this.rule.actions) {
      if (action.field === 'KeyFieldList') {
        this.doKeyFieldListAction(action);
      } else {
        this.doFieldAction(action);
      }
    }
  }

  private doFieldAction(action: RuleAction) {
    const control = this.form.getControl(action.field);

    switch (action.cmd) {
      case 'setValue':
        control?.setValue(action.value);
        break;

      case 'show':
        control?.show();
        break;

      case 'hide':
        control?.hide();
        break;

      case 'enable':
        control?.enable();
        break;

      case 'disable':
        control?.disable();
        break;

      default:
        break;
    }
  }

  private doKeyFieldListAction(action: RuleAction) {
    switch (action.cmd) {
      case 'setValue':
        this.form.setLayout.next(action.value as string);
        break;
      case 'disable':
        this.form.disableLayoutSelector.next(true);
        break;
      case 'enable':
        this.form.disableLayoutSelector.next(false);
        break;
    }
  }
}
