import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  ContextService,
  SystemService,
} from '@maximizer/core/shared/data-access';
import { catchError } from 'rxjs';
import { SystemInfo } from '@maximizer/core/shared/domain';

@Component({
  selector: 'maximizer-outlook-compose-warning',
  templateUrl: './compose-warning.component.html',
})
export class OutlookComposeWarningComponent implements OnInit {
  private _debugOverrideVersion = false;
  isVersionValid = false;

  maximizerUrl = '';
  systemInfo?: SystemInfo;
  loading = true;

  eventType: 'fail' | 'success' | undefined;
  @Output() syncConfigEmitter: EventEmitter<{
    isVersionValid: boolean;
  }> = new EventEmitter();

  constructor(
    private systemInfoService: SystemService,
    private contextService: ContextService,
  ) {}

  ngOnInit() {
    this.maximizerUrl = this.contextService.website;
    this.validateComposeSyncConfig();
  }

  private validateComposeSyncConfig() {
    this.systemInfoService
      .getInfo()
      .pipe(
        catchError(async () => {
          return undefined;
        }),
      )
      .subscribe((data) => {
        if (!data) {
          this.emitInvalidEvent();
          return;
        }

        this.validateDbVersion(data);

        if (!this.isVersionValid) {
          this.emitInvalidEvent();
          return;
        }

        this.syncConfigEmitter.emit({ isVersionValid: true });
      });
  }

  /**
   * OutlookSync API requires 2024M7 for authentication
   * Major 2 Minor 45 equals 2024M7
   */
  private validateDbVersion(systemInfo: SystemInfo): void {
    if (this._debugOverrideVersion) {
      this.isVersionValid = true;
    } else {
      if (!systemInfo) {
        this.isVersionValid = false;
        return;
      }

      this.isVersionValid =
        systemInfo.VersionMajor > 2 ||
        (systemInfo.VersionMajor === 2 && systemInfo.VersionMinor >= 45);
    }
  }


  private emitInvalidEvent() {
    this.loading = false;
    this.syncConfigEmitter.emit({ isVersionValid: false });
  }
}
