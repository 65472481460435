import { getFontAwesomeIcon, getMaximizerIcon, getSvgIcon } from './icon-name';
import { Octopus } from '@maximizer/core/shared/domain';
export const InteractionIcons: { [value: number | string]: string } = {
  18: getFontAwesomeIcon('mug-saucer', 'solid'),
  34: getFontAwesomeIcon('envelope'),
  42: getFontAwesomeIcon('tags', 'solid'),
  59: getFontAwesomeIcon('video', 'solid'),
  122: getFontAwesomeIcon('square-twitter', 'brand'),
  123: getFontAwesomeIcon('square-facebook', 'brand'),
  133: getFontAwesomeIcon('linkedin', 'brand'),
  150: getFontAwesomeIcon('rss', 'solid'),
  219: getFontAwesomeIcon('utensils', 'solid'),
  237: getFontAwesomeIcon('desktop', 'solid'),
  245: getFontAwesomeIcon('circle', 'solid'),
  321: getFontAwesomeIcon('thumbs-up', 'solid'),
  430: getFontAwesomeIcon('paper-plane', 'solid'),
  437: getFontAwesomeIcon('share-nodes', 'solid'),
  512: getFontAwesomeIcon('whatsapp', 'brand'),
  534: getFontAwesomeIcon('note-sticky', 'solid'),
  602: getFontAwesomeIcon('hashtag', 'solid'),
  635: getFontAwesomeIcon('handshake'),
  643: getFontAwesomeIcon('circle-user', 'solid'),
  646: getFontAwesomeIcon('id-badge'),
  677: getSvgIcon('docusign'),
  678: getSvgIcon('zendesk'),
  [Octopus.DefaultInteractions.CALL]: getFontAwesomeIcon('phone', 'solid'),
  [Octopus.DefaultInteractions.EMAIL]: getFontAwesomeIcon('envelope', 'solid'),
  [Octopus.DefaultInteractions.APPOINTMENT]: getFontAwesomeIcon('calendar'),
  [Octopus.DefaultInteractions.TASK]: getFontAwesomeIcon(
    'circle-check',
    'solid',
  ),
  [Octopus.DefaultInteractions.SMS]: getFontAwesomeIcon('comments', 'solid'),
  [Octopus.DefaultInteractions.CHAT]: getFontAwesomeIcon(
    'comment-dots',
    'solid',
  ),
  [Octopus.DefaultInteractions.NOTE]: getFontAwesomeIcon(
    'note-sticky',
    'solid',
  ),
  [Octopus.DefaultInteractions.DOCUMENT]: getMaximizerIcon('document-uploaded'),
  [Octopus.RecurringInteractions.Task]: getMaximizerIcon('recurring-task'),
  [Octopus.RecurringInteractions.Appointment]: getMaximizerIcon(
    'recurring-appointment',
  ),
};
