import { ListItem } from '../../../models';
import { NoteFieldOptionsResponse } from '../models';
import { ConfigurationMapper } from './configuration';

export class NoteMapper extends ConfigurationMapper<
  NoteFieldOptionsResponse,
  ListItem<string>[]
> {
  from(source: NoteFieldOptionsResponse): ListItem<string>[] {
    return source.Note?.FieldOptions?.Type?.map(
      type => this.mapKeyAndDisplay(type)
    ) ?? [];
  }
}
