import { Mapper } from '../../../mappers';
import { Link } from '../../../models';
import { CoachingDocumentSetup } from '../models/coaching-document-setup';

export class CoachingLinkMapper extends Mapper<CoachingDocumentSetup, Link> {
  from(source: CoachingDocumentSetup): Link {
    return {
      id: source.Key || '',
      title: source.Name,
      description: source.Description,
      url: source.FileUrl,
      processId: source.SalesProcessSetupKey,
      stageId: source.SalesStageSetupKey
    };
  }
}