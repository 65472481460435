export interface Response {
  Code: ResponseStatusCode;
  Msg?: string[] | ResponseError[];
}

export interface ResponseError {
  Message: string;
  ErrorCode: number;
  Trace: string;
}

export interface DataResponse<T> {
  Data: T[];
}

export interface PaginationResponse {
  NextPageKey: string;
}

export interface WriteResponse<T> {
  Data: T;
}

export interface ValidationResponse<T> extends WriteResponse<T> {
  Validation: {
    [K in keyof T]?: {
      Mandatory?: boolean;
    };
  };
}

export enum ResponseStatusCode {
  Successful = 0,
  Unsuccessful = -1,
  InvalidAuthentication = -2,
  AccessDenied = -3,
  Query = -4,
  Access = -5,
  Data = -6,
  Transformation = -7,
  Throttle = -8,
}
