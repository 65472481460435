import {
  EntityEvent,
  InteractionType,
  ListItem,
  Octopus,
} from '@maximizer/core/shared/domain';

export interface OutlookTimelineFilter {
  interactions: string[];
  notes: number[];
}

export interface OutlookTimelineConfiguration {
  types: InteractionType[];
  notes: ListItem<number>[];
  documents: ListItem<number>[];
}

export interface OutlookTimelineEvent extends EntityEvent {
  subject?: string;
  description?: string;
  important?: boolean;
  date: { value: Date; display: string };
  interactionType?: InteractionType;
  noteType?: Octopus.ValueAndDisplay<number>;
  creator: { value: string; display: string };
  entriesDisplay?: string;
  direction: OutlookTimelineDirection;
}

export enum OutlookTimelineDirection {
  None = 0,
  Incoming = 1,
  Outgoing = 2,
}

export interface OutlookTimelineFilterConfiguration {
  users?: [];
  items?: [];
}
