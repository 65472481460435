import { Address, AddressFieldsEditableListFields } from './address';
import { Email, EmailFieldsEditableListFields } from './email';
import { Entity, EntityFields } from './entity';
import { EntryKey } from './entry-key';
import { FieldOptions, FieldOptionsResponse } from './field-options';
import { KeyAndDisplay, TypedKeyAndDisplay } from './key-and-display';
import { Phone, PhoneFieldsEditableListFields } from './phone';
import {
  ReadRequest,
  Request,
  RequestConfiguration,
  WriteRequest,
} from './request';
import { DataResponse, Response, WriteResponse } from './response';
import { SecurityStatus } from './security-status';
import { ValueAndDisplay } from './value-and-display';

export class AbEntryType {
  static readonly Contact = 'Contact';
  static readonly Company = 'Company';
  static readonly Individual = 'Individual';
}

export const AddressBookFieldsMaxValue = 9999999999;

export enum AbEntryFields {
  Address = 'Address',
  AccountManager = 'AccountManager',
  CompanyName = 'CompanyName',
  Creator = EntityFields.Creator,
  Department = 'Department',
  Division = 'Division',
  Email = 'Email',
  Email1 = 'Email1',
  Email2 = 'Email2',
  Email3 = 'Email3',
  FirstName = 'FirstName',
  FullName = 'FullName',
  Key = EntityFields.Key,
  Industry = 'Udf/$TYPEID(57994)',
  LastContactDate = 'LastContactDate',
  LastName = 'LastName',
  MaxExchangeDist = 'MaxExchangeDist',
  MiddleName = 'MiddleName',
  MrMs = 'MrMs',
  ParentKey = 'ParentKey',
  Parent = 'Parent',
  Partner = 'Partner',
  PartnerKey = 'PartnerKey',
  Position = 'Position',
  Phone = 'Phone',
  Phone1 = 'Phone1',
  Phone2 = 'Phone2',
  Phone3 = 'Phone3',
  Phone4 = 'Phone4',
  Salutation = 'Salutation',
  Territory = 'Territory',
  TerritoryKey = 'TerritoryKey',
  TerritoryStatus = 'TerritoryStatus',
  Type = 'Type',
  WebSite = 'WebSite',
  Starred = 'Starred',
}

export interface AbEntry extends Entity {
  [EntityFields.Key]: string | null;
  [AbEntryFields.Type]: string;
  [AbEntryFields.CompanyName]: string;
  [EntityFields.DisplayValue]: string;
  [AbEntryFields.MrMs]: string;
  [AbEntryFields.FirstName]: string;
  [AbEntryFields.MiddleName]: string;
  [AbEntryFields.LastName]: string;
  [AbEntryFields.FullName]: string;
  [AbEntryFields.Position]: string;
  [AbEntryFields.Salutation]: string;
  [AbEntryFields.Department]: string;
  [AbEntryFields.Division]: string;
  [AbEntryFields.Phone]: Phone;
  [AbEntryFields.Phone1]: Phone;
  [AbEntryFields.Phone2]: Phone;
  [AbEntryFields.Phone3]: Phone;
  [AbEntryFields.Phone4]: Phone;
  [AbEntryFields.Email]: Email;
  [AbEntryFields.Email1]: Email;
  [AbEntryFields.Email2]: Email;
  [AbEntryFields.Email3]: Email;
  [AbEntryFields.ParentKey]: EntryKey | null;
  [AbEntryFields.Parent]: AbEntry | null;
  [AbEntryFields.Address]: Address;
  [AbEntryFields.AccountManager]: KeyAndDisplay[];
  [EntityFields.SecStatus]: SecurityStatus;
  [EntityFields.Creator]: KeyAndDisplay;
  [EntityFields.CreationDate]: string;
  [EntityFields.ModifiedBy]: KeyAndDisplay;
  [EntityFields.LastModifyDate]: string;
  [AbEntryFields.WebSite]: string;
  [AbEntryFields.LastContactDate]: ValueAndDisplay<string>;
  [AbEntryFields.PartnerKey]: string;
  [AbEntryFields.Industry]: KeyAndDisplay;
}
export const AbEntryFieldsEditableListFields = {
  [AbEntryFields.CompanyName]: 60014,
  [AbEntryFields.MrMs]: 60005,
  [AbEntryFields.Salutation]: 60001,
  [AbEntryFields.Position]: 60006,
  [AbEntryFields.Department]: 60011,
  [AbEntryFields.Division]: 60012,
  [AbEntryFields.Phone1]: PhoneFieldsEditableListFields,
  [AbEntryFields.Phone2]: PhoneFieldsEditableListFields,
  [AbEntryFields.Phone3]: PhoneFieldsEditableListFields,
  [AbEntryFields.Phone4]: PhoneFieldsEditableListFields,
  [AbEntryFields.Email1]: EmailFieldsEditableListFields,
  [AbEntryFields.Email2]: EmailFieldsEditableListFields,
  [AbEntryFields.Email3]: EmailFieldsEditableListFields,
  [AbEntryFields.Address]: AddressFieldsEditableListFields,
};
export interface AbRecentItems {
  Items: Array<AbRecentEntry>;
  Key: string;
  Length: number;
}

export interface AbRecentEntry {
  AbEntry: AbEntry;
}

export interface AbEntryReadRequest extends Request {
  AbEntry: ReadRequest<AbEntry>;
}

export interface AbEntryWriteRequest extends Request {
  AbEntry: WriteRequest<AbEntry>;
}

export interface AbEntryResponse extends Response {
  AbEntry: DataResponse<AbEntry>;
}

export interface AbEntryRecentResponse extends Response {
  RecentEntry: DataResponse<AbRecentItems>;
}

export interface AbEntryRecentReadRequest extends Request {
  RecentEntry: ReadRequest<AbRecentItems>;
}

export interface AbEntryWriteResponse extends Response {
  AbEntry: WriteResponse<AbEntry>;
}

export const AbEntryReadDriver: RequestConfiguration = {
  Drivers: {
    IAbEntrySearcher: 'Maximizer.Model.Access.Sql.AbEntrySearcher',
  },
};

export interface AbEntryFieldOptions {
  'Phone/Description': TypedKeyAndDisplay<string>;
  'Email/Description': TypedKeyAndDisplay<string>;
}

export interface AbEntryFieldOptionsRequest extends Request {
  AbEntry: FieldOptions<AbEntryFieldOptions>;
}

export interface AbEntryFieldOptionsResponse extends Response {
  AbEntry: FieldOptionsResponse<AbEntryFieldOptions>;
}
