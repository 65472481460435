import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  DocumentService,
  GlobalStore,
  SessionStorageService,
} from '@maximizer/core/shared/data-access';
import {
  AbEntrySearch,
  CDN_URL,
  DocumentType,
  LeadSearch,
  LeadStatus,
  CreateDocument,
  Security,
} from '@maximizer/core/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { PagerSettings } from '@progress/kendo-angular-listview';
import { NavigationEnd, Router } from '@angular/router';
import {
  AbEntrySearchService,
  LeadSearchService,
} from '@maximizer/core/global-search/data-access';
import { catchError, finalize, firstValueFrom, forkJoin, of } from 'rxjs';
import {
  AbEntryService,
  LeadService,
  OutlookService,
  OutlookStore,
} from '@maximizer/outlook/shared/data-access';
import { TranslateService } from '@ngx-translate/core';
import {
  CaseCard,
  OpportunityCard,
  OutlookAbEntryDetails,
  OutlookEmail,
  OutlookLeadSearch,
  OutlookSaveEmail,
} from '@maximizer/outlook/shared/domain';
import { DataResult, State, process } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { isNullOrEmpty } from '@maximizer/shared/util';
import { OpportunityService } from '@maximizer/outlook/opportunity/data-access';
import { CaseService } from '@maximizer/outlook/case/data-access';

@Component({
  selector: 'maximizer-outlook-read',
  templateUrl: './read.component.html',
})
export class OutlookReadComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @ViewChild(TooltipDirective)
  tooltips?: TooltipDirective;

  get inMaximizerDataResult(): DataResult {
    return process(this.inMaximizer, this.state);
  }

  newEntries: string[] = [];

  pageTitle = '';
  deepLink = '';
  resourceName = '';

  showSkeleton = true;
  showAutocompleteSkeleton = false;
  saveEmailLoading = false;
  showPopup = false;
  loadingRelated = false;

  previousAutocompleteInput = '';
  autocompleteInput = '';
  abEntrySelectedList = [];
  popupEntryList: OutlookEmail[] = [];

  outlookEmails: OutlookEmail[] = [];

  notInMaximizer: OutlookEmail[] = [];
  inMaximizer: OutlookEmail[] = [];
  otherInMaximizer: OutlookEmail[] = [];
  hasAbEntries?: boolean;

  relatedSavedEmailMessage = '';
  opportunities: OpportunityCard[] = [];
  cases: CaseCard[] = [];

  related = {
    opportunity: {
      hasEntries: false,
      emailCount: 0,
      savedEmails: [] as OpportunityCard[],
    },
    case: {
      hasEntries: false,
      emailCount: 0,
      savedEmails: [] as CaseCard[],
    },
  };

  userRights?: {
    opportunity: Security;
    customerService: Security;
  };

  relatedTitle = this.translate.instant('outlook.main.related.noRelatedTitle');
  showRelatedSection = true;

  timeoutForChange = 0;

  pageSize = 4;
  pagerSettingsNotInMax: PagerSettings | boolean = false;
  pagerSettingsInMax: PagerSettings | boolean = false;
  defaultPagerSettings: PagerSettings = {
    position: 'bottom',
    pageSizeValues: false,
    info: false,
    previousNext: true,
    type: 'numeric',
    buttonCount: 4,
  };

  state: State = {
    skip: 0,
    take: 4,
  };

  addMenuItems: { id: string; icon: string; route: string }[] = [
    {
      id: 'individual',
      route: '/abentry/add/individual',
      icon: 'fa-icons icon-regular icon-18 icon-building-user',
    },
    {
      id: 'contact',
      route: '/abentry/add/contact',
      icon: 'fa-icons icon-solid icon-18 icon-user',
    },
    {
      id: 'lead',
      route: '/lead/add',
      icon: 'fa-icons icon-solid icon-18 icon-user-magnifying-glass',
    },
  ];

  constructor(
    @Inject(CDN_URL) public cdn: string,
    private documentService: DocumentService,
    private abentrySearchService: AbEntrySearchService,
    private leadSearchService: LeadSearchService,
    private leadService: LeadService,
    private router: Router,
    private translate: TranslateService,
    private abentryService: AbEntryService,
    private storage: SessionStorageService,
    private outlookService: OutlookService,
    private opportunityService: OpportunityService,
    private caseService: CaseService,
    public globalStore: GlobalStore,
    public outlookStore: OutlookStore,
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event: unknown) => {
      if (!(event instanceof NavigationEnd)) return;
      if (event.urlAfterRedirects.startsWith('/read')) {
        const newItems = this.storage.get(
          this.outlookService.storagePartitionKey + 'new-item',
        );
        if (!newItems) return;
        const newEntriesArray = JSON.parse(newItems);
        if (this.newEntries.length !== newEntriesArray?.length) {
          const emails = this.outlookService.getReadEmails();
          this.initialize(emails);
        }
      }
    });

    this.outlookService.updateEmails().then(() => {
      const emails = this.outlookService.getReadEmails();
      this.initialize(emails);
    });

    this.setUserRights();
  }

  initialize(emails: OutlookEmail[]) {
    if (emails.length === 0) {
      this.showSkeleton = false;
      return;
    }

    const lowerCaseEmails = emails.map((o) => {
      o.emailAddress = o.emailAddress.toLocaleLowerCase();
      return o;
    });
    this.outlookEmails = [...lowerCaseEmails];

    this.loadEmailsInMaximizer();
    this.loadSavedToOthersInMaximizer();
  }

  setUserRights(): void {
    const user = this.globalStore.session()?.user;

    if (!user) {
      return;
    }

    this.userRights = {
      opportunity: user.rights.opportunities,
      customerService: user.rights.customerService,
    };
  }

  resetAllProps() {
    this.showSkeleton = true;
    this.disableSaveEmailAllEntries = false;
    this.saveEmailFromPopupLoading = false;
    this.saveEmailLoading = false;
    this.showAutocompleteSkeleton = false;
    this.notInMaximizer = [];
    this.inMaximizer = [];
    this.otherInMaximizer = [];
    this.pagerSettingsInMax = false;
    this.pagerSettingsNotInMax = false;
  }

  async loadSavedToOthersInMaximizer() {
    const entries = await this.outlookService.getSavedEmails();
    if (!entries?.othersInMaximizer) return;
    const abentries =
      entries.othersInMaximizer
        .filter((o) => o.type === 'abentry')
        .map((o) => o.id ?? '') ?? [];
    const leads =
      entries.othersInMaximizer
        .filter((o) => o.type === 'lead')
        .map((o) => o.id ?? '') ?? [];

    forkJoin({
      abentries:
        abentries.length === 0
          ? of([])
          : this.abentryService.getByKeys(abentries),
      leads: leads.length === 0 ? of([]) : this.leadService.getKeys(leads),
    })
      .pipe(catchError(async (error) => this.showLoadError(error)))
      .subscribe((data) => {
        if (data.abentries.length === 0 && data.leads.length === 0) {
          return;
        }
        this.populateOthersInMaximizer(entries, data.abentries, data.leads);
      });
  }

  private populateOthersInMaximizer(
    entries: OutlookSaveEmail,
    abentries: OutlookAbEntryDetails[],
    leads: OutlookLeadSearch[],
  ) {
    const maxAbentries = this.mapAbEntriesToOutlookEmail(abentries);
    maxAbentries.forEach((o) => {
      o.emailSaved = true;
      const found = entries.othersInMaximizer.find((ab) => ab.id === o.id);
      if (found) o.emailKey = found.emailKey;
    });
    const maxLeads = this.mapLeadsToOutlookEmail(leads);
    maxLeads.forEach((o) => {
      const found = entries.othersInMaximizer.find((ab) => ab.id === o.id);
      if (found) o.emailKey = found.emailKey;
      o.emailSaved = true;
    });
    const allEntries = maxAbentries.concat(maxLeads);
    this.otherInMaximizer = [...allEntries];
  }

  loadEmailsInMaximizer() {
    const emails = this.outlookEmails.map((o) => o.emailAddress);
    if (emails.length === 0) {
      this.showSkeleton = false;
      return;
    }
    this.showSkeleton = true;
    forkJoin({
      abentries: this.abentryService.getByEmails(emails),
      leads: this.leadService.getByEmails(emails),
    })
      .pipe(
        catchError(async (error) => this.showLoadError(error)),
        finalize(() => {
          this.mapNotInMaximizer();
          setTimeout(() => {
            this.showSkeleton = false;
          }, this.timeoutForChange);
        }),
      )
      .subscribe(async (data) => {
        if (data.abentries.length === 0 && data.leads.length === 0) {
          return;
        }
        await this.populateInMaximizer(emails, data);
      });
  }

  private async populateInMaximizer(
    emails: string[],
    data: { abentries: OutlookAbEntryDetails[]; leads: OutlookLeadSearch[] },
  ) {
    let abentries: OutlookEmail[] = this.mapAbEntriesToOutlookEmail(
      data.abentries,
    );

    const leads: OutlookEmail[] = this.mapLeadsToOutlookEmail(data.leads);

    if (leads.length > 0) {
      abentries = abentries.concat(leads);
    }
    if (abentries.length > 4) {
      this.pagerSettingsInMax = { ...this.defaultPagerSettings };
    }
    abentries.sort(
      (a, b) => emails.indexOf(a.emailAddress) - emails.indexOf(b.emailAddress),
    );

    this.mapEntriesWithNew(abentries);
    this.getSavedEmails();
    this.inMaximizer = [...this.inMaximizer];

    this.hasAbEntryTypes();
  }

  hasAbEntryTypes() {
    this.hasAbEntries = this.inMaximizer.some(
      (item) => item.type === 'abentry',
    );

    if (this.hasAbEntries) {
      const abEntryIds = this.inMaximizer
        .filter((entry) => entry.type === 'abentry')
        .map((entry) => entry.id ?? '');

      this.outlookStore.updateInMaximizerIdList(abEntryIds);
      this.readRelatedEntries(abEntryIds);
    }
  }

  readRelatedEntries(keys: string[]): void {
    this.loadingRelated = true;
    forkJoin({
      opportunities: this.opportunityService
        .getOpportunityByContactId(keys)
        .pipe(
          catchError((error) => {
            console.error('Error fetching opportunities:', error);
            return of([]);
          }),
        ),
      cases: this.caseService.getCaseByContactId(keys).pipe(
        catchError((error) => {
          console.error('Error fetching cases:', error);
          return of([]);
        }),
      ),
    })
      .pipe(
        finalize(() => {
          this.loadingRelated = false;
        }),
      )
      .subscribe((result) => {
        if (result.opportunities.length) {
          this.related.opportunity.hasEntries = true;
          this.opportunities = result.opportunities;
        }
        if (result.cases.length) {
          this.related.case.hasEntries = true;
          this.cases = result.cases;
        }

        if (
          this.related.opportunity.hasEntries ||
          this.related.case.hasEntries
        ) {
          this.getOutlookSavedOpportunitiesAndCases();
        }

        this.renderRelatedSection();
      });
  }

  async getOutlookSavedOpportunitiesAndCases() {
    let allEmails: { opportunities: string[]; cases: string[] } = {
      opportunities: [],
      cases: [],
    };

    try {
      allEmails = await this.outlookService.getSavedEmails();

      for (const opportunitiesFromOutlook of allEmails.opportunities) {
        const entryFromMaximizerIndex = this.opportunities.findIndex(
          (email) => email.key === opportunitiesFromOutlook,
        );
        if (entryFromMaximizerIndex === -1) continue;
        this.related.opportunity.emailCount++;
      }

      for (const casesFromOutlook of allEmails.cases) {
        const entryFromMaximizerIndex = this.cases.findIndex(
          (email) => email.key === casesFromOutlook,
        );
        if (entryFromMaximizerIndex === -1) continue;
        this.related.case.emailCount++;
      }
    } catch (error) {
      console.error('Error retrieving saved emails:', error);
    } finally {
      if (
        this.related.opportunity.emailCount === 1 ||
        this.related.case.emailCount === 1
      ) {
        this.populateSingleRelatedSavedEmail(
          allEmails.opportunities,
          allEmails.cases,
        );
      }

      this.updateRelatedSavedEmailMessage();
    }
  }

  populateSingleRelatedSavedEmail(
    opportunities: string[],
    cases: string[],
  ): void {
    if (this.related.opportunity.emailCount === 1 && opportunities.length > 0) {
      const matchedOpportunity = this.opportunities.find(
        (email) => email.key === opportunities[0],
      );
      if (matchedOpportunity) {
        this.related.opportunity.savedEmails.push(matchedOpportunity);
      }
    } else if (this.related.case.emailCount === 1 && cases.length > 0) {
      const matchedCase = this.cases.find((email) => email.key === cases[0]);
      if (matchedCase) {
        this.related.case.savedEmails.push(matchedCase);
      }
    }
  }

  private async getSavedEmails() {
    const allEmails = await this.outlookService.getSavedEmails();

    for (const entryFromOutlook of allEmails.inMaximizer) {
      const entryFromMaximizerIndex = this.inMaximizer.findIndex(
        (o) => o.id === entryFromOutlook.id,
      );
      if (entryFromMaximizerIndex === -1) continue;

      this.inMaximizer[entryFromMaximizerIndex].emailSaved = true;
    }

    if (this.inMaximizer.every((o) => o.emailSaved)) {
      this.disableSaveEmailAllEntries = true;
    }
  }

  navigateToAdd(event: { route: string }, email: string, name: string) {
    this.tooltips?.hide();
    this.router.navigate([event.route], { queryParams: { email, name } });
  }

  navigateToEntry(id: string, type: string) {
    this.tooltips?.hide();
    this.router.navigate(['/' + type + '/' + id]);
  }

  navigateToRelated(): void {
    this.router.navigate(['/related']);
  }

  getEntryType(type: string, abentryType?: string) {
    if (type === 'lead') return 'lead';
    return abentryType;
  }

  async saveEmailForEntry(entry: OutlookEmail) {
    if (entry.id === undefined) return;

    try {
      this.saveEmailLoading = true;

      const emlFile = await this.outlookService.getReadEmlFile();
      if (!emlFile) throw new Error('Unable to get eml file');

      const documentId = await this.createEmailInMaximizer(emlFile, entry.id);
      if (!documentId) {
        this.showSaveEmailErrorNotification(false);
        return;
      }

      entry.emailSaved = true;
      this.showSaveEmailSuccessNotification(false);
      await this.outlookService.saveKeysInOutlook([entry], false);
    } catch (error) {
      console.error('Error', error);
      this.showSaveEmailErrorNotification(false);
    } finally {
      this.saveEmailLoading = false;
    }
  }

  disableSaveEmailAllEntries = false;
  async saveEmailForAllEntries() {
    try {
      this.saveEmailLoading = true;
      const toSaveEntries = this.inMaximizer.filter((o) => !o.emailSaved);
      if (toSaveEntries.length === 0) return;

      const emlFile = await this.outlookService.getReadEmlFile();

      if (!emlFile) {
        throw new Error('Unable to get eml file');
      }

      const createdEmails: { parentKey: string; documentId: string }[] = [];
      const failedEmails = [];
      for (const entry of toSaveEntries) {
        if (!entry.id) continue;
        const documentId = await this.createEmailInMaximizer(emlFile, entry.id);
        if (documentId != null) {
          createdEmails.push({ parentKey: entry.id, documentId });
        } else {
          failedEmails.push(entry.id);
        }
      }

      if (failedEmails.length > 0) {
        this.showSaveEmailErrorNotification(true);
        return;
      }

      this.inMaximizer
        .filter((element) =>
          createdEmails.find((o) => o.parentKey === element.id),
        )
        .forEach((element) => {
          element.emailSaved = true;
        });
      this.inMaximizer = [...this.inMaximizer];
      this.showSaveEmailSuccessNotification(true);
      if (failedEmails.length === 0) {
        this.disableSaveEmailAllEntries = true;
      }
      await this.outlookService.saveKeysInOutlook(this.inMaximizer, false);
    } catch (error) {
      console.error('Error', error);
      this.showSaveEmailErrorNotification(true);
    } finally {
      this.saveEmailLoading = false;
    }
  }

  inMaximizerPageChange(event: PageChangeEvent) {
    this.state.skip = event.skip;
    this.state.take = event.take;
  }

  saveEmailFromPopupLoading = false;
  async saveEmailFromPopup(entry: OutlookEmail) {
    try {
      if (!entry.id) return;

      this.saveEmailFromPopupLoading = true;
      let index = -1;
      const existsInMaximizerList = this.inMaximizer.find((item, idx) => {
        if (item.id === entry.id) {
          index = idx;
          return true;
        }
        return false;
      });

      const emlFile = await this.outlookService.getReadEmlFile();
      if (!emlFile) throw new Error('Unable to get eml file');

      const documentId = await this.createEmailInMaximizer(emlFile, entry.id);

      if (!documentId) {
        this.showSaveEmailErrorNotification(false);
        return;
      }

      entry.emailSaved = true;
      this.showPopup = false;
      this.autocompleteInput = '';

      if (existsInMaximizerList) {
        existsInMaximizerList.emailSaved = true;
        existsInMaximizerList.emailKey = documentId;
        this.inMaximizer = [...this.inMaximizer];
        await this.outlookService.saveKeysInOutlook(
          [existsInMaximizerList],
          false,
        );

        const page = Math.floor(index / 4);
        if (!(this.state.skip === 0 && page === 0)) {
          this.state.skip = page * 4;
        }
      } else {
        this.otherInMaximizer.push(entry);
        entry.emailKey = documentId;
        await this.outlookService.saveKeysInOutlook([entry], true);
        this.otherInMaximizer = [...this.otherInMaximizer];
      }

      this.showSaveEmailSuccessNotification(false);
    } catch (error) {
      console.error('Error', error);
      this.showSaveEmailErrorNotification(false);
    } finally {
      this.saveEmailFromPopupLoading = false;
    }
  }

  handleAutocompleteValueChange(inputText: string) {
    if (this.previousAutocompleteInput != inputText) {
      this.previousAutocompleteInput = inputText;
    }
    if (inputText.length < 3) {
      this.showAutocompleteSkeleton = false;
      return;
    }

    this.showAutocompleteSkeleton = true;
    this.showPopup = true;
    this.popupEntryList = [];

    setTimeout(() => {
      if (this.previousAutocompleteInput !== inputText) return;
      this.loadAutocompleteData(inputText);
    }, 1000);
  }

  @ViewChild('popup', { read: ElementRef })
  public popup?: ElementRef;
  @HostListener('document:click', ['$event'])
  public handleClickOutsidePopup(event: KeyboardEvent): void {
    try {
      if (!event.target) return;
      const el = event.target as HTMLElement;
      if (el.id === 'saveOtherEntryButton') return;
      if (!this.popup?.nativeElement.contains(event.target)) {
        this.handleLeavePopup();
      }
    } catch (error) {
      console.error('Click outside', error);
    }
  }

  handleLeavePopup() {
    if (this.saveEmailFromPopupLoading) return;
    this.showAutocompleteSkeleton = false;
    this.showPopup = false;
  }

  private loadAutocompleteData(inputText: string) {
    forkJoin({
      abentries: this.abentrySearchService.search(inputText, true),
      leads: this.leadSearchService.search(inputText, true),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
          return { abentries: [], leads: [] };
        }),
        finalize(() => (this.showAutocompleteSkeleton = false)),
      )
      .subscribe((data) => {
        let mappedData: OutlookEmail[] = [];

        let companies: OutlookEmail[] = [];
        if (data.abentries.length > 0) {
          const mappedAbEntries = this.mapAbEntrySearchToOutlookEmail(
            data.abentries,
          );
          const notCompany: OutlookEmail[] = mappedAbEntries.filter(
            (o) => o.abentryType !== 'company',
          );
          mappedData = mappedData.concat(notCompany);

          companies = mappedAbEntries.filter(
            (o) => o.abentryType === 'company',
          );
        }

        if (data.leads.length > 0) {
          const filteredLeads: LeadSearch[] = data.leads.filter(
            (o: LeadSearch) => o.status != LeadStatus.Converted,
          );
          const mappedLeads = this.mapLeadsToOutlookEmail(
            filteredLeads as OutlookLeadSearch[],
          );
          mappedData = mappedData.concat(mappedLeads);
        }

        mappedData.sort((a, b) => b.displayName.localeCompare(a.displayName));
        companies.sort((a, b) => b.displayName.localeCompare(a.displayName));
        companies = companies.concat(mappedData);

        this.mapPopupEntryList(companies);
      });
  }
  private mapEntriesWithNew(localEmails: OutlookEmail[]) {
    const partitionKey =
      Office.context.partitionKey ??
      Office.context.mailbox?.userProfile.emailAddress ??
      'mx_default';
    const newItem = this.storage.get(partitionKey + 'new-item');
    if (newItem) {
      this.newEntries = JSON.parse(newItem);

      for (const entry of this.newEntries) {
        const index = localEmails.findIndex((o) => o.emailAddress === entry);
        if (index === -1) continue;

        const dataFromOctopus = localEmails[index];
        localEmails.splice(index, 1);
        dataFromOctopus.isNewEntry = true;
        localEmails.unshift(dataFromOctopus);
      }
    }
    this.inMaximizer = [...localEmails];
  }

  private mapNotInMaximizer() {
    this.notInMaximizer = this.outlookEmails.filter(
      (o) =>
        !this.inMaximizer.find(
          (inMax) => inMax.emailAddress === o.emailAddress,
        ),
    );
    if (this.notInMaximizer.length > 4) {
      this.pagerSettingsNotInMax = { ...this.defaultPagerSettings };
    }
  }

  private mapAbEntriesToOutlookEmail(
    abentries?: OutlookAbEntryDetails[],
  ): OutlookEmail[] {
    if (!abentries) return [];

    const emails = this.outlookEmails.map((o) => o.emailAddress);
    return (
      abentries.map((ab) => {
        const emailFromOutlook = ab.emails.find((o) =>
          emails.find(
            (e) => e.toLocaleLowerCase() === o.value.toLocaleLowerCase(),
          ),
        );

        let name = ab.name;

        if (ab.type.toLocaleLowerCase() === 'company') {
          name = ab.companyName;
        }
        return {
          id: ab.key,
          emailAddress: emailFromOutlook?.value.toLocaleLowerCase(),
          displayName: name,
          type: 'abentry',
          abentryType: ab.type.toLocaleLowerCase(),
        } as OutlookEmail;
      }) ?? []
    );
  }

  private mapAbEntrySearchToOutlookEmail(
    abentries: AbEntrySearch[],
  ): OutlookEmail[] {
    return abentries.map((ab) => {
      let name = ab.name;

      if (ab.type.toLocaleLowerCase() === 'company') {
        name = ab.companyName;
      }

      return {
        id: ab.key ?? '',
        displayName: name,
        emailAddress: ab.email.toLocaleLowerCase(),
        type: 'abentry',
        abentryType: ab.type.toLocaleLowerCase() as
          | 'contact'
          | 'individual'
          | 'company',
      };
    });
  }

  private mapLeadsToOutlookEmail(lead?: OutlookLeadSearch[]): OutlookEmail[] {
    if (!lead) return [];
    return lead
      .filter((o) => o.status !== 2)
      .map((result) => {
        return {
          id: result.key,
          emailAddress: result.email.toLocaleLowerCase(),
          displayName: result.name,
          type: 'lead',
        };
      });
  }

  private showSaveEmailSuccessNotification(isAllEntries: boolean) {
    const translationEntry = isAllEntries
      ? 'outlook.email-notification.success-all-entries'
      : 'outlook.email-notification.success-entry';
    const successMsg = this.translate.instant(translationEntry);

    this.notification.showMessage('success', successMsg);
  }

  private showSaveEmailErrorNotification(isAllEntries: boolean) {
    const translationEntry = isAllEntries
      ? 'outlook.email-notification.fail-all-entries'
      : 'outlook.email-notification.fail-entry';
    const errorMsg = this.translate.instant(translationEntry);

    this.notification.showMessage('error', errorMsg);
  }

  private showLoadError(error: unknown) {
    console.error('Err', error);
    this.notification.show('load', 'error');
    return { abentries: [], leads: [] };
  }

  private mapPopupEntryList(mappedData: OutlookEmail[]) {
    for (const m of mappedData) {
      if (this.otherInMaximizer.find((o) => o.id === m.id)) continue;

      const existInMaximizer = this.inMaximizer.find((o) => o.id === m.id);
      if (existInMaximizer?.emailSaved) {
        m.emailSaved = true;
      }
      this.popupEntryList.push(m);
    }
  }

  private async createEmailInMaximizer(
    emlFile: File,
    parentKey: string,
  ): Promise<string | null> {
    const attachment$ = this.documentService.createAttachment(
      emlFile,
      'application/octet-stream',
    );
    const attachmentId = await firstValueFrom(attachment$);

    const item = this.outlookService.mailboxItem;
    if (!item) return null;

    const document: CreateDocument = {
      name: isNullOrEmpty(item.subject) ? 'Untitled email' : item.subject,
      binaryDataId: attachmentId ?? '',
      dateTime: item.dateTimeCreated,
      extension: '.eml',
      type: DocumentType.Emails,
      size: emlFile.size,
      parentKey: parentKey,
    };

    const document$ = this.documentService.createDocument(document);
    return firstValueFrom(document$);
  }

  private updateRelatedSavedEmailMessage() {
    let message = '';

    if (
      this.related.opportunity.emailCount === 1 &&
      this.related.case.emailCount === 0
    ) {
      message = this.translate.instant('outlook.main.related.message1', {
        opp: this.related.opportunity.savedEmails[0].objective,
      });
    } else if (
      this.related.opportunity.emailCount > 1 &&
      this.related.case.emailCount === 0
    ) {
      message = this.translate.instant('outlook.main.related.message2', {
        count: this.related.opportunity.emailCount,
      });
    } else if (
      this.related.case.emailCount === 1 &&
      this.related.opportunity.emailCount === 1
    ) {
      message = this.translate.instant('outlook.main.related.message3');
    } else if (
      this.related.case.emailCount === 1 &&
      this.related.opportunity.emailCount === 0
    ) {
      message = this.translate.instant('outlook.main.related.message4', {
        case: this.related.case.savedEmails[0].subject,
      });
    } else if (
      this.related.case.emailCount > 1 &&
      this.related.opportunity.emailCount === 0
    ) {
      message = this.translate.instant('outlook.main.related.message5', {
        count: this.related.case.emailCount,
      });
    } else {
      message = this.translate.instant('outlook.main.related.message6', {
        oppCount: this.related.opportunity.emailCount,
        caseCount: this.related.case.emailCount,
      });
    }

    this.relatedSavedEmailMessage = message;
  }

  private renderRelatedSection(): void {
    if (this.userRights?.customerService.read && this.userRights?.opportunity) {
      if (this.related.opportunity.hasEntries || this.related.case.hasEntries) {
        this.relatedTitle = this.translate.instant(
          'outlook.main.related.fullTitle',
        );
      }
    } else if (!this.userRights?.customerService.read) {
      this.relatedOpportunityTiles();
    } else if (!this.userRights?.opportunity.read) {
      this.relatedCasesTitles();
    }
  }

  private relatedOpportunityTiles(): void {
    if (this.related.opportunity.hasEntries) {
      this.relatedTitle = this.translate.instant(
        'outlook.main.related.opportunitiesTitle',
      );
    } else {
      this.relatedTitle = this.translate.instant(
        'outlook.main.related.noRelatedOpportunities',
      );
    }
  }

  private relatedCasesTitles(): void {
    if (this.related.case.hasEntries) {
      this.relatedTitle = this.translate.instant(
        'outlook.main.related.casesTitle',
      );
    } else {
      this.relatedTitle = this.translate.instant(
        'outlook.main.related.noRelatedCases',
      );
    }
  }
}
