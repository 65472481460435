import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipelineService } from './services/pipeline.service';
import { RevenueTypeService } from './services';

@NgModule({
  imports: [CommonModule],
  providers: [PipelineService, RevenueTypeService],
})
export class CoreSettingsDataAccessModule {}
