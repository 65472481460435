import { EntityFields } from './entity';
import { KeyAndDisplay } from './key-and-display';
import { ReadRequest, Request } from './request';
import { DataResponse, Response } from './response';

export const EmailProfileCode = 'EmailProfile';

export enum EmailProfileFields {
  EmailAddress = 'EmailAddress',
  EmailType = 'EmailType',
  Comment = 'Comment',
  EnrollDate = 'EnrollDate',
  ExpiryDate = 'ExpiryDate',
}

export interface EmailProfile {
  [EntityFields.Key]: string;
  [EmailProfileFields.EmailAddress]: string;
  [EmailProfileFields.EmailType]: KeyAndDisplay;
  [EmailProfileFields.Comment]: KeyAndDisplay;
  [EmailProfileFields.EnrollDate]: string;
  [EmailProfileFields.ExpiryDate]: string;
}

export interface EmailProfileReadRequest extends Request {
  EmailProfile: ReadRequest<EmailProfile>;
}

export interface EmailProfileResponse extends Response {
  EmailProfile: DataResponse<EmailProfile>;
}
