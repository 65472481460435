import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { AddressBookService } from './services/address-book.service';
import { AppointmentService } from './services/appointment.service';
import { AvatarService } from './services/avatar.service';
import { ConfigurationService } from './services/configuration.service';
import { ContextService } from './services/context.service';
import { EmailDocumentService } from './services/email-document.service';
import { EmailProfileService } from './services/email-profile.service';
import { EmailValidationService } from './services/email-validation.service';
import {
  FeatureService,
  OnPremiseFeatureService,
} from './services/feature.service';
import { FieldOptionsService } from './services/field-options.service';
import { LocaleService } from './services/locale.service';
import { MetadataService } from './services/metadata.service';
import { NoteService } from './services/note.service';
import { NotificationSetupService } from './services/notification-setup.service';
import { OpportunityService } from './services/opportunity.service';
import { SalesTeamService } from './services/sales-team.service';
import { SessionStorageService } from './services/session-storage.service';
import { SessionService } from './services/session.service';
import { SmartFillService } from './services/smart-fill.service';
import { StatisticsService } from './services/statistics.service';
import { TabsService } from './services/tabs.service';
import { TokenService } from './services/token.service';
import { UserService } from './services/user.service';
import { GlobalStore } from './state/global.store';
import { DocumentService } from './services/document.service';
import { OpportunityStore } from './state/opportunity.store';
import { InteractionLogService } from './services/interaction-log.service';
import { EncryptionService } from './services/encryption.service';
import { OAuth2Service } from './services';
import { OAuth2EnvModel } from './models';
import { SystemService } from './services/system.service';

export const DEBUG = new InjectionToken<string>('debug');
export const FEATURE_CLIENT_ID = new InjectionToken<string>('featureClientId');
export const SMART_FILL = new InjectionToken<string>('smartFill');
export const EXPORT_NOTIFICATION = new InjectionToken<string>(
  'exportRowsNotification',
);
export const WEBHOOK_ID = new InjectionToken<string>('webhookRecipientId');

export const APP_VERSION = new InjectionToken<string>('appVersion');
export const PRODUCT = new InjectionToken<string>('product');

export const GLOBAL_SERVICES_URL = new InjectionToken<string>(
  'globalServicesUrl',
);
export const GLOBAL_SERVICES_KEY = new InjectionToken<string>(
  'globalServicesKey',
);
export const OAUTH2_CLIENT_ID = new InjectionToken<string>('oauth2ClientId');
export const OAUTH2_CLIENT_ID_EMEA = new InjectionToken<string>(
  'oauth2ClientIdEmea',
);

@NgModule({
  imports: [CommonModule],
})
export class DataAccessModule {
  static forRoot(
    debug: boolean,
    featureClientId?: string,
    smartFill?: string,
    exportRowsNotification?: number,
    webhookRecipientId?: string,
    appVersion?: string,
    product?: string,
    oauth2?: OAuth2EnvModel,
  ): ModuleWithProviders<DataAccessModule> {
    return {
      ngModule: DataAccessModule,
      providers: [
        AddressBookService,
        AppointmentService,
        AvatarService,
        ConfigurationService,
        ContextService,
        DocumentService,
        EmailDocumentService,
        EmailProfileService,
        EmailValidationService,
        {
          provide: FeatureService,
          useFactory: (context: ContextService) => {
            if (context.isCloud) {
              return new FeatureService(context, featureClientId ?? '', debug);
            } else {
              return new OnPremiseFeatureService(
                context,
                featureClientId ?? '',
                debug,
              );
            }
          },
          deps: [ContextService],
        },
        FieldOptionsService,
        GlobalStore,
        InteractionLogService,
        LocaleService,
        MetadataService,
        NoteService,
        NotificationSetupService,
        OpportunityService,
        OpportunityStore,
        SalesTeamService,
        SessionStorageService,
        SessionService,
        SmartFillService,
        StatisticsService,
        TabsService,
        TokenService,
        UserService,
        EncryptionService,
        SystemService,
        OAuth2Service,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true,
        },
        { provide: DEBUG, useValue: debug },
        { provide: FEATURE_CLIENT_ID, useValue: featureClientId },
        { provide: SMART_FILL, useValue: smartFill },
        { provide: EXPORT_NOTIFICATION, useValue: exportRowsNotification },
        { provide: WEBHOOK_ID, useValue: webhookRecipientId },
        { provide: APP_VERSION, useValue: appVersion },
        { provide: PRODUCT, useValue: product },
        { provide: GLOBAL_SERVICES_URL, useValue: oauth2?.globalServicesUrl },
        { provide: GLOBAL_SERVICES_KEY, useValue: oauth2?.globalServicesKey },
        { provide: OAUTH2_CLIENT_ID, useValue: oauth2?.oauth2ClientId },
        {
          provide: OAUTH2_CLIENT_ID_EMEA,
          useValue: oauth2?.oauth2ClientIdEmea,
        },
      ],
    };
  }
}
