import { Component, Input, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { StringField } from '@maximizer/core/shared/domain';
import {
  TextAreaComponent,
  TextBoxComponent,
} from '@progress/kendo-angular-inputs';

@Component({
  selector: 'maximizer-string-field',
  template: `
    <kendo-formfield
      *ngIf="field.lines; else textbox"
      [formGroup]="form.control"
      [ngClass]="[field.labelPosition ?? 'left', field.containerClass ?? '']"
    >
      <kendo-label
        maximizerFieldLabel
        [field]="field"
        [for]="input"
        [required]="field.required ?? false"
      >
      </kendo-label>
      <kendo-textarea
        #input
        resizable="vertical"
        [rows]="field.lines"
        [formControlName]="field.id"
        [ngClass]="field.cssClass"
        [readonly]="field.readonly"
        [placeholder]="field.placeholder ?? ''"
        [maxlength]="field.maxlength!"
      ></kendo-textarea>
      <kendo-formhint *ngIf="field.hint">{{ field.hint }}</kendo-formhint>
      <kendo-formerror
        >{{
          'JSS_GenTxtPleaseEnter' | resourceOrTranslate: 'error.enterField'
        }}
        {{ field.name }}</kendo-formerror
      >
    </kendo-formfield>
    <ng-template #textbox>
      <kendo-formfield
        [formGroup]="form.control"
        [ngClass]="[field.labelPosition ?? 'left', field.containerClass ?? '']"
      >
        <kendo-label
          maximizerFieldLabel
          [field]="field"
          [for]="input"
          [required]="field.required ?? false"
        >
        </kendo-label>
        <kendo-textbox
          #input
          [formControlName]="field.id"
          [ngClass]="field.cssClass"
          [clearButton]="field.clearButton ?? true"
          [readonly]="field.readonly"
          [placeholder]="field.placeholder ?? ''"
          [maxlength]="field.maxlength!"
        ></kendo-textbox>
        <kendo-formhint *ngIf="field.hint">{{ field.hint }}</kendo-formhint>
        <kendo-formerror
          >{{
            'JSS_GenTxtPleaseEnter' | resourceOrTranslate: 'error.enterField'
          }}
          {{ field.name }}</kendo-formerror
        >
      </kendo-formfield>
    </ng-template>
  `,
  styles: [
    `
      :host {
        @apply w-full;
      }
    `,
  ],
})
export class StringFieldComponent {
  @Input({ required: true })
  field!: StringField;

  @ViewChild('input')
  input!: TextBoxComponent | TextAreaComponent;

  constructor(public form: FormGroupDirective) {}
}
