import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { InsightsInterceptor } from './interceptor/insights.interceptor';
import { InsightsService } from './services/insights.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    InsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InsightsInterceptor,
      multi: true,
    },
  ],
})
export class InsightsModule {}
