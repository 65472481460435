import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextService } from './context.service';
import { Observable, delay, forkJoin, map, of, shareReplay, tap } from 'rxjs';
import { OAuth2Token, Octopus } from '@maximizer/core/shared/domain';
import { OAuth2Service, REFRESH_TOKEN_DELAY } from './oauth2.service';

@Injectable()
export class TokenService {
  private tokenRequest?: Observable<string>;
  private sessionExpiredRequest?: Observable<boolean>;
  private readonly tokenEndpoint =
    '/Services/AuthenticationService.asmx/GetWebAPIToken';
  private readonly tokenWithIdentityEndpoint =
    '/Services/AuthenticationService.asmx/GetWebAPITokenWithIdentity';
  private readonly sessionExpiredEndpoint =
    '/mvc/SessionExpired/SessionExpired/SessionExpired';
  constructor(
    private http: HttpClient,
    private context: ContextService,
    private oauth2: OAuth2Service,
  ) {}

  authenticate(
    database: string,
    user: string,
    password: string,
    vendorId?: string,
    applicationKey?: string,
  ): Observable<string> {
    const request: Octopus.AuthenticationRequest = {
      Database: database,
      UID: user,
      Password: password,
      VendorId: vendorId,
      AppKey: applicationKey,
    };

    return this.http
      .post<Octopus.AuthenticationResponse>(
        `${this.context.api}${Octopus.Action.AUTHENTICATE}`,
        request,
      )
      .pipe(
        map((response) => {
          return response.Data.Token;
        }),
        tap((token) => {
          this.context.token = token;
        }),
      );
  }

  getToken(): Observable<string> {
    if (!this.context.token) {
      if (!this.tokenRequest) {
        this.tokenRequest = this.getTokenRequest().pipe(
          map((response) => {
            return response.d;
          }),
          tap((response) => {
            this.context.token = response;
            this.tokenRequest = undefined;
          }),
          shareReplay(1),
        );
      }
      return this.tokenRequest;
    }
    return of(this.context.token);
  }

  getSessionExpired(): Observable<boolean> {
    if (!this.sessionExpiredRequest) {
      this.sessionExpiredRequest = this.getSessionExpiredRequest().pipe(
        tap(() => {
          this.sessionExpiredRequest = undefined;
        }),
        shareReplay(1),
      );
    }
    return this.sessionExpiredRequest;
  }

  getSession(): Observable<{ token: string; isSessionExpired: boolean }> {
    if (!this.context.token && this.context.refresh_token) {
      return this.getRefreshToken().pipe(
        map((response) => {
          return { token: response, isSessionExpired: false };
        }),
      );
    }
    if (!this.context.token) {
      return forkJoin({
        token: this.getToken(),
        isSessionExpired: this.getSessionExpired(),
      }).pipe(
        map((response) => {
          return {
            token: response.token,
            isSessionExpired: response.isSessionExpired,
          };
        }),
      );
    }
    return of({ token: this.context.token, isSessionExpired: false });
  }

  private getSessionExpiredRequest(): Observable<boolean> {
    return this.http.post<boolean>(
      this.context.website + this.sessionExpiredEndpoint,
      {},
      { observe: 'body' },
    );
  }

  private getTokenRequest(): Observable<{ d: string }> {
    return this.http.post<{ d: string }>(
      this.context.website +
        (this.context.identityToken
          ? this.tokenWithIdentityEndpoint
          : this.tokenEndpoint),
      { IdentityToken: this.context.identityToken },
    );
  }

  getRefreshToken(): Observable<string> {
    if (this.context.token) {
      return of(this.context.token);
    }

    if (!this.tokenRequest) {
      if (this.oauth2.getRefreshInProgress()) {
        delay(REFRESH_TOKEN_DELAY);
      }
      const oauth2 = this.oauth2.getStorageToken();
      if (oauth2) {
        const result = this.verifyRefreshTokenValidity(oauth2);
        if (result) {
          return of(result);
        }
      }

      this.tokenRequest = this.getRefreshTokenRequest().pipe(
        tap((response) => {
          if (response === '') {
            this.oauth2.clearAuth();
            window.location.href = window.location.origin + '/login?error=auth';
            return;
          }
          this.context.token = response;
          this.tokenRequest = undefined;
        }),
        shareReplay(1),
      );
    }
    return this.tokenRequest;
  }

  getRefreshTokenRequest(): Observable<string> {
    return this.oauth2.refreshToken().pipe(
      map((result) => {
        if (!result) return '';
        this.populateContextTokenAndRefreshToken(result);
        return result.access_token;
      }),
    );
  }

  verifyRefreshTokenValidity(oauth2: OAuth2Token): string | null {
    if (this.context.refresh_token !== oauth2.refresh_token) {
      this.populateContextTokenAndRefreshToken(oauth2);
      return oauth2.access_token;
    }
    const expireAtLocal = oauth2.expires_at ?? '';
    const expiresAt = new Date(expireAtLocal);
    const now = new Date();
    if (expiresAt > now) {
      this.populateContextTokenAndRefreshToken(oauth2);
      return oauth2.access_token;
    }
    return null;
  }

  populateContextTokenAndRefreshToken(oauth2: OAuth2Token): void {
    this.context.refresh_token = oauth2.refresh_token;
    this.context.token = oauth2.access_token;
  }
}
