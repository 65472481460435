import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextService } from '@maximizer/core/shared/data-access';
import { OpportunitySearch, Octopus } from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import {
  GlobalSearchService,
  SearchEscapeCharacters,
} from './global-search-service';
import { escapeCharacters } from '@maximizer/shared/util';

@Injectable()
export class OpportunitiesSearchService
  implements GlobalSearchService<OpportunitySearch>
{
  constructor(
    private readonly _http: HttpClient,
    private readonly _context: ContextService,
  ) {}

  search(
    searchPhrase: string,
    useWildcard = false,
    take = 15,
  ): Observable<OpportunitySearch[]> {
    const request: Octopus.OpportunityReadRequest = {
      Opportunity: {
        Criteria: {
          SearchQuery: {
            $PHRASE: `${escapeCharacters(searchPhrase, SearchEscapeCharacters)}${!useWildcard ? '' : '*'}`,
          },
          Top: take,
        },
        OrderBy: {
          Fields: [{ Status: 'ASC' }, { LastModifyDate: 'DESC' }],
        },
        Scope: {
          Fields: {
            Key: 1,
            Status: 1,
            RevenueType: 1,
            DisplayValue: 1,
            AbEntry: {
              CompanyName: 1,
              FirstName: 1,
              LastName: 1,
              Type: 1,
              Key: 1,
            },
            Contact: {
              FirstName: 1,
              LastName: 1,
              Key: 1,
              ParentKey: 1,
            },
            LastModifyDate: {
              DisplayValue: 1,
              Value: 1,
            },
            Objective: 1,
          },
        },
      },
      Globalization: Octopus.DefaultGlobalization,
      Configuration: Octopus.OpportunityReadDriver,
    };

    return this._http
      .post<Octopus.OpportunityResponse>(
        `${this._context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result && result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.OpportunitySearchMapper();
            return result.Opportunity.Data.map((entries) =>
              mapper.from(entries),
            );
          }
          return [];
        }),
      );
  }
}
