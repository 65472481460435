import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

type OutlookState = {
  inMaximizerIds: string[];
};

const initialState: OutlookState = {
  inMaximizerIds: [],
};

export const OutlookStore = signalStore(
  withDevtools('global'),
  withState({
    ...initialState,
    inMaximizerIds: JSON.parse(localStorage.getItem('inMaximizerIds') || '[]'),
  }),
  withMethods((store) => ({
    updateInMaximizerIdList(inMaximizerIds: string[]) {
      patchState(store, () => ({ inMaximizerIds }));
      localStorage.setItem('inMaximizerIds', JSON.stringify(inMaximizerIds));
    },
  })),
);

export type OutlookStore = InstanceType<typeof OutlookStore>;
