import {
  GlobalServicesLoginDetails,
  Session,
} from '@maximizer/core/shared/domain';
import { AppStoreRedirectParams } from '../models';
import { buildApiWebRequest, mapDataCenterFromToken } from './datacenter.util';

export function constructAppStoreParams(
  session: Session,
  alias: string,
  token: string,
  tenantId: string,
  logInDetails: GlobalServicesLoginDetails,
  install: boolean,
): string | undefined {
  const dataCenterId = mapDataCenterFromToken(token);

  if (!dataCenterId) {
    console.error('Could not resolve dataCenterId');
    return undefined;
  }

  const params: AppStoreRedirectParams = {
    webApiUrlRequest: buildApiWebRequest(dataCenterId, alias),
    userId: session.user.id,
    alias,
    dataCenterId,
    customerId: tenantId ?? '',
    tenantPlatform: 0,
    tenantName: logInDetails?.tenant.name,
    tenantKey: session.database,
    workspace: session.workspace,
    maxToken: token,
  };

  if (install) {
    delete params.maxToken;
  }

  const hasInvalidParam = Object.values(params).some((value) =>
    [null, undefined, ''].includes(value),
  );

  return hasInvalidParam ? undefined : JSON.stringify(params);
}

export function constructAppStoreForm(
  params: string,
  url: string,
): {
  form: HTMLFormElement;
  window: Window;
} | null {
  const newWindow = window.open('', '_blank');
  if (!newWindow) {
    console.error('Failed to open new window.');
    return null;
  }

  const appStoreForm = newWindow.document.createElement('form');
  appStoreForm.method = 'POST';
  appStoreForm.action = url;

  const dataParametersInput = newWindow.document.createElement('input');
  dataParametersInput.type = 'hidden';
  dataParametersInput.name = 'DataParameters';
  dataParametersInput.value = params;

  appStoreForm.appendChild(dataParametersInput);
  newWindow.document.body.appendChild(appStoreForm);

  return { form: appStoreForm, window: newWindow };
}
