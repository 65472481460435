import { Octopus } from '@maximizer/core/shared/domain';
import { InteractionLogForm, InteractionLogFieldOption } from '../../../models';

export class InteractionFieldOptionMapper extends Octopus.ConfigurationMapper<
  Octopus.InteractionLogFieldOptionsResponse,
  InteractionLogFieldOption
> {
  from(
    source: Octopus.InteractionLogFieldOptionsResponse,
  ): InteractionLogFieldOption {
    const interactionLog = source.InteractionLog?.FieldOptions;

    return {
      categories:
        interactionLog?.Category?.Data?.map(this.mapFieldOption).sort(
          (first, second) => first.name.localeCompare(second.name),
        ) ?? [],
      results:
        interactionLog?.Result?.Data?.map(this.mapFieldOption).sort(
          (first, second) => first.name.localeCompare(second.name),
        ) ?? [],
    };
  }
}
export class InteractionFormMapper extends Octopus.ConfigurationMapper<
  InteractionLogForm,
  Octopus.InteractionLogSave
> {
  override from(source: InteractionLogForm): Octopus.InteractionLogSave {
    return {
      Key: source.key,
      Subject: source.subject,
      Type: Octopus.InteractionCode.CALL.toString(),
      StartDate: source.startDate,
      EndDate: source.endDate,
      User: source.user,
      Result: source.result,
      Direction: source.direction,
      Data: source.phone ?? null,
      Category: source.category,
      Description: source.callLog,
      LeadKey: source.leadKey,
      AbEntryKey: source.abEntryKey,
      Duration: source.duration,
    };
  }
}
