import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ContextService } from '@maximizer/core/shared/data-access';
import { Octopus } from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';
import {
  NoteFieldOption,
  NoteFieldOptionMapper,
  NoteForm,
  NoteFormMapper,
  NoteSummary,
  NoteSummaryMapper,
} from '@maximizer/outlook/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  constructor(
    private http: HttpClient,
    private context: ContextService,
  ) {}

  getFieldOption(parentKey: string): Observable<NoteFieldOption> {
    const request: Octopus.NoteFieldOptionsRequest = {
      Note: {
        Data: { ParentKey: parentKey },
        FieldOptions: {
          Category: [
            {
              Key: 1,
              DisplayValue: 1,
            },
          ],
        },
      },
    };

    return this.http
      .post<Octopus.NoteFieldOptionsResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new NoteFieldOptionMapper();
            return mapper.from(result);
          }
          return { categories: [], types: [] };
        }),
      );
  }

  create(taskForm: NoteForm): Observable<string | null> {
    const mapper = new NoteFormMapper();
    const data = mapper.from(taskForm);
    const request: Octopus.NoteCreateRequest = {
      Configuration: Octopus.NoteWriteDriver,
      Note: {
        Data: data,
      },
    };
    return this.http
      .post<Octopus.NoteCreateResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result?.Code === Octopus.ResponseStatusCode.Successful) {
            return result.Note.Data.Key;
          }
          throw new HttpErrorResponse({ error: 'Unsuccessful response' });
        }),
      );
  }

  getSummary(key: string): Observable<NoteSummary> {
    const query: Octopus.LogicalQuery<Octopus.Note> = {
      $AND: [{ ParentKey: { $EQ: key } }, { Type: { $EQ: 0 } }],
    };

    const fields = this.getSummaryScope();
    const request = {
      Configuration: Octopus.NoteReadDriver,
      Note: {
        Scope: {
          Fields: fields,
        },
        Criteria: {
          SearchQuery: query,
          Top: 1,
        },
        OrderBy: {
          Fields: [{ DateTime: 'DESC' }],
        },
      },
      'Note.Count': {
        Scope: {
          Fields: {
            '$COUNT()': 1,
          },
        },
        Criteria: {
          SearchQuery: query,
        },
      },

      Globalization: Octopus.DefaultGlobalization,
    };

    return this.http
      .post<Octopus.NoteSummaryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new NoteSummaryMapper();
            return mapper.from(result);
          }

          return {
            noteAmount: 0,
          };
        }),
      );
  }

  private getSummaryScope(): Octopus.Scope<Octopus.Note> {
    return {
      Key: 1,
      Text: 1,
      RichText: 1,
      Category: 1,
      Important: 1,
      DateTime: 1,
      Creator: { Value: 1, DisplayValue: 1 },
      DisplayValue: 1,
    };
  }
}
