import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Octopus } from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import { ContextService } from './context.service';

@Injectable()
export class EmailDocumentService {
  constructor(
    private context: ContextService,
    private httpClient: HttpClient,
  ) {}

  delete(emailDocumentKeys: string[]): Observable<boolean> {
    const request: { [name: string]: unknown } = {};

    if (emailDocumentKeys) {
      emailDocumentKeys.forEach(
        (key, index) =>
          (request['EmailDocument.' + index] = { Data: { Key: key } }),
      );
    }

    return this.httpClient
      .post<Octopus.Response>(
        `${this.context.api}${Octopus.Action.DELETE}`,
        request,
        {
          headers: this.getRequestHeaders(),
        },
      )
      .pipe(
        map((result) => {
          return result.Code === Octopus.ResponseStatusCode.Successful;
        }),
      );
  }
  private getRequestHeaders(): { [header: string]: string } {
    return {
      'mx-insights-entity': 'EmailDocument',
    };
  }
}
