import { ReadRequest, Request } from './request';
import { DataResponse, Response } from './response';

export const SingleUserKey = 'U2FsZXNUZWFtCTY1NTM1';

export interface SalesTeam {
  Key: string;
  DisplayName: string;
  MemberKeys?: SalesTeamMember[];
  Inactive: boolean;
}

export enum TeamMemberRole {
  Member = 0,
  Leader = 1,
}

export enum TeamMemberRights {
  Edit = 0,
  Audit = 1,
  Standard = 2,
}

export interface SalesTeamMember {
  Key: string;
  DisplayValue: string;
  Role: TeamMemberRole;
  Right: TeamMemberRights;
}

export interface SalesTeamReadRequest extends Request {
  SalesTeam: ReadRequest<SalesTeam>;
}

export interface SalesTeamResponse extends Response {
  SalesTeam: DataResponse<SalesTeam>;
}
