import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderModule as KendoLoaderModule } from '@progress/kendo-angular-indicators';
import { LoaderComponent } from './loader.component';

@NgModule({
  imports: [CommonModule, KendoLoaderModule],
  exports: [LoaderComponent],
  declarations: [LoaderComponent],
})
export class UiLoaderModule {}
