import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ContextService } from './context.service';
import { Octopus, NotificationSetup } from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import { EXPORT_NOTIFICATION, WEBHOOK_ID } from '../data-access.module';

@Injectable()
export class NotificationSetupService {
  constructor(
    private http: HttpClient,
    private context: ContextService,
    @Inject(EXPORT_NOTIFICATION) private exportRowsNotification: number,
    @Inject(WEBHOOK_ID) private webhookRecipientId: number,
  ) {}

  createExportAbEntryNotification(recipient: string): Observable<boolean> {
    const query: NotificationSetup = {
      key: null,
      name: 'AddressBook export alert',
      tag: Octopus.NotificationSetupTag.AbEntry,
      entityType: Octopus.EntityType.AbEntry,
      recipientUid: recipient,
    };

    const request = this.getRequest(query);

    return this.http
      .post<Octopus.NotificationSetupWriteResponse>(
        this.context.api + Octopus.Action.CREATE,
        request,
      )
      .pipe(map(this.mapCreateNotificationResponse));
  }

  createExportOpportunityNotification(recipient: string): Observable<boolean> {
    const query: NotificationSetup = {
      key: null,
      name: 'Opportunities export alert',
      tag: Octopus.NotificationSetupTag.Opportunity,
      entityType: Octopus.EntityType.Opportunity,
      recipientUid: recipient,
    };

    const request: Octopus.NotificationSetupWriteRequest =
      this.getRequest(query);

    return this.http
      .post<Octopus.NotificationSetupWriteResponse>(
        this.context.api + Octopus.Action.CREATE,
        request,
      )
      .pipe(map(this.mapCreateNotificationResponse));
  }

  private mapCreateNotificationResponse(
    result: Octopus.NotificationSetupWriteResponse,
  ): boolean {
    if (
      result.Code === Octopus.ResponseStatusCode.Successful &&
      result.NotificationSetup?.Data
    ) {
      return true;
    }
    return false;
  }

  removeNotification(key: string): Observable<boolean> {
    const request: Octopus.NotificationSetupWriteRequest = {
      NotificationSetup: {
        Data: {
          Key: key,
        },
      },
      Configuration: Octopus.NotificationSetupReadDriver,
    };

    return this.http
      .post<Octopus.NotificationSetupWriteResponse>(
        `${this.context.api}${Octopus.Action.DELETE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            return true;
          }
          return false;
        }),
      );
  }

  readExportNotificationList(userKey: string): Observable<NotificationSetup[]> {
    const request: Octopus.NotificationSetupReadRequest = {
      NotificationSetup: {
        Criteria: {
          SearchQuery: {
            $AND: [
              {
                Type: {
                  $EQ: Octopus.NotificationType.NotificationService,
                },
              },
              {
                Entitytype: {
                  $EQ: Octopus.EntityType.Export,
                },
              },
              {
                Recipients: {
                  $IN: [userKey],
                },
              },
            ],
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            Name: 1,
            Tag: 1,
          },
        },
      },
      Configuration: Octopus.NotificationSetupReadDriver,
    };

    return this.http
      .post<Octopus.NotificationSetupResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.NotificationExportConfigurationMapper();
            return result.NotificationSetup.Data.map((notification) =>
              mapper.from(notification),
            );
          }
          return [];
        }),
      );
  }

  private getRequest(
    query: NotificationSetup,
  ): Octopus.NotificationSetupWriteRequest {
    return {
      NotificationSetup: {
        Data: {
          Key: null,
          EntityType: Octopus.EntityType.Export,
          Type: Octopus.NotificationType.NotificationService,
          Trigger: Octopus.NotificationTrigger.Read,
          Status: Octopus.NotificationStatus.Enabled,
          Name: query.name,
          Tag: query.tag,
          Criteria: {
            $AND: [
              {
                Rows: {
                  $GE: this.exportRowsNotification,
                },
              },
              {
                EntityType: {
                  $EQ: query.entityType,
                },
              },
            ],
          },
          NotifySource: false,
          Targets: [
            {
              Key: {
                ID: Octopus.InteractionCode.EMAIL,
              },
              Data: `<?xml version="1.0" encoding="UTF-8"?><data><ns_target_id>${this.webhookRecipientId}</ns_target_id></data>`,
            },
          ],
          Recipients: [
            {
              Key: {
                UID: query.recipientUid,
              },
            },
          ],
          SecAccess: {
            Write: [
              {
                Key: {
                  Uid: '*',
                },
              },
            ],
            Read: [
              {
                Key: {
                  Uid: '*',
                },
              },
            ],
          },
        },
      },
      Configuration: Octopus.NotificationSetupReadDriver,
    };
  }
}
