import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  OAuth2Service,
  SessionStorageService,
} from '@maximizer/core/shared/data-access';
import { GlobalServicesLoginDetails } from '@maximizer/core/shared/domain';
import { InsightsService } from '@maximizer/core/shared/insights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { catchError, of } from 'rxjs';
@Component({
  selector: 'maximizer-outlook-authorize',
  template: '',
})
export class OutlookAuthorizeComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;

  constructor(
    private oauthService: OAuth2Service,
    private sessionStorage: SessionStorageService,
    private route: ActivatedRoute,
    private insightsService: InsightsService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const errorParam = params['error'];
      if (errorParam) {
        this.handleError();
        return;
      }
      const code = params['code'];
      const state = params['state'];
      const loginHint = params['login_hint'];

      const sessionAuth = this.sessionStorage.get('auth_code');
      const url = this.sessionStorage.get('url');
      if (!sessionAuth || !url) return;

      const parsedJson = JSON.parse(sessionAuth);
      const auth_code = parsedJson as {
        url: string;
        state: string;
        code_challenge: string;
        original_code: string;
      };
      if (auth_code.state !== state) return;
      const loginDetailsObj = this.sessionStorage.get('login_details');
      if (!loginDetailsObj) return;
      const loginDetails = JSON.parse(
        loginDetailsObj,
      ) as GlobalServicesLoginDetails;
      this.oauthService.loginDetails = loginDetails;

      this.oauthService
        .getToken(code, url + '/authorize', auth_code.original_code, loginHint)
        .pipe(
          catchError((error) => {
            console.error('Error on login', error);
            this.insightsService.trackEvent(
              { name: 'Login-fail' },
              SeverityLevel.Error,
              { error },
            );
            this.logAuthDetailsToInsights(
              'OAuth-Login-Fail',
              loginDetails,
              error,
            );
            return of(null);
          }),
        )
        .subscribe((data) => {
          if (data === null) {
            this.handleError();
            this.logAuthDetailsToInsights(
              'OAuth-Login-Null',
              loginDetails,
              'Null return from get token OAuth request',
            );
            return;
          }

          this.logAuthDetailsToInsights('OAuth-Login-Success', loginDetails);

          const messageObject = {
            messageType: 'success',
            auth: data,
            db: loginDetails,
          };
          const jsonMessage = JSON.stringify(messageObject);
          if (Office.context.ui) {
            Office.context.ui.messageParent(jsonMessage);
          } else {
            const origin = window.location.origin;
            window.opener.postMessage(
              { type: 'outlook', auth: jsonMessage },
              origin,
            );
          }
        });
    });
  }

  logAuthDetailsToInsights(
    name: string,
    loginDetails: GlobalServicesLoginDetails,
    error?: string,
  ): void {
    this.insightsService.trackEvent({ name }, SeverityLevel.Information, {
      eventId: 'outlook-auth-service',
      alias: loginDetails.tenant.alias,
      error: error ?? 'n/a',
    });
  }

  private handleError() {
    const messageObject = { messageType: 'dialogClosed' };
    const jsonMessage = JSON.stringify(messageObject);
    Office.context.ui.messageParent(jsonMessage);
  }
}
