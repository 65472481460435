export enum OutlookCreationEvent {
  Appointment = 'appointment-creation',
  CallLog = 'calllog-creation',
  Note = 'note-creation',
  Opportunity = 'opportunity-creation',
  Task = 'task-creation',
  AbEntry = 'abentry-creation',
  Company = 'company-creation',
  Lead = 'lead-creation',
}
export enum OutlookUpdateEvent {
  AbEntry = 'abentry-update',
  Lead = 'lead-update',
}