import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ContextService,
  OAuth2Service,
} from '@maximizer/core/shared/data-access';
import { SystemInfo } from '@maximizer/core/shared/domain';

@Component({
  selector: 'maximizer-root-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  private _debugOverrideVersion = false;

  loading = true;
  configurationSyncId?: string;
  maximizerUrl = '';
  isVersionValid = false;
  systemInfo?: SystemInfo;

  constructor(
    private router: Router,
    private contextService: ContextService,
    private oauth2Service: OAuth2Service,
  ) {}

  ngOnInit() {
    try {
      if (!this.contextService.token) {
        this.handleAuthError();
        return;
      }
      if (Office.context.mailbox?.item) {
        const item = Office.context.mailbox.item;
        if (item && !item.itemId) {
          this.router.navigate(['/compose-email']);
          return;
        }
      }
      this.router.navigate(['/read-email']);
    } catch (error) {
      console.error('Error on home', error);
      this.handleAuthError();
    }
  }

  private handleAuthError(showNotification = false) {
    this.oauth2Service.clearAuth();
    window.location.href = `${window.location.origin}/login${showNotification ? '?error=auth' : ''}`;
  }
}
