import { AbEntry } from './ab-entry';
import { FieldOptions, FieldOptionsResponse } from './field-options';
import { TypedKeyAndDisplay } from './key-and-display';
import { Lead } from './lead';
import {
  ReadRequest,
  Request,
  RequestConfiguration,
  WriteRequest,
} from './request';
import { DataResponse, Response, WriteResponse } from './response';
import { ValueAndDisplay } from './value-and-display';

export interface Appointment {
  Key: string;
  Subject: string;
  Location: ValueAndDisplay<string>;
  CreationDate: string;
  DisplayValue: ValueAndDisplay<string>;
  StartDate: string;
  EndDate: string;
  AbEntries: AbEntry[];
  Leads?: Lead[];
}

export interface AppointmentReadRequest extends Request {
  Appointment: ReadRequest<Appointment>;
}

export interface AppointmentResponse extends Response {
  Appointment: DataResponse<Appointment>;
}

export const AppointmentReadDriver: RequestConfiguration = {
  Drivers: {
    IApptSearcher: 'Maximizer.Model.Access.Sql.ApptSearcher',
  },
};

export interface AppointmentFieldOptions {
  InteractionCategory: TypedKeyAndDisplay<string>;
  Result: TypedKeyAndDisplay<string>;
  Location: TypedKeyAndDisplay<string>;
}

export interface AppointmentFieldOptionsRequest extends Request {
  Appointment: FieldOptions<AppointmentFieldOptions>;
}

export interface AppointmentFieldOptionsResponse extends Response {
  Appointment: FieldOptionsResponse<AppointmentFieldOptions>;
}

export interface AppointmentCreate {
  Key: string | null;
  Subject: string;
  StartDate: string;
  EndDate: string;
  Location: string | null;
  Organizer: string;
  Creator: string;

  Description: string | null;
  InteractionCategory: string | null;
  Result: string | null;
  Users: string[];
  AbEntries: {
    Key: {
      Value: string;
    };
  }[];
  Leads: {
    Key: {
      Value: string;
    };
  }[];
}

export interface AppointmentCreateRequest extends Request {
  Appointment: WriteRequest<AppointmentCreate>;
}

export interface AppointmentCreateResponse extends Response {
  Appointment: WriteResponse<AppointmentCreate>;
}

export interface AppointmentSummaryCount {
  '$COUNT()': number;
}

export interface AppointmentSummaryResponse extends Response {
  Appointment: DataResponse<Appointment>;
  'Appointment.Count': DataResponse<AppointmentSummaryCount>;
}
