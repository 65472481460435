export class DefaultRevenueTypes {
  static readonly NEW_BUSINESS = '60001';
  static readonly EXISTING_BUSINESS = '60002';

  static isNewBusiness(value: string): boolean {
    return value === this.NEW_BUSINESS;
  }
  static isExistingBusiness(value: string): boolean {
    return value === this.EXISTING_BUSINESS;
  }

  static isDefaultRevenueType(value: string): boolean {
    return this.isNewBusiness(value) || this.isExistingBusiness(value);
  }
}
