import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IconsModule } from '@progress/kendo-angular-icons';
import {
  UiModule,
  defaultTranslateConfiguration,
} from '@maximizer/core/shared/ui';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { OutlookSharedDomainModule } from '@maximizer/outlook/shared/domain';
import { OutlookSharedDataAccessModule } from '@maximizer/outlook/shared/data-access';
import { OutlookMenuUiModule } from '@maximizer/outlook/menu/ui';
import { OutlookSharedUiModule } from '@maximizer/outlook/shared/ui';
import { EntrySummaryComponent } from './entry-summary/entry-summary.component';
import { EntryDetailsComponent } from './entry-details/entry-details.component';
import { EntryPageComponent } from './entry-page/entry-page.component';
import { OutlookTimelineUiModule } from '@maximizer/outlook/timeline/ui';
import { OutlookOpportunityUiModule } from '@maximizer/outlook/opportunity/ui';
import { OutlookTaskUiModule } from '@maximizer/outlook/task/ui';
import { OutlookNoteUiModule } from '@maximizer/outlook/note/ui';
import { OutlookAppointmentUiModule } from '@maximizer/outlook/appointment/ui';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { RouterModule } from '@angular/router';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

@NgModule({
  declarations: [
    EntrySummaryComponent,
    EntryDetailsComponent,
    EntryPageComponent,
  ],
  exports: [EntrySummaryComponent, EntryDetailsComponent, EntryPageComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    InputsModule,
    DropDownsModule,
    LabelModule,
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    IntlModule,
    DateInputsModule,
    FormFieldModule,
    IconsModule,
    ProgressBarModule,
    IndicatorsModule,
    RouterModule,
    UiModule,
    OutlookSharedDomainModule,
    OutlookSharedDataAccessModule,
    OutlookSharedUiModule,
    OutlookMenuUiModule,
    OutlookTimelineUiModule,
    OutlookOpportunityUiModule,
    OutlookTaskUiModule,
    OutlookNoteUiModule,
    OutlookAppointmentUiModule,
    TooltipsModule,
    TranslateModule.forChild({
      ...defaultTranslateConfiguration,
      extend: true,
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class OutlookEntryUiModule {}
