import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CDN_URL } from '@maximizer/core/shared/domain';
import { EmbeddedComponent } from '@maximizer/core/shared/ui';
import { AppointmentService } from '@maximizer/outlook/appointment/data-access';
import { AppointmentSummary } from '@maximizer/outlook/shared/domain';
import { catchError, finalize } from 'rxjs';

@Component({
  selector: 'maximizer-summary-appointment',
  templateUrl: './summary-appointment.component.html',
})
export class SummaryAppointmentComponent
  extends EmbeddedComponent
  implements OnInit
{
  constructor(
    @Inject(CDN_URL) public cdn: string,
    element: ElementRef,
    private appointmentService: AppointmentService,
  ) {
    super(element);
    this.configuration = {
      appointmentAmount: 0,
    };
  }

  configuration: AppointmentSummary;
  abEntryKey: string | null = null;
  leadKey: string | null = null;

  @Output() loadErrorEvent: EventEmitter<string> = new EventEmitter();
  @Output() loadingEvent: EventEmitter<boolean> = new EventEmitter();
  loading = true;
  
  @Input() deepLink?: string;
  @Input() type?: string;
  @Input() id = '';

  ngOnInit(): void {
    if (this.type == 'lead') this.leadKey = decodeURI(this.id);
    if (this.type == 'abentry') this.abEntryKey = decodeURI(this.id);
    this.loadData();
  }

  private loadData(): void {
    const key = this.leadKey ?? this.abEntryKey;
    if (!key || key == '') return;

    this.appointmentService
      .getSummary(key, this.type ?? '')
      .pipe(
        catchError(async () => this.loadErrorEvent?.emit('appointment')),
        finalize(() => {
          this.loadingEvent.emit(false);
          this.loading = false;
        }),
      )

      .subscribe((data) => {
        if (!data) return;

        if (data.appointmentAmount > 0) {
          data.appointmentAmount = data.appointmentAmount - 1;
        }
        this.configuration = data;
      });
  }
}
