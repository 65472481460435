import { InsightsService } from '@maximizer/core/shared/insights';
import { getDecodedAccessToken } from './datacenter.util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export enum OutlookInsightsEvents {
  AppStoreRequest = 'app-store-request',
  Onboarding = 'outlook-onboarding',
}

export interface InsightsLogDetails {
  insights: InsightsService;
  name: string;
  eventId: OutlookInsightsEvents;
  token: string;
  alias?: string;
  error?: string | Error;
  metadata?: Record<string, unknown>;
}

export function logCustomEventDetailsToInsights(
  event: InsightsLogDetails,
): void {
  const name = event.name;
  const customProperties = {
    error: event.error ?? '',
    decodedToken: getDecodedAccessToken(event.token),
    databaseAlias: event.alias ?? '',
    eventId: event.eventId,
  };

  event.insights.trackEvent(
    { name },
    SeverityLevel.Information,
    customProperties,
  );
}
