import { Octopus } from '../..';
import { Mapper } from '../../../mappers';
import { Session } from '../../../models';

export class SessionMapper extends Mapper<Octopus.Session, Session> {
  from(source: Octopus.Session): Session {
    return {
      database: source.AddressBook.Database,
      displayName: source.AddressBook.DisplayValue,
      workspace: source.AddressBook.WorkSpaceId,
      alias: source.AddressBook.Alias,
    } as Session;
  }
}
