import { Address } from './address';
import { Entity, EntityFields } from './entity';
import { Phone } from './phone';
import {
  ReadRequest,
  Request,
  RequestConfiguration,
  WriteRequest,
} from './request';
import { DataResponse, Response, WriteResponse } from './response';
import { ValueAndDisplay } from './value-and-display';

export enum LeadFields {
  FirstName = 'FirstName',
  LastName = 'LastName',
  CompanyName = 'CompanyName',
  Email = 'Email',
  CreationDate = 'CreationDate',
  CloseDate = 'CloseDate',
  LastModifyDate = 'LastModifyDate',
  Phone1 = 'Phone1',
  Phone2 = 'Phone2',
  Position = 'Position',
  Address = 'Address',
  WebSite = 'WebSite',
  Status = 'Status',
  ArchivedDate = 'ArchivedDate',
}

export interface Lead extends Entity {
  [EntityFields.Key]: string | null;
  [LeadFields.FirstName]: string;
  [LeadFields.LastName]: string;
  [LeadFields.CompanyName]: string;
  [LeadFields.Position]: string;
  [LeadFields.Email]: string;
  [LeadFields.CreationDate]: ValueAndDisplay<string>;
  [LeadFields.CloseDate]: string;
  [LeadFields.LastModifyDate]: ValueAndDisplay<string>;
  [LeadFields.Phone1]: Phone;
  [LeadFields.Phone2]: Phone;
  [LeadFields.WebSite]?: string;
  [LeadFields.Address]?: Address;
  [LeadFields.Status]?: number;
  [LeadFields.ArchivedDate]?: string;
}

export interface LeadReadRequest extends Request {
  Lead: ReadRequest<Lead>;
}

export interface LeadResponse extends Response {
  Lead: DataResponse<Lead>;
}

export const LeadReadDriver: RequestConfiguration = {
  Drivers: {
    ILeadSearcher: 'Maximizer.Model.Access.Sql.LeadSearcher',
  },
};

export interface LeadWriteRequest extends Request {
  Lead: WriteRequest<Lead>;
}

export interface LeadWriteResponse extends Response {
  Lead: WriteResponse<Lead>;
}
