import { ErrorHandler, Injectable, effect } from '@angular/core';
import { Router } from '@angular/router';
import {
  ContextService,
  GlobalStore,
} from '@maximizer/core/shared/data-access';
import { Session } from '@maximizer/core/shared/domain';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  ICustomProperties,
  IDependencyTelemetry,
  IEventTelemetry,
  IExceptionTelemetry,
  IPageViewTelemetry,
  ITelemetryItem,
  ITraceTelemetry,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { getState } from '@ngrx/signals';

@Injectable()
export class InsightsService {
  private session?: Session;
  private angularPlugin = new AngularPlugin();
  private appInsights?: ApplicationInsights;
  private eventLevel: SeverityLevel = SeverityLevel.Information;
  private customProperties?: Record<string, string>;
  private trackPageViewEvent = true;

  constructor(
    private readonly router: Router,
    private readonly global: GlobalStore,
    readonly context: ContextService,
  ) {
    effect(() => {
      const state = getState(this.global);
      const insightsConfiguration =
        state.insightsConfiguration[context.dataCenter ?? ''] ??
        state.insightsConfiguration['default'];
      if (insightsConfiguration?.applicationInsights?.instrumentationKey) {
        if (state.session) {
          this.session = state.session;
        }
        this.appInsights = new ApplicationInsights({
          config: {
            enableAutoRouteTracking: false,
            extensions: [this.angularPlugin],
            extensionConfig: {
              [this.angularPlugin.identifier]: {
                router: this.router,
                error: new ErrorHandler(),
              },
            },
            ...insightsConfiguration.applicationInsights,
          },
        });
        this.eventLevel = insightsConfiguration.eventLevel;
        this.trackPageViewEvent = insightsConfiguration.trackPageView;
        this.appInsights.loadAppInsights();
        this.appInsights.clearAuthenticatedUserContext();
        this.setContextData(insightsConfiguration.customProperties);
      }
    });
  }

  setContextData(customProperties?: Record<string, string>): void {
    if (customProperties) {
      this.customProperties = this.customProperties
        ? { ...this.customProperties, ...customProperties }
        : customProperties;
    }

    if (!this.appInsights || !this.session) return;

    this.appInsights.addTelemetryInitializer((envelope) => {
      if (envelope.baseType === 'PageviewData' && !this.trackPageViewEvent) {
        return false;
      }
      this.addTelemetryData(envelope);
      return true;
    });
    this.appInsights.setAuthenticatedUserContext(
      this.session.user.key,
      this.session.database,
      true,
    );
  }

  private addTelemetryData(envelope: ITelemetryItem): void {
    try {
      envelope.data = {
        ...(envelope.data ?? {}),
        ...(this.customProperties ?? {}),
        workspaceId: this.session?.workspace,
        databaseId: this.session?.database,
        userKey: this.session?.user.key,
        userId: this.session?.user.id,
        alias: this.session?.alias,
      };

      if (envelope.baseType == 'PageviewData' && envelope.baseData) {
        const uri = envelope.baseData['uri'];
        if (!uri) return;

        const result: [string] = uri.split('/');
        const name =
          result.length > 0 ? result[result.length - 1] : 'maximizer';
        envelope.baseData['name'] = 'page-' + name;
      }
    } catch (error) {
      console.warn('AppInsights failed to load telemetry data', error);
    }
  }

  trackEvent(
    event: IEventTelemetry,
    level: SeverityLevel = SeverityLevel.Information,
    customProperties?: ICustomProperties,
  ): this {
    try {
      if (level >= this.eventLevel) {
        this.appInsights?.trackEvent(event, customProperties);
      }
    } catch (error) {
      console.warn('AppInsights failed to track event:', error);
    }
    return this;
  }

  trackPageView(pageView?: IPageViewTelemetry): this {
    try {
      this.appInsights?.trackPageView(pageView);
    } catch (error) {
      console.warn('AppInsights failed to track page view:', error);
    }
    return this;
  }

  trackException(
    exception: IExceptionTelemetry,
    properties?: ICustomProperties,
  ): this {
    try {
      this.appInsights?.trackException(exception, properties);
    } catch (error) {
      console.warn('AppInsights failed to track exception:', error);
    }
    return this;
  }

  trackTrace(message: ITraceTelemetry, properties?: ICustomProperties): this {
    try {
      this.appInsights?.trackTrace(message, properties);
    } catch (error) {
      console.warn('AppInsights failed to track page view:', error);
    }
    return this;
  }

  trackDependencyData(dependency: IDependencyTelemetry): this {
    try {
      this.appInsights?.trackDependencyData(dependency);
    } catch (error) {
      console.warn('AppInsights failed to track dependency data:', error);
    }
    return this;
  }

  flush(): this {
    try {
      this.appInsights?.flush();
    } catch (error) {
      console.warn('AppInsights failed to flush:', error);
    }
    return this;
  }
}
