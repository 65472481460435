/* eslint-disable @typescript-eslint/no-explicit-any */

export async function tryOfficeAsync<T>(
  officeMethod: (arg: any, callback: (asyncResult: Office.AsyncResult<T>) => void) => void,
  mapOnlyFirstArg = false,
  ...args: any[]
): Promise<T | null> {
    return new Promise<T | null>((resolve) => {
      const methodArgs = mapOnlyFirstArg ? args[0] : args;
      officeMethod(methodArgs, (asyncResult: Office.AsyncResult<T>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          resolve(asyncResult.value ?? null);
        } else {
          resolve(null);
        }
      });
    });
  }


  export async function tryOfficeWithFallbackAsync<T>(
    officeMethod: (arg: any, callback: (asyncResult: Office.AsyncResult<T>) => void) => void,
    officeMethodAlternative: (arg: any, callback: (asyncResult: Office.AsyncResult<T>) => void) => void,
    mapOnlyFirstArg = false,
    ...args: any[]
  ): Promise<T | null> {
      return new Promise<T | null>((resolve) => {
        const methodArgs = mapOnlyFirstArg ? args[0] : args;
        officeMethod(methodArgs, (asyncResult: Office.AsyncResult<T>) => {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            resolve(asyncResult.value);
          } else {
            officeMethodAlternative(methodArgs, (asyncResultAlternative: Office.AsyncResult<T>) => {  
              if (asyncResultAlternative.status === Office.AsyncResultStatus.Succeeded) {
                resolve(asyncResultAlternative.value);
              } else {
                resolve(null);
              }
            });
          }
        });
      });
    }