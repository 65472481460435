import { LeadSearch, LeadStatus } from '../../../models';
import { Lead as OctopusLead } from '../models';
import { ConfigurationMapper } from './configuration';

export class LeadSearchMapper extends ConfigurationMapper<
  OctopusLead,
  LeadSearch
> {
  from(source: OctopusLead): LeadSearch {
    return {
      key: source.Key ?? '',
      name: `${source.FirstName} ${source.LastName}`.trim(),
      companyName: source.CompanyName,
      email: source.Email,
      lastModifiedDate: source.LastModifyDate?.Value,
      lastModifiedDisplayValue: source.LastModifyDate?.DisplayValue,
      phone1: source.Phone1?.Number,
      phone2: source.Phone2?.Number,
      status: source.Status as LeadStatus,
    };
  }
}
