export const environment = {
  production: true,
  cdn: 'https://cdn-maximizer-dev.azureedge.net',
  launchDarklyClientId: '643ede7c67be3b12d67ac7c1',
  smartFill: 'https://smartfill-prod.maximizer.com',
  instrumentationKey: '00c46e52-bfa2-496e-be2f-1c2a2bc90131',
  product: 'outlook',
  oauth: {
    globalServicesKey: 'f656d2e5d3d448c192cf60d6d366e60d',
    globalServicesUrl: 'https://api.maximizer.com/cloud',
    oauth2ClientId: 'b9o5qt6y9t863u1mox9c',
    oauth2ClientIdEmea: 'xl9493py0a6igchb2fo5',
  },
  integration: {
    url: 'https://api.maximizer.com/integration',
    subscriptionKey: '01b4d72b9c094057a5af9ba185092855',
  },
  appStoreOAuthUrl:
    'https://appstoresrv.maximizer.com/appstore/midwareolsync2/index',
  appStoreConfigurationUrl:
    'https://appstoresrv.maximizer.com/appstore/midwareolsync2/configurationhandler',
};
