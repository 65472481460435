/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { PopupComponent } from '@progress/kendo-angular-popup';

@Directive({ selector: 'kendo-popup' })
export class KendoPopupDirective {
  @Output() outsideClick = new EventEmitter<void>();

  popup?: PopupComponent;

  constructor(
    private elementRef: ElementRef,
    view: ViewContainerRef,
  ) {
    this.popup = view.injector.get(PopupComponent);
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement) {
    const anchor =
      (this.popup?.anchor as ElementRef).nativeElement ??
      (this.popup?.anchor as HTMLElement);
    const clickedOutside =
      !this.elementRef.nativeElement.contains(targetElement) &&
      !anchor.contains(targetElement) &&
      anchor !== targetElement;
    if (clickedOutside) {
      this.outsideClick.emit();
    }
  }
}
