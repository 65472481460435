import { EntryType } from '../../../models';
import { Case } from './case';
import { CustomColumItem } from './columns';
import { InteractionType } from './interaction-type';
import { KeyAndDisplay } from './key-and-display';
import { Opportunity } from './opportunity';
import { ReadRequest, Request, RequestConfiguration } from './request';
import { DataResponse, Response } from './response';
import { ValueAndDisplay } from './value-and-display';

export interface InteractionWith {
  Opportunity: Opportunity;
  Case: Case;
}
export interface Interaction {
  Key: string | null;
  ParentKey: string | null;
  Source: string | null;
  Type: InteractionType;
  Subject: string;
  With: InteractionWith;
  StartDate: ValueAndDisplay<string>;
  EntryName: { Value: string };
  Direction: number;
  EntryType: CustomColumItem<EntryType>;
  AbEntries: KeyAndDisplay[];
  Leads: KeyAndDisplay[];
}

export interface InteractionReadRequest extends Request {
  Interaction: ReadRequest<Interaction>;
}

export interface InteractionSearchResponse extends Response {
  Interaction: DataResponse<Interaction>;
}

export const InteractionReadDriver: RequestConfiguration = {
  Drivers: {
    IInteractionEntrySearcher: 'Maximizer.Model.Access.Sql.InteractionSearcher',
  },
};
