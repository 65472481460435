import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@progress/kendo-angular-editor';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { LabelModule } from '@progress/kendo-angular-label';
import { RichTextEditorComponent } from './rich-text-editor.component';
import { UiPipesModule } from '../../pipes/pipes.module';
import { UiLoaderModule } from '../loader/loader.module';
import { CustomToolAlignComponent } from './rich-text-editor.custom-align.component';
@NgModule({
  imports: [
    ButtonsModule,
    PopupModule,
    CommonModule,
    EditorModule,
    InputsModule,
    FormsModule,
    LabelModule,
    ReactiveFormsModule,
    UiLoaderModule,
    UiPipesModule,
  ],
  exports: [RichTextEditorComponent],
  declarations: [RichTextEditorComponent, CustomToolAlignComponent],
  providers: [],
})
export class UiRichTextEditorModule {}
