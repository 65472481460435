import { SecurityAccess } from "../api/octopus"

export interface CreateDocument {
    binaryDataId: string
    dateTime: Date, 
    name: string, 
    description?: string, 
    extension: string,
    type: DocumentType, 
    size: number, 
    parentKey: string,
    secAccess?: SecurityAccess
}


export enum DocumentType { 
    MaximizerDocuments = 1, 
    Emails = 3, 
    LinkedDocuments = 8, 
    HyperlinkDocuments = 12, 
    Others = 7
}
