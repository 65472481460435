import { ConfigurationMapper } from './configuration';
import { NotificationSetup as OctopusNotification } from '../models';
import { NotificationSetup } from '../../../models';

export class NotificationExportConfigurationMapper extends ConfigurationMapper<
  OctopusNotification,
  NotificationSetup
> {
  from(source: OctopusNotification): NotificationSetup {
    return {
      key: source.Key ?? '',
      name: source.Name,
      entityType: source.EntityType,
      tag: source.Tag,
    };
  }
}
