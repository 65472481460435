export enum FieldType {
  AbEntryKey = 'AbEntryKey',
  AbEntryObject = 'AbEntryObject',
  AddressObject = 'AddressObject',
  BooleanField = 'BooleanField',
  CampaignKey = 'CampaignKey',
  CampaignObject = 'CampaignObject',
  CaseKey = 'CaseKey',
  CurrencyField = 'CurrencyField',
  DateTimeField = 'DateTimeField',
  DurationField = 'DurationField',
  EmailField = 'EmailField',
  EnumFieldOfStringItem = 'EnumField<StringItem>',
  EnumFieldOfPartnerCompetitor = 'EnumField<PartnerCompetitorObject>',
  EnumFieldOfEntityKey = 'EnumField<MxEntityKey>',
  EnumFieldOfUidKey = 'EnumField<UidKey>',
  NumericField = 'NumericField',
  OpportunityKey = 'OpportunityKey',
  PhoneField = 'PhoneField',
  RefIntegerField = 'RefIntegerField',
  RefLongField = 'RefLongField',
  SalesTeamKey = 'SalesTeamKey',
  SalesTeamObject = 'SalesTeamObject',
  SalesProcessSetupKey = 'SalesProcessSetupKey',
  SalesProcessSetupObject = 'SalesProcessSetupObject',
  SalesProcessKey = 'SalesProcessKey',
  SalesProcessObject = 'SalesProcessObject',
  SalesStageSetupKey = 'SalesStageSetupKey',
  SalesStageSetupObject = 'SalesStageSetupObject',
  SalesStageKey = 'SalesStageKey',
  SalesStageObject = 'SalesStageObject',
  SecAccess2LvlField = 'SecAccess2LvlField',
  SecAccessField = 'SecAccessField',
  SecStatusField = 'SecStatusField',
  StringField = 'StringField',
  TerritoryKey = 'TerritoryKey',
  TerritoryObject = 'TerritoryObject',
  UidKey = 'UidKey',
}
