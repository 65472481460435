import { KeyAndDisplay } from './key-and-display';

export interface InteractionType extends KeyAndDisplay {
  Icon: number;
  Inactive: boolean;
  External: boolean;
}

export enum InteractionCode {
  CALL = 60001,
  EMAIL = 60002,
  APPOINTMENT = 60003,
  TASK = 60004,
  SMS = 60005,
  CHAT = 60006,
  NOTE = 60007,
  DOCUMENT = 60008,
}

export type InteractionCodeString = `${InteractionCode}`;

export class RecurringInteractions {
  static readonly Task = 'recurring-task';
  static readonly Appointment = 'recurring-appointment';
}

export class DefaultInteractions {
  static readonly CALL = '60001';
  static readonly EMAIL = '60002';
  static readonly APPOINTMENT = '60003';
  static readonly TASK = '60004';
  static readonly SMS = '60005';
  static readonly CHAT = '60006';
  static readonly NOTE = '60007';
  static readonly DOCUMENT = '60008';

  static isCall(value: string): boolean {
    return value === this.CALL;
  }
  static isEmail(value: string): boolean {
    return value === this.EMAIL;
  }
  static isAppointment(value: string): boolean {
    return value === this.APPOINTMENT;
  }
  static isTask(value: string): boolean {
    return value === this.TASK;
  }
  static isSms(value: string): boolean {
    return value === this.SMS;
  }
  static isChat(value: string): boolean {
    return value === this.CHAT;
  }
  static isNote(value: string): boolean {
    return value === this.NOTE;
  }
  static isDocument(value: string): boolean {
    return value === this.DOCUMENT;
  }
  static isDefaultInteraction(value: string): boolean {
    return (
      this.isCall(value) ||
      this.isEmail(value) ||
      this.isAppointment(value) ||
      this.isTask(value) ||
      this.isSms(value) ||
      this.isChat(value) ||
      this.isNote(value) ||
      this.isDocument(value)
    );
  }
}

export class DefaultNotes {
  static readonly MANUAL = 0;
}
