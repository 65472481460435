export interface UserSyncConfiguration {
  id: string;
  enabled: boolean;
  contactsSync: {
    enabled: boolean;
    favoriteListKey: string;
    favoriteListName: string;
    outlookCategory: string;
  };
  tasksSync: {
    enabled: boolean;
  };
  appointmentsSync: {
    enabled: boolean;
  };
  emailsSync: {
    enabled: boolean;
    saveIncomingEmails: boolean;
    saveOutgoingEmails: boolean;
  };
}

export interface UpdateSyncConfiguration {
  maximizerUserUid: string;
  contactsSync: {
    enabled: boolean;
    favoriteListKey: string;
    favoriteListName: string;
    outlookCategory: string;
  };
  tasksSync: {
    enabled: boolean;
  };
  appointmentsSync: {
    enabled: boolean;
  };
  emailsSync: {
    enabled: boolean;
  };
}

export enum OutlookSyncEntityType {
  AbEntry = 1,
  Lead = 2,
}

export enum OutlookSyncStatus {
  Enqueued = 1,
  Syncing = 2,
  Complete = 3,
  Warning = 4,
  Problem = 5,
}

export enum OutlookSyncType {
  Contacts = 1,
  Appointments = 2,
  Tasks = 3,
  Emails = 4,
}
