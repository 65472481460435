export function joinNonEmpty(source: string[], separator: string): string {
  return source.filter((text) => text?.length > 0).join(separator);
}

export function capitalize(value?: string): string {
  return value
    ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    : '';
}

export function decapitalize(value: string): string {
  return value.charAt(0).toLowerCase() + value.slice(1).toLowerCase();
}

export function extractDomain(url: string): string {
  if (url) {
    try {
      const domain = new URL(url);
      return domain.hostname.replace('www.', '');
    } catch {
      return '';
    }
  }
  return '';
}

export function repeat(value: string, times: number): string {
  return times > 0 ? value.repeat(times) : '';
}

export function normalizeLineBreak(value: string): string {
  return value.replace(/\\r\\n/g, '\r\n');
}

export function escapeCharacters(
  value: string,
  charactersToEscape: string,
): string {
  const escapeRegex = new RegExp(
    `[${charactersToEscape
      .split('')
      .map((char) => '\\' + char)
      .join('')}]`,
    'g',
  );
  return value.replace(escapeRegex, '\\$&');
}

export function replaceChar(
  input: string,
  indexToReplace: number,
  replacementChar: string,
): string {
  return (
    input.substring(0, indexToReplace) +
    replacementChar +
    input.substring(indexToReplace + 1)
  );
}

export function cleanUpJsonString(json: string): string {
  const cleanedJson = json
    .replace(/\r\n/g, '') // Remove '\r\n'
    .replace(/\r/g, '') // Remove '\r'
    .replace(/\n/g, '') // Remove '\n'
    .replace(/\\/g, '') // Remove other escape characters
    .replace(/,\s*}/, '}'); // Remove trailling comma

  // Check if the string is enclosed in curly braces, if not, add them
  return cleanedJson.startsWith('{') && cleanedJson.endsWith('}')
    ? cleanedJson
    : `{${cleanedJson}}`;
}

export function isNullOrEmpty(value?: string): boolean {
  return value === null || value === undefined || value === '';
}

export function ifWhitespaceThenEmpty(value: string) {
  return isNullOrEmpty(value?.trim()) ? '' : value;
}

export function ifNullOrUndefinedThenEmpty(value?: string | null) : string {
  return value ?? '';
}

export function hasValuesAndNotEqual(value1: string, value2: string): boolean {
  return !isNullOrEmpty(value1) && !isNullOrEmpty(value2) && value1 !== value2;
}

export function replaceStringWithValues(
  value: string,
  ...replacements: (string | number)[]
): string {
  let result = value;
  for (let i = 0; i < replacements.length; i++) {
    const regex = new RegExp(`\\{${i}\\}|\\$${i}`, 'g');
    result = result.replace(regex, String(replacements[i]));
  }
  return result;
}

export function replaceLastCoincide(
  value: string,
  replacement: string,
  searchValue: string,
): string {
  const lastIndex = value.lastIndexOf(searchValue);

  if (lastIndex !== -1) {
    return (
      value.substring(0, lastIndex) +
      replacement +
      value.substring(lastIndex + 1)
    );
  } else {
    return value;
  }
}

export function generateStrongPassword(length: number): string {
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numberChars = '0123456789';
  const specialChars = '!@#$%&_';

  let password = '';

  // Ensure at least one character of each type
  password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
  password += numberChars[Math.floor(Math.random() * numberChars.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  const remainingLength = length - 3; // Subtracting 3 because we already added one of each required type

  // Generate remaining characters
  for (let i = 0; i < remainingLength; i++) {
    const charSet =
      lowercaseChars + uppercaseChars + numberChars + specialChars;
    password += charSet[Math.floor(Math.random() * charSet.length)];
  }

  // Shuffle the characters
  password = password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');

  return password;
}

export function splitStringWithIndex(text: string, splitCharSize: number): { index: number, text: string }[] {
  const result: { index: number, text: string }[] = [];

  for (let i = 0; i < text.length; i += splitCharSize) {
    result.push({
      index: i / splitCharSize,
      text: text.slice(i, i + splitCharSize)
    });
  }

  return result;
}

export function hasMinimumLength(phrase: string, minChars: number): boolean {
  return phrase.trim().length >= minChars;
}
