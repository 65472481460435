import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { Field, FieldType } from '@maximizer/core/shared/domain';
import { StringFieldComponent } from './string-field.component';
import { SelectorFieldComponent } from './selector-field.component';
import { DateFieldComponent } from './date-field.component';
import { NumberFieldComponent } from './number-field.component';
import { DisplayFieldComponent } from './display-field.component';

@Component({
  selector: 'maximizer-dynamic-field',
  template: ``,
})
export class DynamicFieldComponent implements OnInit, OnChanges {
  @Input({ required: true })
  field!: Field;

  componentTypes: { [key in FieldType]: Type<unknown> | null } = {
    string: StringFieldComponent,
    number: NumberFieldComponent,
    dateTime: DateFieldComponent,
    selector: SelectorFieldComponent,
    display: DisplayFieldComponent,
  };
  component?: ComponentRef<unknown>;

  constructor(
    private detector: ChangeDetectorRef,
    private view: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.createField();
  }

  ngOnChanges(): void {
    if (this.component) {
      this.component.setInput('field', this.field);
    }
  }

  private createField() {
    const componentType = this.componentTypes[this.field.type ?? 'display'];
    if (componentType) {
      this.component = this.view.createComponent(componentType);
      this.component.setInput('field', this.field);
      this.detector.detectChanges();
    }
  }
}
