import { Mapper } from '../../../mappers';
import { EntryType, InteractionSearch, RelatedEntry } from '../../../models';
import {
  Interaction as OctopusInteraction,
  InteractionWith as OctopusInteractionWith,
} from '../models/interaction';

export class InteractionSearchMapper extends Mapper<
  OctopusInteraction,
  InteractionSearch
> {
  from(source: OctopusInteraction): InteractionSearch {
    return {
      key: source.Key,
      subject: source.Subject,
      direction: source.Direction,
      source: source.Source,
      type: {
        display: source.Type.DisplayValue,
        type: source.Type.Key,
        icon: source.Type.Icon,
        external: source.Type.External,
      },
      name: source.EntryName.Value,
      abEntryKey: source.AbEntries
        ? source.AbEntries.map((item) => item.Key)[0]
        : null,
      leadKey: source.Leads ? source.Leads.map((item) => item.Key)[0] : null,
      regardingEntryType: this.retrieveEntryType(source.With),
      lastUpdateDisplayValue: source.StartDate?.DisplayValue,
      lastUpdateValue: source.StartDate?.Value,
      regarding: this.retrieveRegarding(source.With),
      withKey: this.retrieveWithKey(source.With),
      nameEntryType: {
        type: source.EntryType?.Value?.trim() as EntryType,
        display: '',
        id: '',
      },
    };
  }

  private retrieveEntryType(
    val: OctopusInteractionWith,
  ): RelatedEntry | undefined {
    if (val?.Case) return { type: 'case', display: '', id: '' };
    if (val?.Opportunity) return { type: 'opportunity', display: '', id: '' };
    return undefined;
  }

  private retrieveRegarding(val: OctopusInteractionWith): string {
    return val?.Case?.Subject || val?.Opportunity?.Objective || '';
  }

  private retrieveWithKey(val: OctopusInteractionWith): string {
    return val?.Case?.Key || val?.Opportunity?.Key || '';
  }
}
