<div class="flex p-4 bg-max-white justify-between">
  <div>
    <div
      class="h-8 w-8 bg-neutral-20 rounded-md flex items-center justify-center"
    >
      <span class="svg-icons icon-opportunity-in-progress icon-16 mt-1"></span>
    </div>
  </div>
  <div class="flex-grow px-2 flex flex-col gap-y-1">
    <p class="text-body line-clamp-1">{{ opportunity.objective }}</p>
    <p class="caption line-clamp-1">{{ opportunity.abEntry.companyName }}</p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.opportunity.stage' | translate }}:
      {{ opportunity.currentStage }}
    </p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.opportunity.contact' | translate }}:
      {{ opportunity.contact?.firstName }} {{ opportunity.contact?.lastName }}
    </p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.opportunity.closeDate' | translate }}:
      {{ opportunity.closeDate }}
    </p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ opportunity.forecastRevenue?.DisplayValue }}
    </p>
    @if (opportunity.owner) {
      <div>
        <p class="text-body py-1">
          {{ 'outlook.opportunity.owner' | translate }}:
        </p>
        <div class="flex items-center">
          <span class="avatar avatar-xs">{{
            opportunity.owner.display | nameInitials
          }}</span>
          <p class="text-body pl-1">{{ opportunity.owner.display }}</p>
        </div>
      </div>
    }
  </div>
  <div>
    <button
      kendoButton
      fillMode="flat"
      [disabled]="opportunity.isSaved || !rights?.update"
      (click)="save.emit(opportunity.key)"
      [iconClass]="
        opportunity.isSaved
          ? 'svg-icons icon-saved-email text-neutral-20 icon-20'
          : !rights?.update
            ? 'maximizer-icons icon-save-email text-neutral-20 icon-24'
            : 'maximizer-icons icon-save-email text-inserv-100 icon-24'
      "
    ></button>
  </div>
</div>
