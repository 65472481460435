import { NgModule } from '@angular/core';
import { AvatarPipe } from './avatar.pipe';
import { CurrencyFormatPipe } from './currency-format.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { FileIconPipe } from './file-icon.pipe';
import { FormatFileSizePipe } from './file-size.pipe';
import { FormatPipe } from './format.pipe';
import { HighlightTextPipe } from './highlight-text.pipe';
import { InteractionIconPipe } from './interaction-icon.pipe';
import { NameInitialsPipe } from './name-initials.pipe';
import { NoteIconPipe } from './note-icon.pipe';
import { RelatedEntryIconPipe } from './related-entry-icon.pipe';
import { RelativeDatePipe } from './relative-date.pipe';
import { ReplacePipe } from './replace.pipe';
import { ResourceOrTranslatePipe } from './resource-or-translate.pipe';
import { SumPipe } from './sum.pipe';
import { TemplatePipe } from './template.pipe';
import { TextTruncatePipe } from './text-truncate.pipe';
import { QuarterFormatPipe } from './quarter-format.pipe';
import { LazyArrayPipe } from './lazy-array.pipe';
import { RegexReplacePipe } from './regex-replace.pipe';

@NgModule({
  imports: [],
  exports: [
    AvatarPipe,
    CurrencyFormatPipe,
    FileIconPipe,
    FormatFileSizePipe,
    DateFormatPipe,
    FormatPipe,
    HighlightTextPipe,
    InteractionIconPipe,
    LazyArrayPipe,
    NameInitialsPipe,
    NoteIconPipe,
    RelatedEntryIconPipe,
    RelativeDatePipe,
    ReplacePipe,
    RegexReplacePipe,
    ResourceOrTranslatePipe,
    SumPipe,
    TemplatePipe,
    TextTruncatePipe,
    QuarterFormatPipe,
  ],
  declarations: [
    AvatarPipe,
    CurrencyFormatPipe,
    FileIconPipe,
    FormatFileSizePipe,
    DateFormatPipe,
    FormatPipe,
    HighlightTextPipe,
    InteractionIconPipe,
    LazyArrayPipe,
    NameInitialsPipe,
    NoteIconPipe,
    RelatedEntryIconPipe,
    RelativeDatePipe,
    ReplacePipe,
    RegexReplacePipe,
    ResourceOrTranslatePipe,
    SumPipe,
    TemplatePipe,
    TextTruncatePipe,
    QuarterFormatPipe,
  ],
})
export class UiPipesModule {}
