import { Octopus } from '@maximizer/core/shared/domain';
import { CaseCard, CaseFieldOption } from '../../../models';

export class CaseCardMapper extends Octopus.ConfigurationMapper<
  Octopus.Case,
  CaseCard
> {
  override from(source: Octopus.Case): CaseCard {
    return {
      key: source.Key,
      parentKey: source.ParentKey,
      subject: source.Subject,
      caseNumber: source.CaseNumber,
      status: source.Status?.DisplayValue ?? null,
      abEntry: {
        key: source.AbEntry.Key ?? null,
        companyName: this.mapCompanyOrIndividual(source.AbEntry),
      },
      contact: source.Contact
        ? {
            key: source.Contact.Key,
            firstName: source.Contact.FirstName,
            lastName: source.Contact.LastName,
          }
        : null,
      owner: source.Owner
        ? {
            value: source.Owner?.Value ?? '',
            display: source.Owner?.DisplayValue ?? '',
          }
        : null,
      isSaved: false,
    };
  }

  private mapCompanyOrIndividual(entry?: Octopus.AbEntry): string {
    if (entry) {
      switch (entry.Type) {
        case Octopus.AbEntryType.Company:
          return entry.CompanyName;
        case Octopus.AbEntryType.Individual:
          return `${entry.FirstName} ${entry.LastName}`.trim();
      }
    }

    return '';
  }
}

export class CaseFieldOptionsMapper extends Octopus.ConfigurationMapper<
  Octopus.CaseFieldOptionsResponse,
  CaseFieldOption
> {
  from(source: Octopus.CaseFieldOptionsResponse): CaseFieldOption {
    const csCase = source.Case?.FieldOptions;

    return {
      status: csCase?.Status?.map(this.mapValueAndDisplay) ?? [],
      subject: csCase?.Subject?.map(this.mapValueAndDisplay) ?? [],
      product: csCase?.Product?.map(this.mapValueAndDisplay) ?? [],
      category: csCase?.Category?.map(this.mapValueAndDisplay) ?? [],
    };
  }
}
