<div class="bg-neutral-10">
  <div class="bg-neutral-90 h-8 flex text-white justify-between items-center">
    @if (onboardingStore.inProgress() && supportedOnboardingPage) {
      <div
        class="bg-neutral-80 h-8 flex items-center justify-between pl-4 w-[160px]"
      >
        <div class="flex items-center">
          <span
            class="svg-icons icon-onboarding icon-16 h-4 w-4 flex-shrink-0"
          ></span>
          <span class="subtitle-1 leading-none px-2">
            {{ 'outlook.onboarding.title' | translate }}
          </span>
        </div>
        <button
          kendoButton
          fillMode="flat"
          iconClass="fa-icons icon-xmark icon-12 "
          [attr.aria-label]="'outlook.onboarding.exitOnboarding' | translate"
          (click)="this.exit.emit()"
        ></button>
      </div>
    } @else {
      <div class="flex items-center">
        @if (!onboardingStore.inProgress()) {
          @if (!hideBack) {
            <button
              kendoButton
              fillMode="flat"
              iconClass="fa-icons icon-regular icon-16 icon-chevron-left"
              [attr.aria-label]="'outlook.buttons.back' | translate"
              (click)="goBack()"
            ></button>
          }
          <p class="subtitle-1 pl-4 line-clamp-1">{{ pageTitle }}</p>
        }
      </div>
    }
    @if (!showOnlyTitle) {
      <div class="flex gap-1">
        @if (
          onboardingStore.isOnboardingVisible() &&
          !onboardingStore.inProgress() &&
          supportedOnboardingPage &&
          outlookStore.doesGraphAccountMatchInbox() &&
          outlookStore.hasActiveAccount()
        ) {
          <button
            kendoButton
            fillMode="flat"
            iconClass="svg-icons icon-onboarding icon-16"
            (click)="startOnboarding = true"
            [attr.aria-label]="'outlook.onboarding.startOnboarding' | translate"
          ></button>
        }
        <kendo-dropdownbutton
          id="outlook-menu-ui-menu-open"
          fillMode="flat"
          [attr.aria-label]="'outlook.menu.label' | translate"
          iconClass="fa-icons icon icon-16 icon-bars"
          [data]="menu"
          [disabled]="
            onboardingStore.isOnboardingVisible() &&
            onboardingStore.inProgress() &&
            supportedOnboardingPage
          "
        >
          <ng-template kendoDropDownButtonItemTemplate let-dataItem>
            <div
              id="outlook-menu-ui-menu-{{ dataItem.id }}"
              class="w-full flex items-center max-w-[250px]"
              [class]="dataItem.class"
            >
              @if (dataItem.icon) {
                <span [class]="dataItem.icon" class="mr-2"></span>
              }
              <p
                kendoTooltip
                [tooltipTemplate]="tooltipTemplate"
                position="bottom"
                [callout]="false"
                [title]="dataItem.text"
                class="overflow-hidden text-ellipsis whitespace-nowrap"
                [removeIfNotOverflow]="true"
              >
                {{ dataItem.text }}
              </p>
            </div>
          </ng-template>
        </kendo-dropdownbutton>
      </div>
    }
  </div>

  @if (!this.outlookStore.hasActiveAccount()) {
    @defer (on timer (500ms)) {
      <maximizer-outlook-msal-account-warning></maximizer-outlook-msal-account-warning>
    }
  } @else if (
    !outlookStore.doesGraphAccountMatchInbox() &&
    this.outlookStore.hasActiveAccount() &&
    hideMsalMismatchDialog
  ) {
    @defer (on timer(1000ms)) {
      <maximizer-outlook-msal-mismatch-error></maximizer-outlook-msal-mismatch-error>
    }
  }
</div>

@if (onboardingStore.inProgress() && supportedOnboardingPage) {
  <maximizer-outlook-onboarding-stepper
    [@slideDown]
  ></maximizer-outlook-onboarding-stepper>
}

<ng-template #tooltipTemplate let-anchor>
  <p class="caption text-neutral-90 whitespace-nowrap">
    {{ anchor.nativeElement.getAttribute('data-title') }}
  </p>
</ng-template>

@if (startOnboarding) {
  <maximizer-outlook-onboarding-celebration
    (action)="handleOnboardingAction($event)"
    [step]="'start'"
  ></maximizer-outlook-onboarding-celebration>
}

@if (showRemindLater) {
  <maximizer-outlook-onboarding-remind-later
    (showRemindLater)="showRemindLater = $event"
  ></maximizer-outlook-onboarding-remind-later>
}

@if (!this.outlookStore.hasActiveAccount() && !hideMsalLoginDialog) {
  <maximizer-outlook-msal-login-message
    (hideMsalLoginDialog)="hideMsalLoginDialog = $event"
  ></maximizer-outlook-msal-login-message>
}

@if (
  !outlookStore.doesGraphAccountMatchInbox() &&
  this.outlookStore.hasActiveAccount() &&
  !hideMsalMismatchDialog
) {
  @defer (on timer(500ms)) {
    <maximizer-outlook-msal-mismatch-message
      (hideMsalMismatchDialog)="hideMsalMismatchDialog = $event"
    ></maximizer-outlook-msal-mismatch-message>
  }
}

@if (openSyncSettings) {
  <maximizer-outlook-sync-settings
    (openSyncSettings)="openSyncSettings = $event"
  ></maximizer-outlook-sync-settings>
}
