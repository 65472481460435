import {
  OnInit,
  Component,
  HostBinding,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  CountryListItem,
  LocaleService,
  SessionStorageService,
} from '@maximizer/core/shared/data-access';
import { CDN_URL } from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
} from '@maximizer/core/shared/ui';
import {
  LeadService,
  OutlookService,
} from '@maximizer/outlook/shared/data-access';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, forkJoin } from 'rxjs';
import { Validators } from '@angular/forms';
import { OutlookLeadForm } from '@maximizer/outlook/shared/domain';
import { ActivatedRoute, Router } from '@angular/router';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';

@Component({
  selector: 'maximizer-add-lead',
  templateUrl: './add-lead.component.html',
})
export class AddLeadComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;
  constructor(
    @Inject(CDN_URL) public cdn: string,
    private translate: TranslateService,
    private leadService: LeadService,
    private localService: LocaleService,
    private route: ActivatedRoute,
    private router: Router,
    private storage: SessionStorageService,
    private outlookService: OutlookService,
  ) {}

  leadKey = '';
  canSave = true;
  loading = true;
  addPhone2 = false;
  defaultCountry?: CountryListItem;

  leadData?: OutlookLeadForm;
  form?: FormGroupWrapper<OutlookLeadForm>;
  countries?: CountryListItem[];

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  private readonly newPartitionKey = 'new-item';

  ngOnInit(): void {
    this.defaultCountry = {
      id: '',
      name: this.translate.instant('outlook.lead.country-placeholder'),
      flag: '',
    };
    this.loadData();
  }

  private setForm(): void {
    const email = this.route.snapshot.queryParamMap.get('email');
    const name = this.route.snapshot.queryParamMap.get('name');
    const fullName = name?.split(' ') ?? ['', email ?? ''];
    let firstName = '';
    let lastName = '';
    if (fullName.length == 1) lastName = fullName[0];
    else {
      firstName = fullName[0] ?? '';
      lastName = fullName[1] ?? '';
    }

    this.form = FormWrapperBuilder.group<OutlookLeadForm>(
      {
        key: null,
        firstName: [firstName, [Validators.maxLength(79)]],
        lastName: [lastName, [Validators.required, Validators.maxLength(79)]],
        email: [email, []],
        address1: [null, [Validators.maxLength(79)]],
        address2: [null, [Validators.maxLength(79)]],
        phone1: [
          null,
          [Validators.maxLength(21), Validators.pattern(/^[\d\s().\-_]*$/)],
        ],
        phone2: [
          null,
          [Validators.maxLength(21), Validators.pattern(/^[\d\s().\-_]*$/)],
        ],
        company: [null, [Validators.maxLength(79)]],
        position: [null, [Validators.maxLength(79)]],
        website: [null, [Validators.maxLength(255)]],
        city: [null, [Validators.maxLength(79)]],
        province: [null, [Validators.maxLength(79)]],
        postalCode: [null, [Validators.maxLength(79)]],
        country: [null, [Validators.maxLength(79)]],
      },

      this.save,
      () => {
        this.form?.control.setErrors(null);
        if (this.form?.valid) {
          this.canSave = true;
        } else {
          this.canSave = false;
        }
      },
    );
  }

  private loadData(): void {
    forkJoin({
      countries: this.localService.getCountries(),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
          return null;
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => {
        if (!data) return;

        if (data.countries) this.countries = data.countries;
        this.setForm();
      });
  }

  save() {
    if (!this.form) {
      return;
    }
    const validation = this.form.validate();
    if (!validation.valid) return;

    this.loading = true;

    this.leadService
      .save(this.form.value)
      .pipe(
        catchError(async () => {
          this.notification.show('create', 'error');
          return null;
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((result) => {
        if (!result) return;
        const existing = this.storage.get(
          this.outlookService.storagePartitionKey + this.newPartitionKey,
        );
        let entryArray = [];
        if (existing) {
          entryArray = JSON.parse(existing);
        }
        if (this.form) {
          entryArray.push(this.form.value.email);
          this.storage.set(
            this.outlookService.storagePartitionKey + this.newPartitionKey,
            JSON.stringify(entryArray),
          );
        }

        this.notification.show('create', 'success');
        this.router.navigate(['/'], { queryParams: {} });
      });
  }

  onRemovePhone() {
    this.form?.patch({ phone2: '' });
    this.addPhone2 = false;
  }

  cancel(): void {
    this.router.navigate(['/'], { queryParams: {} });
  }
}
