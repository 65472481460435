import {
  ReadRequest,
  Request,
  RequestConfiguration,
  WriteRequest,
} from './request';
import { DataResponse, Response, WriteResponse } from './response';
import { FieldOptions, FieldOptionsResponse } from './field-options';
import { AbEntry } from './ab-entry';
import { KeyAndDisplay, TypedKeyAndDisplay } from './key-and-display';
import { EntryKey } from './entry-key';
import { IdKey, UserKey } from './key';

export interface Task {
  Key: string;
  Creator: UserKey;
  AssignedTo: KeyAndDisplay;
  DateTime: string;
  CreationDate: string;
  Priority: string;
  Completed: string;
  Activity: string;
  AbEntryKey: EntryKey;
  LeadKey: EntryKey;
  With: { OpportunityObject: { Key: IdKey<string> } };
  Result: string;
  Category: string;
  AbEntry: AbEntry;
}
export interface TaskReadRequest extends Request {
  Task: ReadRequest<Task>;
}

export interface TaskResponse extends Response {
  Task: DataResponse<Task>;
}

export const TaskReadDriver: RequestConfiguration = {
  Drivers: {
    ITaskSearcher: 'Maximizer.Model.Access.Sql.TaskSearcher',
  },
};

export interface TaskFieldOptions {
  Category: TypedKeyAndDisplay<string>;
  Result: TypedKeyAndDisplay<string>;
  Priority: TypedKeyAndDisplay<string>;
}

export interface TaskFieldOptionsRequest extends Request {
  Task: FieldOptions<TaskFieldOptions>;
}

export interface TaskFieldOptionsResponse extends Response {
  Task: FieldOptionsResponse<TaskFieldOptions>;
}

export interface TaskCreate {
  Key: string | null;
  Activity: string;
  Datetime: Date;
  Alarm: Date | null;
  Priority: string | null;
  Category?: string | null;
  Result?: string | null;
  AssignedTo: {
    UID: string;
  };
  AbEntryKey: string | null;
  LeadKey: string | null;
}

export interface TaskCreateRequest extends Request {
  Task: WriteRequest<TaskCreate>;
}

export interface TaskCreateResponse extends Response {
  Task: WriteResponse<TaskCreate>;
}
