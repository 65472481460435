import { Entity, EntityFields, EntityCount } from './entity';
import { AbEntry } from './ab-entry';
import { FieldOptions, FieldOptionsResponse } from './field-options';
import {
  ReadRequest,
  Request,
  RequestConfiguration,
  WriteRequest,
} from './request';
import { Response, DataResponse, WriteResponse } from './response';
import { ValueAndDisplay } from './value-and-display';

export enum CaseFields {
  CaseNumber = 'CaseNumber',
  Subject = 'Subject',
  AbEntry = 'AbEntry',
  Contact = 'Contact',
  FollowUpDate = 'FollowUpDate',
  Status = 'Status',
  Severity = 'Severity',
  Priority = 'Priority',
  AssignedTo = 'AssignedTo',
  ResolvedBy = 'ResolvedBy',
  ResolvedDate = 'ResolvedDate',
  Overdue = 'Overdue',
  Age = 'Age',
  ParentKey = 'ParentKey',
  Owner = 'Owner',
}

export interface Case extends Entity {
  [EntityFields.Key]: string;
  [CaseFields.CaseNumber]: string;
  [CaseFields.Subject]: string;
  [CaseFields.AbEntry]: AbEntry;
  [CaseFields.Contact]: AbEntry;
  [EntityFields.LastModifyDate]: ValueAndDisplay<string>;
  [EntityFields.CreationDate]: ValueAndDisplay<string>;
  [CaseFields.Status]: ValueAndDisplay<string>;
  [CaseFields.Severity]: ValueAndDisplay<number>;
  [CaseFields.Priority]: ValueAndDisplay<number>;
  [CaseFields.ParentKey]: string;
  [CaseFields.Owner]: ValueAndDisplay<string>;
  [CaseFields.AssignedTo]: {
    Key: {
      Uid: string;
      Value: string;
    };
    DisplayValue: string;
  };
  [CaseFields.ResolvedBy]: {
    Key: {
      Uid: string;
      Value: string;
    };
    DisplayValue: string;
  };
  [CaseFields.Overdue]: boolean;
}

export interface CaseFieldOption {
  Status: StatusOptionsGroup;
  Priority: ValueAndDisplay<string>;
  Type: ValueAndDisplay<string>;
  Queue: ValueAndDisplay<string>;
  AssignedTo: ValueAndDisplay<string>;
  Subject: ValueAndDisplay<string>;
  Product: ValueAndDisplay<string>;
  Category: ValueAndDisplay<string>;
}

export interface StatusOptionsGroup {
  Value: string;
  DisplayValue: string;
  Group: [
    {
      Value: string;
      DisplayValue: string;
    },
  ];
}

export enum DefaultCaseStatus {
  Unassigned = '57999',
  Assigned = '57998',
  Escalated = '57997',
  CallBack = '57996',
  WaitingForCustomer = '57995',
  Abandoned = '57994',
  Resolved = '57993',
}

export enum StatusAvailability {
  Assign = '1',
  Escalate = '2',
  Resolve = '4',
}

export interface CaseCount extends EntityCount {
  'Case/AssignedTo': string;
}

export interface CaseCreate {
  Key?: string | null;
  Subject: string;
  Description: string;
  AbEntryKey: string;
  ContactKey: string | null;
  Status: string;
  Product: string[];
  Category: string[] | null;
}

export interface CaseReadRequest extends Request {
  Case: ReadRequest<Case>;
}

export interface CaseWriteRequest extends Request {
  Case: WriteRequest<CaseCreate>;
}

export interface CaseFieldOptionsRequest extends Request {
  Case: FieldOptions<CaseFieldOption>;
}

export interface CaseResponse extends Response {
  Case: DataResponse<Case>;
}

export interface CaseCountRequest extends Request {
  Case: ReadRequest<CaseCount>;
}

export interface CaseCountResponse extends Response {
  Case: DataResponse<CaseCount>;
}

export interface CaseWriteResponse extends Response {
  Case: WriteResponse<CaseCreate>;
}

export interface CaseFieldOptionsResponse extends Response {
  Case: FieldOptionsResponse<CaseFieldOption>;
}

export const CaseReadDriver: RequestConfiguration = {
  Drivers: {
    ICaseSearcher: 'Maximizer.Model.Access.Sql.CaseSearcher',
  },
};
