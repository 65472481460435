import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entity, ListItem, Octopus } from '@maximizer/core/shared/domain';
import { ContextService } from './context.service';
import { EntityService } from './entity.service';
import { MetadataService } from './metadata.service';
import { map, Observable } from 'rxjs';
import { FeatureService } from './feature.service';

@Injectable()
export class AddressBookService extends EntityService {
  private useSqlWriteDriver?: boolean;

  constructor(
    http: HttpClient,
    context: ContextService,
    metadata: MetadataService,
    private readonly feature: FeatureService,
  ) {
    super(Octopus.AbEntryEntityCode, http, context, metadata);
  }

  get readDriver(): Octopus.RequestConfiguration {
    return Octopus.AbEntryReadDriver;
  }

  get writeDriver(): Octopus.RequestConfiguration {
    if (this.useSqlWriteDriver === undefined) {
      this.useSqlWriteDriver = this.feature.isFeatureOn(
        'core-abentry-use-sql-driver',
        false,
      );
    }

    return this.useSqlWriteDriver
      ? Octopus.AbEntryWriteDriver
      : { Drivers: {} };
  }

  override getFieldsForDefaultEntry(): Octopus.Scope<Entity> {
    return {
      [Octopus.AbEntryFields.LastName]: this.getValueAndDisplayScope(),
    };
  }

  override getTypesScope(metadata = true): Octopus.Scope<Entity> {
    return {
      ...super.getTypesScope(metadata),
      AddressObject: this.getAddressScope(metadata),
      EmailField: this.getEmailScope(metadata),
      PhoneField: this.getPhoneScope(metadata),
      TerritoryObject: this.getKeyAndDisplayScope(metadata),
    };
  }

  getParentKey(key: string): Observable<string | null> {
    const request: Octopus.AbEntryReadRequest = {
      AbEntry: {
        Criteria: {
          SearchQuery: {
            Key: {
              $EQ: key,
            },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            ParentKey: 1,
          },
        },
      },
      Configuration: Octopus.AbEntryReadDriver,
    };

    return this.http
      .post<Octopus.AbEntryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            if (result.AbEntry.Data) {
              return (
                result.AbEntry.Data[0].ParentKey?.Value ??
                result.AbEntry.Data[0].Key ??
                null
              );
            }
          }
          return null;
        }),
      );
  }

  getParent(id: string[]): Observable<ListItem<string>[]> {
    const request: Octopus.AbEntryReadRequest = {
      AbEntry: {
        Criteria: {
          SearchQuery: {
            Key: {
              $IN: id,
            },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            DisplayValue: 1,
          },
        },
      },
      Configuration: Octopus.AbEntryReadDriver,
    };

    return this.http
      .post<Octopus.AbEntryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            return result.AbEntry.Data.map((entry) => {
              return {
                id: entry.Key ?? '',
                name: entry.DisplayValue,
              };
            });
          }
          return [];
        }),
      );
  }
  getReportsTo(parentKey: string): Observable<ListItem<string | null>[]> {
    const request: Octopus.AbEntryReadRequest = {
      AbEntry: {
        Criteria: {
          SearchQuery: {
            ParentKey: {
              $EQ: parentKey,
            },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            FullName: 1,
          },
        },
      },
      Configuration: Octopus.AbEntryReadDriver,
    };

    return this.http
      .post<Octopus.AbEntryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            return result.AbEntry.Data.map((entry) => {
              return {
                id: entry.Key ?? null,
                name: entry.FullName,
              };
            });
          }
          return [];
        }),
      );
  }
}
