import { ValueAndDisplay } from './value-and-display';
export enum EmailFields {
  Default = 'Default',
  Description = 'Description',
  Address = 'Address',
}

export interface Email {
  Default?: boolean;
  Description: string;
  Address: string;
  DisplayValue?: string;
}

export type EntityEmail = {
  [T in keyof Email]: ValueAndDisplay<Email[T]>;
};

export const EmailFieldsEditableListFields = {
  [EmailFields.Description]: 60032,
};
