import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextService } from '@maximizer/core/shared/data-access';
import { LeadSearch, Octopus } from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import {
  GlobalSearchService,
  SearchEscapeCharacters,
} from './global-search-service';
import { escapeCharacters } from '@maximizer/shared/util';

@Injectable()
export class LeadSearchService implements GlobalSearchService<LeadSearch> {
  constructor(
    private readonly _http: HttpClient,
    private readonly _context: ContextService,
  ) {}

  search(
    searchPhrase: string,
    useWildcard = false,
    take = 15,
  ): Observable<LeadSearch[]> {
    const request: Octopus.LeadReadRequest = {
      Lead: {
        Criteria: {
          SearchQuery: {
            $PHRASE: `${escapeCharacters(searchPhrase, SearchEscapeCharacters)}${!useWildcard ? '' : '*'}`,
          },
          Top: take,
        },
        OrderBy: {
          Fields: [{ LastModifyDate: 'DESC' }],
        },
        Scope: {
          Fields: {
            Key: 1,
            FirstName: 1,
            LastName: 1,
            CompanyName: 1,
            Email: 1,
            CreationDate: {
              DisplayValue: 1,
              Value: 1,
            },
            LastModifyDate: {
              DisplayValue: 1,
              Value: 1,
            },
            Status: 1,
          },
        },
      },
      Globalization: Octopus.DefaultGlobalization,
      Configuration: Octopus.LeadReadDriver,
    };

    return this._http
      .post<Octopus.LeadResponse>(
        `${this._context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result && result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.LeadSearchMapper();
            return result.Lead.Data.map((entries) => mapper.from(entries));
          }
          return [];
        }),
      );
  }
}
