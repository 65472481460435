export class Key extends Object {
  type: string;
  value: string;
  number: number;

  constructor(value: string) {
    super();

    try {
      const parts = atob(value).split('\t');
      this.type = parts[0];
      this.value = parts[1];
      this.number = parseInt(parts[2] ?? '0', 10);
    } catch {
      this.type = '';
      this.value = '';
      this.number = 0;
    }
  }

  override toString(): string {
    return btoa(`${this.type}\t${this.value}\t${this.number}`);
  }
}
