import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextService } from '@maximizer/core/shared/data-access';
import { CaseSearch, Octopus } from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import {
  GlobalSearchService,
  SearchEscapeCharacters,
} from './global-search-service';
import { escapeCharacters } from '@maximizer/shared/util';

@Injectable()
export class CasesSearchService implements GlobalSearchService<CaseSearch> {
  constructor(
    private readonly _http: HttpClient,
    private readonly _context: ContextService,
  ) {}

  search(
    searchPhrase: string,
    useWildcard = false,
    take = 15,
  ): Observable<CaseSearch[]> {
    const request: Octopus.CaseReadRequest = {
      Case: {
        Criteria: {
          SearchQuery: {
            $PHRASE: `${escapeCharacters(searchPhrase, SearchEscapeCharacters)}${!useWildcard ? '' : '*'}`,
          },
          Top: take,
        },
        OrderBy: {
          Fields: [{ LastModifyDate: 'DESC' }],
        },
        Scope: {
          Fields: {
            Key: 1,
            Subject: 1,
            AbEntry: {
              CompanyName: 1,
              FirstName: 1,
              LastName: 1,
              Type: 1,
              Key: 1,
            },
            Contact: {
              CompanyName: 1,
              FullName: 1,
              Key: 1,
            },
            LastModifyDate: {
              DisplayValue: 1,
              Value: 1,
            },
          },
        },
      },
      Globalization: Octopus.DefaultGlobalization,
      Configuration: Octopus.CaseReadDriver,
    };

    return this._http
      .post<Octopus.CaseResponse>(
        `${this._context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result && result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.CaseSearchMapper();
            return result.Case.Data.map((entries) => mapper.from(entries));
          }
          return [];
        }),
      );
  }
}
