import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DialogsModule as KendoDialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonModule } from '@progress/kendo-angular-buttons';

@NgModule({
  exports: [
    ConfirmDialogComponent,
  ],
  declarations: [
    ConfirmDialogComponent,
  ],
  imports: [
    CommonModule,
    KendoDialogsModule,
    ButtonModule,
  ]
})
export class UiCommonDialogsModule { }
