import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'maximizer-outlook-form-error',
  template: `
    <div *ngIf="errorObject !== null">
      <kendo-formerror *ngIf="errorObject['required'] ?? false">{{
        'outlook.forms.required-error' | translate: { fieldName: translation }
      }}</kendo-formerror>

      <kendo-formerror *ngIf="errorObject['maxlength'] ?? false">{{
        'outlook.forms.maxlength-error'
          | translate
            : {
                count:
                  errorObject['maxlength'].requiredLength ??
                  errorObject['maxlength'],
              }
      }}</kendo-formerror>

      <kendo-formerror
        *ngIf="
          !errorObject['maxlength'] &&
          ((errorObject['valid'] ||
            errorObject['email'] ||
            errorObject['pattern']) ??
            false)
        "
        >{{
          'outlook.forms.valid-error' | translate: { fieldName: translation }
        }}</kendo-formerror
      >

      <kendo-formerror
        *ngIf="!errorObject['maxlength'] && (errorObject['exist'] ?? false)"
        >{{
          'outlook.forms.exist-error' | translate: { fieldName: translation }
        }}</kendo-formerror
      >
    </div>
  `,
})
export class OutlookFormErrorComponent implements OnInit {
  constructor(private translate: TranslateService) {}
  @Input() type: 'email' | 'normal' = 'normal';

  @Input() fieldTranslation = '';

  @Input() errorObject: ValidationErrors | null = null;

  translation = '';

  ngOnInit(): void {
    this.translate.get(this.fieldTranslation).subscribe((o) => {
      this.translation = o.toLowerCase();
    });
  }
}
