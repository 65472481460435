import { Mapper } from '../../../mappers';
import { EmailProfile, EmailType, ListItem } from '../../../models';
import { KeyAndDisplay } from '../models';
import { EmailProfile as OctopusEmailProfile } from '../models/email-profile';

export class EmailProfileMapper extends Mapper<
  OctopusEmailProfile[],
  EmailProfile[]
> {
  from(source: OctopusEmailProfile[]): EmailProfile[] {
    return source.map((item) => {
      return this.mapEmailProfile(item);
    });
  }

  mapEmailProfile(source: OctopusEmailProfile): EmailProfile {
    return {
      key: source.Key ?? '',
      emailAddress: source.EmailAddress,
      emailType: this.mapItem(source.EmailType),
      comment: this.mapItem(source.Comment),
      enrollDate: source.EnrollDate,
      expiryDate: source.ExpiryDate,
    };
  }

  mapItem(source: KeyAndDisplay): ListItem<string> {
    return {
      id: source.Key,
      name: source.DisplayValue,
    };
  }
  populateEmailTypes(
    emailAddress: string,
    emailTypes: ListItem<string>[],
    emailProfiles: EmailProfile[],
  ): EmailType[] {
    return emailTypes.map((emailType) => {
      const emailProfile = emailProfiles.find(
        (item) => (item.emailType as ListItem<string>).id === emailType.id,
      );
      return this.mapEmailProfileWithEmailType(
        emailAddress,
        emailType,
        emailProfile,
      );
    });
  }
  private mapEmailProfileWithEmailType(
    emailAddress: string,
    emailType: ListItem<string>,
    emailProfile?: EmailProfile,
  ): EmailType {
    if (emailProfile) {
      return {
        key: emailProfile.key,
        emailType: emailType.id,
        emailAddress: emailAddress,
        name: emailType.name,
        comment: (emailProfile.comment as ListItem<string>).id,
        enrollDate: emailProfile.enrollDate,
        expiryDate: emailProfile.expiryDate,
        isSelect: true,
      };
    }
    return {
      key: null,
      emailType: emailType.id,
      name: emailType.name,
      emailAddress: emailAddress,
      comment: '',
      enrollDate: null,
      expiryDate: null,
      isSelect: false,
    };
  }
  convertEmailTypeToEmailProfile(emailType: EmailType): EmailProfile {
    return {
      key: emailType.key,
      emailAddress: emailType.emailAddress,
      emailType: emailType.emailType,
      comment: emailType.comment,
      enrollDate: emailType.enrollDate,
      expiryDate: emailType.expiryDate,
    };
  }
}
