export interface Key {
  Value: string;
}

export interface IdKey<T> extends Key {
  Id: T;
}

export interface UserKey extends Partial<Key> {
  Uid: string;
}

export enum KeyType {
  AbEntry = 'AbEntry',
  Appointment = 'Appointment',
  Company = 'Company',
  Contact = 'Contact',
  CSCase = 'CSCase',
  Individual = 'Individual',
  Opportunity = 'Opportunity',
  Task = 'Task',
  User = 'User',
  SalesTeam = 'SalesTeam',
}
