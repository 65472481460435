import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationModule as KendoNotificationModule } from '@progress/kendo-angular-notification';
import { NotificationComponent } from './notification.component';

@NgModule({
  imports: [CommonModule, KendoNotificationModule],
  exports: [NotificationComponent],
  declarations: [NotificationComponent],
})
export class UiNotificationModule {}
