import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContextService } from '@maximizer/core/shared/data-access';
import { Octopus } from '@maximizer/core/shared/domain';
import {
  LeadSearchMapper,
  OutlookLeadForm,
  OutlookLeadSearch,
} from '@maximizer/outlook/shared/domain';
import { Observable, map } from 'rxjs';

@Injectable()
export class LeadService {
  constructor(
    private http: HttpClient,
    private context: ContextService,
  ) {}

  getByKey(key: string): Observable<OutlookLeadSearch | null> {
    const query: Octopus.Query<Octopus.Lead> = {
      Key: {
        $EQ: { Value: key },
      },
    };

    return this.getLeads(query).pipe(
      map((leads) => (leads.length ? leads[0] : null)),
    );
  }

  getLeads(
    query?: Octopus.Query<Octopus.Lead>,
  ): Observable<OutlookLeadSearch[]> {
    const request = this.getRequest(query);

    return this.http
      .post<Octopus.LeadResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(map((result) => this.mapLeadResponseToLeadSearch(result)));
  }

  getByEmails(emails: string[]): Observable<OutlookLeadSearch[]> {
    const searchQuery: Octopus.LogicalQuery<Octopus.Lead> = {
      $OR: [],
    };
    for (const email of emails) {
      const query: Octopus.Query<Octopus.Lead> = {
        Email: { $EQ: { Address: email } },
      };
      searchQuery.$OR?.push(query);
    }

    const request = this.getSmallRequest(searchQuery);
    return this.http
      .post<Octopus.LeadResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(map((result) => this.mapLeadResponseToLeadSearch(result)));
  }

  getKeys(keys: string[]): Observable<OutlookLeadSearch[]> {
    const searchQuery: Octopus.LogicalQuery<Octopus.Lead> = {
      $OR: [],
    };
    for (const key of keys) {
      const query: Octopus.Query<Octopus.Lead> = {
        Key: { $EQ: key },
      };
      searchQuery.$OR?.push(query);
    }

    const request = this.getSmallRequest(searchQuery);
    return this.http
      .post<Octopus.LeadResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(map((result) => this.mapLeadResponseToLeadSearch(result)));
  }

  private getSmallRequest(
    searchQuery: Octopus.LogicalQuery<Octopus.Lead>,
  ): Octopus.LeadReadRequest {
    return {
      Lead: {
        Criteria: {
          SearchQuery: searchQuery,
        },
        Scope: {
          Fields: {
            Key: 1,
            FirstName: 1,
            LastName: 1,
            CompanyName: 1,
            Email: 1,
            Status: 1,
          },
        },
      },
    };
  }
  private getRequest(
    query?: Octopus.Query<Octopus.Lead>,
  ): Octopus.LeadReadRequest {
    const searchQuery: Octopus.LogicalQuery<Octopus.Lead> = {
      $AND: [],
    };
    if (query) {
      searchQuery.$AND?.push(query);
    }
    return {
      Lead: {
        Scope: {
          Fields: {
            Key: 1,
            Position: 1,
            Phone1: {
              Description: 1,
              Number: 1,
              Extension: 1,
            },
            Phone2: {
              Description: 1,
              Number: 1,
              Extension: 1,
            },
            Email: 1,
            MrMs: 1,
            FirstName: 1,
            LastName: 1,
            CompanyName: 1,
            WebSite: 1,
            Address: {
              Key: 1,
              AddressLine1: 1,
              AddressLine2: 1,
              City: 1,
              Country: 1,
              StateProvince: 1,
              ZipCode: 1,
            },
            Status: 1,
            ArchivedDate: 1,
          },
        },
        Criteria: {
          SearchQuery: searchQuery,
        },
        OrderBy: {
          Fields: [
            {
              FirstName: 'ASC',
            },
          ],
        },
      },
      Configuration: Octopus.LeadReadDriver,
    };
  }

  update(lead: OutlookLeadForm): Observable<string | null> {
    const request: Octopus.LeadWriteRequest = {
      Lead: {
        Data: {
          Key: lead.key ?? '',
          FirstName: lead.firstName?.trim() ?? '',
          LastName: lead.lastName?.trim() ?? '',
          CompanyName: lead.company?.trim() ?? '',
          Position: lead.position?.trim() ?? '',
          Email: lead.email,
          Phone1: {
            Description: 'Phone 1',
            Number: lead.phone1?.trim() ?? '',
            Extension: '',
          },
          Phone2: {
            Description: 'Phone 2',
            Number: lead.phone2?.trim() ?? '',
            Extension: '',
          },
          Address: {
            AddressLine1: lead.address1,
            AddressLine2: lead.address2,
            City: lead.city,
            StateProvince: lead.province,
            Country: lead.country,
            Description: 'Main',
            ZipCode: lead.postalCode,
          },
          Website: lead.website,
        },
      },
    };
    return this.http
      .post<Octopus.LeadWriteResponse>(
        `${this.context.api}${Octopus.Action.UPDATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result?.Code === Octopus.ResponseStatusCode.Successful) {
            return result.Lead.Data.Key ?? '';
          }
          throw new Error('Unsuccessful request:' + result?.Code);
        }),
      );
  }

  save(lead: OutlookLeadForm): Observable<string | null> {
    const request: Octopus.LeadWriteRequest = {
      Lead: {
        Data: {
          Key: null,
          FirstName: lead.firstName?.trim() ?? '',
          LastName: lead.lastName?.trim() ?? '',
          CompanyName: lead.company?.trim() ?? '',
          Position: lead.position?.trim() ?? '',
          Email: lead.email,
          Phone1: {
            Description: 'Phone 1',
            Number: lead.phone1?.trim() ?? '',
            Extension: '',
          },
          Phone2: {
            Description: 'Phone 2',
            Number: lead.phone2?.trim() ?? '',
            Extension: '',
          },
          Address: {
            AddressLine1: lead.address1,
            AddressLine2: lead.address2,
            City: lead.city,
            StateProvince: lead.province,
            Country: lead.country,
            Description: 'Main',
            ZipCode: lead.postalCode,
          },
          Website: lead.website,
        },
      },
    };
    return this.http
      .post<Octopus.LeadWriteResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result?.Code === Octopus.ResponseStatusCode.Successful) {
            return result.Lead.Data.Key ?? '';
          }
          throw new Error('Unsuccessful request:' + result?.Code);
        }),
      );
  }

  private mapLeadResponseToLeadSearch(
    result: Octopus.LeadResponse,
  ): OutlookLeadSearch[] {
    if (result.Code === Octopus.ResponseStatusCode.Successful) {
      const mapper = new LeadSearchMapper();
      return result.Lead.Data.map((lead) => mapper.from(lead));
    }
    return [];
  }
}
