import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Entity,
  Field,
  Key,
  ListItem,
  Octopus,
  Opportunity,
  OpportunityFilters,
  Stage,
} from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import { ContextService } from './context.service';
import { EntityService } from './entity.service';
import { MetadataService } from './metadata.service';
import { FeatureService } from './feature.service';

@Injectable()
export class OpportunityService extends EntityService {
  private useSqlWriteDriver?: boolean;

  constructor(
    http: HttpClient,
    context: ContextService,
    metadata: MetadataService,
    private feature: FeatureService,
  ) {
    super(Octopus.OpportunityEntityCode, http, context, metadata);
  }

  get readDriver(): Octopus.RequestConfiguration {
    return Octopus.OpportunityReadDriver;
  }

  get writeDriver(): Octopus.RequestConfiguration {
    if (this.useSqlWriteDriver === undefined) {
      this.useSqlWriteDriver = this.feature.isFeatureOn(
        'core-opportunity-use-sql-driver',
        false,
      );
    }

    return this.useSqlWriteDriver
      ? Octopus.OpportunityWriteDriver
      : { Drivers: {} };
  }

  search(
    query: Octopus.SearchQuery<Octopus.Opportunity>,
    fields: Field[] = [],
  ): Observable<Opportunity[]> {
    const scope: Octopus.Scope<Octopus.Opportunity> = {
      ...this.getScopeForFields(fields),
      Key: 1,
      AbEntry: {
        Key: 1,
        Type: 1,
        CompanyName: 1,
        FirstName: 1,
        LastName: 1,
        DisplayValue: 1,
      },
      Contact: {
        Key: 1,
        FirstName: 1,
        LastName: 1,
        DisplayValue: 1,
      },
      Leader: this.getKeyAndDisplayScope(false),
      Objective: 1,
      Description: 1,
      Status: this.getValueAndDisplayScope<number>(false),
      StartDate: this.getValueAndDisplayScope<string>(false),
      CloseDate: this.getValueAndDisplayScope<string>(false),
      Cost: this.getCurrencyScope(false),
      ActualRevenue: this.getCurrencyScope(false),
      ForecastRevenue: this.getCurrencyScope(false),
      CorporateRevenue: this.getCurrencyScope(false),
      CurrentSalesStageAge: 1,
      SalesProcessSetupKey: 1,
      SalesStageSetup: {
        Key: 1,
        SalesProcessSetupKey: 1,
        DisplayValue: 1,
        ProbabilityClose: 1,
        TargetAge: 1,
      },
      CurrentSalesStage: this.getKeyAndDisplayScope(false),
      Product: {
        DisplayValue: 1,
      },
    };

    const request: Octopus.OpportunityReadRequest = {
      Configuration: this.readDriver,
      Globalization: Octopus.DefaultGlobalization,
      Opportunity: {
        Criteria: {
          SearchQuery: query,
        },
        Scope: {
          Fields: scope,
        },
      },
    };

    return this.http
      .post<Octopus.OpportunityResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.OpportunityMapper();
            return result.Opportunity.Data.map((opportunity) =>
              mapper.from(opportunity, fields),
            );
          }
          return [];
        }),
      );
  }

  getFiltersQuery(
    filter: OpportunityFilters,
  ): Octopus.LogicalQuery<Octopus.Opportunity> {
    const query: Octopus.LogicalQuery<Octopus.Opportunity> = {
      $AND: [
        {
          CloseDate:
            filter.date === Octopus.DateOptionId.CUSTOM
              ? {
                  $RANGE: Octopus.getDateRange(
                    filter.range?.start ?? new Date(),
                    filter.range?.end ?? new Date(),
                  ),
                }
              : { $EQ: Octopus.getDateOffset(filter.date) },
        },
        {
          SalesProcessSetupKey: { $EQ: filter.pipeline },
        },
      ],
    };

    if (filter.revenueType?.length) {
      query.$AND?.push({
        RevenueType: {
          $IN: filter.revenueType,
        },
      });
    }

    if (filter.teamOwner?.length) {
      const owners: string[] = [];
      const teams: string[] = [];

      filter.teamOwner.forEach((item) => {
        const key = new Key(item);
        if (key.type === Octopus.KeyType.User) {
          owners.push(item);
        } else {
          teams.push(item);
        }
      });

      const teamOwnerQuery: Octopus.LogicalQuery<Octopus.Opportunity> = {
        $OR: [],
      };

      if (owners.length) {
        teamOwnerQuery.$OR?.push({
          Leader: {
            $IN: owners,
          },
        });
      }

      if (teams.length) {
        teamOwnerQuery.$OR?.push({
          'SalesTeam/Key': {
            $IN: teams,
          },
        });
      }

      query.$AND?.push(teamOwnerQuery);
    }

    if (filter.amount?.type && (filter.amount.min || filter.amount.max)) {
      query.$AND?.push({
        CorporateRevenue:
          filter.amount.type === Octopus.CriteriaOptionId.RANGE
            ? {
                $RANGE: [filter.amount.min, filter.amount.max],
              }
            : {
                [filter.amount.type]: filter.amount.min,
              },
      });
    }

    return query;
  }

  getPartnerCompetitor(id: string[]): Observable<ListItem<string>[]> {
    const request: Octopus.AbEntryReadRequest = {
      AbEntry: {
        Criteria: {
          SearchQuery: {
            Key: {
              $IN: id,
            },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            DisplayValue: 1,
            Address: {
              City: 1,
              StateProvince: 1,
            },
          },
        },
      },
      Configuration: Octopus.AbEntryReadDriver,
    };

    return this.http
      .post<Octopus.AbEntryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            return result.AbEntry.Data.map((entry) => {
              const name = [
                entry.DisplayValue,
                entry.Address?.City,
                entry.Address?.StateProvince,
              ]
                .filter((item) => item)
                .join(', ');
              return {
                id: entry.Key ?? '',
                name,
              };
            });
          }
          return [];
        }),
      );
  }

  getStages(id: string): Observable<Stage[]> {
    const request: Octopus.SalesStageReadRequest = {
      SalesStage: {
        Criteria: {
          SearchQuery: {
            OpportunityKey: { $EQ: id },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            SalesStageSetupKey: 1,
            SalesProcessSetupKey: 1,
            DisplayValue: 1,
            ProbabilityClose: 1,
            TargetAge: 1,
            Age: {
              Value: 1,
              DisplayValue: 1,
            },
            Current: 1,
          },
        },
      },
    };

    return this.http
      .post<Octopus.SalesStageResponse>(
        this.context.api + Octopus.Action.READ,
        request,
      )
      .pipe(
        map((response) => {
          if (response.Code === 0 && response.SalesStage?.Data) {
            const mapper = new Octopus.SalesStageMapper();
            return response.SalesStage.Data.map(mapper.from);
          }
          return [];
        }),
      );
  }

  override getFieldsForDefaultEntry(): Octopus.Scope<Entity> {
    return {
      [Octopus.OpportunityFields.AbEntry]: this.getKeyAndDisplayScope(),
      [Octopus.OpportunityFields.AbEntryKey]: this.getValueAndDisplayScope(),
      [Octopus.OpportunityFields.ActualRevenue]: this.getCurrencyScope(),
      [Octopus.OpportunityFields.Age]: this.getValueAndDisplayScope(),
      [Octopus.OpportunityFields.CloseDate]: this.getValueAndDisplayScope(),
      [Octopus.OpportunityFields.Comment]: this.getValueAndDisplayScope(),
      [Octopus.OpportunityFields.StartDate]: this.getValueAndDisplayScope(),
      [Octopus.OpportunityFields.Status]: this.getValueAndDisplayScope(),
      [Octopus.OpportunityFields.Reason]: this.getValueAndDisplayScope(),
    };
  }

  override getTypesScope(metadata = true): Octopus.Scope<Entity> {
    return {
      ...super.getTypesScope(metadata),
      CampaignObject: this.getKeyAndDisplayScope(metadata),
      CurrencyField: this.getCurrencyScope(metadata),
      'EnumField<PartnerCompetitorObject>':
        this.getPartnerCompetitorScope(metadata),
      SalesTeamObject: this.getKeyAndDisplayScope(metadata),
      SalesProcessObject: this.getKeyAndDisplayScope(metadata),
      SalesProcessSetupObject: this.getKeyAndDisplayScope(metadata),
      SalesStageObject: this.getKeyAndDisplayScope(metadata),
      SalesStageSetupObject: this.getKeyAndDisplayScope(metadata),
    };
  }
}
