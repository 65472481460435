<div class="flex p-4 bg-max-white justify-between">
  <div>
    <span class="svg-icons icon-case icon-24"></span>
  </div>
  <div class="flex-grow px-2 flex flex-col gap-y-1">
    <p class="text-body line-clamp-1">{{ case.subject }}</p>
    <p class="caption line-clamp-1">{{ case.abEntry.companyName }}</p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.case.status' | translate }}: {{ case.status }}
    </p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.case.title' | translate }}: {{ case.caseNumber }}
    </p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.case.contact' | translate }}: {{ case.contact?.firstName }}
      {{ case.contact?.lastName }}
    </p>
    @if (case.owner) {
      <div>
        <p class="text-body py-1">{{ 'outlook.case.owner' | translate }}:</p>
        <div class="flex items-center">
          <span class="avatar avatar-xs">{{
            case.owner.display | nameInitials
          }}</span>
          <p class="text-body pl-1">{{ case.owner.display }}</p>
        </div>
      </div>
    }
  </div>
  <div>
    <button
      kendoButton
      fillMode="flat"
      [disabled]="case.isSaved || !rights?.update"
      (click)="save.emit(case.key)"
      [iconClass]="
        case.isSaved
          ? 'svg-icons icon-saved-email text-neutral-20 icon-20'
          : !rights?.update
            ? 'maximizer-icons icon-save-email text-neutral-20 icon-24'
            : 'maximizer-icons icon-save-email text-inserv-100 icon-24'
      "
    ></button>
  </div>
</div>
