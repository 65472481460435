import { Component, ElementRef, Input } from '@angular/core';
import { EmbeddedComponent, InteractionIconPipe, NoteIconPipe } from '@maximizer/core/shared/ui';
import { OutlookTimelineDirection, OutlookTimelineEvent } from '@maximizer/outlook/shared/domain';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'maximizer-outlook-timeline-card',
  templateUrl: './timeline-card.component.html',
})
export class OutlookTimelineCardComponent extends EmbeddedComponent {
  private eventTitle?: string;
  private eventConnection: { from: string, to: string, with: string, for: string};
  constructor(
    element: ElementRef,
    private translateService: TranslateService
  ) {
    super(element);
    this.eventConnection = this.translateService.instant('outlook.timeline.eventConnection');
  }
  
  @Input()
  event!: OutlookTimelineEvent;

  expanded = false;
  toggleExpand() {
    this.expanded = !this.expanded;
  }

  private iconPipe = new NoteIconPipe();
  private interactionPipe = new InteractionIconPipe();
  
  getIcon(event: OutlookTimelineEvent) {
    switch (event.type) {
      case 'document':
      case 'appointment':
      case 'interaction':
      case 'task':
      case 'email':
        if(event.interactionType != null){
          return this.interactionPipe.transform(event.interactionType);
        }
        return 'fa-icons icon-solid icon-note-sticky';
      case 'note':
        return this.iconPipe.transform(event.noteType?.Value ?? 0)
      default:
        break;
    }
    return 'fa-icons icon-solid icon-note-sticky';
  }

  getTitleConnection(event: OutlookTimelineEvent){
    let connection;
    switch (event.type) {
      case 'appointment':
      case 'task':
        return this.eventConnection.with;
      case 'interaction':
      case 'email':
        if(event.direction === OutlookTimelineDirection.Incoming){
          connection = this.eventConnection.from;          
          break;
        }
        if(event.direction === OutlookTimelineDirection.Outgoing){
          connection = this.eventConnection.to;
          break;
        }
        connection = this.eventConnection.for;
        break;
      default:
        connection = this.eventConnection.for;
        break;
    }

    return connection;
  }
}
