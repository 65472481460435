import { Pipe, PipeTransform } from '@angular/core';
import { getMaximizerIcon, NoteIcons } from '../icons';

@Pipe({
  name: 'noteIcon',
})
export class NoteIconPipe implements PipeTransform {
  transform(type: number): string {
    return getMaximizerIcon(NoteIcons[type]);
  }
}
