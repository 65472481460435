import {
  TaskFieldOption,
  TaskForm,
  TaskSummary,
  TaskSummaryCard,
} from '../../../models';
import { Octopus } from '@maximizer/core/shared/domain';
import { TaskComposeSummaryResponse, TaskSummaryResponse } from '../models';
export class TaskFieldOptionMapper extends Octopus.ConfigurationMapper<
  Octopus.TaskFieldOptionsResponse,
  TaskFieldOption
> {
  from(source: Octopus.TaskFieldOptionsResponse): TaskFieldOption {
    const task = source.Task?.FieldOptions;

    const result = {
      categories: task?.Category?.map(this.mapKeyAndDisplay) ?? [],
      results: task?.Result?.map(this.mapKeyAndDisplay) ?? [],
      priorities: task?.Priority?.map(this.mapKeyAndDisplay) ?? [],
    };
    return result;
  }
}
export class TaskFormMapper extends Octopus.ConfigurationMapper<
  TaskForm,
  Octopus.TaskCreate
> {
  override from(source: TaskForm): Octopus.TaskCreate {
    return {
      Key: source.key,
      Activity: source.activity,
      Datetime: source.dueDate,
      Alarm: source.alarm,
      Priority: source.priority,
      Category: source.category,
      Result: source.result,
      AssignedTo: { UID: source.assignedUser ?? Octopus.CurrentUser },
      AbEntryKey: source.abEntryKey,
      LeadKey: source.leadKey,
    };
  }
}

export class TaskSummaryMapper extends Octopus.ConfigurationMapper<
  TaskSummaryResponse,
  TaskSummary
> {
  override from(source: TaskSummaryResponse): TaskSummary {
    const overdueObj =
      source['Task.Overdue']?.Data?.length > 0
        ? source['Task.Overdue'].Data[0]
        : null;
    const upcomingObject =
      source['Task.Upcoming']?.Data?.length > 0
        ? source['Task.Upcoming'].Data[0]
        : null;
    const overdueCountObject =
      source['Task.OverdueCount']?.Data?.length > 0
        ? source['Task.OverdueCount'].Data[0]
        : null;
    const upcomingCountObject =
      source['Task.UpcomingCount']?.Data?.length > 0
        ? source['Task.UpcomingCount'].Data[0]
        : null;

    const result: TaskSummary = {
      overdueTaskAmount: overdueCountObject?.['$COUNT()'] ?? 0,
      upcomingTaskAmount: upcomingCountObject?.['$COUNT()'] ?? 0,
    };

    if (overdueObj) {
      result.overdue = {
        assignedTo: overdueObj.AssignedTo,
        description: overdueObj.Activity,
        dueDate: new Date(overdueObj.DateTime),
        creationDate: new Date(overdueObj.CreationDate),
        key: overdueObj.Key,
        priority: overdueObj.Priority,
      };
    }
    if (upcomingObject) {
      result.upcoming = {
        assignedTo: upcomingObject.AssignedTo,
        description: upcomingObject.Activity,
        dueDate: new Date(upcomingObject.DateTime),
        creationDate: new Date(upcomingObject.CreationDate),
        key: upcomingObject.Key,
        priority: upcomingObject.Priority,
      };
    }
    return result;
  }
}

export class TaskComposeSummaryMapper extends Octopus.ConfigurationMapper<
  TaskComposeSummaryResponse,
  TaskSummaryCard | null
> {
  override from(source: TaskComposeSummaryResponse): TaskSummaryCard | null {
    const upcomingObject =
      (source.Task?.Data?.length ?? 0) > 0 ? source.Task.Data[0] : null;

    if (!upcomingObject) return null;
    
    return {
        assignedTo: upcomingObject.AssignedTo,
        description: upcomingObject.Activity,
        dueDate: new Date(upcomingObject.DateTime),
        creationDate: new Date(upcomingObject.CreationDate),
        key: upcomingObject.Key,
        priority: upcomingObject.Priority,
      };
  }
}
