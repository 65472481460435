import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import {
  UiModule,
  defaultTranslateConfiguration,
} from '@maximizer/core/shared/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import {
  IndicatorsModule,
  SkeletonModule,
} from '@progress/kendo-angular-indicators';
import { OutlookSharedDataAccessModule } from '@maximizer/outlook/shared/data-access';
import { TimelineComponent } from './timeline/timeline.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { PopupModule } from '@progress/kendo-angular-popup';
import { OutlookTimelineCardComponent } from './timeline-card/timeline-card.component';
import { OutlookSharedDomainModule } from '@maximizer/outlook/shared/domain';
import { OutlookSharedUiModule } from '@maximizer/outlook/shared/ui';

@NgModule({
  declarations: [TimelineComponent, OutlookTimelineCardComponent],
  exports: [TimelineComponent, OutlookTimelineCardComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    IntlModule,
    LabelModule,
    ButtonsModule,
    DateInputsModule,
    FormFieldModule,
    ReactiveFormsModule,
    DropDownsModule,
    LayoutModule,
    IconsModule,
    FormsModule,
    InputsModule,
    ProgressBarModule,
    IndicatorsModule,
    UiModule,
    PopupModule,
    DropDownsModule,
    ButtonsModule,
    SkeletonModule,
    LabelModule,
    TooltipsModule,
    OutlookSharedDomainModule,
    OutlookSharedDataAccessModule,
    OutlookSharedUiModule,
    TranslateModule.forChild({
      ...defaultTranslateConfiguration,
      extend: true,
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class OutlookTimelineUiModule {}
