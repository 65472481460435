export interface Globalization {
  Culture?: string;
  TimeZone: string;
  TimeZoneDesignator: boolean;
}

export const CurrentUser = '$CURRENTUSER()';

export const DefaultGlobalization: Globalization = {
  Culture: CurrentUser,
  TimeZone: CurrentUser,
  TimeZoneDesignator: true,
};
