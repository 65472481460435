import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addDays, isBefore } from 'date-fns';
import { map, Observable, of, tap } from 'rxjs';
import { ContextService } from './context.service';
import { SessionStorageService } from './session-storage.service';

interface AvatarSessionItem {
  image: string;
  expiration: string;
}

@Injectable()
export class AvatarService {
  constructor(
    private http: HttpClient,
    private context: ContextService,
    private storage: SessionStorageService,
  ) {}

  get(key: string): Observable<string> {
    const cached = this.fromSession(key);
    if (cached) {
      return of(`url(data:image/png;base64,${cached})`);
    }

    return this.http
      .get(
        `${this.context.website}/mvc/AddressBook/ViewDetails/GetPhoto/${key}`,
        { responseType: 'text' },
      )
      .pipe(
        tap((image) => this.addToSession(key, image)),
        map((image) => (image ? `url(data:image/png;base64,${image})` : '')),
      );
  }

  private fromSession(key: string): string | null {
    try {
      if (this.storage.isEnabled) {
        const storageKey = key + '_photo';
        const item = this.storage.get(storageKey);
        if (item) {
          const data = JSON.parse(item) as AvatarSessionItem;
          if (data?.expiration) {
            if (isBefore(Date.now(), Date.parse(data.expiration))) {
              return data.image;
            } else {
              this.storage.remove(storageKey);
            }
          }
        }
      }
    } catch {
      return null;
    }
    return null;
  }

  private addToSession(key: string, image: string): void {
    if (this.storage.isEnabled) {
      const storageKey = key + '_photo';
      const expiration = addDays(Date.now(), 1);
      const data = {
        image,
        expiration,
      };
      this.storage.set(storageKey, JSON.stringify(data));
    }
  }
}
