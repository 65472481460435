import { GroupedListItem, Octopus } from '@maximizer/core/shared/domain';
import {
  AppointmentFieldOption,
  AppointmentForm,
  AppointmentSummary,
} from '../../../models';

export class AppointmentFieldOptionMapper extends Octopus.ConfigurationMapper<
  Octopus.AppointmentFieldOptionsResponse,
  AppointmentFieldOption
> {
  from(
    source: Octopus.AppointmentFieldOptionsResponse,
  ): AppointmentFieldOption {
    const appointment = source.Appointment?.FieldOptions;

    const result = {
      categories:
        appointment?.InteractionCategory?.map(this.mapKeyAndDisplay) ?? [],
      results: appointment?.Result?.map(this.mapKeyAndDisplay) ?? [],
      locations: appointment?.Location?.map(this.mapKeyAndDisplay) ?? [],
    };
    return result;
  }
}
export class AppointmentFormMapper extends Octopus.ConfigurationMapper<
  AppointmentForm,
  Octopus.AppointmentCreate
> {
  combineDateAndTime(date: Date, time: Date): Date {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();

    const combinedDateTime: Date = new Date(
      year,
      month,
      day,
      hours,
      minutes,
      seconds,
    );

    return combinedDateTime;
  }
  extractAttendees(
    source: GroupedListItem<string>[],
    type: string,
  ): { Key: { Value: string } }[] {
    return source
      .filter((o) => o.group == type)
      .map((o) => ({ Key: { Value: o.id } }));
  }

  override from(source: AppointmentForm): Octopus.AppointmentCreate {
    const result = {
      Key: source.key,
      Subject: source.subject,
      StartDate: this.combineDateAndTime(
        source.date,
        source.startTime,
      ).toISOString(),
      EndDate: this.combineDateAndTime(
        source.date,
        source.endTime,
      ).toISOString(),
      Location: source.location,
      Organizer: source.assignedUser ?? Octopus.CurrentUser,
      Creator: source.creator ?? Octopus.CurrentUser,
      Description: source.description,
      InteractionCategory: source.category,
      Result: source.result,
      Users:
        source.attendees?.filter((o) => o.group == 'user').map((o) => o.id) ??
        [],
      AbEntries: this.extractAttendees(source.attendees ?? [], 'abentry'),
      Leads: this.extractAttendees(source.attendees ?? [], 'lead'),
    };
    return result;
  }
}

export class AppointmentSummaryMapper extends Octopus.ConfigurationMapper<
  Octopus.AppointmentSummaryResponse,
  AppointmentSummary
> {
  override from(
    source: Octopus.AppointmentSummaryResponse,
  ): AppointmentSummary {
    const appointmentObj =
      source.Appointment.Data?.length > 0 ? source.Appointment.Data[0] : null;

    const appointmentAmount =
      source['Appointment.Count']?.Data?.length > 0
        ? source['Appointment.Count'].Data[0]
        : null;

    const result: AppointmentSummary = {
      appointmentAmount: appointmentAmount?.['$COUNT()'] ?? 0,
    };

    if (appointmentObj) {
      result.appointment = {
        key: appointmentObj.Key,
        subject: appointmentObj.Subject,
        startDate: appointmentObj.StartDate,
        endDate: appointmentObj.EndDate,
        description: appointmentObj.DisplayValue,
        location: appointmentObj.Location,
        creationDate: appointmentObj.CreationDate,
      };
    }
    return result;
  }
}
