import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed } from '@angular/core';
import {
  DateRangeDisplay,
  Field,
  Octopus,
  Opportunity,
  OpportunityFilters,
  PipelineListItem,
  SortDirection,
} from '@maximizer/core/shared/domain';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';

export type OpportunityState = {
  selectedPipeline: PipelineListItem | null;
  showFinalStages: boolean;
  sort: {
    by: string;
    direction: SortDirection;
  };
  filter: OpportunityFilters;
  opportunities: Opportunity[];
  layout: Field[];
};

const initialState: OpportunityState = {
  selectedPipeline: null,
  showFinalStages: true,
  sort: { by: Octopus.OpportunityFields.CloseDate, direction: 'ASC' },
  filter: {
    date: Octopus.DateOptionId.THISWEEK,
    pipeline: '',
  },
  opportunities: [],
  layout: [],
};

export const OpportunityStore = signalStore(
  withDevtools('opportunities'),
  withState(initialState),
  withComputed(({ opportunities, filter }) => ({
    inProgressOpportunities: computed(() =>
      opportunities().filter(
        (opportunity) =>
          opportunity.status.value === Octopus.OpportunityStatus.InProgress,
      ),
    ),
    dateRangeDisplay: computed<DateRangeDisplay>(() => ({
      dateSelected: Octopus.DateOptions[filter().date],
      dateRangeSelected: filter().range,
      customDateSelected: filter().date === Octopus.DateOptionId.CUSTOM,
    })),
  })),
  withMethods((store) => ({
    selectPipeline(pipeline: PipelineListItem): void {
      patchState(store, () => ({ selectedPipeline: pipeline }));
    },
    showOrHideFinalStages(show: boolean): void {
      patchState(store, () => ({
        showFinalStages: show,
      }));
    },
    setFilter(filter: OpportunityFilters): void {
      patchState(store, () => ({
        filter,
      }));
    },
    setOpportunities(opportunities: Opportunity[]): void {
      patchState(store, () => ({
        opportunities,
      }));
    },
    setSort(by: string, direction: SortDirection): void {
      patchState(store, () => ({
        sort: { by, direction },
      }));
    },
    setLayout(layout: Field[]): void {
      patchState(store, () => ({
        layout,
      }));
    },
  })),
);

export type OpportunityStore = InstanceType<typeof OpportunityStore>;
