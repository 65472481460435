<kendo-loader
  *ngIf="type === 'kendo'; else legacy"
  type="pulsing"
  class="z-20"
></kendo-loader>

<ng-template #legacy>
  <div class="image"></div>
</ng-template>

<div class="mask"></div>
