import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  OfficeItem,
  OutlookEmail,
  OutlookSaveEmail,
} from '@maximizer/outlook/shared/domain';
import { BehaviorSubject, lastValueFrom, timeout } from 'rxjs';
import {
  tryOfficeAsync,
  tryOfficeWithFallbackAsync,
} from '../../utils/outlook/outlook.util';
import { EntryType } from '@maximizer/core/shared/domain';
import { OutlookMsalService } from '..';
import { GRAPH_API_URI } from '../../outlook-shared-data-access.module';
import { InsightsService } from '@maximizer/core/shared/insights';

@Injectable({
  providedIn: 'root',
})
export class OutlookService {
  customProperties?: Office.CustomProperties;
  roamingSettings?: Office.RoamingSettings;
  emailSubject = new BehaviorSubject<OutlookEmail[]>([]);

  get mailbox(): Office.Mailbox {
    return Office.context.mailbox;
  }

  get mailboxItem(): OfficeItem {
    return Office.context.mailbox.item;
  }

  get storagePartitionKey(): string {
    return (
      Office.context.partitionKey ??
      Office.context.mailbox?.userProfile.emailAddress ??
      'mx_default'
    );
  }

  private composeId: string | null = null;

  constructor(
    @Inject(GRAPH_API_URI) private readonly graphApiUri: string,
    private readonly httpClient: HttpClient,
    private readonly msalService: OutlookMsalService,
    private readonly insightsService: InsightsService,
  ) {
    if (Office.context.mailbox) {
      this.roamingSettings = Office.context.roamingSettings;
      this.updateProperties().then(() => {
        if (this.isCompose) {
          this.startHeartBeat();
        }
      });
    }
  }

  async updateProperties() {
    if (!Office.context.mailbox.item) {
      console.error('Mailbox item is unavailable');
      return;
    }

    const result = await tryOfficeAsync<Office.CustomProperties>(
      Office.context.mailbox.item.loadCustomPropertiesAsync,
    );

    if (!result) {
      console.error('Unable to load custom properties');
      return;
    }

    this.customProperties = result;
  }

  get isCompose() {
    return this.mailboxItem?.getComposeTypeAsync;
  }

  async updateEmails(): Promise<void> {
    const item = this.mailboxItem;
    if (!item) return;

    if (!this.isCompose) {
      const newEmails = this.getReadEmails();
      this.emailSubject.next(Array.from(newEmails));
    } else {
      await this.getComposeEmails(item);
    }
  }

  getReadEmails(): Office.EmailAddressDetails[] {
    const item = Office.context.mailbox.item;
    if (!item) return [];
    const newEmails: Office.EmailAddressDetails[] = [];
    const officeEmail =
      Office.context.mailbox?.userProfile.emailAddress.toLocaleLowerCase();

    const from = this.getSenderEmail(item);
    if (from) {
      this.addEmailsToNewList([from], newEmails, officeEmail);
    }

    this.addEmailsToNewList(item.to, newEmails, officeEmail);
    this.addEmailsToNewList(item.cc, newEmails, officeEmail);
    const bcc = item.bcc as unknown as Office.EmailAddressDetails[];
    this.addEmailsToNewList(bcc, newEmails, officeEmail);

    return newEmails;
  }

  private addEmailsToNewList(
    emails: Office.EmailAddressDetails[],
    newEmails: Office.EmailAddressDetails[],
    officeEmail: string,
  ) {
    if (!emails.length) return;

    for (const email of emails) {
      const emailEntryLower = email.emailAddress.toLocaleLowerCase();
      if (emailEntryLower.localeCompare(officeEmail) === 0) continue;
      if (newEmails.find((n) => n.emailAddress === emailEntryLower)) continue;
      newEmails.push(email);
    }
  }

  getSenderEmail(item: OfficeItem): Office.EmailAddressDetails | null {
    if (!item) return null;

    const from = item.from;
    const result = item.from as Office.EmailAddressDetails;

    if (from) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result.emailAddress = from.emailAddress || (from as any).address;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result.displayName = from.displayName || (from as any).name;
      return result;
    }
    return null;
  }

  async getReadEmlFile(): Promise<File | null> {
    try {
      if (!this.mailboxItem) return null;

      const restItemId = Office.context.mailbox.convertToRestId(
        this.mailboxItem.itemId,
        Office.MailboxEnums.RestVersion.v2_0,
      );

      const emlContent = (await this.getMimeContent(restItemId)) ?? '';
      if (!emlContent) {
        throw new Error('Failed to retrieve EML content');
      }

      const subject = this.mailboxItem.subject ?? 'Untitled email';
      const emailName = subject.replace(/"/g, '_').trim() + '.eml';
      const blob = new Blob([emlContent], { type: 'application/octet-stream' });

      return new File([blob], emailName, {
        type: 'application/octet-stream',
      });
    } catch (error) {
      console.error('Error in getReadEmlFile:', error);
      throw error;
    }
  }

  private async getMimeContent(itemId: string): Promise<string | null> {
    try {
      const msalAuthentication = await this.msalService.getMsalAuthentication();

      if (!msalAuthentication?.accessToken) {
        console.error('getMimeContent: Failed to retrieve access token');
        return null;
      }

      const getMessageUrl = `${this.graphApiUri}/messages/${itemId}/$value`;

      const emailContent = await lastValueFrom(
        this.httpClient
          .get(getMessageUrl, {
            headers: new HttpHeaders({
              Authorization: `Bearer ${msalAuthentication.accessToken}`,
              'Content-Type': 'message/rfc822',
            }),
            responseType: 'text',
          })
          .pipe(timeout(15000)),
      );

      return emailContent;
    } catch (error) {
      console.error('Error fetching MIME content:', error);
      if (error instanceof Error) {
        this.insightsService.trackException({ error });
      } else {
        this.insightsService.trackException({
          error: new Error('Unknown error fetching MIME content'),
        });
      }
      return null;
    }
  }

  async getInternetMessageId(itemId: string): Promise<string | null> {
    try {
      const restItemId = Office.context.mailbox.convertToRestId(
        itemId,
        Office.MailboxEnums.RestVersion.v2_0,
      );
      const msalAuthentication = await this.msalService.getMsalAuthentication();

      if (!msalAuthentication?.accessToken) {
        console.error('getInternetMessageId: Failed to retrieve access token');
        return null;
      }

      const getMessageUrl = `${this.graphApiUri}/messages/${restItemId}/?$select=internetMessageId`;
      const response = await lastValueFrom(
        this.httpClient
          .get<{ internetMessageId?: string }>(getMessageUrl, {
            headers: new HttpHeaders({
              Authorization: `Bearer ${msalAuthentication.accessToken}`,
            }),
            responseType: 'json',
          })
          .pipe(timeout(15000)),
      );

      if (!response?.internetMessageId) {
        console.error('Response does not contain internetMessageId', response);
        return null;
      }

      return response.internetMessageId;
    } catch (error) {
      console.error('Error fetching internetMessageId:', error);
      if (error instanceof Error) {
        this.insightsService.trackException({ error });
      } else {
        this.insightsService.trackException({
          error: new Error('Unknown error fetching internetMessageId'),
        });
      }
      return null;
    }
  }

  async saveKeysInOutlook(entries: OutlookEmail[], isOtherEntries: boolean) {
    if (!this.customProperties) {
      await this.updateProperties();
      if (!this.customProperties) return;
    }
    let savedEmails = await this.getSavedEmails();
    if (!savedEmails)
      savedEmails = {
        othersInMaximizer: [],
        inMaximizer: [],
        opportunities: [],
        cases: [],
      };

    entries.forEach((entry) => {
      if (isOtherEntries) {
        if (
          savedEmails.othersInMaximizer.findIndex((o) => o.id == entry.id) != -1
        )
          return;
        savedEmails.othersInMaximizer.push(entry);
      } else {
        if (savedEmails.inMaximizer.findIndex((o) => o.id == entry.id) != -1)
          return;
        savedEmails.inMaximizer.push(entry);
      }
    });

    try {
      const savedEmailInMaximizer = JSON.stringify(savedEmails.inMaximizer);
      const savedEmailOthersInMaximizer = JSON.stringify(
        savedEmails.othersInMaximizer,
      );

      this.customProperties.remove('savedEmails');
      this.customProperties.set('savedInMaximizer', savedEmailInMaximizer);
      this.customProperties.set(
        'savedOtherInMaximizer',
        savedEmailOthersInMaximizer,
      );
      this.customProperties.saveAsync();
    } catch (error) {
      console.error('Error saving keys in Outlook:', error);
    }
  }

  async saveOpportunityOrCaseInOutlook(key: string, type: EntryType) {
    if (!this.customProperties) {
      await this.updateProperties();
      if (!this.customProperties) return;
    }

    let savedEmails = await this.getSavedEmails();
    if (!savedEmails) {
      savedEmails = {
        othersInMaximizer: [],
        inMaximizer: [],
        opportunities: [],
        cases: [],
      };
    }

    if (type === 'opportunity') {
      if (savedEmails.opportunities.findIndex((item) => item === key) !== -1) {
        return;
      }
      savedEmails.opportunities.push(key);
    } else if (type === 'case') {
      if (savedEmails.cases.findIndex((item) => item === key) !== -1) {
        return;
      }
      savedEmails.cases.push(key);
    }

    try {
      const savedEmailInOpportunity = JSON.stringify(savedEmails.opportunities);
      const savedEmailInCase = JSON.stringify(savedEmails.cases);

      this.customProperties.set('savedToOpportunity', savedEmailInOpportunity);
      this.customProperties.set('savedToCase', savedEmailInCase);
      this.customProperties.saveAsync();
    } catch (error) {
      console.error('Error saving keys in Outlook:', error);
    }
  }

  async getSavedEmails(retry = false): Promise<OutlookSaveEmail> {
    const result: OutlookSaveEmail = {
      othersInMaximizer: [],
      inMaximizer: [],
      opportunities: [],
      cases: [],
    };
    try {
      await this.updateProperties();

      result.inMaximizer = this.getEmailsFromOutlookByKey(
        'savedInMaximizer',
      ) as OutlookEmail[];
      result.othersInMaximizer = this.getEmailsFromOutlookByKey(
        'savedOtherInMaximizer',
      ) as OutlookEmail[];
      result.opportunities = this.getEmailsFromOutlookByKey(
        'savedToOpportunity',
      ) as string[];
      result.cases = this.getEmailsFromOutlookByKey('savedToCase') as string[];
    } catch (error) {
      if (retry) {
        console.error('Error in getSavedEmails method:', error);
        return result;
      }
      console.log('Error retrieving savedEmails, retrying.');
      return await this.getSavedEmails(true);
    }
    return result;
  }

  async checkSavedEmailByKey(parentKey: string): Promise<boolean> {
    try {
      const result: OutlookSaveEmail = {
        othersInMaximizer: [],
        inMaximizer: [],
        opportunities: [],
        cases: [],
      };

      await this.updateProperties();

      result.inMaximizer = this.getEmailsFromOutlookByKey(
        'savedInMaximizer',
      ) as OutlookEmail[];
      result.othersInMaximizer = this.getEmailsFromOutlookByKey(
        'savedOtherInMaximizer',
      ) as OutlookEmail[];
      result.opportunities = this.getEmailsFromOutlookByKey(
        'savedToOpportunity',
      ) as string[];
      result.cases = this.getEmailsFromOutlookByKey('savedToCase') as string[];

      const existInMax = result.inMaximizer.find((o) => o.id === parentKey);
      const notInMax = result.othersInMaximizer.find((o) => o.id === parentKey);
      const oppInMax = result.opportunities.includes(parentKey);
      const caseInMax = result.cases.includes(parentKey);

      return (
        existInMax?.emailSaved ||
        notInMax?.emailSaved ||
        oppInMax ||
        caseInMax ||
        false
      );
    } catch (error) {
      console.error(
        'Error in checkSavedEmailByKey method for parentKey:',
        parentKey,
        error,
      );
      return false;
    }
  }

  private getEmailsFromOutlookByKey(key: string): OutlookEmail[] | string[] {
    try {
      if (!this.customProperties) return [];
      const result = this.customProperties.get(key);

      if (!result) {
        return [];
      }
      if (typeof result == 'object') return result;

      return JSON.parse(result);
    } catch (error) {
      console.error('Error GetEmailsFromOutlookByKey', key, error);
      return [];
    }
  }

  // ---------- COMPOSE Methods ----------

  isOutlookLegacyDesktop(): boolean {
    return Office.context.platform === Office.PlatformType.PC;
  }

  async getComposeEmails(item: OfficeItem) {
    if (!item) return;
    const userOfficeEmail =
      Office.context.mailbox.userProfile.emailAddress.toLocaleLowerCase();
    const emails: OutlookEmail[] = [];
    const to = await tryOfficeAsync<Office.EmailAddressDetails[]>(
      item.to.getAsync,
      false,
    );
    if (to?.length)
      to.forEach((o) =>
        this.addEmailToComposeList(
          'to',
          o as OutlookEmail,
          emails,
          userOfficeEmail,
        ),
      );
    const cc = await tryOfficeAsync<Office.EmailAddressDetails[]>(
      item.cc.getAsync,
      false,
    );
    if (cc?.length)
      cc.forEach((o) =>
        this.addEmailToComposeList(
          'cc',
          o as OutlookEmail,
          emails,
          userOfficeEmail,
        ),
      );
    const bcc = await tryOfficeAsync<Office.EmailAddressDetails[]>(
      item.bcc.getAsync,
      false,
    );
    if (bcc?.length)
      bcc.forEach((o) =>
        this.addEmailToComposeList(
          'bcc',
          o as OutlookEmail,
          emails,
          userOfficeEmail,
        ),
      );

    this.emailSubject.next(emails);
  }

  private addEmailToComposeList(
    position: 'from' | 'to' | 'cc' | 'bcc',
    outlook: OutlookEmail,
    emails: OutlookEmail[],
    userOfficeEmail: string,
  ): OutlookEmail[] {
    if (outlook.emailAddress.toLocaleLowerCase() === userOfficeEmail)
      return emails;
    outlook.position = position;
    outlook.localId = outlook.emailAddress + emails.length;
    outlook.emailAddress = outlook.emailAddress.toLocaleLowerCase();
    emails.push(outlook);
    return emails;
  }

  async addEmailToPosition(
    email: OutlookEmail,
    position: 'to' | 'cc' | 'bcc',
  ): Promise<boolean> {
    const item = Office.context.mailbox.item;
    const finalEmail = email as Office.EmailAddressDetails;
    if (!finalEmail || !item) return false;
    try {
      switch (position) {
        case 'to':
          await tryOfficeAsync<void>(item.to.addAsync, false, finalEmail);
          break;
        case 'cc':
          await tryOfficeAsync<void>(item.cc.addAsync, false, finalEmail);
          break;
        case 'bcc':
          await tryOfficeAsync<void>(item.bcc.addAsync, false, finalEmail);
          break;
        default:
          return false;
      }
      return true;
    } catch (error) {
      console.error('Error while adding email to Outlook', error);
    }
    return false;
  }

  async getComposeEmailId(): Promise<string | null> {
    if (!this.mailboxItem) {
      return null;
    }
    if (this.composeId) {
      return this.composeId;
    }

    if (this.mailboxItem.itemId) {
      this.composeId = this.mailboxItem.itemId;
    } else {
      this.composeId = await tryOfficeWithFallbackAsync<string>(
        this.mailboxItem.getItemIdAsync,
        this.mailboxItem.saveAsync,
      );
    }
    return this.composeId;
  }

  // Track inactivity, office.js doesn't provide onclose/onsend for Taskpane
  static heartbeatInterval?: number;

  async startHeartBeat() {
    if (OutlookService.heartbeatInterval) return;
    const isWindows = this.isOutlookLegacyDesktop();
    if (isWindows) return;

    await this.setHeartbeat();
    OutlookService.heartbeatInterval = setInterval(async () => {
      await this.setHeartbeat();
    }, 4000);
  }

  private async setHeartbeat() {
    let itemId = await this.getComposeEmailId();
    if (!itemId) {
      itemId = 'defaultHeartbeatItem';
    }

    const heartbeat = { id: itemId, date: new Date().toUTCString() };
    if (!this.customProperties) {
      await this.updateProperties();
    }
    this.customProperties?.set('EmailOpening', JSON.stringify(heartbeat));
    this.customProperties?.saveAsync((asyncResult) => {
      if (asyncResult.status !== Office.AsyncResultStatus.Succeeded) {
        console.log('Save heartbeat fail');
      }
    });
  }
}
