import { Mapper } from '../../../mappers';
import { UrlHeaders } from '../../../models';
import { Headers } from '../models';

export class HeadersMapper extends Mapper<Headers, UrlHeaders> {
  from(source: Headers): UrlHeaders {
    return {
      description: source['og:description'],
      image: source['og:image'],
      site_name: source['og:site_name'],
      title: source['og:title'],
      type: source['og:type'],
      url: source['og:url'],
    };
  }
}
