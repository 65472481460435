import { EntityFields } from './entity';
import { ReadRequest, Request, WriteRequest } from './request';
import { DataResponse, WriteResponse } from './response';
import { ValueAndDisplay } from './value-and-display';

export enum AddressFields {
  AddressLine1 = 'AddressLine1',
  AddressLine2 = 'AddressLine2',
  City = 'City',
  Country = 'Country',
  Default = 'Default',
  Description = 'Description',
  ParentKey = 'ParentKey',
  StateProvince = 'StateProvince',
  ZipCode = 'ZipCode',
}

export interface Address<T = string> {
  [EntityFields.Key]?: string;
  [AddressFields.Description]: T;
  [AddressFields.AddressLine1]: T;
  [AddressFields.AddressLine2]: T;
  [AddressFields.City]: T;
  [AddressFields.Country]: T;
  [AddressFields.StateProvince]: T;
  [AddressFields.ZipCode]: T;
  [AddressFields.Default]?: boolean;
  [AddressFields.ParentKey]?: string;
  [EntityFields.DisplayValue]?: string;
}

export type EntityAddress = Address<ValueAndDisplay<string>>;

export const AddressFieldsEditableListFields = {
  [AddressFields.City]: 60007,
  [AddressFields.StateProvince]: 60008,
  [AddressFields.Country]: 60009,
};

export interface AddressReadRequest extends Request {
  Address: ReadRequest<Address>;
}

export interface AddressWriteRequest extends Request {
  Address: WriteRequest<Address>;
}

export interface AddressResponse extends Response {
  Address: DataResponse<Address>;
}

export interface AddressWriteResponse extends Response {
  Address: WriteResponse<Address>;
}
