import { Octopus } from '../..';
import { Mapper } from '../../../mappers';
import { Statistics } from '../../../models';

export class StatisticsMapper extends Mapper<Octopus.Statistics, Statistics> {
  from(source: Octopus.Statistics): Statistics {
    return {
      storageDb: source.Statistics.storageDb,
      storageBlob: source.Statistics.storageBlob
    } as Statistics;
  }
}
