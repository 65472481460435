import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContextService } from '@maximizer/core/shared/data-access';
import { Octopus } from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';
import {
  AbEntryDetailsMapper,
  AbEntryEditMapper,
  AbEntryFieldOption,
  AbEntryFieldOptionMapper,
  AbEntrySearchMapper,
  OutlookAbEntryDetails,
  OutlookAbEntryForm,
  OutlookAbEntrySearch,
} from '@maximizer/outlook/shared/domain';
@Injectable()
export class AbEntryService {
  constructor(
    private http: HttpClient,
    private context: ContextService,
  ) {}

  getByKey(key: string): Observable<OutlookAbEntrySearch | null> {
    const query: Octopus.Query<Octopus.AbEntry> = {
      Key: {
        $EQ: { Value: key },
      },
    };
    const request = this.getRequest(query);

    return this.http
      .post<Octopus.AbEntryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new AbEntrySearchMapper();
            return result.AbEntry.Data.map((abEntry) => mapper.from(abEntry));
          }
          return [];
        }),
      )
      .pipe(map((leads) => (leads.length ? leads[0] : null)));
  }

  getAllDetailsByKey(key: string): Observable<OutlookAbEntryDetails | null> {
    const query: Octopus.Query<Octopus.AbEntry> = {
      Key: {
        $EQ: { Value: key },
      },
    };

    const request = this.getRequest(query);

    return this.http
      .post<Octopus.AbEntryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new AbEntryDetailsMapper();
            const abentryMap = result.AbEntry.Data.map((abEntry) =>
              mapper.from(abEntry),
            );
            return abentryMap.length > 0 ? abentryMap[0] : null;
          }
          return null;
        }),
      );
  }

  getByEmails(emails: string[]): Observable<OutlookAbEntryDetails[]> {
    const searchQuery: Octopus.LogicalQuery<Octopus.AbEntry> = {
      $OR: [],
    };
    for (const email of emails) {
      let query: Octopus.Query<Octopus.AbEntry> = {
        Email1: { $EQ: { Address: email } },
      };
      searchQuery.$OR?.push(query);
      query = {
        Email2: { $EQ: { Address: email } },
      };
      searchQuery.$OR?.push(query);
      query = {
        Email3: { $EQ: { Address: email } },
      };
      searchQuery.$OR?.push(query);
    }

    const request = this.getSmallRequest(searchQuery);

    return this.http
      .post<Octopus.AbEntryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(map((result) => this.mapAbEntryDetailsList(result)));
  }

  getByKeys(keys: string[]): Observable<OutlookAbEntryDetails[]> {
    const searchQuery: Octopus.LogicalQuery<Octopus.AbEntry> = {
      $OR: [],
    };
    for (const key of keys) {
      const query: Octopus.Query<Octopus.AbEntry> = {
        Key: { $EQ: key },
      };
      searchQuery.$OR?.push(query);
    }

    const request = this.getSmallRequest(searchQuery);

    return this.http
      .post<Octopus.AbEntryResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(map((result) => this.mapAbEntryDetailsList(result)));
  }

  private getSmallRequest(
    searchQuery: Octopus.LogicalQuery<Octopus.AbEntry>,
  ): Octopus.AbEntryReadRequest {
    return {
      AbEntry: {
        Criteria: {
          SearchQuery: searchQuery,
        },
        Scope: {
          Fields: {
            Key: 1,
            FirstName: 1,
            LastName: 1,
            CompanyName: 1,
            Type: 1,
            Email1: {
              Address: 1,
              Description: 1,
              Default: 1,
            },
            Email2: {
              Address: 1,
              Description: 1,
              Default: 1,
            },
            Email3: {
              Address: 1,
              Description: 1,
              Default: 1,
            },
            LastContactDate: 1
          },
        },
      },
    };
  }
  private getRequest(
    query?: Octopus.Query<Octopus.AbEntry>,
  ): Octopus.AbEntryReadRequest {
    const searchQuery: Octopus.LogicalQuery<Octopus.AbEntry> = {
      $AND: [],
    };
    if (query) {
      searchQuery.$AND?.push(query);
    }
    return {
      AbEntry: {
        Scope: {
          Fields: {
            Key: 1,
            Phone: {
              Description: 1,
              Number: 1,
              Extension: 1,
            },
            Phone1: {
              Extension: 1,
              Number: 1,
              Description: 1,
            },
            Phone2: {
              Extension: 1,
              Number: 1,
              Description: 1,
            },
            Phone3: {
              Extension: 1,
              Number: 1,
              Description: 1,
            },
            Phone4: {
              Extension: 1,
              Number: 1,
              Description: 1,
            },
            Email: {
              Address: 1,
              Description: 1,
              Default: 1,
            },
            Email1: {
              Address: 1,
              Description: 1,
              Default: 1,
            },
            Email2: {
              Address: 1,
              Description: 1,
              Default: 1,
            },
            Email3: {
              Address: 1,
              Description: 1,
              Default: 1,
            },
            FirstName: 1,
            LastName: 1,
            CompanyName: 1,
            Position: 1,
            WebSite: 1,
            Address: {
              Key: 1,
              AddressLine1: 1,
              AddressLine2: 1,
              City: 1,
              Country: 1,
              StateProvince: 1,
              ZipCode: 1,
            },
            ParentKey: {
              Value: 1,
            },
            LastContactDate: {
              Value: 1,
            },
            Type: 1,
          },
        },
        Criteria: {
          SearchQuery: searchQuery,
        },
        OrderBy: {
          Fields: [
            {
              FirstName: 'ASC',
            },
          ],
        },
      },
      Configuration: Octopus.AbEntryReadDriver,
    };
  }

  update(query: OutlookAbEntryForm): Observable<boolean> {
    const mapper = new AbEntryEditMapper();
    const request: Octopus.AbEntryWriteRequest = mapper.from(query);
    return this.http
      .post<Octopus.AbEntryWriteResponse>(
        `${this.context.api}${Octopus.Action.UPDATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result?.Code === Octopus.ResponseStatusCode.Successful) {
            return result.Code == 0;
          }
          throw new Error('Unsuccessful request: ' + result?.Code);
        }),
      );
  }

  save(query: OutlookAbEntryForm): Observable<string | null> {
    const mapper = new AbEntryEditMapper();
    const request: Octopus.AbEntryWriteRequest = mapper.from(query);
    return this.http
      .post<Octopus.AbEntryWriteResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result?.Code === Octopus.ResponseStatusCode.Successful) {
            return result.AbEntry.Data.Key ?? '';
          }
          throw new Error('Failed to save abentry');
        }),
      );
  }

  saveCompany(companyName: string): Observable<string | null> {
    const request: Octopus.AbEntryWriteRequest = {
      AbEntry: {
        Data: {
          Key: null,
          CompanyName: companyName,
          Type: 'Company',
        },
      },
    };
    return this.http
      .post<Octopus.AbEntryWriteResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result?.Code === Octopus.ResponseStatusCode.Successful) {
            return result.AbEntry.Data.Key ?? '';
          }
          throw new Error('Failed to create company');
        }),
      );
  }

  getFieldOption(): Observable<AbEntryFieldOption> {
    const request: Octopus.AbEntryFieldOptionsRequest = {
      AbEntry: {
        FieldOptions: {
          'Phone/Description': [
            {
              Key: 1,
              DisplayValue: 1,
            },
          ],
          'Email/Description': [
            {
              Key: 1,
              DisplayValue: 1,
            },
          ],
        },
      },
    };

    return this.http
      .post<Octopus.AbEntryFieldOptionsResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new AbEntryFieldOptionMapper();
            return mapper.from(result);
          }
          return { phoneDescriptions: [], emailDescriptions: [] };
        }),
      );
  }

  private mapAbEntryDetailsList(
    result: Octopus.AbEntryResponse,
  ): OutlookAbEntryDetails[] {
    if (result.Code === Octopus.ResponseStatusCode.Successful) {
      const mapper = new AbEntryDetailsMapper();
      return result.AbEntry.Data.map((abEntry) => mapper.from(abEntry));
    }
    return [];
  }
}
