export interface ConfigurationSetting extends ConfigurationSettingCodes {
  id: string;
  textValue?: string | null;
  intValue?: number | null;
}

export interface ConfigurationSettingCodes {
  code1?: string;
  code2?: string;
  code3?: string;
  code4?: string;
}

export enum ConfigurationSettingId {
  showDisabledUsers = 'DisplayUsersByDisabled',
  culture = 'CulturePreference',
}
