import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { SessionStorageService } from '@maximizer/core/shared/data-access';
import { CDN_URL } from '@maximizer/core/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { Router } from '@angular/router';
import {
  Observable,
  Subscription,
  catchError,
  finalize,
  forkJoin,
  of,
} from 'rxjs';
import {
  AbEntryService,
  LeadService,
  OutlookService,
} from '@maximizer/outlook/shared/data-access';
import {
  OpportunityRevenueSum,
  OutlookAbEntryDetails,
  OutlookEmail,
  OutlookLeadSearch,
} from '@maximizer/outlook/shared/domain';
import { OpportunityService } from '@maximizer/outlook/opportunity/data-access';
import { TaskService } from '@maximizer/outlook/task/data-access';
import { InteractionService } from '@maximizer/outlook/interaction-log/data-access';
import { OutlookComposeSaveToEmailComponent } from './save-to-email/save-to-email.component';

@Component({
  selector: 'maximizer-outlook-compose',
  templateUrl: './compose.component.html',
})
export class OutlookComposeComponent implements OnDestroy {
  @HostBinding('class.max-outlook') hostClass = true;

  isVersionValid = false;
  loading = true;

  addMenuItems: { id: string; icon: string; route: string }[] = [
    {
      id: 'individual',
      route: '/abentry/add/individual',
      icon: 'fa-icons icon-regular icon-18 icon-building-user',
    },
    {
      id: 'contact',
      route: '/abentry/add/contact',
      icon: 'fa-icons icon-solid icon-18 icon-user',
    },
    {
      id: 'lead',
      route: '/lead/add',
      icon: 'fa-icons icon-solid icon-18 icon-user-magnifying-glass',
    },
  ];

  pageTitle = '';

  showSkeleton = true;

  // Raw data
  abentries: OutlookAbEntryDetails[] = [];
  leads: OutlookLeadSearch[] = [];
  outlookEmails: OutlookEmail[] = [];

  // Mapped data
  uniqueEmailsCardList: OutlookEmail[] = [];

  selectedDropdownItemPosition = 0;
  emailAddedFromSearch?: OutlookEmail;
  newEntries: string[] = [];

  selectedEmail?: OutlookEmail;
  selectedEmailSummary: {
    lastContacted?: Date | null;
    currentOpp?: string;
    task?: string;
  } = {};

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  lastContactDays: number | null = null;
  lastContactMessage: string | null = null;
  showSkeletonCardData = true;

  recipientChangedHandlerAdded = false;
  emailSubjectSubscription?: Subscription;

  @ViewChild(OutlookComposeSaveToEmailComponent)
  multiselectComponent!: OutlookComposeSaveToEmailComponent;

  constructor(
    @Inject(CDN_URL) public cdn: string,
    private leadService: LeadService,
    private router: Router,
    private abentryService: AbEntryService,
    private storage: SessionStorageService,
    private outlookService: OutlookService,
    private opportunityService: OpportunityService,
    private taskService: TaskService,
    private interactionService: InteractionService,
  ) {}

  async handleComposeValidation(event: { isVersionValid: boolean }) {
    this.isVersionValid = event.isVersionValid;
    this.loading = false;
    if (this.isVersionValid) {
      await this.initializeCompose();
    }
  }

  ngOnDestroy(): void {
    this.emailSubjectSubscription?.unsubscribe();
    try {
      this.outlookService.mailboxItem?.removeHandlerAsync(
        Office.EventType.RecipientsChanged,
      );
    } catch (error) {
      console.error('destroy', error);
    }
  }

  async initializeCompose() {
    this.emailSubjectSubscription = this.outlookService.emailSubject
      .pipe(
        catchError(async (error) => {
          this.showLoadError(error);
          return [];
        }),
        finalize(() => {
          this.showSkeleton = false;
        }),
      )
      .subscribe((emails: OutlookEmail[]) => {
        this.initialize(emails);
      });

    if (this.outlookService.mailbox) {
      const item = this.outlookService.mailboxItem;
      if (!item || this.recipientChangedHandlerAdded) return;

      item.addHandlerAsync(
        Office.EventType.RecipientsChanged,
        this.composeItemsChanged.bind(this),
      );
      this.recipientChangedHandlerAdded = true;
    }
    await this.outlookService.updateEmails();
  }

  initialize(emails: OutlookEmail[]) {
    this.resetAllProps();
    if (emails.length === 0) {
      this.showSkeleton = false;
      this.selectedEmail = undefined;
      this.outlookEmails = [];
      this.selectedEmailSummary = {};
      this.uniqueEmailsCardList = [];
      this.selectedDropdownItemPosition = 0;
      this.multiselectComponent.resetComponentProperties();
      this.storage.remove(
        this.outlookService.storagePartitionKey + 'selectedEmail',
      );
    } else {
      this.outlookEmails = [...emails];
      this.loadEmailsInMaximizer();
    }
  }

  handleEmailAddedFromSearch(email: OutlookEmail) {
    if (email.abentryType === 'contact') {
      const idx = email.displayName.lastIndexOf(' - ');
      if (idx !== -1) {
        email.displayName = email.displayName.substring(0, idx);
      }
    }
    email.localId = email.id;

    this.emailAddedFromSearch = email;
  }

  resetAllProps() {
    this.showSkeleton = this.currentEntryStillExists();
  }

  async composeItemsChanged() {
    this.resetAllProps();
    const item = this.outlookService.mailboxItem;
    if (!item) return;
    this.emailAddedFromSearch = undefined;
    this.outlookService.updateEmails();
    this.updateSelectedEntry();
  }

  // -------------- LOAD DATA --------------

  loadEmailsInMaximizer() {
    const emails = this.outlookEmails.map((o) => o.emailAddress);

    if (emails.length === 0) {
      this.showSkeleton = false;
      return;
    }
    this.showSkeleton = true;
    forkJoin({
      abentries: this.abentryService.getByEmails(emails),
      leads: this.leadService.getByEmails(emails),
    })
      .pipe(
        catchError(async (error) => this.showLoadError(error)),
        finalize(() => {
          this.showSkeleton = false;
        }),
      )
      .subscribe(async (data) => {
        this.abentries = data.abentries;
        this.leads = data.leads;

        const maximizerEntries = this.mapFromMaximizerToOutlook(data);
        await this.mapOutlookEntriesToComponents(maximizerEntries);
      });
  }

  private mapFromMaximizerToOutlook(data: {
    abentries: OutlookAbEntryDetails[];
    leads: OutlookLeadSearch[];
  }) {
    if (this.outlookEmails.length === 0) return [];

    let maximizerEntries: OutlookEmail[] = this.mapAbEntriesToOutlookEmail(
      data.abentries,
    );

    const leads: OutlookEmail[] = this.mapLeadsToOutlookEmail(data.leads);

    if (leads.length > 0) {
      maximizerEntries = maximizerEntries.concat(leads);
    }

    this.mapEntriesWithNew(maximizerEntries);
    return maximizerEntries;
  }

  // -------------- ADD DATA TO COMPONENT --------------
  private async mapOutlookEntriesToComponents(
    maximizerEntries: OutlookEmail[],
  ) {
    let uniqueEmails =
      this.classifyUniqueMaximizerAndOutlookEmails(maximizerEntries);
    uniqueEmails = this.sortEmailsWithOutlookOrder(uniqueEmails);
    if (uniqueEmails.length) {
      uniqueEmails.forEach((o) => (o.localId = o.id));
      this.uniqueEmailsCardList = Array.from(uniqueEmails);
      this.updateSelectedEntry();
    }

    await this.multiselectComponent?.initialize(
      this.outlookEmails,
      maximizerEntries,
    );
  }

  private handleEmailAddedDropdown() {
    const exist = this.uniqueEmailsCardList.find(
      (o) => o.id === this.emailAddedFromSearch?.id,
    );
    if (exist) {
      this.selectedEmail = exist;
      this.onSelectedEmailValueChange(exist);
    }
  }

  private updateSelectedEntry() {
    if (this.emailAddedFromSearch) {
      this.handleEmailAddedDropdown();
      return;
    }

    if (this.currentEntryStillExists()) return;
    if (this.entryExistsInSessionStorage()) return;

    this.selectedEmail = this.uniqueEmailsCardList[0];
    this.onSelectedEmailValueChange(this.selectedEmail);
  }

  private currentEntryStillExists(): boolean {
    const exists =
      this.selectedEmail &&
      this.uniqueEmailsCardList.find(
        (o) =>
          o.localId === this.selectedEmail?.localId ||
          o.id === this.selectedEmail?.id,
      );

    return exists !== undefined;
  }
  private entryExistsInSessionStorage(): boolean {
    const selectedEmailJson = this.storage.get(
      this.outlookService.storagePartitionKey + 'selectedEmail',
    );
    if (selectedEmailJson === 'undefined') return false;
    if (selectedEmailJson === undefined || selectedEmailJson === null)
      return false;
    const result = JSON.parse(selectedEmailJson);
    if (!result) return false;

    if (
      !this.uniqueEmailsCardList.find(
        (o) => o.localId === result?.localId || o.id === result?.id,
      )
    )
      return false;

    this.selectedEmail = result;
    this.onSelectedEmailValueChange(result);
    return true;
  }

  private classifyUniqueMaximizerAndOutlookEmails(
    maximizerEntries: OutlookEmail[],
  ) {
    let uniqueEmailsForCards: OutlookEmail[] = [];
    for (const outlookEmail of this.outlookEmails) {
      const existsInMax = maximizerEntries.filter(
        (o) => o.emailAddress === outlookEmail.emailAddress,
      );
      existsInMax.forEach((e) => {
        const copy = { ...e };
        copy.position = outlookEmail.position;
        copy.localId = e.id;
        if (uniqueEmailsForCards.find((o) => o.id === copy.id)) return;
        uniqueEmailsForCards.push(copy);
      });
    }

    const emailsNotInMaximizer: OutlookEmail[] = this.outlookEmails.filter(
      (o) =>
        !uniqueEmailsForCards.find((m) => o.emailAddress === m.emailAddress),
    );

    const uniqueEmailsNotInMaximizer: OutlookEmail[] = [];
    for (const email of emailsNotInMaximizer) {
      if (
        uniqueEmailsNotInMaximizer.find(
          (o) => o.emailAddress === email.emailAddress,
        )
      ) {
        continue;
      }
      uniqueEmailsNotInMaximizer.push(email);
    }
    uniqueEmailsForCards = uniqueEmailsForCards.concat(
      uniqueEmailsNotInMaximizer,
    );
    return uniqueEmailsForCards;
  }

  private sortEmailsWithOutlookOrder(array: OutlookEmail[]): OutlookEmail[] {
    const map = this.outlookEmails.map((o) => o.emailAddress);
    return array.sort(
      (a, b) => map.indexOf(a.emailAddress) - map.indexOf(b.emailAddress),
    );
  }

  // -------------- ENTRY CARD --------------
  navigateToAdd(event: { route: string }, email: string, name: string) {
    this.router.navigate([event.route], {
      queryParams: { email, name },
      replaceUrl: true,
    });
  }

  navigateToEntry(id: string | undefined, type: string | undefined) {
    this.router.navigate(['/' + type + '/' + id]);
  }

  navigateCardsRight() {
    if (this.uniqueEmailsCardList.length === 0) return;
    const currentIdx = this.selectedDropdownItemPosition - 1;
    const lastPosition = this.uniqueEmailsCardList.length - 1;
    const nextPosition = currentIdx + 1 > lastPosition ? 0 : currentIdx + 1;
    this.selectedEmail = this.uniqueEmailsCardList[nextPosition];

    this.onSelectedEmailValueChange(this.selectedEmail);
  }

  navigateCardsLeft() {
    if (this.uniqueEmailsCardList.length === 0) return;
    const currentIdx = this.selectedDropdownItemPosition - 1;
    const lastPosition = this.uniqueEmailsCardList.length - 1;
    const nextPosition = currentIdx - 1 < 0 ? lastPosition : currentIdx - 1;
    this.selectedEmail = this.uniqueEmailsCardList[nextPosition];

    this.onSelectedEmailValueChange(this.selectedEmail);
  }

  // -------------- DROPDOWN CARD --------------
  onSelectedEmailValueChange(emailEntry: OutlookEmail) {
    let idx = this.uniqueEmailsCardList.findIndex(
      (o) => o.localId == emailEntry.localId,
    );

    if (idx === -1) {
      this.selectedEmail = this.uniqueEmailsCardList[0];
      idx = this.uniqueEmailsCardList.findIndex(
        (o) => o.localId == emailEntry.localId,
      );
    }

    this.selectedDropdownItemPosition = idx + 1;
    this.storage.set('selectedEmail', JSON.stringify(emailEntry));
    this.getEntrySummary();
  }

  getEntrySummary() {
    this.selectedEmailSummary.currentOpp = undefined;
    this.selectedEmailSummary.task = undefined;
    this.selectedEmailSummary.lastContacted = undefined;

    if (!this.selectedEmail?.id) return;
    this.showSkeletonCardData = true;

    let oppService: Observable<OpportunityRevenueSum | null> = of(null);
    if (this.selectedEmail?.type === 'abentry' && this.selectedEmail.id) {
      oppService = this.opportunityService.getOpenSum(this.selectedEmail.id);
    }
    if (!this.selectedEmail.type) {
      this.selectedEmail.type =
        window.atob(this.selectedEmail.id) === 'Lead' ? 'lead' : 'abentry';
    }
    forkJoin({
      opportunity: oppService,
      task: this.taskService.getComposeSummary(
        this.selectedEmail.id,
        this.selectedEmail.type,
      ),
      lastInteraction: this.interactionService.getLastContact(
        this.selectedEmail.id,
        this.selectedEmail.type,
      ),
    })
      .pipe(finalize(() => (this.showSkeletonCardData = false)))
      .subscribe((data) => {
        this.selectedEmailSummary.currentOpp = data.opportunity?.current;
        this.selectedEmailSummary.task = data.task?.description;
        if (this.selectedEmail?.type === 'lead') {
          this.selectedEmailSummary.lastContacted = data.lastInteraction;
          this.daysAgo(data.lastInteraction);
          this.lastContactMessage = this.getDaysAgoTranslation();
        } else {
          const abentry = this.abentries.find(
            (o) => o.key === this.selectedEmail?.id,
          );
          this.selectedEmailSummary.lastContacted =
            abentry?.lastContactDateValue;
          this.daysAgo(abentry?.lastContactDateValue);
          this.lastContactMessage = this.getDaysAgoTranslation();
        }
      });
  }

  // -------------- UI HTML --------------
  getEntryType(type: string, abentryType?: string) {
    if (type === 'lead') return 'lead';
    return abentryType;
  }

  getAvatarLetters(dataItem: OutlookEmail) {
    if (!dataItem.emailAddress && !dataItem.displayName) return '';
    if (!dataItem.displayName) {
      return dataItem.emailAddress?.substring(0, 2) ?? '';
    }
    if (dataItem?.abentryType === 'contact') {
      const contactName = dataItem.displayName.substring(
        0,
        dataItem.displayName.lastIndexOf(' - '),
      );
      const lastNameIdx = contactName.lastIndexOf(' ');

      if (lastNameIdx === -1) {
        const firstLetters = dataItem.displayName.substring(0, 2);
        return `${firstLetters[0]} ${firstLetters[1]}`;
      }
      return contactName;
    }

    if (
      dataItem.displayName.indexOf(' ') === -1 &&
      dataItem.displayName.length > 1
    ) {
      if (dataItem.emailAddress === undefined) return '';
      const firstLetters = dataItem.emailAddress.substring(0, 2);
      return `${firstLetters[0]} ${firstLetters[1]}`;
    }
    return dataItem.displayName;
  }

  daysAgo(value: Date | undefined | null) {
    if (!value) {
      this.lastContactDays = null;
      return;
    }
    const currentDate = new Date();
    const targetDate = new Date(value);
    const diffInTime = currentDate.getTime() - targetDate.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
    if (isNaN(diffInDays)) {
      this.lastContactDays = null;
    } else {
      this.lastContactDays = diffInDays;
    }
  }

  getDaysAgoTranslation() {
    if (this.lastContactDays == null) return 'outlook.entry-page.notContacted';
    if (this.lastContactDays < 0) return '';
    if (this.lastContactDays == 0) return 'outlook.entry-page.today';
    return 'outlook.entry-page.daysago';
  }

  getAvatarClass(type?: string, abentryType?: string): string {
    if (abentryType === 'individual' || abentryType === 'company') {
      return 'avatar-border avatar-square';
    }
    if (type === undefined) return 'avatar-square';
    return 'avatar-border';
  }
  private mapEntriesWithNew(localEmails: OutlookEmail[]) {
    const newItem = this.storage.get('new-item');
    if (newItem) {
      this.newEntries = JSON.parse(newItem);

      for (const entry of this.newEntries) {
        const index = localEmails.findIndex((o) => o.emailAddress === entry);
        if (index === -1) continue;

        const dataFromOctopus = localEmails[index];
        localEmails.splice(index, 1);
        dataFromOctopus.isNewEntry = true;
        localEmails.unshift(dataFromOctopus);
      }
    }
  }

  private mapAbEntriesToOutlookEmail(
    abentries?: OutlookAbEntryDetails[],
  ): OutlookEmail[] {
    if (!abentries) return [];

    const emails = this.outlookEmails.map((o) => o.emailAddress);
    return (
      abentries.map((ab) => {
        const emailFromOutlook = ab.emails.find((o) =>
          emails.find(
            (e) => e.toLocaleLowerCase() === o.value.toLocaleLowerCase(),
          ),
        );

        let name = ab.name;

        if (ab.type.toLocaleLowerCase() === 'company') {
          name = ab.companyName;
        }
        return {
          id: ab.key,
          emailAddress: emailFromOutlook?.value.toLocaleLowerCase(),
          displayName: name,
          type: 'abentry',
          abentryType: ab.type.toLocaleLowerCase(),
        } as OutlookEmail;
      }) ?? []
    );
  }

  private mapLeadsToOutlookEmail(lead?: OutlookLeadSearch[]): OutlookEmail[] {
    if (!lead) return [];
    return lead
      .filter((o) => o.status !== 2)
      .map((result) => {
        return {
          id: result.key,
          emailAddress: result.email.toLocaleLowerCase(),
          displayName: result.name,
          type: 'lead',
        };
      });
  }

  private showLoadError(error: unknown) {
    console.error('Err', error);
    this.notification.show('load', 'error');
    return { abentries: [], leads: [] };
  }
}
