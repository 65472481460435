<maximizer-outlook-menu
  [id]="id"
  [pageType]="type"
  [pageTitle]="'outlook.callLog.add-title' | translate"
></maximizer-outlook-menu>
<div class="flex flex-col h-full scrollbar" #anchor>
  <maximizer-loader *ngIf="loading" type="legacy"></maximizer-loader>
  <form
    *ngIf="form"
    class="flex flex-col p-2 text-caption space-y-2"
    [formGroup]="form.group"
  >
    <kendo-formfield>
      <kendo-label
        [for]="direction"
        [text]="'outlook.callLog.direction' | translate"
        >*
      </kendo-label>

      <kendo-dropdownlist
        [data]="direction"
        textField="name"
        valueField="id"
        fillMode="solid"
        formControlName="direction"
        [valuePrimitive]="true"
        required
      >
      </kendo-dropdownlist>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.callLog.phone' | translate"></kendo-label>
      <kendo-dropdownlist
        [data]="configuration?.phones"
        fillMode="solid"
        formControlName="phone"
        textField="name"
        valueField="name"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [for]="startDate"
        [text]="'outlook.callLog.startDate' | translate"
        >*
      </kendo-label>
      <kendo-datetimepicker
        #startDate
        [format]="fullFormat"
        formControlName="startDate"
        required
        calendarType="classic"
        (valueChange)="onStartChange()"
      >
      </kendo-datetimepicker>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [for]="endDate"
        [text]="'outlook.callLog.endDate' | translate"
        >*
      </kendo-label>
      <kendo-datetimepicker
        #endDate
        [format]="fullFormat"
        formControlName="endDate"
        [min]="now"
        required
        calendarType="classic"
      >
      </kendo-datetimepicker>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [for]="subject"
        [text]="'outlook.callLog.subject' | translate"
        >*
      </kendo-label>
      <kendo-textbox
        formControlName="subject"
        #subject
        required
        [maxlength]="1024"
      ></kendo-textbox>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [text]="'outlook.callLog.category' | translate"
      ></kendo-label>
      <kendo-dropdownlist
        [data]="configuration?.callLogOptions?.categories"
        textField="name"
        valueField="id"
        fillMode="solid"
        formControlName="category"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.callLog.result' | translate"></kendo-label>
      <kendo-dropdownlist
        [data]="configuration?.callLogOptions?.results"
        textField="name"
        valueField="id"
        fillMode="solid"
        formControlName="result"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label [text]="'outlook.callLog.callLog' | translate">
      </kendo-label>
      <kendo-textarea
        [rows]="6"
        resizable="vertical"
        formControlName="callLog"
      ></kendo-textarea>
    </kendo-formfield>
  </form>

  <div
    class="bg-neutral-5 z-40 mt-auto flex space-x-2 p-2 w-full bottom-0 sticky"
  >
    <button
      kendoButton
      class="uppercase rounded ml-auto"
      themeColor="primary"
      [disabled]="!canSave"
      (click)="save()"
    >
      <span *ngIf="!loading; else loader"
        >{{ 'outlook.buttons.save' | translate }}
      </span>
      <ng-template #loader>
        <kendo-loader type="pulsing" themeColor="primary" size="small">
        </kendo-loader>
      </ng-template>
    </button>
    <button
      kendoButton
      rounded="large"
      class="uppercase rounded"
      themeColor="primary"
      (click)="cancel()"
      [disabled]="loading"
      fillMode="flat"
    >
      {{ 'outlook.buttons.cancel' | translate }}
    </button>
  </div>
</div>

<maximizer-outlook-notification #notification [closable]="true" entryId="call">
</maximizer-outlook-notification>
