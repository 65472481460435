import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { Session } from '@maximizer/core/shared/domain';
import {
  IConfig,
  IConfiguration,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

export type InsightsConfiguration = {
  applicationInsights: IConfiguration & IConfig;
  eventLevel: SeverityLevel;
  trackPageView: boolean;
  customProperties?: Record<string, string>;
};

export type DataCenterInsightsConfiguration = Record<
  string,
  InsightsConfiguration
>;

type GlobalState = {
  session: Session | null;
  insightsConfiguration: DataCenterInsightsConfiguration;
};

const initialState: GlobalState = {
  session: null,
  insightsConfiguration: {},
};

export const GlobalStore = signalStore(
  withDevtools('global'),
  withState(initialState),
  withMethods((store) => ({
    setSession(session: Session) {
      patchState(store, () => ({ session }));
    },
    setInsightsConfiguration(
      insightsConfiguration: DataCenterInsightsConfiguration,
    ) {
      patchState(store, () => ({ insightsConfiguration }));
    },
  })),
);

export type GlobalStore = InstanceType<typeof GlobalStore>;
