import { Octopus } from '@maximizer/core/shared/domain';
import { NoteFieldOption, NoteForm, NoteSummary } from '../../../models';

export class NoteFieldOptionMapper extends Octopus.ConfigurationMapper<
  Octopus.NoteFieldOptionsResponse,
  NoteFieldOption
> {
  from(source: Octopus.NoteFieldOptionsResponse): NoteFieldOption {
    const task = source.Note?.FieldOptions;

    const result = {
      categories: task?.Category?.map(this.mapKeyAndDisplay) ?? [],
      types: task?.Type?.map(this.mapKeyAndDisplay) ?? [],
    };
    return result;
  }
}

export class NoteFormMapper extends Octopus.ConfigurationMapper<
  NoteForm,
  Octopus.NoteCreate
> {
  override from(source: NoteForm): Octopus.NoteCreate {
    return {
      Key: source.key,
      ParentKey: source.parentKey,
      DateTime: source.dateTime,
      Type: { Key: source.type },
      Category: source.category,
      RichText: source.richText,
      Creator: { UID: source.creator ?? Octopus.CurrentUser },
      Important: source.important,
    };
  }
}

export class NoteSummaryMapper extends Octopus.ConfigurationMapper<
  Octopus.NoteSummaryResponse,
  NoteSummary
> {
  override from(source: Octopus.NoteSummaryResponse): NoteSummary {
    const noteObj = source.Note.Data?.length > 0 ? source.Note.Data[0] : null;
    const noteAmount =
      source['Note.Count']?.Data?.length > 0
        ? source['Note.Count'].Data[0]
        : null;

    const result: NoteSummary = {
      noteAmount: noteAmount?.['$COUNT()'] ?? 0,
    };

    if (noteObj) {
      result.note = {
        category: noteObj.Category,
        creator: noteObj.Creator.DisplayValue,
        dateTime: noteObj.DateTime,
        displayValue: noteObj.DisplayValue,
        important: noteObj.Important,
        key: noteObj.Key,
        richText: noteObj.RichText,
        text: noteObj.Text,
      };
    }
    return result;
  }
}
