import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { Field, Octopus } from '@maximizer/core/shared/domain';
import { LabelComponent } from '@progress/kendo-angular-label';
import { ResourceOrTranslatePipe } from '../../pipes/resource-or-translate.pipe';

@Directive({
  selector: '[maximizerFieldLabel]',
  providers: [ResourceOrTranslatePipe],
})
export class FieldLabelDirective implements OnChanges {
  @Input({ required: true })
  field!: Field;

  @Input({ required: true })
  required!: boolean;

  readonly currencyLabel = {
    resource: 'JSS_GenTxtCurrency',
    translation: 'texts.currency',
  };

  readonly customLabels: Record<
    string,
    { resource: string; translation: string }
  > = {
    [Octopus.OpportunityFields.ActualRevenueCurrency]: this.currencyLabel,
    [Octopus.OpportunityFields.ForecastRevenueCurrency]: this.currencyLabel,
  };

  constructor(
    private label: LabelComponent,
    private element: ElementRef,
    private resource: ResourceOrTranslatePipe,
  ) {}

  ngOnChanges(): void {
    const element = this.element.nativeElement;
    const label = this.label;
    const field = this.field;
    const required = this.required ? '*' : '';
    let text = this.field.name
      ?.split('/')
      .map((part) => part.trim())
      .join(' / ');

    element.classList.add('text-caption', 'line-clamp-2');
    if (field.hint) {
      element.classList.add('items-baseline');
    }
    element.setAttribute('title', this.field.description ?? text);

    if (!text && this.customLabels[field.id]) {
      text = this.resource.transform(
        this.customLabels[field.id].resource,
        this.customLabels[field.id].translation,
      );
    }

    label.text = text ? text + required : '';
  }
}
