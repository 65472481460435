import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnboardingStep } from './stepper.model';

@Component({
  selector: 'maximizer-outlook-stepper',
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class OutlookStepperComponent {
  defaultOnboarding: OnboardingStep[] = [
    {
      step: 1,
      name: this.useTranslation('outlook.onboarding.createIndividual'),
      status: 'active',
      icon: {
        name: 'building-user',
        type: 'fa',
      },
    },
    {
      step: 2,
      name: this.useTranslation('outlook.onboarding.saveIndividual'),
      status: 'default',
      icon: {
        name: 'save-email',
        type: 'maximizer',
      },
    },
    {
      step: 3,
      name: this.useTranslation('outlook.onboarding.createOpportunity'),
      status: 'default',
      icon: {
        name: 'add-opportunity',
        type: 'maximizer',
      },
    },
    {
      step: 4,
      name: this.useTranslation('outlook.onboarding.inviteTeam'),
      status: 'default',
      icon: {
        name: 'circle-user',
        type: 'fa',
      },
    },
  ];

  constructor(private translation: TranslateService) {}

  private useTranslation(translationKey: string) {
    return this.translation.instant(translationKey);
  }
}
