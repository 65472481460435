import { jwtDecode } from 'jwt-decode';

export type DataCenterType = DataCenterId;

export enum DataCenterId {
  CaStaging = 'CADEVTEST_LAB_CA',
  CaDevelopment = 'DEVELOPMENT_LAB_CA',
  CaProduction = 'PRODUCTION_CA',
  UkProduction = 'PRODUCTION_UK',
  UsProduction = 'PRODUCTION_US',
  CaQa = 'QA_LAB_CA',
}

export enum DataCenterSubdomain {
  CaStaging = 'stagingw',
  CaDevelopment = 'developmentw',
  CaProduction = 'caw',
  UkProduction = 'ukw',
  UsProduction = 'usw',
  CaQa = 'qaw',
}

export function mapDataCenterFromToken(
  token: string,
): DataCenterId | undefined {
  const decodedToken = getDecodedAccessToken(token);

  if (!decodedToken?.['mx-octloc']) {
    return undefined;
  }

  const dataCenter = decodedToken['mx-octloc'];

  switch (dataCenter) {
    case 'ca1':
      return DataCenterId.CaProduction;
    case 'uk1':
      return DataCenterId.UkProduction;
    case 'us1':
      return DataCenterId.UsProduction;
    case 'staging1':
      return DataCenterId.CaStaging;
    case 'qa1':
      return DataCenterId.CaQa;
    case 'development1':
      return DataCenterId.CaDevelopment;
    default:
      return undefined;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getDecodedAccessToken(token: string): any {
  try {
    return jwtDecode(token);
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error('Error decoding JWT:', error.message);
    }
    return null;
  }
}

export function buildApiWebRequest(
  dataCenterId: DataCenterId,
  alias: string,
): string {
  let subdomain: DataCenterSubdomain;

  switch (dataCenterId) {
    case DataCenterId.CaProduction:
      subdomain = DataCenterSubdomain.CaProduction;
      break;
    case DataCenterId.UkProduction:
      subdomain = DataCenterSubdomain.UkProduction;
      break;
    case DataCenterId.UsProduction:
      subdomain = DataCenterSubdomain.UsProduction;
      break;
    case DataCenterId.CaStaging:
      subdomain = DataCenterSubdomain.CaStaging;
      break;
    case DataCenterId.CaQa:
      subdomain = DataCenterSubdomain.CaQa;
      break;
    case DataCenterId.CaDevelopment:
      subdomain = DataCenterSubdomain.CaDevelopment;
      break;
  }

  return `https://${subdomain}.maximizercrmlive.com/${alias}?request=api`;
}
