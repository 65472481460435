import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  OAuth2Service,
  SessionStorageService,
} from '@maximizer/core/shared/data-access';
import {
  CDN_URL,
  GlobalServicesLoginDetails,
} from '@maximizer/core/shared/domain';
import { InsightsService } from '@maximizer/core/shared/insights';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
} from '@maximizer/core/shared/ui';
import { catchError, finalize } from 'rxjs';
@Component({
  selector: 'maximizer-outlook-login-email',
  templateUrl: './login-email.component.html',
})
export class OutlookLoginEmailComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;

  constructor(
    @Inject(CDN_URL) public cdn: string,
    private insights: InsightsService,
    private session: SessionStorageService,
    private oauthService: OAuth2Service,
  ) {}

  emailForm?: FormGroupWrapper<{ email: string }>;
  dbForm?: FormGroupWrapper<{ alias: string }>;

  globalServiceDatabases: GlobalServicesLoginDetails[] = [];
  dropdownDbs: { text: string; tenantId: string }[] = [];
  selectedDatabase?: { text: string; tenantId: string };
  currentStep = 1;
  loading = false;
  email = '';

  ngOnInit(): void {
    this.insights.trackPageView();

    this.setEmailForm();
    this.proceed();
  }

  private setEmailForm(): void {
    try {
      this.emailForm = FormWrapperBuilder.group<{ email: string }>(
        {
          email: [
            null,
            [
              Validators.maxLength(255),
              Validators.pattern(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/)
            ],
          ],
        },

        this.proceed,
        () => {
          this.emailForm?.control.setErrors(null);
          if (!this.emailForm?.value.email) {
            const email = this.emailForm?.group.get('email');
            email?.setErrors({ required: true });
          }
        },
      );
    } catch (error) {
      console.error(error);
    }
  }

  private setDbForm(): void {
    try {
      this.dbForm = FormWrapperBuilder.group<{ alias: string }>(
        {
          alias: [null, [Validators.required]],
        },

        this.navigateToOAuth2,
        () => {
          this.dbForm?.control.setErrors(null);
        },
      );
    } catch (error) {
      console.error(error);
    }
  }

  proceed() {
    if (!this.emailForm) {
      return;
    }
    const validation = this.emailForm.validate();
    if (!validation.valid) return;

    const email = this.emailForm?.group.get('email');

    if (!this.emailForm?.value.email) {
      email?.setErrors({ required: true });
      return;
    }

    this.getDatabases(this.emailForm.value.email);
  }

  goBack() {
    if (this.currentStep === 1) {
      const messageObject = { messageType: 'dialogClosed' };
      const jsonMessage = JSON.stringify(messageObject);
      Office.context.ui.messageParent(jsonMessage);
    } else {
      this.currentStep = 1;
      this.dbForm = undefined;
      this.dropdownDbs = [];
      this.globalServiceDatabases = [];
    }
  }

  getDatabases(email: string): void {
    this.email = email;
    this.loading = true;
    this.oauthService
      .getLoginDetails(email)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
        catchError(async (error) => {
          const emailForm = this.emailForm?.group.get('email');
          emailForm?.setErrors({ exist: true });
          console.error('Error', error);
        }),
      )
      .subscribe((result) => {
        if (result == null || result?.length === 0) {
          const emailForm = this.emailForm?.group.get('email');
          emailForm?.setErrors({ exist: true });
          return; 
        }
        this.globalServiceDatabases = result;
        this.setDbForm();
        this.dropdownDbs =
          this.globalServiceDatabases.map((o) => ({
            text: o.tenant.name,
            tenantId: o.tenant.id,
          })) ?? [];

        this.selectedDatabase = { ...this.dropdownDbs[0] };

        if (result.length === 1) {
          this.navigateToOAuth2();
        } else {
          this.currentStep = 2;
          this.insights.trackPageView();
        }
      });
  }

  navigateToOAuth2(): boolean {
    if (!this.selectedDatabase) return false;
    const loginDetails = this.globalServiceDatabases.find(
      (o) => o.tenant.id === this.selectedDatabase?.tenantId,
    );

    if (!loginDetails) return false;
    const url = window.location.href.substring(
      0,
      window.location.href.indexOf('/login-email'),
    );
    this.session.set('url', url);
    const redirectUri = encodeURIComponent(url + '/authorize');
    const final = this.oauthService.getOAuthLoginUrl(loginDetails,redirectUri);
    if (!final) return false;

    this.session.set('auth_code', JSON.stringify(final));
    this.session.set('login_details', JSON.stringify(loginDetails));
    
    window.location.href = final.url;
    this.loading = true;
    return true;
  }
}