import { Octopus } from "@maximizer/core/shared/domain";
import { OutlookLeadSearch } from "../../../models/lead";


export class LeadSearchMapper extends Octopus.ConfigurationMapper<
Octopus.Lead,
  OutlookLeadSearch
> {
 
  from(source: Octopus.Lead): OutlookLeadSearch {
    return {
      key: source.Key ?? '',
      name: `${source.FirstName} ${source.LastName}`.trim(),
      firstName: source.FirstName.trim(),
      lastName: source.LastName.trim(),
      email: source.Email,
      phone1: this.getPhoneNumber(source.Phone1),
      phone2: this.getPhoneNumber(source.Phone2),
      address: {
        address1: source.Address?.AddressLine1,
        address2: source.Address?.AddressLine2,        
        zipcode: source.Address?.ZipCode,
        city: source.Address?.City,
        provinceState: source.Address?.StateProvince, 
        country: source.Address?.Country
      },
      companyName: source.CompanyName, 
      position: source.Position,
      website: source.WebSite,
      status: source.Status ?? 0,
      archivedDate: source.ArchivedDate ? new Date(source.ArchivedDate) : undefined
    }
  }
  
  private getPhoneNumber(phone?: Octopus.Phone){
    if(!phone) return undefined;
    if(phone.Extension) return `${phone?.Number} ${phone.Extension}`.trim();
    return phone?.Number.trim();
  }
}
