<p class="mt-4 font-bold text-xs">
  {{ 'outlook.summary.opportunity.title' | translate }}
</p>
  
<maximizer-outlook-skeleton *ngIf="loading"
containerClasses="h-[120px] w-full rounded-md bg-neutral-25"
></maximizer-outlook-skeleton>

<div *ngIf="!loading && !configuration.opportunity" class="flex flex-row">
  <div class="flex flex-col min-h-[120px] m-auto">
    <img
        class="mx-auto mt-auto max-h-[40px]" 
          [src]="cdn + '/assets/images/outlook/opportunity-emptystate.png'"
          alt="No opportunities available"
        />
    <span class="mb-auto mt-2 text-xs text-center">{{
      'outlook.summary.opportunity.emptyState' | translate
    }}</span>
  </div>
</div>
<div *ngIf="!loading && configuration.opportunity" class="mt-2 text-xs space-y-2">
  <div
    class="bg-white min-h-[120px] max-h-[150px] w-100 rounded shadow-card flex flex-col p-2 pl-2 mb-2 justify-around"
  >
    <div class="flex flex-row">
      <em
        class="mr-1 my-auto fa-icons icon-solid icon-12 icon-dollar-sign text-neutral-70"
      ></em>
      <span class="font-bold my-auto text-ellipsis overflow-hidden whitespace-nowrap">
        {{ configuration.opportunity.objective }}</span
      >
    </div>

    <p class="text-neutral-70 text-ellipsis overflow-hidden whitespace-nowrap">
      {{ configuration.opportunity.description }}
    </p>

    <div class="flex flex-row">
      <span class="text-neutral-70 mr-1"
        >{{ 'outlook.summary.opportunity.stageValue' | translate }}:
      </span>
      <span>${{ configuration.opportunity.stageValue }}</span>
      <span class="ml-auto"
        >${{ configuration.opportunity.forecastRevenue }}</span
      >
    </div>

    <div class="flex flex-row">
      <span class="text-neutral-70 mr-1"
        >{{ 'outlook.summary.opportunity.closingDate' | translate }}:
      </span>
      <span>{{configuration.opportunity.closeDate| date: 'MMMM dd, yyyy hh:mm a'}}</span
      >
    </div>
    <div class="flex flex-row">
      <span class="ml-auto">{{
        configuration.opportunity.lastModifyDate | date: 'MMMM dd, yyyy hh:mm a'
      }}</span>
    </div>
  </div>
  <a
    class="mb-2 text-inserv-100 line-clamp-3 underline"
    [href]="deepLink"
    target="_blank"
    >{{ configuration.opportunitiesAmount }}
    {{ 'outlook.summary.opportunity.amount' | translate }} ${{
      configuration.opportunitiesWorthValue
    }}
  </a>
</div>
