import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Octopus, Statistics } from '@maximizer/core/shared/domain';
import { map, Observable, of } from 'rxjs';
import { ContextService } from './context.service';

@Injectable()
export class StatisticsService {
  endpoint: string;
  statistics!: Statistics;
  constructor(private http: HttpClient, private context: ContextService) {
    this.endpoint = `${this.context.api}${Octopus.Action.STATISTICS}`;
  }
  getStatistics(): Observable<Statistics> {
    if (this.statistics) {
      return of(this.statistics);
    }

    const request: Octopus.StatisticsReadRequest = {
      Statistics: {
        storageDb: 1,
        storageBlob: 1,
      },
    };

    return this.http.post<Octopus.Statistics>(this.endpoint, request).pipe(
      map((result) => {
        const mapper = new Octopus.StatisticsMapper();
        return mapper.from(result);
      })
    );
  }
}
