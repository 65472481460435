import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardButtonComponent } from './card-button/card-button.component';
import { CircleButtonComponent } from './circle-button/circle-button.component';

@NgModule({
  imports: [CommonModule],
  exports: [CardButtonComponent, CircleButtonComponent],
  declarations: [CardButtonComponent, CircleButtonComponent],
})
export class UiButtonsModule {}
