import { Component, ElementRef, EventEmitter, HostListener,  Inject,  Output, ViewChild } from '@angular/core';
import { AbEntrySearchService, LeadSearchService } from '@maximizer/core/global-search/data-access';
import { AbEntrySearch, CDN_URL, LeadSearch, LeadStatus } from '@maximizer/core/shared/domain';
import { OutlookService } from '@maximizer/outlook/shared/data-access';
import { OutlookEmail, OutlookLeadSearch } from '@maximizer/outlook/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { Collision } from '@progress/kendo-angular-popup';
import { catchError, finalize, forkJoin } from 'rxjs';

@Component({
  selector: 'maximizer-outlook-compose-add-email',
  templateUrl: './add-email.component.html',
})
export class OutlookComposeAddEmailComponent {
  constructor(
    @Inject(CDN_URL) public cdn: string,
    private abentrySearchService: AbEntrySearchService,
    private leadSearchService: LeadSearchService,
    private outlookService: OutlookService,
  ) {
  }

  @Output() emailAdded = new EventEmitter<OutlookEmail>();

  showAutocompleteSkeleton = false;
  addEmailFromPopupLoading = false;
  showPopup = false;

  previousAutocompleteInput = '';
  autocompleteInput = '';

  searchInMaximizerList: { outlook: OutlookEmail, showPopup: boolean }[] = [];

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @ViewChild('popup', { read: ElementRef })
  popup?: ElementRef;

  @ViewChild("itemPopup", { read: ElementRef }) 
  itemPopup?: ElementRef;
  itemPopupCollision: Collision = { horizontal: "flip", vertical: "fit" };

  @HostListener("document:keydown", ["$event"])
  closeItemPopupOnEscape(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.closeAllItemPopups();
    }
  }

  closeAllItemPopups(){
    const showPopups = this.searchInMaximizerList.filter(o => o.showPopup);
    if(!showPopups) return;
    showPopups.forEach(o => o.showPopup = false);
  }

  @HostListener('document:click', ['$event'])
  handleClickOutsidePopup(event: KeyboardEvent): void {
    try {
      if (!event.target) return;
      const el = event.target as HTMLElement;
      if (['addEmailTo', 'addEmailCc', 'addEmailBcc'].includes(el.id)) return;
      if (!this.popup?.nativeElement.contains(event.target)) {
        this.handleLeavePopup();
      }
    } catch (error) {
      console.error('Click outside', error);
    }
  }
  handleAutocompleteValueChange(inputText: string) {
    if (this.previousAutocompleteInput != inputText) {
      this.previousAutocompleteInput = inputText;
    }
    if (inputText.length < 3) {
      this.showAutocompleteSkeleton = false;
      return;
    }

    this.showAutocompleteSkeleton = true;
    this.showPopup = true;
    this.searchInMaximizerList = [];

    setTimeout(() => {
      if (this.previousAutocompleteInput !== inputText) return;
      this.loadAutocompleteData(inputText);
    }, 1000);
  }


  resetAllProps() {
    this.addEmailFromPopupLoading = false;
    this.showAutocompleteSkeleton = false;
    this.showPopup = false;
    this.previousAutocompleteInput = '';
    this.autocompleteInput = '';
    this.searchInMaximizerList = [];
  }

  async addPopupEmailToOutlook(
    email: OutlookEmail,
    position: 'to' | 'cc' | 'bcc',
  ) {
    try{
        this.showAutocompleteSkeleton = true;
        email.position = position;
        await this.outlookService.addEmailToPosition(email, position);
        this.emailAdded.emit(email);
        this.resetAllProps();
        this.notification.show('add', 'success', false);
    } catch(error){
        this.notification.show('add', 'error');
        console.error("Failed to add email", error);
    }
  }
  getAvatarLetters(dataItem: OutlookEmail) {
    if (!dataItem.emailAddress && !dataItem.displayName) return '';
    if (!dataItem.displayName) {
      return dataItem.emailAddress?.substring(0, 2) ?? '';
    }
    if (dataItem?.abentryType === 'contact') {
      const contactName = dataItem.displayName.substring(
        0,
        dataItem.displayName.lastIndexOf(' - '),
      );
      const lastNameIdx = contactName.lastIndexOf(' ');

      if (lastNameIdx === -1) {
        const firstLetters = dataItem.displayName.substring(0, 2);
        return `${firstLetters[0]} ${firstLetters[1]}`;
      }
      return contactName;
    }

    if (
      dataItem.displayName.indexOf(' ') === -1 &&
      dataItem.displayName.length > 1
    ) {
      if (dataItem.emailAddress === undefined) return '';
      const firstLetters = dataItem.emailAddress.substring(0, 2);
      return `${firstLetters[0]} ${firstLetters[1]}`;
    }
    return dataItem.displayName;
  }


  private loadAutocompleteData(inputText: string) {
    forkJoin({
      abentries: this.abentrySearchService.search(inputText, true),
      leads: this.leadSearchService.search(inputText, true),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
          return { abentries: [], leads: [] };
        }),
        finalize(() => (this.showAutocompleteSkeleton = false)),
      )
      .subscribe((data) => {
        let mappedData: OutlookEmail[] = [];

        let companies: OutlookEmail[] = [];
        if (data.abentries.length > 0) {
          const mappedAbEntries = this.mapSearchAbentryToOutlookEmail(
            data.abentries,
          );
          const notCompany: OutlookEmail[] = mappedAbEntries.filter(
            (o) => o.abentryType !== 'company',
          );
          mappedData = mappedData.concat(notCompany);

          companies = mappedAbEntries.filter(
            (o) => o.abentryType === 'company',
          );
        }

        if (data.leads.length > 0) {
          const filteredLeads: LeadSearch[] = data.leads.filter(
            (o: LeadSearch) => o.status != LeadStatus.Converted,
          );
          const mappedLeads = this.mapLeadsToOutlookEmail(
            filteredLeads as OutlookLeadSearch[],
          );
          mappedData = mappedData.concat(mappedLeads);
        }

        mappedData.sort((a, b) => a.displayName.localeCompare(b.displayName));
        companies.sort((a, b) => b.displayName.localeCompare(a.displayName));
        companies = companies

          .concat(mappedData)
          .filter((o) => o.emailAddress !== '' && o.emailAddress !== undefined);
        this.mapPopupEntryList(companies);
      });
  }

  handleLeavePopup() {
    if (this.addEmailFromPopupLoading) return;
    this.showAutocompleteSkeleton = false;
    this.showPopup = false;
  }
  private mapPopupEntryList(mappedData: OutlookEmail[]) {
    for (const m of mappedData) {
      this.searchInMaximizerList.push({outlook: m, showPopup: false});
    }
  }

  private mapSearchAbentryToOutlookEmail(
    abentries: AbEntrySearch[],
  ): OutlookEmail[] {
    return abentries.map((ab) => {
      let name = ab.name;

      if (ab.type.toLocaleLowerCase() === 'company') {
        name = ab.companyName;
      }
      if (ab.type.toLocaleLowerCase() === 'contact') {
        name = ab.name + ' - ' + ab.companyName;
      }

      return {
        id: ab.key ?? '',
        displayName: name,
        emailAddress: ab.email.toLocaleLowerCase(),
        type: 'abentry',
        abentryType: ab.type.toLocaleLowerCase() as
          | 'contact'
          | 'individual'
          | 'company',
      };
    });
  }
  private mapLeadsToOutlookEmail(lead?: OutlookLeadSearch[]): OutlookEmail[] {
    if (!lead) return [];
    return lead
      .filter((o) => o.status !== 2)
      .map((result) => {
        return {
          id: result.key,
          emailAddress: result.email.toLocaleLowerCase(),
          displayName: result.name,
          type: 'lead',
        };
      });
  }

  handleClickItemPopup(dataItem: { outlook: OutlookEmail, showPopup: boolean}){
    let result = true;
    const showPopup = this.searchInMaximizerList.filter(o => o.showPopup === true);
    if(showPopup.length > 0) this.closeAllItemPopups();
    if(showPopup.find(o => o.outlook.id === dataItem.outlook.id)) {
        result = false;
    }
    dataItem.showPopup = result;
  }
}