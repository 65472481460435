/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';
import { ContextService } from '../services/context.service';
import { Octopus } from '@maximizer/core/shared/domain';
import { TokenService } from '../services/token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private readonly SessionExpiredPage = '/SessionExpired.aspx';

  constructor(
    private token: TokenService,
    private context: ContextService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes('Data.svc') &&
      !request.url.endsWith(Octopus.Action.AUTHENTICATE)
    ) {
      return this.getOctopusRequest(request, next);
    }
    return next.handle(request);
  }

  private getOctopusRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.token.getSession().pipe(
      switchMap((response) => {
        if (response.isSessionExpired) {
          location.href = this.context.website + this.SessionExpiredPage;
        }

        const headers = new HttpHeaders().append(
          'Authorization',
          'Bearer ' + this.context.token,
        );
        const newRequest = request.clone({ headers });
        return next.handle(newRequest).pipe(
          switchMap((event) => {
            if (event.type == HttpEventType.Response) {
              if (this.isInvalidAuthentication(event)) {
                this.context.token = '';
                return this.getOctopusRequest(request, next);
              }
            }
            return of(event);
          }),
        );
      }),
    );
  }

  private isInvalidAuthentication(event: HttpEvent<any>): boolean {
    const httpResponse = event as HttpResponse<any>;
    const response = httpResponse.body as Octopus.Response;
    const invalidToken = 'invalid token';

    if (response) {
      const code = response.Code;
      const messages = response.Msg ?? [];

      const invalid = code === Octopus.ResponseStatusCode.InvalidAuthentication;
      const unsuccessful = code === Octopus.ResponseStatusCode.Unsuccessful;

      return (
        invalid ||
        (unsuccessful &&
          messages.some(
            (error: string | Octopus.ResponseError) =>
              (typeof error === 'string' &&
                error.toLowerCase().includes(invalidToken)) ||
              (error as Octopus.ResponseError).Message?.toLowerCase().includes(
                invalidToken,
              ),
          ))
      );
    }
    return false;
  }
}
