import { Mapper } from '../../../mappers';
import { Stage } from '../../../models';
import { SalesStageSetup, SalesStage } from '../models/stage';

export class SalesStageSetupMapper extends Mapper<SalesStageSetup, Stage> {
  from(source: SalesStageSetup): Stage {
    return {
      id: source.Key ?? '',
      processId: source.SalesProcessSetupKey,
      name: source.DisplayValue,
      probability: source.ProbabilityClose,
      targetAge: source.TargetAge,
    };
  }
}

export class SalesStageMapper extends SalesStageSetupMapper {
  override from(source: SalesStage): Stage {
    return {
      ...super.from(source),
      id: source.SalesStageSetupKey,
      age: source.Age?.DisplayValue,
      current: source.Current,
    };
  }
}
