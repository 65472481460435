import { TranslationListItem } from '../../../models';

export enum CriteriaOptionId {
  EQUALS = '$EQ',
  NOT_EQUALS = '$NE',
  GREATER_THAN_OR_EQUAL = '$GE',
  LESS_THAN_OR_EQUAL = '$LE',
  GREATER_THAN = '$GT',
  LESS_THAN = '$LT',
  RANGE = '$RANGE',
}

export const CriteriaOptions: Record<string, TranslationListItem<string>> = {
  [CriteriaOptionId.EQUALS]: {
    id: CriteriaOptionId.EQUALS,
    translationID: 'criteria.equals',
    resourceID: 'JSS_GenTextSearchOptMatch',
    description: '=',
  },
  [CriteriaOptionId.NOT_EQUALS]: {
    id: CriteriaOptionId.NOT_EQUALS,
    translationID: 'criteria.notEquals',
    resourceID: 'JSS_GenTextSearchOptNotmatch',
    description: '!=',
  },
  [CriteriaOptionId.GREATER_THAN_OR_EQUAL]: {
    id: CriteriaOptionId.GREATER_THAN_OR_EQUAL,
    translationID: 'criteria.greatOrEqual',
    resourceID: 'JSS_GenTextSearchOptGreaterEqual',
    description: '>=',
  },
  [CriteriaOptionId.LESS_THAN_OR_EQUAL]: {
    id: CriteriaOptionId.LESS_THAN_OR_EQUAL,
    translationID: 'criteria.lessOrEqual',
    resourceID: 'JSS_GenTextSearchOptLessEqual',
    description: '<=',
  },
  [CriteriaOptionId.GREATER_THAN]: {
    id: CriteriaOptionId.GREATER_THAN,
    translationID: 'criteria.greaterThan',
    resourceID: 'JSS_GenTextSearchOptGreater',
    description: '>',
  },
  [CriteriaOptionId.LESS_THAN]: {
    id: CriteriaOptionId.LESS_THAN,
    translationID: 'criteria.lessThan',
    resourceID: 'JSS_GenTextSearchOptLess',
    description: '<',
  },
  [CriteriaOptionId.RANGE]: {
    id: CriteriaOptionId.RANGE,
    translationID: 'criteria.between',
    resourceID: 'JSS_GenTextSearchOptBetween',
  },
};

export function getCriteriaOptions(
  ids: CriteriaOptionId[],
): TranslationListItem<string>[] {
  const options: TranslationListItem<string>[] = [];
  ids.forEach((id) => options.push(CriteriaOptions[id]));
  return options;
}
