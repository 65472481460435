<maximizer-outlook-menu
  [id]="id"
  [pageType]="type"
  [pageTitle]="pageTitle"
></maximizer-outlook-menu>

<div
  class="h-full w-full text-xs flex flex-col scrollbar"
  (scroll)="onScroll()"
>
  @if (!showSkeleton && saveEmailLoading) {
    <maximizer-loader type="legacy"></maximizer-loader>
  } @else if (showSkeleton) {
    <div class="sticky top-0 my-3 mx-6">
      <div class="flex">
        <maximizer-outlook-skeleton
          containerClasses="h-12 w-12 max-w-12 rounded-full bg-neutral-20 mr-4"
          fullWidth="none"
        ></maximizer-outlook-skeleton>
        <maximizer-outlook-skeleton
          class="mt-auto"
          containerClasses="h-8 w-full rounded-md bg-neutral-20"
          fullWidth="full"
        ></maximizer-outlook-skeleton>
      </div>
      <maximizer-outlook-skeleton
        class="my-4"
        containerClasses="h-8 w-full rounded-md bg-neutral-20"
        fullWidth="full"
      ></maximizer-outlook-skeleton>
      <maximizer-outlook-skeleton
        class="mb-4"
        containerClasses="h-4 w-full rounded-md bg-neutral-20"
        fullWidth="full"
      ></maximizer-outlook-skeleton>
      <div class="flex mb-4 justify-around">
        <div class="flex" *ngFor="let test of [0, 1, 2, 3, 4]">
          <maximizer-outlook-skeleton
            containerClasses="h-8 w-8 max-w-8 rounded-full bg-neutral-20"
            fullWidth="none"
          ></maximizer-outlook-skeleton>
        </div>
      </div>
    </div>
  }

  @if (!showSkeleton) {
    <div class="sticky top-0 mt-3 mx-6">
      <!-- Entry row -->
      <div class="flex justify-between items-start py-1">
        <div class="flex">
          <a
            class="flex mr-2 my-auto no-underline"
            target="_blank"
            [href]="deepLink"
          >
            <div
              class="avatar avatar-lg avatar-neutral-30 avatar-border text-xl text-black font-bold"
              [ngClass]="
                abentry &&
                (abentry.type === 'Individual' || abentry.type === 'Company')
                  ? 'avatar-square'
                  : ''
              "
            >
              <span
                class="subtitle-1"
                *ngIf="abentry && abentry.type !== 'Company'"
                >{{ abentry.name | nameInitials }}</span
              >
              <span
                *ngIf="lead"
                class="fa-icons icon icon-solid icon-18 icon-user-magnifying-glass cursor-pointer m-auto"
              ></span>

              <span
                *ngIf="abentry && abentry.type === 'Company'"
                class="fa-icons icon icon-regular icon-22 icon-building m-auto cursor-pointer"
              ></span>
            </div>
          </a>

          <div class="flex flex-col py-1">
            <span class="subtitle-1 break-all">{{
              abentry?.name ?? lead?.name
            }}</span>
            <span class="subtitle-2 break-all"
              >{{ abentry?.position ?? lead?.position }}
            </span>
            <span
              *ngIf="!abentry?.parentKey; else notContact"
              class="break-all no-underline"
              >{{ abentry?.companyName ?? lead?.companyName }}</span
            >
            <ng-template #notContact>
              <div
                kendoTooltip
                position="bottom"
                [tooltipWidth]="200"
                [tooltipTemplate]="openInMaximizerTooltip"
              >
                <div class="hover:shadow text-xxs break-all">
                  <a
                    [href]="companyDeepLink"
                    target="_blank"
                    title="Link to company"
                    class="text-xs hover:underline text-inserv-100 no-underline"
                    >{{ abentry?.companyName }}</a
                  >
                </div>
              </div>
            </ng-template>
          </div>

          <ng-template #openInMaximizerTooltip>
            <span class="text-xxs justify-normal text-neutral-90">{{
              'outlook.entry-page.openCompanyInMaximizer' | translate
            }}</span>
          </ng-template>
        </div>

        @if (abentry?.type !== 'Company') {
          <div
            kendoTooltip
            position="bottom"
            [tooltipWidth]="180"
            [tooltipTemplate]="tooltipTemplate"
          >
            <button
              kendoButton
              class="edit"
              [routerLink]="
                lead?.status === leadStatus.Archived ? null : 'edit'
              "
              [queryParams]="{ entryType }"
              [disabled]="lead ? !userRights?.lead?.update : false"
              fillMode="flat"
              iconClass="fa-icons icon-solid icon-14 px-2 icon-pencil"
              [attr.title]="
                lead?.status === leadStatus.Archived ? 'active' : null
              "
              [ngClass]="
                lead?.status === leadStatus.Archived
                  ? 'text-neutral-40 cursor-default'
                  : 'text-black cursor-pointer'
              "
            ></button>
          </div>
        }

        <ng-template #tooltipTemplate>
          <span class="text-xs justify-normal text-neutral-90">{{
            'outlook.entry-page.archivedTooltip' | translate
          }}</span>
        </ng-template>
      </div>

      <!-- Info row -->
      <div class="min-h-10 pt-2 pb-4">
        <maximizer-loader *ngIf="showSkeleton" type="legacy"></maximizer-loader>
        <div class="flex" *ngIf="abentry">
          <span class="text-caption text-neutral-90"
            >{{ 'outlook.entry-page.currentOpportunities' | translate }}:
          </span>
          <span class="text-caption text-neutral-70 ml-2">{{
            opportunities?.current ?? '$0.00'
          }}</span>
        </div>
        <div class="flex" *ngIf="abentry">
          <span class="text-caption text-neutral-90"
            >{{ 'outlook.entry-page.completedOpportunities' | translate }}:
          </span>
          <span class="text-caption text-neutral-70 ml-2">{{
            opportunities?.completed ?? '$0.00'
          }}</span>
        </div>
        <div class="flex" *ngIf="abentry?.type !== 'Company'">
          <span class="text-caption text-neutral-90"
            >{{ 'outlook.entry-page.lastContactedDate' | translate }}:
          </span>
          <span
            class="text-caption text-neutral-70 ml-2"
            *ngIf="
              lastContactDays === null || lastContactDays >= 0;
              else futureDate
            "
            >{{
              lastContactMessage ?? '' | translate: { days: lastContactDays }
            }}</span
          >

          <ng-template #futureDate>
            <span class="text-caption text-neutral-70 ml-2">
              {{ abentry?.lastContactDateValue | date: 'MMM dd, YYYY' }}
            </span>
          </ng-template>
        </div>

        <div class="flex" *ngIf="lead && lead.status === 3">
          <span class="text-caption text-neutral-90"
            >{{ 'outlook.entry-page.archivedDate' | translate }}:
          </span>
          <span class="text-caption text-neutral-70 ml-2">{{
            lead.archivedDate | date: 'MMM dd, YYYY'
          }}</span>
        </div>
      </div>

      <!-- Auto-Save Email Toggle -->
      @if (showAutoSaveEmailToggle) {
        <div class="flex gap-1.5 items-center pb-4">
          <span
            kendoTooltip
            [tooltipTemplate]="linkToAppStoreTooltip"
            [tooltipWidth]="250"
            [callout]="false"
            position="bottom"
            [closable]="true"
            [showOn]="outlookSyncInstalled ? 'none' : 'click'"
          >
            <kendo-switch
              kendoTooltip
              size="small"
              [(ngModel)]="autoSave"
              [disabled]="autoSaveDisabled || syncLoading"
              (valueChange)="onAutoSaveEmailChange($event)"
              [tooltipTemplate]="autoSaveDisabledTooltip"
              [tooltipWidth]="210"
              [callout]="false"
              [attr.title]="
                autoSaveDisabled
                  ? ('outlook.entry-page.autoSave.label' | translate)
                  : null
              "
            ></kendo-switch>
          </span>
          <p class="text-caption text-neutral-90">
            {{ 'outlook.entry-page.autoSave.label' | translate }}
          </p>
          <span
            kendoTooltip
            class="fa-icons icon-solid icon-12 icon-circle-question text-neutral-90"
            title="{{ 'outlook.entry-page.autoSave.help' | translate }}"
            [tooltipWidth]="250"
            [callout]="false"
            [tooltipTemplate]="autoSaveTooltip"
          ></span>
          @if (syncLoading) {
            <kendo-loader
              class="pl-4"
              type="pulsing"
              themeColor="primary"
              size="small"
            ></kendo-loader>
          }
        </div>

        <ng-template #autoSaveDisabledTooltip>
          <div class="ml-2 offset-left-tooltip">
            <p class="text-body">
              {{ 'outlook.entry-page.autoSave.disabled' | translate }}
            </p>
          </div>
        </ng-template>

        <ng-template #linkToAppStoreTooltip>
          <div class="pt-2 flex flex-col gap-2 ml-2 offset-left-tooltip">
            <p class="subtitle-2">
              {{ 'outlook.entry-page.autoSave.newFeature' | translate }}
            </p>
            <p class="text-body">
              {{ 'outlook.entry-page.autoSave.installation' | translate }}
            </p>
            <p
              class="text-body"
              [innerHtml]="
                'outlook.entry-page.autoSave.installationNote' | translate
              "
            ></p>
            <div class="flex justify-end">
              <button
                kendoButton
                themeColor="primary"
                (click)="buildAppStoreFormRequest()"
              >
                {{ 'outlook.buttons.install' | translate }}
              </button>
            </div>
          </div>
        </ng-template>

        <ng-template #autoSaveTooltip>
          <p class="text-body">
            {{ 'outlook.entry-page.autoSave.tooltip' | translate }}
          </p>
        </ng-template>
      }

      <!-- Action row -->
      <div
        class="action-buttons flex pb-2"
        [ngClass]="lead ? 'justify-around' : 'justify-between'"
      >
        <div
          *ngIf="showSaveEmail && !emailIsSaved"
          (click)="saveEmailForEntry()"
          class="rounded-full w-8 h-8 flex justify-center items-center"
          [ngClass]="
            disabled
              ? 'bg-neutral-30'
              : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
          "
          [title]="'outlook.main.tooltip.save-email' | translate"
        >
          <span
            class="maximizer-icons icon-save-email text-max-white icon-18"
          ></span>
        </div>
        <div
          *ngIf="showSaveEmail && emailIsSaved"
          class="rounded-full w-8 h-8 flex bg-neutral-30 justify-center items-center"
          title="Saved email"
        >
          <span
            [title]="'outlook.main.tooltip.email-saved' | translate"
            class="maximizer-icons icon-saved-email text-max-white icon-16"
          ></span>
        </div>
        <div
          *ngIf="abentry"
          [routerLink]="
            disabled || !userRights?.opportunities?.insert
              ? null
              : 'add-opportunity'
          "
          class="rounded-full w-8 h-8 pl-0.5 flex justify-center items-center"
          [ngClass]="
            disabled || !userRights?.opportunities?.insert
              ? 'bg-neutral-30'
              : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
          "
          title="Add opportunity"
        >
          <span
            class="maximizer-icons icon-add-opportunity text-max-white icon-16"
          ></span>
        </div>
        <div
          *ngIf="abentry"
          [routerLink]="
            disabled || !userRights?.customerService?.insert ? null : 'add-case'
          "
          class="rounded-full w-8 h-8 flex items-center justify-center"
          [ngClass]="
            disabled || !userRights?.customerService?.insert
              ? 'bg-neutral-30'
              : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
          "
          title="Add case"
        >
          <span
            class="maximizer-icons icon-add-case text-max-white icon-18"
          ></span>
        </div>
        <div
          [routerLink]="disabled ? null : 'add-task'"
          class="rounded-full w-8 h-8 flex justify-center items-center"
          [ngClass]="
            disabled
              ? 'bg-neutral-30'
              : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
          "
          title="Add task"
        >
          <span
            class="maximizer-icons icon-add-task text-max-white icon-16"
          ></span>
        </div>
        <div
          [routerLink]="
            disabled || !userRights?.notes?.insert ? null : 'add-note'
          "
          class="rounded-full w-8 h-8 flex justify-center items-center"
          [ngClass]="
            disabled || !userRights?.notes?.insert
              ? 'bg-neutral-30'
              : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
          "
          title="Add note"
        >
          <span
            class="maximizer-icons icon-add-note text-max-white icon-16"
          ></span>
        </div>
        @if (lead) {
          <div
            [routerLink]="
              disabled || !userRights?.interactionLog?.insert
                ? null
                : 'add-call-log'
            "
            class="rounded-full w-8 h-8 flex justify-center items-center"
            [ngClass]="
              disabled || !userRights?.interactionLog?.insert
                ? 'bg-neutral-30'
                : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
            "
            title="Add call log"
          >
            <span
              class="maximizer-icons icon-add-call text-max-white icon-16"
            ></span>
          </div>
          <div
            [routerLink]="disabled ? null : 'add-appointment'"
            class="rounded-full w-8 h-8 flex justify-center items-center"
            [ngClass]="
              disabled
                ? 'bg-neutral-30'
                : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
            "
            title="Add appointment"
          >
            <span
              class="maximizer-icons icon-add-appointment text-max-white icon-16"
            ></span>
          </div>
        } @else {
          <div
            class="rounded-full w-8 h-8 flex justify-center items-center bg-inserv-100 hover:bg-inserv-120 cursor-pointer"
            title="More options"
          >
            <kendo-dropdownbutton
              #dropdownButton
              [data]="moreActions"
              [disabled]="disabled"
              [buttonClass]="['hover:bg-transparent, hover:shadow-none']"
              fillMode="flat"
              iconClass="fa-icons icon-16 icon-ellipsis text-max-white rounded-full"
            >
              <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                <div
                  class="more-actions flex items-center"
                  (click)="dataItem.click()"
                >
                  <span [class]="dataItem.icon"></span>
                  <span class="ml-2">{{ dataItem.text }}</span>
                </div>
              </ng-template>
            </kendo-dropdownbutton>
          </div>
        }
      </div>
    </div>
  }

  <!-- Tabs -->
  <kendo-tabstrip
    class="w-full flex-1 flex sticky top-0 z-30 bg-white entry-page"
    tabAlignment="justify"
  >
    <kendo-tabstrip-tab
      [selected]="true"
      title="summary"
      cssClass="flex-1 gap-0"
    >
      <ng-template kendoTabTitle>
        <p class="text-xs mx-auto">
          {{ 'outlook.entry-page.summary' | translate }}
        </p>
      </ng-template>
      <ng-template kendoTabContent>
        <maximizer-entry-summary
          [id]="id"
          [type]="type"
          [lead]="lead"
          [abentry]="abentry"
          [deepLink]="deepLink"
          [oppLink]="oppLink"
        ></maximizer-entry-summary>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="timeline" cssClass="flex-1 gap-0">
      <ng-template kendoTabTitle>
        <p class="text-xs mx-auto">
          {{ 'outlook.entry-page.timeline' | translate }}
        </p>
      </ng-template>
      <ng-template kendoTabContent>
        <maximizer-outlook-timeline
          class="h-full"
          [id]="id"
          [type]="type"
          [refreshEvent]="refreshTimelineEmitter"
        ></maximizer-outlook-timeline>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="details" cssClass="flex-1 gap-0">
      <ng-template kendoTabTitle>
        <p class="text-xs mx-auto">
          {{ 'outlook.entry-page.details' | translate }}
        </p>
      </ng-template>
      <ng-template kendoTabContent>
        <maximizer-entry-details
          [id]="id"
          [type]="type"
        ></maximizer-entry-details>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>

<maximizer-outlook-notification
  #notification
  [closable]="true"
  [entryId]="entryType"
>
</maximizer-outlook-notification>
