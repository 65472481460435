import {
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  CountryListItem,
  LocaleService,
} from '@maximizer/core/shared/data-access';
import { CDN_URL } from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
} from '@maximizer/core/shared/ui';
import { AbEntryService } from '@maximizer/outlook/shared/data-access';
import {
  OutlookAbEntryDetails,
  OutlookAbEntryForm,
} from '@maximizer/outlook/shared/domain';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';

@Component({
  selector: 'maximizer-edit-abentry',
  templateUrl: './edit-abentry.component.html',
})
export class EditAbEntryComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;
  constructor(
    @Inject(CDN_URL) public cdn: string,
    private translate: TranslateService,
    private abentryService: AbEntryService,
    private localService: LocaleService,
    private location: Location,
  ) {}

  private _abentryKey = '';
  canSave = false;
  loading = true;

  abentry: OutlookAbEntryDetails | null = null;
  phones: { type: string; value: string }[] = [];
  emails: { type: string; value: string }[] = [];

  emailOptions: string[] = [];
  phoneOptions: string[] = [];
  phoneLimit = 4;
  emailLimit = 3;
  defaultCountry?: CountryListItem;

  form?: FormGroupWrapper<OutlookAbEntryForm>;
  countries?: CountryListItem[];

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @Input()
  set id(id: string) {
    this._abentryKey = decodeURI(id);
  }
  get id() {
    return this._abentryKey;
  }
  @Input() entryType?: string;

  ngOnInit(): void {
    this.defaultCountry = {
      id: '',
      name: this.translate.instant('outlook.abentry.country-placeholder'),
      flag: '',
    };

    if (this._abentryKey && this._abentryKey != '') this.loadData();
  }

  private loadData(): void {
    forkJoin({
      countries: this.localService.getCountries(),
      abentry: this.abentryService.getAllDetailsByKey(this._abentryKey),
      fieldOptions: this.abentryService.getFieldOption(),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => {
        if (!data) return;
        if (!data.abentry) return;

        if (data.countries) this.countries = data.countries;

        this.abentry = data.abentry;
        this.setForm(data.abentry);
        this.phoneOptions =
          data.fieldOptions.phoneDescriptions?.map((o) => o.id ?? '') ?? [];
        this.emailOptions =
          data.fieldOptions.emailDescriptions?.map((o) => o.id ?? '') ?? [];
      });
  }

  private setForm(abentry: OutlookAbEntryDetails): void {
    this.prepareDynamicFields(abentry);

    this.form = FormWrapperBuilder.group<OutlookAbEntryForm>(
      {
        key: abentry.key,
        firstName: [abentry.firstName, [Validators.maxLength(79)]],
        lastName: [abentry.lastName, [Validators.maxLength(79)]],

        email1: [this.emails[0]?.value, []],
        email1Description: [this.emails[0]?.type, [Validators.maxLength(15)]],
        email2: [
          this.emails[1]?.value,
          {
            validators: [Validators.maxLength(255), Validators.email],
            updateOn: 'blur',
          },
        ],
        email2Description: [this.emails[1]?.type, [Validators.maxLength(15)]],
        email3: [
          this.emails[2]?.value,
          {
            validators: [Validators.maxLength(255), Validators.email],
            updateOn: 'blur',
          },
        ],
        email3Description: [this.emails[2]?.type, [Validators.maxLength(15)]],

        phone1: [this.phones[0]?.value, [Validators.maxLength(21)]],
        phone1Description: [this.phones[0]?.type, [Validators.maxLength(21)]],
        phone2: [this.phones[1]?.value, [Validators.maxLength(21)]],
        phone2Description: [this.phones[1]?.type, [Validators.maxLength(21)]],
        phone3: [this.phones[2]?.value, [Validators.maxLength(21)]],
        phone3Description: [this.phones[2]?.type, [Validators.maxLength(21)]],
        phone4: [this.phones[3]?.value, [Validators.maxLength(21)]],
        phone4Description: [this.phones[3]?.type, [Validators.maxLength(21)]],

        address1: [abentry.address.address1, [Validators.maxLength(79)]],
        address2: [abentry.address.address2, [Validators.maxLength(79)]],

        company: [abentry.companyName, [Validators.maxLength(79)]],
        position: [abentry.position, [Validators.maxLength(79)]],
        website: [abentry.website, [Validators.maxLength(255)]],
        city: [abentry.address.city, [Validators.maxLength(79)]],
        province: [abentry.address.provinceState, [Validators.maxLength(79)]],
        postalCode: [abentry.address.zipcode, [Validators.maxLength(79)]],
        country: [abentry.address.country, [Validators.maxLength(79)]],
        type: abentry.type,
        parentKey: abentry.parentKey,
      },

      this.save,
      () => {
        this.form?.control.setErrors(null);
        if (this.form?.valid) {
          this.canSave = true;
        } else {
          this.canSave = false;
        }
      },
    );
    this.form.validate();
  }

  private prepareDynamicFields(abentry: OutlookAbEntryDetails): void {
    for (const phone of abentry.phones) {
      if (phone.value.trim() == '') continue;
      this.phones.push({
        type: phone?.type ?? '',
        value: phone?.value ?? '',
      });
    }
    if (this.phones.length == 0) {
      this.phones.push({ type: '', value: '' });
    }

    for (const email of abentry.emails) {
      if (email.value.trim() == '') continue;
      this.emails.push({
        type: email?.type.trim() ?? '',
        value: email?.value.trim() ?? '',
      });
    }
    if (this.emails.length == 0) {
      this.emails.push({ type: '', value: '' });
    }
  }

  save() {
    if (!this.form) {
      return;
    }
    const validation = this.form.validate();
    if (!validation.valid) return;
    this.form.patch({
      key: this._abentryKey,
    });

    this.loading = true;
    this.abentryService
      .update(this.form.value)
      .pipe(
        catchError(async () => {
          this.notification.show('save', 'error');
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((result) => {
        if (!result) return;
        this.notification.show('save', 'success', false);
        this.location.back();
      });
  }

  cancel(): void {
    this.location.back();
  }

  onEntryChange(event: string, prop: string, index: number) {
    const array = prop.includes('email') ? this.emails : this.phones;
    if (prop.includes('Description')) {
      const limitProp = prop.includes('email') ? 16 : 22;
      let eventLimited = event;
      if (event.length >= limitProp) {
        eventLimited = event.slice(0, limitProp);
        this.form?.patch({
          [prop]: eventLimited,
        });
      }
      array[index].type = eventLimited.trim();
    } else {
      array[index].value = event.trim();
    }
  }

  onRemoveEntry(i: number, prop: string) {
    if (prop.includes('email')) {
      this.emails.splice(i, 1);
      const array = JSON.parse(JSON.stringify(this.emails)); // update form reference
      this.emails = array;
      this.updateEmails();
    } else {
      const array = JSON.parse(JSON.stringify(this.phones)); // update form reference
      array.splice(i, 1);
      this.phones = array;
      this.updatePhones();
    }
  }

  onAddEntry(type: string) {
    if (type.includes('email')) {
      this.emails.push({ type: '', value: '' });
    } else {
      this.phones.push({
        type: '',
        value: '',
      });
    }
  }

  private updateEmails() {
    this.form?.group.patchValue({
      email1: this.emails[0]?.value.trim() ?? '',
      email1Description: this.emails[0]?.type.trim() ?? '',

      email2: this.emails[1]?.value.trim() ?? '',
      email2Description: this.emails[1]?.type.trim() ?? '',

      email3: this.emails[2]?.value.trim() ?? '',
      email3Description: this.emails[2]?.type.trim() ?? '',
    });
  }

  private updatePhones() {
    this.form?.patch({
      phone1: this.phones[0]?.value.trim() ?? '',
      phone1Description: this.phones[0]?.type.trim() ?? '',
      phone2: this.phones[1]?.value.trim() ?? '',
      phone2Description: this.phones[1]?.type.trim() ?? '',
      phone3: this.phones[2]?.value.trim() ?? '',
      phone3Description: this.phones[2]?.type.trim() ?? '',
      phone4: this.phones[3]?.value.trim() ?? '',
      phone4Description: this.phones[3]?.type.trim() ?? '',
    });
  }

  getFieldLimit(prop: string) {
    if (prop.includes('email')) return this.emailLimit;
    return this.phoneLimit;
  }

  getEmailErrorObject(prop: string) {
    return this.form?.group.controls[prop].errors;
  }
}
