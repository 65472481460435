<div class="bg-neutral-80 p-2 flex justify-center items-center">
  <div class="flex justify-center items-center">
    @for (step of defaultOnboarding; track $index) {
      <div
        class="step w-[72px] h-[60px] py-[34px] px-3 relative flex justify-center items-center"
        [ngClass]="[step.status]"
      >
        <div class="flex flex-col justify-center items-center z-20">
          <span
            class="{{ step.icon.type }}-icons icon-14 icon-{{
              step.icon.name
            }} mb-0.5"
          ></span>
          <p class="caption text-center">
            {{ step.name }}
          </p>
        </div>
      </div>
    }
  </div>
</div>
