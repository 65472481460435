import { Octopus } from '@maximizer/core/shared/domain';
import { parseISO } from 'date-fns';
import {
  OutlookTimelineConfiguration,
  OutlookTimelineEvent,
} from '../../../models';

export class TimelineConfigurationMapper extends Octopus.ConfigurationMapper<
  Octopus.TimelineFieldOptionsResponse,
  OutlookTimelineConfiguration
> {
  from(
    source: Octopus.TimelineFieldOptionsResponse,
  ): OutlookTimelineConfiguration {
    const timeline = source.Timeline?.FieldOptions;

    return {
      types: timeline?.Type?.map(this.mapInteractionType) ?? [],
      notes: timeline?.NoteType?.map(this.mapValueAndDisplay) ?? [],
      documents: timeline?.DocumentType?.map(this.mapValueAndDisplay) ?? [],
    };
  }
}

export class TimelineMapper extends Octopus.ConfigurationMapper<
  Octopus.Timeline[],
  OutlookTimelineEvent[]
> {
  from(source: Octopus.Timeline[]): OutlookTimelineEvent[] {
    return this.getEvents(source);
  }

  getEvents(source: Octopus.Timeline[]): OutlookTimelineEvent[] {
    this.removeDuplicatedEmails(source);

    return source.map((event) => {
      return this.mapEvent(event);
    });
  }

  removeDuplicatedEmails(source: Octopus.Timeline[]): void {
    type Emails = { [key: string]: Octopus.Timeline[] };
    const interactions = source.filter(
      (item) => item.Type.Key === Octopus.DefaultInteractions.EMAIL,
    );

    if (interactions.length) {
      const emails: Emails = interactions.reduce(function (group, interaction) {
        const key = `${interaction.StartDate.Value}-${interaction.Creator.Value}-${interaction.Subject}`;
        group[key] = group[key] || [];
        group[key].push(interaction);
        return group;
      }, {} as Emails);

      Object.keys(emails).forEach((key) => {
        const group = emails[key];
        if (group.length > 1 && group.some((item) => item.With)) {
          group.forEach((interaction) => {
            if (!interaction.With) {
              const index = source.findIndex(
                (item) => item.Key === interaction.Key,
              );
              source.splice(index, 1);
            }
          });
        }
      });
    }
  }

  mapEvent(source: Octopus.Timeline): OutlookTimelineEvent {
    const forValue = source.AbEntries || source.Leads;
    let entriesDisplay = '';
    if (forValue?.length > 0) {
      entriesDisplay = forValue.map((o) => o.DisplayValue).join(', ');
    }
    const event: OutlookTimelineEvent = {
      key: source.Key ?? '',
      type: 'interaction',
      subject: source.Subject,
      description: source.Description ? source.Description.trim() : undefined,
      date: {
        value: parseISO(source.StartDate.Value),
        display: source.StartDate.DisplayValue,
      },
      important: source.Important,
      creator: {
        value: source.Creator.Value,
        display: source.Creator.DisplayValue,
      },
      entriesDisplay: entriesDisplay,
      direction: source.Direction,
    };

    if (Octopus.DefaultInteractions.isNote(source.Type.Key)) {
      event.type = 'note';
      event.noteType = source.NoteType;
    }

    if (Octopus.DefaultInteractions.isAppointment(source.Type.Key)) {
      event.type = 'appointment';
    }

    if (Octopus.DefaultInteractions.isTask(source.Type.Key)) {
      event.type = 'task';
    }

    if (Octopus.DefaultInteractions.isEmail(source.Type.Key)) {
      event.type = 'email';
      event.description = '';
    }

    if (Octopus.DefaultInteractions.isDocument(source.Type.Key)) {
      event.type = 'document';
    }

    if (Octopus.DefaultInteractions.isDefaultInteraction(source.Type.Key)) {
      event.interactionType = {
        display: source.Type.DisplayValue,
        icon: source.Type.Icon,
        type: source.Type.Key,
        external: source.Type.External,
      };
    }
    return event;
  }
}
