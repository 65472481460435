import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  SessionService,
  UserService,
} from '@maximizer/core/shared/data-access';
import { ListItem, User } from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
  StringValidator,
} from '@maximizer/core/shared/ui';

import { forkJoin, finalize, catchError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NoteService } from '@maximizer/outlook/note/data-access';
import {
  NoteForm,
  NoteModuleConfiguration,
} from '@maximizer/outlook/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'maximizer-add-note',
  templateUrl: './add-note.component.html',
})
export class AddNoteComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;
  constructor(
    private noteService: NoteService,
    private session: SessionService,
    private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  currentUser?: User;
  form?: FormGroupWrapper<NoteForm>;
  configuration?: NoteModuleConfiguration;

  loading = true;
  canSave = false;

  noneValue: ListItem<string> = {
    id: '-1',
    name: this.translate.instant('outlook.forms.none'),
  };

  now = new Date();
  fullFormat = 'dd MMMM yyyy HH:mm:ss';

  private _abEntryKey: string | null = null;
  private _leadKey: string | null = null;

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @Input() type!: 'lead' | 'abentry';

  @Input()
  set id(id: string) {
    if (this.type == 'lead') this._leadKey = decodeURI(id);
    if (this.type == 'abentry') this._abEntryKey = decodeURI(id);
  }
  get id() {
    return this._leadKey ?? this._abEntryKey ?? '';
  }

  ngOnInit(): void {
    this.setForm();

    this.configuration = {
      options: {
        categories: [this.noneValue],
        types: [this.noneValue],
      },
      users: [],
    };

    this.form?.patch({
      category: this.noneValue.id,
    });

    if (this._leadKey || this._abEntryKey) this.loadData();
  }

  transformToNullWhenNone(input: string | null) {
    if (input == this.noneValue.id) return null;
    return input;
  }

  save(): void {
    if (!this.form) {
      return;
    }
    const category = this.form.value.category;
    this.form.patch({
      category: this.transformToNullWhenNone(this.form.value.category),
      type: 0,
    });

    const validation = this.form.validate();
    if (!validation.valid) return;

    this.loading = true;
    this.noteService
      .create(this.form.value)
      .pipe(
        catchError(async () => {
          this.form?.patch({ category: category });
          this.notification.show('create', 'error');
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((result) => {
        if (result == null) return;
        this.notification.show('create', 'success');
        this.router.navigate(['../'], { relativeTo: this.route });
      });
  }

  cancel(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private setForm(): void {
    this.form = FormWrapperBuilder.group<NoteForm>(
      {
        key: null,
        parentKey: this._abEntryKey ?? this._leadKey,
        dateTime: [this.now, [Validators.required]],
        richText: [
          null,
          {
            validators: Validators.compose([
              Validators.required,
              Validators.minLength(1),
              StringValidator.NotEmpty(),
            ]),
          },
        ],
        important: false,
        category: '-1',
        type: '-1',
        creator: [
          null,
          {
            validators: Validators.compose([
              Validators.required,
              Validators.minLength(1),
              StringValidator.NotEmpty(),
            ]),
            updateOn: 'blur',
          },
        ],
        abEntryKey: null,
        leadKey: null,
      },

      this.save,
      () => {
        this.form?.control.setErrors(null);
        if (this.form?.valid) {
          this.canSave = true;
        } else {
          this.canSave = false;
        }
      },
    );
  }

  private loadData(): void {
    forkJoin({
      interactionOptions: this.noteService.getFieldOption(
        this._abEntryKey ?? this._leadKey ?? '',
      ),
      users: this.userService.getActiveUsers(),
      currentUser: this.session.getCurrentUser(),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => {
        if (data == null) return;

        const { categories, types } = data.interactionOptions;
        categories.unshift(this.noneValue);
        types.unshift(this.noneValue);

        this.configuration = {
          options: {
            categories: categories ?? [this.noneValue],
            types: types ?? [this.noneValue],
          },
          users: data.users ?? [],
        };

        this.form?.patch({
          creator: data.currentUser.id,
          category: this.noneValue.id,
        });
      });
    if (!this.configuration) return;
  }
}
