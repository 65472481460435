import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { FormArrayWrapper } from './form-array-wrapper';
import { FormControlWrapper } from './form-control-wrapper';
import { FormGroupWrapper } from './form-group-wrapper';
import { FormControls, FormDefinition } from './form-types';

function createControl<T, K extends keyof T>(
  control: AbstractControl,
  name: string
): FormControlWrapper<T[K]> {
  return new FormControlWrapper<T[K]>(control, name);
}

export function createControls<T>(
  parent: AbstractControl,
  definition: FormDefinition<T>
): FormControls<T> {
  const controls: {
    [K in keyof T]?:
      | FormControlWrapper<T[K]>
      | FormArrayWrapper<T[K]>
      | FormGroupWrapper<T[K]>;
  } = {};

  Object.keys(definition).forEach((field) => {
    const control = parent.get(field);
    if (control) {
      const controlDefinition = definition[field as keyof T];
      if (
        controlDefinition instanceof FormArrayWrapper ||
        controlDefinition instanceof FormGroupWrapper
      ) {
        controls[field as keyof T] = controlDefinition;
        if (parent instanceof FormGroup) {
          parent.setControl(field, controlDefinition.control);
        }
      } else {
        controls[field as keyof T] = createControl<T, keyof T>(control, field);
      }
    }
  });
  return controls as FormControls<T>;
}

export class FormWrapperBuilder {
  private static _builder: FormBuilder;

  static get builder(): FormBuilder {
    if (!FormWrapperBuilder._builder) {
      FormWrapperBuilder._builder = new FormBuilder();
    }
    return FormWrapperBuilder._builder;
  }

  static group<T>(
    definition: FormDefinition<T>,
    onSubmit?: (isFirstSubmit: boolean) => void,
    onChanges?: (value: T) => void
  ): FormGroupWrapper<T> {
    const group = FormWrapperBuilder.builder.group(definition);
    const controls = createControls(group, definition);

    return new FormGroupWrapper<T>(group, controls, onSubmit, onChanges);
  }

  static array<T>(shape: FormDefinition<T>) {
    const array = FormWrapperBuilder.builder.array([]);

    return new FormArrayWrapper<T>(array, shape);
  }
}
