import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { InteractionService } from '@maximizer/outlook/interaction-log/data-access';
import { SessionService } from '@maximizer/core/shared/data-access';
import {
  InteractionLogForm,
  InteractionLogModuleConfiguration,
  ListItem,
  Octopus,
  User,
} from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
  StringValidator,
} from '@maximizer/core/shared/ui';

import { forkJoin, finalize, catchError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Validators } from '@angular/forms';
import {
  AbEntryService,
  LeadService,
} from '@maximizer/outlook/shared/data-access';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'maximizer-call-log',
  templateUrl: './call-log.component.html',
})
export class CallLogComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;
  constructor(
    private abEntryService: AbEntryService,
    private interactionService: InteractionService,
    private session: SessionService,
    private leadService: LeadService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  private _currentUser?: User;
  private _abEntryKey: string | null = null;
  private _leadKey: string | null = null;

  form?: FormGroupWrapper<InteractionLogForm>;
  configuration?: InteractionLogModuleConfiguration;
  loading = true;
  canSave = false;
  now = new Date();
  fullFormat = 'dd MMMM yyyy HH:mm:ss';

  noneValue: ListItem<string> = {
    id: '-1',
    name: this.translate.instant('outlook.forms.none'),
  };
  direction: ListItem<number>[] = [
    {
      id: 1,
      name: this.translate.instant('outlook.callLog.directionIncoming'),
    },
    {
      id: 2,
      name: this.translate.instant('outlook.callLog.directionOutgoing'),
    },
  ];

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @Input() type!: 'lead' | 'abentry';

  @Input()
  set id(id: string) {
    if (this.type == 'lead') this._leadKey = decodeURI(id);
    if (this.type == 'abentry') this._abEntryKey = decodeURI(id);
  }
  get id() {
    return this._leadKey ?? this._abEntryKey ?? '';
  }

  ngOnInit(): void {
    this.setForm();

    this.configuration = {
      callLogOptions: {
        categories: [this.noneValue],
        results: [this.noneValue],
      },
      phones: [this.noneValue],
    };

    this.form?.patch({
      phone: this.noneValue.name,
      category: this.noneValue.id,
      result: this.noneValue.id,
    });

    if (this._leadKey) this.loadLead();
    if (this._abEntryKey) this.loadAbEntry();
  }

  transformToNullWhenNone(input: string | null, prop: string) {
    if (prop == 'id') {
      if (input == this.noneValue.id) return null;
    } else if (input == this.noneValue.name) {
      return null;
    }
    return input;
  }

  save(): void {
    if (!this.form) return;

    const phoneField = this.form.value.phone ?? this.noneValue.name;
    const categoryField = this.form.value.category ?? this.noneValue.id;
    const resultField = this.form.value.result ?? this.noneValue.id;

    this.form.patch({
      user: this._currentUser?.key ?? '$CURRENT_USER()',
      abEntryKey: this._abEntryKey,
      leadKey: this._leadKey,
      phone: this.transformToNullWhenNone(this.form.value.phone, 'name'),
      category: this.transformToNullWhenNone(this.form.value.category, 'id'),
      result: this.transformToNullWhenNone(this.form.value.result, 'id'),
    });

    if (this.form.value.startDate && this.form.value.endDate) {
      const endTime = this.form.value.endDate.getTime();
      const startTime = this.form.value.startDate.getTime();
      this.form.value.duration = endTime - startTime;
    }

    const validation = this.form.validate();

    if (!validation.valid) return;

    this.loading = true;
    this.interactionService
      .save(this.form.value)
      .pipe(
        catchError(async () => {
          this.onSaveError(phoneField, categoryField, resultField);
          this.notification.show('create', 'error');
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((result) => {
        if (result == null) return;

        this.notification.show('create', 'success');
        this.router.navigate(['../'], { relativeTo: this.route });
      });
  }

  cancel(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
  onSaveError(phone: string, category: string, result: string) {
    this.form?.patch({
      phone: phone,
      category: category,
      result: result,
    });
  }

  onStartChange() {
    if (!this.form) return;
    this.now = this.form.controls.startDate.value;
  }

  private setForm(): void {
    this.form = FormWrapperBuilder.group<InteractionLogForm>(
      {
        key: null,
        user: null,
        phone: '-1',
        category: '-1',
        direction: [2, [Validators.required]],
        subject: [
          null,
          {
            validators: Validators.compose([
              Validators.required,
              Validators.minLength(1),
              StringValidator.NotEmpty(),
              Validators.maxLength(1024),
            ]),
            updateOn: 'blur',
          },
        ],
        callLog: null,
        result: '-1',
        startDate: [this.now, [Validators.required]],
        endDate: [
          new Date(this.now.getTime() + 30 * 60 * 1000),
          [Validators.required],
        ],
        abEntryKey: null,
        leadKey: null,
        type: Octopus.InteractionCode.CALL,
        duration: null,
      },

      this.save,
      () => {
        this.form?.control.setErrors(null);
        if (this.form?.valid) {
          this.canSave = true;
        } else {
          this.canSave = false;
        }
      },
    );
  }

  private loadLead(): void {
    forkJoin({
      interactionOptions: this.interactionService.getFieldOption(),
      currentUser: this.session.getCurrentUser(),
      lead: this.leadService.getByKey(this._leadKey ?? ''),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => {
        if (data == null) return;

        this._currentUser = data.currentUser;
        const { categories, results } = data.interactionOptions;
        const phoneList: ListItem<string>[] = [this.noneValue];
        categories.unshift(this.noneValue);
        results.unshift(this.noneValue);

        this.validateAndAddPhone(data.lead?.phone1, phoneList);
        this.validateAndAddPhone(data.lead?.phone2, phoneList);

        this.configuration = {
          callLogOptions: {
            categories: categories ?? [this.noneValue],
            results: results ?? [this.noneValue],
          },
          phones: phoneList,
        };
        this.form?.patch({
          phone: this.noneValue.name,
          category: this.noneValue.id,
          result: this.noneValue.id,
        });
      });
    if (!this.configuration) return;
  }

  private loadAbEntry(): void {
    forkJoin({
      interactionOptions: this.interactionService.getFieldOption(),
      currentUser: this.session.getCurrentUser(),
      abentry: this.abEntryService.getByKey(this._abEntryKey ?? ''),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => {
        if (data == null) return;

        this._currentUser = data.currentUser;
        const { categories, results } = data.interactionOptions;
        const phoneList: ListItem<string>[] = [this.noneValue];
        categories.unshift(this.noneValue);
        results.unshift(this.noneValue);

        this.validateAndAddPhone(data.abentry?.phone1, phoneList);
        this.validateAndAddPhone(data.abentry?.phone2, phoneList);
        this.validateAndAddPhone(data.abentry?.phone3, phoneList);

        this.configuration = {
          callLogOptions: {
            categories: categories ?? [this.noneValue],
            results: results ?? [this.noneValue],
          },
          phones: phoneList,
        };
        this.form?.patch({
          phone: this.noneValue.name,
          category: this.noneValue.id,
          result: this.noneValue.id,
        });
      });
    if (!this.configuration) return;
  }

  private validateAndAddPhone(
    phone: string | undefined,
    phoneList: ListItem<string>[],
  ) {
    if (phone && phone.length > 1)
      phoneList.push({
        id: phoneList.length.toString(),
        name: phone.trim(),
      });
  }
}
