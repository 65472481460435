import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { SmartFill, UrlHeaders } from '@maximizer/core/shared/domain';
import { ContextService } from './context.service';
import { SMART_FILL } from '../data-access.module';

@Injectable()
export class SmartFillService {
  constructor(
    private http: HttpClient,
    private context: ContextService,
    @Inject(SMART_FILL) private smartFill: string,
  ) {}

  getUrlHeaders(url: string): Observable<UrlHeaders> {
    const mapper = new SmartFill.HeadersMapper();
    const apiUrl = `${this.smartFill}/headers?url=${url}`;
    const headers = new HttpHeaders({
      Authorization: this.context.token,
      'Content-Type': 'application/json',
    });

    return this.http.get<SmartFill.Headers>(apiUrl, { headers }).pipe(
      map((response) => {
        return mapper.from(response);
      }),
    );
  }
}
