import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EntityListCatalog,
  Entity,
  Octopus,
  ListItem,
} from '@maximizer/core/shared/domain';
import { ContextService } from './context.service';
import { Observable, map, of } from 'rxjs';

@Injectable()
export class FieldOptionsService {
  private catalog: EntityListCatalog = {};

  constructor(
    private http: HttpClient,
    private context: ContextService,
  ) {}

  getList<T = unknown>(
    entity: string,
    field: string,
    data?: Entity,
    options?: Entity,
  ): Observable<ListItem<T>[]> {
    if (this.catalog[entity]?.[field]) {
      return of(this.catalog[entity][field] as ListItem<T>[]);
    }

    return this.getLists(
      { [entity]: [field] },
      data ? { [entity]: data } : undefined,
      options ? { [entity]: options } : undefined,
    ).pipe(map((lists) => (lists[entity]?.[field] as ListItem<T>[]) ?? []));
  }

  getLists(
    entities: Record<string, string[]>,
    data?: Record<string, Entity>,
    options?: Record<string, Entity>,
  ): Observable<EntityListCatalog> {
    const request = this.getRequest(entities, data, options);

    const action =
      Object.keys(request).length > 0
        ? this.http.post<Octopus.EntityFieldOptionsResponse>(
            `${this.context.api}${Octopus.Action.READ}`,
            request,
          )
        : of({
            Code: Octopus.ResponseStatusCode.Successful,
          } as Octopus.EntityFieldOptionsResponse);

    return action.pipe(
      map((response) => this.mapResponse(response, entities, data)),
    );
  }

  removeList(entity: string, field: string): void {
    if (this.catalog[entity]) {
      delete this.catalog[entity][field];
    }
  }

  private mapResponse(
    response: Octopus.EntityFieldOptionsResponse,
    entities: Record<string, string[]>,
    data?: Record<string, Entity>,
  ): EntityListCatalog {
    const lists: EntityListCatalog = {};

    Object.keys(entities).forEach((entity) => {
      lists[entity] = {};
      entities[entity].forEach((field) => {
        if (this.catalog[entity]?.[field]) {
          lists[entity][field] = this.catalog[entity][field];
        } else {
          lists[entity][field] =
            response[entity]?.FieldOptions?.[field]?.map((option) => {
              return {
                id: option.Key,
                name: option.DisplayValue,
              };
            }) ?? [];

          if (!data) {
            if (!this.catalog[entity]) {
              this.catalog[entity] = {};
            }

            this.catalog[entity][field] = lists[entity][field];
          }
        }
      });
    });

    return lists;
  }

  private getRequest(
    entities: Record<string, string[]>,
    data?: Record<string, Entity>,
    options?: Record<string, Entity>,
  ): Octopus.EntityFieldOptionsRequest {
    const request: Octopus.EntityFieldOptionsRequest = {};

    Object.keys(entities).forEach((entity) => {
      const hasData = data?.[entity] && Object.keys(data[entity]).length;
      const hasOptions =
        options?.[entity] && Object.keys(options[entity]).length;

      request[entity] = {
        FieldOptions: {},
      };

      if (hasData) {
        request[entity].Data = data[entity];
      }

      if (hasOptions) {
        request[entity].Options = options[entity];
      }

      entities[entity].forEach((field) => {
        if (!this.catalog[entity]?.[field] || hasData) {
          request[entity].FieldOptions[field] = [
            {
              Key: 1,
              DisplayValue: 1,
            },
          ];
        }
      });

      if (Object.keys(request[entity].FieldOptions).length === 0) {
        delete request[entity];
      }
    });

    return request;
  }
}
