import { Mapper } from '../../../mappers';
import { ConfigurationSetting as ConfigurationSettingModel } from '../../../models';
import { ConfigurationSetting } from '../models/configuration-setting';

export class ConfigurationSettingMapper extends Mapper<
  ConfigurationSetting,
  ConfigurationSettingModel
> {
  from(source: ConfigurationSetting): ConfigurationSettingModel {
    return {
      id: source.Key ?? '',
      code1: source.Code1?? '',
      code2: source.Code2?? '',
      code3: source.Code3?? '',
      code4: source.Code4?? '',
      textValue: source.TextValue ?? '',
      intValue: source.IntValue,
    };
  }
}
