import { Mapper } from '../../../mappers';
import { SystemInfo as OctopusSystemInfo } from '../models';
import { SystemInfo } from '../../../models';

export class SystemInfoMapper extends Mapper<
  OctopusSystemInfo,
  SystemInfo
> {
  from(source: OctopusSystemInfo): SystemInfo {
    return {
      Package: source.Package, 
      VersionMajor: source.VersionMajor,
      VersionMinor: source.VersionMinor,
      Components: source.Components
    };
  }
}
