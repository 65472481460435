import { ValueAndDisplay } from './value-and-display';

export enum PhoneFields {
  Description = 'Description',
  Number = 'Number',
  Extension = 'Extension',
}

export interface Phone<T = string> {
  Description: T;
  Number: T;
  Extension: T;
  DisplayValue?: string;
}

export type EntityPhone = Phone<ValueAndDisplay<string>>;

export const PhoneFieldsEditableListFields = {
  [PhoneFields.Description]: 60010,
};
