import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentService } from './services/appointment.service';
import { OutlookSharedDataAccessModule } from '@maximizer/outlook/shared/data-access';

@NgModule({
  imports: [CommonModule, OutlookSharedDataAccessModule],
  providers: [AppointmentService],
})
export class OutlookAppointmentDataAccessModule {}
