import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextService } from '@maximizer/core/shared/data-access';
import {
  AbEntrySearch,
  AbEntryRecent,
  Octopus,
} from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import {
  GlobalSearchService,
  SearchEscapeCharacters,
} from './global-search-service';
import { escapeCharacters } from '@maximizer/shared/util';

@Injectable()
export class AbEntrySearchService
  implements GlobalSearchService<AbEntrySearch>
{
  constructor(
    private readonly _http: HttpClient,
    private readonly _context: ContextService,
  ) {}

  fetchRecentSearch(): Observable<AbEntryRecent[]> {
    const request: Octopus.AbEntryRecentReadRequest = {
      RecentEntry: {
        Criteria: {
          SearchQuery: {
            $AND: [
              { EntityType: { $EQ: Octopus.EntityType.AbEntry } },
              { User: { $EQ: Octopus.CurrentUser } },
              { Type: { $EQ: 1 } },
            ],
          },
        },
        OrderBy: {
          Fields: {
            Items: [
              {
                AbEntry: {
                  LastContactDate: 'DESC',
                },
              },
            ],
          },
        },
        Scope: {
          Fields: {
            Items: [
              {
                AbEntry: {
                  Key: 1,
                  Type: 1,
                  DisplayValue: 1,
                  FirstName: 1,
                  LastName: 1,
                  CompanyName: 1,
                  Email: {
                    Address: 1,
                  },
                  ParentKey: {
                    Value: 1,
                  },
                  LastContactDate: {
                    DisplayValue: 1,
                    Value: 1,
                  },
                  Parent: {
                    Type: 1,
                    DisplayValue: 1,
                  },
                },
              },
            ],
          },
        },
        Globalization: Octopus.DefaultGlobalization,
      },
    };

    return this._http
      .post<Octopus.AbEntryRecentResponse>(
        `${this._context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result && result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.AbEntryRecentMapper();
            return result.RecentEntry.Data
              .filter((data) => data !== null)
              .map((data) => mapper.from(data));
          }
          return [];
        }),
      );
  }

  search(
    searchPhrase: string,
    useWildcard = false,
    take = 15,
    type: Octopus.AbEntryType | undefined = undefined,
  ): Observable<AbEntrySearch[]> {
    const query: Octopus.LogicalQuery<Octopus.AbEntryReadRequest> = {
      $AND: [
        {
          $PHRASE: `${escapeCharacters(searchPhrase, SearchEscapeCharacters)}${!useWildcard ? '' : '*'}`,
        },
      ],
    };

    if (type !== undefined) {
      query.$AND?.push({ Type: { $EQ: type } });
    }

    const request: Octopus.AbEntryReadRequest = {
      AbEntry: {
        Criteria: {
          SearchQuery: query,
          Top: take,
        },
        OrderBy: {
          Fields: [{ LastModifyDate: 'DESC' }],
        },
        Scope: {
          Fields: {
            Key: 1,
            Type: 1,
            DisplayValue: 1,
            FirstName: 1,
            LastName: 1,
            CompanyName: 1,
            Email: {
              Address: 1,
            },
            ParentKey: {
              Value: 1,
            },
            LastContactDate: {
              DisplayValue: 1,
              Value: 1,
            },
            Parent: {
              Type: 1,
              DisplayValue: 1,
            },
          },
        },
      },
      Globalization: Octopus.DefaultGlobalization,
      Configuration: Octopus.AbEntryReadDriver,
    };

    return this._http
      .post<Octopus.AbEntryResponse>(
        `${this._context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result && result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.AbEntrySearchMapper();
            return result.AbEntry.Data.map((entries) => mapper.from(entries));
          }
          return [];
        }),
      );
  }
}
