import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ContextService } from './context.service';
import { ListItem, Octopus } from '@maximizer/core/shared/domain';

@Injectable()
export class NoteService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  getNoteFieldOptions(parentKey: string): Observable<ListItem<string>[]> {
    const request = {
      Note: {
        FieldOptions: {
          Type: [
            {
              Key: 1,
              DisplayValue: 1,
            },
          ],
        },
        Data: {
          ParentKey: parentKey
        }
      },
    };

    return this.http
      .post<Octopus.NoteFieldOptionsResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.Note
          ) {
            const mapper = new Octopus.NoteMapper();
            return mapper.from(result);
          }
          return [];
        }),
      );
  }

  delete(notekeys: string[]): Observable<boolean> {
    const request: { [name: string]: unknown } = {};

    if (notekeys) {
      notekeys.forEach(
        (key, index) => (request['Note.' + index] = { Data: { Key: key } }),
      );
    }

    return this.http
      .post<Octopus.Response>(
        `${this.context.api}${Octopus.Action.DELETE}`,
        request,
        { headers: this.getRequestHeaders() },
      )
      .pipe(
        map((result) => {
          return result.Code === Octopus.ResponseStatusCode.Successful;
        }),
      );
  }
  private getRequestHeaders(): { [header: string]: string } {
    return {
      'mx-insights-entity': 'Note',
    };
  }
}
