import { Mapper } from '../../../mappers';
import {
  SalesTeamMember,
  SalesTeam,
  SalesTeamMemberRight,
} from '../../../models';
import {
  SalesTeam as OctopusSalesTeam,
  SalesTeamMember as OctopusSalesTeamMember,
  TeamMemberRights,
  TeamMemberRole,
} from '../models/sales-team';

export class SalesTeamMapper extends Mapper<OctopusSalesTeam[], SalesTeam[]> {
  from(source: OctopusSalesTeam[]): SalesTeam[] {
    return source.map((item) => {
      return this.mapItem(item);
    });
  }

  mapItem(source: OctopusSalesTeam): SalesTeam {
    return {
      key: source.Key ?? '',
      display: source.DisplayName,
      members: this.sortMembers(source.MemberKeys?.map(this.mapMemberItem)),
    };
  }

  mapMemberItem(source: OctopusSalesTeamMember): SalesTeamMember {
    const rights: { [key in TeamMemberRights]: SalesTeamMemberRight } = {
      [TeamMemberRights.Edit]: 'edit',
      [TeamMemberRights.Audit]: 'audit',
      [TeamMemberRights.Standard]: 'standard',
    };

    return {
      id: source.Key,
      name: source.DisplayValue,
      role: source.Role === TeamMemberRole.Leader ? 'leader' : 'member',
      right: rights[source.Right] ?? 'audit',
    };
  }
  sortMembers(members?: SalesTeamMember[]): SalesTeamMember[] {
    return members
      ? members.sort((mem1, mem2) =>
          mem1.name.toLowerCase().localeCompare(mem2.name.toLowerCase()),
        )
      : [];
  }
}
