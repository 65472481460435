export enum EntryType {
  AddressBook = 'addressBook',
  Case = 'case',
  Campaign = 'campaign',
  Lead = 'lead',
  Opportunity = 'opportunity',
  SalesProcess = 'salesProcess',
  Team = 'team',
  User = 'user'
}
