@if(!loading && !isVersionValid) {
    <div class="h-full w-full flex flex-col" *ngIf="!isVersionValid">
        <div
          class="m-4 rounded p-4"
          [ngClass]="false ? 'bg-white' : 'bg-[#FFFFE0] min-h-40'"
        >
          <span class="subtitle-2"
            >{{ 'outlook.compose.outdated-version.header' | translate }}:</span
          >
          <p class="mt-1 text-body">
            {{ 'outlook.compose.outdated-version.description' | translate }}
          </p>
          <p class="mt-3 text-body whitespace-break-spaces">
            {{ 'outlook.compose.outdated-version.phone' | translate }}
          </p>
          <p
            class="mt-3 text-body whitespace-break-spaces"
            [innerHTML]="'outlook.compose.outdated-version.email' | translate"
          ></p>
        </div>
      </div>
}