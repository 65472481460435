import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SorteableListComponent } from './sorteable-list.component';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { TranslateModule } from '@ngx-translate/core';
import { defaultTranslateConfiguration } from '../../config';
import { UiPipesModule } from '../../pipes';
import { FormsModule } from '@angular/forms';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { KendoModule } from '../../kendo';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SwitchModule,
    SortableModule,
    KendoModule,
    UiPipesModule,
    TooltipsModule,
    TranslateModule.forChild({
      ...defaultTranslateConfiguration,
      extend: true,
    }),
  ],
  exports: [SorteableListComponent],
  declarations: [SorteableListComponent],
})
export class UiSorteableListModule {}
