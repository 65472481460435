<kendo-formfield #anchor>
  <kendo-textbox
    [title]="''"
    [(ngModel)]="autocompleteInput"
    (valueChange)="handleAutocompleteValueChange($event)"
    #searchInMaximizer
    valueField="name"
    [placeholder]="'outlook.forms.searchInMaximizer' | translate"
    class="my-1"
  >
    <ng-template kendoTextBoxPrefixTemplate>
      <em
        class="fa-icons icon icon-solid icon-12 icon-magnifying-glass mx-2"
      ></em>
    </ng-template>
  </kendo-textbox>
</kendo-formfield>

<kendo-popup
  #popup
  [anchor]="searchInMaximizer?.input?.nativeElement"
  (anchorViewportLeave)="handleLeavePopup()"
  class="w-full px-5 rounded-sm"
  *ngIf="showPopup"
  containerClass="my-2"
>
  <div class="h-auto w-full rounded-sm flex my-1">
    <maximizer-loader
      *ngIf="addEmailFromPopupLoading"
      type="legacy"
      class="small"
    ></maximizer-loader>

    <div
      class="h-10 mt-auto w-full flex flex-row space-x-2 px-2"
      *ngIf="showAutocompleteSkeleton"
    >
      <maximizer-outlook-skeleton
        containerClasses="h-9 w-9 max-w-9 rounded-full bg-neutral-20"
        fullWidth="none"
      ></maximizer-outlook-skeleton>
      <maximizer-outlook-skeleton
        containerClasses="h-9 w-full rounded-md bg-neutral-20"
      ></maximizer-outlook-skeleton>
    </div>
    <span
      *ngIf="!showAutocompleteSkeleton && searchInMaximizerList.length === 0"
      class="m-auto"
      >{{ 'outlook.forms.no-data-found' | translate }}</span
    >

    <div
      *ngIf="!showAutocompleteSkeleton && searchInMaximizerList.length > 0"
      class="w-full"
    >
      <div class="px-3" (click)="closeAllItemPopups()">
        <span class="caption whitespace-pre">{{
          'outlook.compose.search.description' | translate
        }}</span>
      </div>
      <kendo-listview
        [kendoListViewBinding]="searchInMaximizerList"
        class="main-page border-none overflow-y-auto max-h-[160px]"
        *ngIf="!showAutocompleteSkeleton"
        itemClass="w-full overflow-x-hidden"
      >
        <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
          <div
            class="mb-1 min-h-10 min-w-200 w-auto flex flex-row cursor-pointer hover:bg-inserv-10 pl-3"
            [ngClass]="dataItem.showPopup ? 'bg-inserv-10' : ''"
          >
            <div
              class="flex-none w-6 my-auto"
              (click)="addPopupEmailToOutlook(dataItem.outlook, 'to')"
            >
              <div
                class="avatar avatar-xs avatar-neutral-20 avatar-border"
                [ngClass]="
                  dataItem.outlook.type === 'abentry' &&
                  (dataItem.outlook.abentryType === 'individual' ||
                    dataItem.outlook.abentryType === 'company')
                    ? 'avatar-square'
                    : ''
                "
              >
                <span
                  *ngIf="
                    dataItem.outlook.type === 'abentry' &&
                    dataItem.outlook.abentryType !== 'company'
                  "
                  >{{ getAvatarLetters(dataItem.outlook) | nameInitials }}</span
                >

                <img
                  *ngIf="dataItem.outlook.type === undefined"
                  alt="Email not in maximizer"
                  [src]="
                    cdn + '/assets/images/outlook/email-not-in-maximizer.svg'
                  "
                  class="w-3 m-auto"
                />

                <em
                  *ngIf="dataItem.outlook.type === 'lead'"
                  class="fa-icons icon icon-solid icon-12 icon-user-magnifying-glass m-auto"
                ></em>
                <em
                  *ngIf="dataItem.outlook.abentryType === 'company'"
                  class="fa-icons icon icon-regular icon-16 icon-building m-auto"
                ></em>
              </div>
            </div>

            <div
              (click)="addPopupEmailToOutlook(dataItem.outlook, 'to')"
              class="bg-gray-300 overflow-hidden text-ellipsis flex flex-col space-y-0.5 ml-2 my-auto shrink"
            >
              <p
                class="text-body overflow-hidden text-ellipsis"
                style="text-wrap: nowrap"
                [title]="dataItem.outlook.displayName"
              >
                {{ dataItem.outlook.displayName }}
              </p>
              <p
                class="caption text-neutral-70 overflow-hidden text-ellipsis"
                [title]="dataItem.outlook.emailAddress"
              >
                {{ dataItem.outlook.emailAddress }}
              </p>
              <span
                class="caption text-neutral-70 break-all"
                *ngIf="dataItem.outlook.type !== undefined"
                >{{
                  (dataItem.outlook.type === 'lead'
                    ? 'outlook.entries.lead'
                    : 'outlook.entries.' + dataItem.outlook.abentryType
                  ) | translate
                }}</span
              >

              <span
                class="caption text-neutral-70 break-all"
                *ngIf="dataItem.outlook.type === undefined"
                >{{ 'outlook.compose.not-in-maximizer' | translate }}</span
              >
            </div>

            <div
              class="flex m-auto mr-2 w-6 h-6 hover:bg-inserv-5"
              #itemAnchor
              (click)="handleClickItemPopup(dataItem)"
            >
              <em
                class="fa-icons icon icon-solid icon-12 icon-ellipsis-vertical m-auto"
              ></em>
            </div>
          </div>

          <kendo-popup
            #itemPopup
            class="w-[100px] rounded-sm"
            [anchor]="itemAnchor"
            (anchorViewportLeave)="dataItem.showPopup = false"
            [collision]="itemPopupCollision"
            *ngIf="dataItem.showPopup"
            [anchorAlign]="{ horizontal: 'right', vertical: 'top' }"
            [popupAlign]="{ horizontal: 'right', vertical: 'top' }"
          >
            <div class="flex-col flex w-full m-auto">
              <kendo-button
                id="addEmailCc"
                size="none"
                class="mt-2 hover:bg-inserv-10 h-10 border-none text-neutral-90"
                fillMode="outline"
                (click)="addPopupEmailToOutlook(dataItem.outlook, 'cc')"
              >
                <span class="text-body">{{
                  'outlook.compose.search.cc' | translate
                }}</span>
              </kendo-button>
              <kendo-button
                id="addEmailBcc"
                size="none"
                class="mb-2 hover:bg-inserv-10 h-10 border-none text-neutral-90"
                fillMode="outline"
                (click)="addPopupEmailToOutlook(dataItem.outlook, 'bcc')"
              >
                <span class="text-body">{{
                  'outlook.compose.search.bcc' | translate
                }}</span>
              </kendo-button>
            </div>
          </kendo-popup>
        </ng-template>
      </kendo-listview>
    </div>
  </div>
</kendo-popup>

<ng-template #tooltipTemplate let-anchor>
  <span class="caption justify-normal whitespace-nowrap text-neutral-90">{{
    anchor.nativeElement.getAttribute('data-title')
  }}</span>
</ng-template>

<maximizer-outlook-notification entryId="email" #notification [closable]="true">
</maximizer-outlook-notification>
