import { NgModule } from '@angular/core';
import { UtilsModule } from '@progress/kendo-angular-utils';
import { KanbanColumnComponent } from './kanban-column.component';
import { KanbanComponent } from './kanban.component';
import { CommonModule } from '@angular/common';
import { KanbanCardComponent } from './kanban-card.component';
import { KanbanPanelComponent } from './kanban-panel.component';
import { KanbanActionComponent } from './kanban-action.component';

@NgModule({
  imports: [CommonModule, UtilsModule],
  exports: [
    KanbanComponent,
    KanbanColumnComponent,
    KanbanCardComponent,
    KanbanPanelComponent,
    KanbanActionComponent,
  ],
  declarations: [
    KanbanComponent,
    KanbanColumnComponent,
    KanbanCardComponent,
    KanbanPanelComponent,
    KanbanActionComponent,
  ],
})
export class UiKanbanModule {}
