import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfigurationSetting,
  ConfigurationSettingCodes,
  Octopus,
  SystemConfiguration,
} from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';
import { ContextService } from './context.service';

@Injectable()
export class ConfigurationService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  getSystemConfiguration(): Observable<SystemConfiguration> {
    const request: Octopus.SystemConfigurationReadRequest = {
      Configuration: {
        SystemOption: {
          FiscalYearStartMonth: 1,
          MultiCurrencyEnabled: 1,
          CorporateCurrency: 1,
        },
      },
    };

    return this.http
      .post<Octopus.SystemConfigurationResponse>(
        `${this.context.api}${Octopus.Action.READ_CONFIGURATION}`,
        request,
      )
      .pipe(
        map((result) => {
          const mapper = new Octopus.SystemConfigurationMapper();
          return mapper.from(result.Configuration.Data);
        }),
      );
  }

  private getConfigurationSettingQuery(
    filter: ConfigurationSettingCodes | ConfigurationSettingCodes[],
    operators?: Partial<
      Record<keyof ConfigurationSettingCodes, Octopus.ComparisonOperator>
    >,
  ): Octopus.LogicalQuery<Octopus.ConfigurationSetting> {
    const filters = Array.isArray(filter) ? filter : [filter];
    const andQuery = filters.map((codes) => {
      const condition: Octopus.LogicalQuery<Octopus.ConfigurationSetting> = {
        $AND: [],
      };
      if (codes.code1) {
        condition.$AND?.push({
          Code1: { [operators?.code1 ?? '$EQ']: codes.code1 },
        });
      }
      if (codes.code2) {
        condition.$AND?.push({
          Code2: { [operators?.code2 ?? '$EQ']: codes.code2 },
        });
      }
      if (codes.code3) {
        condition.$AND?.push({
          Code3: { [operators?.code3 ?? '$EQ']: codes.code3 },
        });
      }
      if (codes.code4) {
        condition.$AND?.push({
          Code4: { [operators?.code4 ?? '$EQ']: codes.code4 },
        });
      }
      return condition;
    });

    return filters.length > 1
      ? {
          $OR: [...andQuery],
        }
      : andQuery[0];
  }

  private getConfigurationSettingRequest(
    query: Octopus.LogicalQuery<Octopus.ConfigurationSetting>,
  ): Octopus.ConfigurationSettingReadRequest {
    return {
      ConfigurationSetting: {
        Criteria: {
          SearchQuery: query,
        },
        Scope: {
          Fields: {
            Key: 1,
            Code1: 1,
            Code2: 1,
            Code3: 1,
            Code4: 1,
            TextValue: 1,
            IntValue: 1,
          },
        },
      },
    };
  }

  getSetting(
    filter: ConfigurationSettingCodes,
    operators?: Partial<
      Record<keyof ConfigurationSettingCodes, Octopus.ComparisonOperator>
    >,
  ): Observable<ConfigurationSetting | null> {
    return this.getSettings(filter, operators).pipe(
      map((settings) => {
        if (settings?.length) {
          return settings[0];
        }
        return null;
      }),
    );
  }

  getSettings(
    filter: ConfigurationSettingCodes | ConfigurationSettingCodes[],
    operators?: Partial<
      Record<keyof ConfigurationSettingCodes, Octopus.ComparisonOperator>
    >,
  ): Observable<ConfigurationSetting[] | null> {
    const query = this.getConfigurationSettingQuery(filter, operators);
    const request = this.getConfigurationSettingRequest(query);

    return this.http
      .post<Octopus.ConfigurationSettingResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.ConfigurationSetting.Data.length > 0) {
            const mapper = new Octopus.ConfigurationSettingMapper();
            return result.ConfigurationSetting.Data.map((item) =>
              mapper.from(item),
            );
          }
          return null;
        }),
      );
  }

  updateSetting(
    id: string | null,
    codes: ConfigurationSettingCodes,
    textValue?: string,
    intValue?: number | null,
  ): Observable<ConfigurationSetting | null> {
    const request: Octopus.ConfigurationSettingWriteRequest = {
      ConfigurationSetting: {
        Data: {
          Key: id,
          TextValue: textValue,
          IntValue: intValue,
        },
      },
    };

    if (id === null) {
      request.ConfigurationSetting.Data.Code1 = codes.code1 ?? '';
      request.ConfigurationSetting.Data.Code2 = codes.code2 ?? '';
      request.ConfigurationSetting.Data.Code3 = codes.code3 ?? '';
      request.ConfigurationSetting.Data.Code4 = codes.code4 ?? '';
    }

    return this.http
      .post<Octopus.ConfigurationSettingWriteResponse>(
        `${this.context.api}${
          id ? Octopus.Action.UPDATE : Octopus.Action.CREATE
        }`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.ConfigurationSettingMapper();
            return mapper.from(result.ConfigurationSetting.Data);
          }
          return null;
        }),
      );
  }

  bulkUpdateSetting(
    action: typeof  Octopus.Action.CREATE | typeof  Octopus.Action.UPDATE,
    configurationSettings: (Octopus.ConfigurationSetting | undefined)[],
  ): Observable<ConfigurationSetting | null> {
    const request: Record<string, { Data: Octopus.ConfigurationSetting | undefined }> = {};

    if (configurationSettings) {
      configurationSettings.forEach(
        (setting, index) =>
          (request[`ConfigurationSetting.${index}`] = { Data: setting }),
      );
    }

    return this.http
      .post<Octopus.ConfigurationSettingWriteResponse>(
        `${this.context.api}${action}`,
        request,
        {
          headers: { 'mx-insights-entity': 'ConfigurationSetting' },
        },
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.ConfigurationSettingMapper();
            return mapper.from(result.ConfigurationSetting.Data);
          }
          return null;
        }),
      );
  }

  bulkDelete(configurationKeys: string[]): Observable<boolean> {
    const request: Record<string, { Data: { Key: string } }> = {};

    if (configurationKeys) {
      configurationKeys.forEach(
        (key, index) =>
          (request[`ConfigurationSetting.${index}`] = { Data: { Key: key } }),
      );
    }

    return this.http
      .post<Octopus.Response>(
        `${this.context.api}${Octopus.Action.DELETE}`,
        request,
      )
      .pipe(
        map((result) => {
          return result.Code === Octopus.ResponseStatusCode.Successful;
        }),
      );
  }
}
