import { withDevtools } from '@angular-architects/ngrx-toolkit';
import {
  ConfigurationSetting,
  EntityCatalog,
  ListItem,
} from '@maximizer/core/shared/domain';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

export type CardLayoutCustomizationState = {
  loading: boolean;
  readOptions: ListItem<string>[];
  writeOptions: ListItem<string>[];
  entityCatalog: EntityCatalog;
  configurationSetting: ConfigurationSetting | null;
};

const initialState: CardLayoutCustomizationState = {
  loading: false,
  readOptions: [],
  writeOptions: [],
  entityCatalog: {
    entity: '',
    fields: {},
    folders: { all: {}, tree: [] },
    layouts: [],
  } as EntityCatalog,
  configurationSetting: null,
};

export const CardLayoutCustomizationStore = signalStore(
  withDevtools('card-layout-customization'),
  withState(initialState),
  withMethods((store) => ({
    setLoading(loading: boolean) {
      patchState(store, () => ({ loading }));
    },
    setOptions(
      readOptions: ListItem<string>[],
      writeOptions: ListItem<string>[],
    ) {
      patchState(store, () => ({ readOptions, writeOptions }));
    },
    setEntityCatalog(entityCatalog: EntityCatalog) {
      patchState(store, () => ({ entityCatalog }));
    },
    setConfigurationSetting(configurationSetting: ConfigurationSetting) {
      patchState(store, () => ({ configurationSetting }));
    },
  })),
);

export type CardLayoutCustomizationStore = InstanceType<
  typeof CardLayoutCustomizationStore
>;
