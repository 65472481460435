import { Request, RequestConfiguration, WriteRequest } from './request';
import { DataResponse, Response, WriteResponse } from './response';
import { FieldOptionsResponse, FieldOptionsWithData } from './field-options';
import { TypedKeyAndDisplay } from './key-and-display';
import { KeyFieldDefinition } from './metadata';
import { ValueAndDisplay } from './value-and-display';

export interface Note {
  Key: string;
  Text: string;
  RichText: string;
  Category: string;
  Important: boolean;
  DateTime: string;
  Creator: ValueAndDisplay<string>;
  DisplayValue: ValueAndDisplay<string>;
}

export interface NoteFieldOptions {
  Category: TypedKeyAndDisplay<string>;
  Type: TypedKeyAndDisplay<string>;
}

export interface NoteFieldOptionsRequest extends Request {
  Note: FieldOptionsWithData<NoteFieldOptions, KeyFieldDefinition>;
}

export interface NoteFieldOptionsResponse extends Response {
  Note: FieldOptionsResponse<NoteFieldOptions>;
}

export interface NoteCreate {
  Key: string | null;
  ParentKey: string | null;
  DateTime: Date;
  Type: {
    Key: number | null;
  };
  Category?: string | null;
  RichText: string;
  Creator: {
    UID: string;
  };
  Important: boolean;
}

export interface NoteCreateRequest extends Request {
  Note: WriteRequest<NoteCreate>;
}

export interface NoteCreateResponse extends Response {
  Note: WriteResponse<NoteCreate>;
}

export const NoteWriteDriver: RequestConfiguration = {
  Drivers: {
    INoteAccess: 'Maximizer.Model.Access.Sql.NoteAccess',
  },
};

export const NoteReadDriver: RequestConfiguration = {
  Drivers: {
    INoteSearcher: 'Maximizer.Model.Access.Sql.NoteSearcher',
  },
};

export interface NoteSummaryCount {
  '$COUNT()': number;
}

export interface NoteSummaryResponse extends Response {
  Note: DataResponse<Note>;
  'Note.Count': DataResponse<NoteSummaryCount>;
}
