import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  computed,
  effect,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SecurityContext,
  Signal,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContextService,
  FeatureService,
  GlobalStore,
  OAuth2Service,
  SessionService,
  SessionStorageService,
} from '@maximizer/core/shared/data-access';
import {
  OnboardingStore,
  OutlookSyncService,
  OutlookStore,
  InitOnboardingAction,
  OutlookMsalService,
  OutlookService,
} from '@maximizer/outlook/shared/data-access';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, slideDownAnimation } from '@maximizer/outlook/shared/ui';
import { CelebrationComponent } from '@maximizer/outlook/onboarding/ui';

enum MenuType {
  Refresh = 'refresh',
  Maximizer = 'maximizer',
  Settings = 'settings',
  Guide = 'guide',
  Logout = 'logout',
  AppVersion = 'appVersion',
  Database = 'database',
  SwitchAccount = 'switchAccount',
}

@Component({
  selector: 'maximizer-outlook-menu',
  templateUrl: './menu.component.html',
  animations: [slideDownAnimation],
  standalone: false,
})
export class OutlookMenuComponent implements OnInit {
  @ViewChild('celebration')
  celebration!: CelebrationComponent;

  @Input() pageTitle = '';
  @Input() pageType!: 'lead' | 'abentry' | 'none';
  @Input() id = '';
  @Input() showOnlyTitle = false;
  @Input() hideBack = false;
  @Input() hideRefresh = false;
  @Input() hideLogout = false;
  @Input() backRoutePath = '';
  @Input() forceWindowRefresh = false;
  @Output() exit = new EventEmitter<void>();
  @Output() initFirstOnboardingStep = new EventEmitter<InitOnboardingAction>();

  readonly userGuideUrl =
    'https://support.maximizer.com/hc/en-us/articles/29776800842253-Microsoft-Outlook-Integration-Getting-Started-Guide-Maximizer-AI-CRM-app-for-Outlook';

  menu: MenuItem[] = [];
  deepLink = '';
  outlookSyncInstalled = false;
  outlookSyncFeatureFlagEnabled = false;
  showRemindLater = false;
  startOnboarding = false;
  supportedOnboardingPage = true;
  mailboxAccount = this.outlookService.mailbox.userProfile.emailAddress;
  hideMsalMismatchDialog = false;
  hideMsalLoginDialog = false;
  openSyncSettings = false;

  hasActiveAccount: Signal<boolean> = computed(() =>
    this.outlookStore.hasActiveAccount(),
  );

  activeAccountMatchEffect = effect(() => {
    if (!this.outlookStore.hasActiveAccount()) return;

    if (this.mailboxAccount === this.outlookStore.graphMailAccount()) {
      this.outlookStore.setDoesActiveAccountMatch(true);
    } else {
      this.outlookStore.setDoesActiveAccountMatch(false);
    }
  });

  constructor(
    private readonly contextService: ContextService,
    private readonly session: SessionService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly globalStore: GlobalStore,
    private readonly oAuthService: OAuth2Service,
    private readonly sessionStorage: SessionStorageService,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly domSanitizer: DomSanitizer,
    private readonly outlookSyncService: OutlookSyncService,
    private readonly outlookService: OutlookService,
    private readonly msalService: OutlookMsalService,
    public onboardingStore: OnboardingStore,
    public feature: FeatureService,
    public outlookStore: OutlookStore,
  ) {
    const showOnboarding = this.feature.isFeatureOn(
      'integration-o365-plg-onboarding-shown',
      false,
    );
    this.onboardingStore.setOnboardingVisibility(showOnboarding);

    this.outlookSyncFeatureFlagEnabled = feature.isFeatureOn(
      'microsoft-outlook-sync-emails-shown',
      false,
    );
  }

  ngOnInit(): void {
    const currentPath = this.getCurrentPath();
    const isOutlookLegacy = this.outlookService.isOutlookLegacyDesktop();
    this.mailboxAccount = this.outlookService.mailbox.userProfile.emailAddress;

    this.initializeMenuItems();
    this.getOutlookSyncSettings();

    if (currentPath === 'login' || this.outlookService.isCompose) {
      this.supportedOnboardingPage = false;
    }

    if (!(isOutlookLegacy && this.outlookService.isCompose)) {
      this.initializeMsalService();
    }

    if (this.contextService.token) {
      const session = this.globalStore.session();
      if (session?.alias) {
        this.populateDeeplink(session.alias);
      } else {
        this.session.getInfo().subscribe((data) => {
          this.populateDeeplink(data.alias);
        });
      }
    }
  }

  async initializeMsalService(): Promise<void> {
    this.hideMsalMismatchDialog =
      localStorage.getItem('hideMismatchMessage') === 'true';

    this.hideMsalLoginDialog =
      localStorage.getItem('hideLoginMessage') === 'true';

    await this.msalService.initializeMsal();

    this.msalService.checkAndSetActiveAccount();

    if (this.hideMsalLoginDialog && !this.hasActiveAccount()) {
      this.msalService.loginPopup();
    }
  }

  private initializeMenuItems(): void {
    if (this.showOnlyTitle) {
      this.hideBack = true;
      return;
    }

    this.menu = [
      {
        id: MenuType.Settings,
        text: this.translate.instant('outlook.menu.settings'),
        icon: 'fa-icons icon-14 icon-gear',
        click: () => (this.openSyncSettings = true),
        disabled: false,
      },
      {
        id: MenuType.Refresh,
        text: this.translate.instant('outlook.menu.refresh'),
        icon: 'fa-icons icon-solid icon-14 icon-refresh',
        click: () => this.refreshWindow(),
      },
      {
        id: MenuType.Maximizer,
        text: this.translate.instant('outlook.menu.maximizer'),
        icon: 'fa-icons icon-solid icon-14 icon-arrow-up-right-from-square',
        click: () => {
          if (!this.deepLink) this.deepLink = 'https://www.maximizer.com';
          window.open(this.deepLink, '_blank', 'noopener');
        },
      },
      {
        id: MenuType.Guide,
        text: this.translate.instant('outlook.menu.guide'),
        icon: 'fa-icons icon-14 icon-book',
        click: () => {
          window.open(this.userGuideUrl, '_blank', 'noopener');
        },
      },
      {
        id: MenuType.Logout,
        text: this.translate.instant('outlook.menu.logout'),
        icon: 'fa-icons icon-solid icon-14 icon-right-from-bracket',
        click: () => {
          this.logout();
        },
      },
      {
        id: MenuType.AppVersion,
        text: `${this.translate.instant('outlook.menu.appVersion')} ${this.contextService.version}`,
        class: 'app-version',
        disabled: true,
      },
      {
        id: MenuType.Database,
        text: `${this.translate.instant('outlook.menu.database')} ${this.contextService.alias}`,
        class: 'database',
        disabled: true,
      },
    ];

    this.menuItemSettings();
  }

  private menuItemSettings(): void {
    if (this.hideRefresh) {
      this.removeMenuItem(MenuType.Refresh);
    }

    if (this.hideLogout) {
      this.removeMenuItem(MenuType.Logout);
    }
  }

  private removeMenuItem(menuItem: MenuType): void {
    this.menu = [...this.menu.filter((item) => item.id !== menuItem)];
  }

  private getCurrentPath(): string | undefined {
    let route = this.route.snapshot;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.routeConfig?.path;
  }

  private getOutlookSyncSettings(): void {
    const session = this.globalStore.session();

    if (
      session &&
      this.outlookSyncFeatureFlagEnabled &&
      this.outlookStore.versionValidForNewSync()
    ) {
      this.outlookSyncService
        .getConfiguration(session.user.id, session.workspace)
        .subscribe((config) => {
          this.outlookSyncInstalled = !!config?.enabled;

          if (!this.outlookSyncInstalled) {
            this.removeMenuItem(MenuType.Settings);
          }
        });
    } else {
      this.outlookSyncInstalled = false;
      this.removeMenuItem(MenuType.Settings);
    }
  }

  private populateDeeplink(alias: string): void {
    const urlArray = this.contextService.website.split('/');
    const domainIndex = urlArray.findIndex((o) =>
      o.toLocaleLowerCase().includes('maximizer'),
    );
    if (domainIndex == -1) return;
    let domain = '';
    for (let i = 0; i <= domainIndex; i++) {
      domain += urlArray[i] + '/';
    }
    const accountLink = domain?.replace('1', 'w') + alias;
    switch (this.pageType) {
      case 'lead':
        this.deepLink = `${accountLink}?sslead=KEY(${this.id})`;
        break;
      case 'abentry':
        this.deepLink = `${accountLink}?ss=KEY(${this.id})`;
        break;
      default:
        this.deepLink = accountLink;
        break;
    }
  }

  private isRouteValid(path: string): boolean {
    const configuredPaths = this.router.config.map((route) => `/${route.path}`);
    return configuredPaths.includes(path);
  }

  goBack(): void {
    if (this.backRoutePath) {
      if (this.isRouteValid(this.backRoutePath)) {
        this.router.navigate([this.backRoutePath]);
      } else {
        this.router.navigate(['/home']);
      }
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  logout(): void {
    this.oAuthService.clearAuth();
    this.session.clearSessionCache();
    this.contextService.clearAuthentication();
    this.sessionStorage.clear();

    const loginPath = '/login';
    const destinationUrl = window.location.origin + loginPath;

    if (destinationUrl.startsWith(window.location.origin)) {
      window.location.href = destinationUrl;
    }
  }

  private refreshWindow(): void {
    if (this.forceWindowRefresh) {
      const destinationUrl = `${window.location.origin}${window.location.pathname}`;
      window.location.href = destinationUrl;
      return;
    }

    const path = this.router.url.split('?')[0];
    const queryParamStr = this.router.url.split('?')[1];
    const queryParams = queryParamStr ? queryParamStr.split('&') : [];

    const url = self
      ? decodeURIComponent(this.router.url)
      : decodeURIComponent(this.location.path());
    const refreshUrl = url === '/' ? '../' : '/';

    this.router
      .navigateByUrl(refreshUrl, { skipLocationChange: false })
      .then(() => {
        const pathDecomposed = decodeURIComponent(path);
        const sanitizedQueryParams = this.sanitizeQueryParams(queryParams);
        const record = Object.fromEntries(
          sanitizedQueryParams.map((item) => {
            const [key, value] = item.split('=');
            return [key, decodeURIComponent(value)];
          }),
        ) as Record<string, string>;
        this.router.navigate([pathDecomposed], { queryParams: record });
      });
  }

  private sanitizeQueryParams(queryParams: string[]): string[] {
    return queryParams.map((param) => {
      const [key, value] = param.split('=');
      const sanitizedKey =
        this.domSanitizer.sanitize(SecurityContext.URL, key) || '';
      const sanitizedValue =
        this.domSanitizer.sanitize(SecurityContext.URL, value) || '';
      return `${sanitizedKey}=${sanitizedValue}`;
    });
  }

  handleOnboardingAction(action: InitOnboardingAction): void {
    this.startOnboarding = false;

    if (action === 'start') {
      this.onboardingStore.setOnboardingInProgress(true);

      const currentPath = this.getCurrentPath();

      if (currentPath !== 'read-email') {
        this.router.navigate(['/home']);
      } else {
        this.initFirstOnboardingStep.emit('start');
      }
    } else {
      this.showRemindLater = true;
      this.onboardingStore.setOnboardingInProgress(false);
    }
  }
}
