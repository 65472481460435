/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormArray, FormGroup } from '@angular/forms';
import { FormControls, ValidationResult, ValidationErrors } from './form-types';

export class FormGroupWrapper<T> {
  private submitCount = 0;
  private isSubmitted = false;

  constructor(
    public control: FormGroup,
    public controls: FormControls<T>,
    private onSubmit?: (isFirstSubmit: boolean) => void,
    onChanges?: (value: T) => void,
  ) {
    if (onChanges) {
      this.control.valueChanges.subscribe(onChanges);
    }
  }

  get group(): FormGroup {
    return this.control;
  }

  get valid(): boolean {
    return this.control.valid;
  }

  get submitted(): boolean {
    return this.isSubmitted;
  }

  get value(): T {
    return this.control.value;
  }

  set value(value: T) {
    this.control.setValue(value as any);
  }

  get errors(): ValidationErrors<T> {
    return this.getErrors();
  }

  public getErrors(): ValidationErrors<T> {
    const errors: ValidationErrors<T> = {};
    for (const name in this.controls) {
      const control = this.controls[name];
      errors[name as keyof T] = control.errors as ValidationErrors<T>[keyof T];
    }
    return errors;
  }

  public validate(): ValidationResult<T> {
    let errors: ValidationErrors<T> = {};

    if (!this.control.valid) {
      this.markAsDirty();
      errors = this.getErrors();
    }

    return { valid: this.control.valid, errors };
  }

  public submit(): void {
    this.isSubmitted = true;
    this.submitCount += 1;
    if (this.onSubmit) {
      this.onSubmit(this.submitCount === 1);
    }
  }

  public validateAndSubmit(): ValidationResult<T> {
    const result = this.validate();
    if (result.valid) {
      this.submit();
    }
    return result;
  }

  public reset(): void {
    for (const name in this.controls) {
      const control = this.controls[name];
      control.reset();
    }
    this.isSubmitted = false;
    this.submitCount = 0;
    this.resetStatus();
  }

  public patch(
    value: Partial<T>,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ) {
    this.control.patchValue(value, options);
  }

  private markAsDirty(
    group: FormGroup | FormArray | undefined = this.control,
  ): void {
    if (group == null) {
      return;
    }

    for (const name in group.controls) {
      if (group.get(name)?.invalid) {
        const control = group.get(name);
        control?.markAsDirty();
        if (control instanceof FormGroup || control instanceof FormArray) {
          this.markAsDirty(control);
        }
      }
    }
  }

  private resetStatus(
    group: FormGroup | FormArray | undefined = this.control,
  ): void {
    if (group == null) {
      return;
    }

    for (const name in group.controls) {
      if (group.get(name)?.invalid) {
        const control = group.get(name);
        control?.markAsPristine();
        control?.markAsUntouched();
        control?.setErrors(null);
        control?.updateValueAndValidity();

        if (control instanceof FormGroup || control instanceof FormArray) {
          this.resetStatus(control);
        }
      }
    }
  }
}
