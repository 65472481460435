import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContextService } from '@maximizer/core/shared/data-access';
import { EntryType, Octopus } from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import {
  OutlookTimelineConfiguration,
  OutlookTimelineEvent,
  OutlookTimelineFilter,
  TimelineConfigurationMapper,
  TimelineMapper,
} from '@maximizer/outlook/shared/domain';

@Injectable()
export class TimelineService {
  constructor(
    private http: HttpClient,
    private context: ContextService,
  ) {}

  readonly fields: Octopus.Scope<Octopus.Timeline> = {
    Key: 1,
    Type: {
      Key: 1,
      DisplayValue: 1,
      Icon: 1,
    },
    Subject: 1,
    Description: 1,
    AbEntries: [
      {
        Key: 1,
        DisplayValue: 1,
      },
    ],
    Leads: [
      {
        Key: 1,
        DisplayValue: 1,
      },
    ],
    Users: [
      {
        Key: 1,
        DisplayValue: 1,
      },
    ],
    NoteType: {
      Value: 1,
      DisplayValue: 1,
    },
    DocumentType: {
      Value: 1,
      DisplayValue: 1,
    },
    StartDate: {
      Value: 1,
      DisplayValue: 1,
    },
    Creator: {
      Value: 1,
      DisplayValue: 1,
    },
    Important: 1,
    Direction: 1,
  };

  private getFilterQuery({
    interactions,
    notes,
  }: OutlookTimelineFilter): Octopus.LogicalQuery<Octopus.Timeline> {
    const query: Octopus.LogicalQuery<Octopus.Timeline> = {
      $OR: [],
    };

    if (interactions?.length) {
      query.$OR?.push({
        Type: {
          $IN: interactions,
        },
      });
    }

    if (notes?.length) {
      query.$OR?.push({
        NoteType: {
          $IN: notes,
        },
      });
    }

    return query;
  }

  private getCriteria(
    filter: OutlookTimelineFilter,
    key?: string,
    type?: EntryType,
    showRelated = false,
  ): Octopus.Criteria<Octopus.Timeline> {
    const filterQuery = this.getFilterQuery(filter);

    const query: Octopus.LogicalQuery<Octopus.Timeline> = {
      $AND: [],
    };

    if (type && key) {
      switch (type) {
        case 'addressBook':
          if (showRelated) {
            query.$AND?.push({
              'AbEntries/Key': {
                $EQ: {
                  Body: key,
                  OrRelated: true,
                },
              },
            });
          } else {
            query.$AND?.push({
              'AbEntries/$item/Key': {
                $EQ: key,
              },
            });
          }

          break;

        case 'lead':
          query.$AND?.push({
            'Leads/$item/Key': {
              $EQ: key,
            },
          });
          break;
        default:
          break;
      }
    }
    if ((filterQuery?.$OR ?? []).length > 0) {
      query.$AND?.push(filterQuery);
    }

    return {
      SearchQuery: query,
    };
  }

  forEntry(
    key: string,
    type: EntryType,
    filter: OutlookTimelineFilter,
    showRelated = false,
  ): Observable<OutlookTimelineEvent[]> {
    const request: Octopus.TimelineReadRequest = {
      Timeline: {
        Scope: { Fields: this.fields },
        Criteria: this.getCriteria(filter, key, type, showRelated),
        OrderBy: { Fields: [{ StartDate: 'DESC' }] },
      },
    };

    return this.http
      .post<Octopus.TimelineResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.Timeline?.Data
          ) {
            const mapper = new TimelineMapper();
            return mapper.getEvents(result.Timeline.Data);
          }
          return [];
        }),
      );
  }

  getConfiguration(): Observable<OutlookTimelineConfiguration | null> {
    const request: Octopus.OutlookTimelineFieldOptionsRequest = {
      Timeline: {
        FieldOptions: {
          Type: [
            {
              Key: 1,
              Icon: 1,
              DisplayValue: 1,
            },
          ],
        },
      },
    };

    return this.http
      .post<Octopus.TimelineFieldOptionsResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.Timeline
          ) {
            const mapper = new TimelineConfigurationMapper();
            return mapper.from(result);
          }
          return null;
        }),
      );
  }
}
