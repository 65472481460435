import { NgModule } from '@angular/core';
import {
  DatePickerModule,
  DateTimePickerModule,
  TimePickerModule,
} from '@progress/kendo-angular-dateinputs';
import {
  AutoCompleteModule,
  ComboBoxModule,
  DropDownListModule,
  DropDownTreesModule,
  MultiSelectModule,
} from '@progress/kendo-angular-dropdowns';
import { ICON_SETTINGS } from '@progress/kendo-angular-icons';
import {
  NumericTextBoxModule,
  TextAreaModule,
  TextBoxModule,
} from '@progress/kendo-angular-inputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import {
  KendoDialogDirective,
  KendoInputDirective,
  KendoMenuPopupDirective,
  KendoPopupDirective,
  KendoPopupSettingsDirective,
  KendoTooltipPopupDirective,
  KendoMultiSelectTreeDirective,
} from './directives';
import { KendoService } from './services/kendo.service';
import { MenuModule } from '@progress/kendo-angular-menu';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IntlModule } from '@progress/kendo-angular-intl';

@NgModule({
  imports: [
    AutoCompleteModule,
    ComboBoxModule,
    DatePickerModule,
    DateTimePickerModule,
    DropDownListModule,
    DropDownTreesModule,
    IntlModule,
    NumericTextBoxModule,
    MenuModule,
    MultiSelectModule,
    PopupModule,
    TextAreaModule,
    TextBoxModule,
    TimePickerModule,
    TooltipModule,
  ],
  exports: [
    KendoMenuPopupDirective,
    KendoTooltipPopupDirective,
    KendoPopupDirective,
    KendoPopupSettingsDirective,
    KendoDialogDirective,
    KendoInputDirective,
    KendoMultiSelectTreeDirective,
  ],
  declarations: [
    KendoMenuPopupDirective,
    KendoTooltipPopupDirective,
    KendoPopupDirective,
    KendoPopupSettingsDirective,
    KendoDialogDirective,
    KendoInputDirective,
    KendoMultiSelectTreeDirective,
  ],
  providers: [
    KendoService,
    { provide: ICON_SETTINGS, useValue: { type: 'font' } },
  ],
})
export class KendoModule {}
