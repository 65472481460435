import { isObject } from '@maximizer/shared/util';
import { parseISO } from 'date-fns';
import { Mapper } from '../../../mappers/mapper';
import { EntityCount, Field } from '../../../models';
import {
  CurrencyAndDisplay,
  DateAndDisplay,
  NumberAndDisplay,
  StringAndDisplay,
  StringArrayAndDisplay,
} from '../../../types';
import {
  AbEntry,
  AbEntryType,
  CurrencyValue,
  Entity,
  FieldType,
  KeyAndDisplay,
  EntityCount as OctopusEntityCount,
  ValueAndDisplay,
} from '../models';

export class EntityCountMapper extends Mapper<OctopusEntityCount, EntityCount> {
  from(source: OctopusEntityCount): EntityCount {
    return {
      count: source['$Count(Key)'],
    };
  }
}

export abstract class EntityMapper<Source, Target> extends Mapper<
  Source,
  Target
> {
  mapStringAndDisplay(
    source: Partial<ValueAndDisplay<string> & KeyAndDisplay>,
  ): StringAndDisplay {
    return {
      value: source?.Key ?? source?.Value ?? '',
      display: source?.DisplayValue ?? '',
    };
  }

  mapNumberAndDisplay(source: ValueAndDisplay<number>): NumberAndDisplay {
    return {
      value: source?.Value ?? null,
      display: source?.DisplayValue ?? '',
    };
  }

  mapDateAndDisplay(source: ValueAndDisplay<string>): DateAndDisplay {
    return {
      value: source?.Value ? parseISO(source.Value) : null,
      display: source?.DisplayValue ?? '',
    };
  }

  mapCurrencyAndDisplay(source: CurrencyValue): CurrencyAndDisplay {
    return {
      value: source?.Value ?? null,
      display: source?.DisplayValue ?? '',
      currencyCode: source?.CurrencyCode ?? '',
    };
  }

  mapCompanyOrIndividual(entry?: AbEntry): string {
    if (entry) {
      switch (entry.Type) {
        case AbEntryType.Company:
          return entry.CompanyName;
        case AbEntryType.Individual:
          return `${entry.FirstName} ${entry.LastName}`.trim();
      }
    }

    return '';
  }

  mapCollection(source: Entity): StringArrayAndDisplay {
    const items = (source?.['Items'] as KeyAndDisplay[]) ?? [];
    return {
      value: items.map((item) => item.Key) ?? [],
      display: items.map((item) => item.DisplayValue).join(', ') ?? '',
    };
  }

  mapFields(source: Entity, fields: Field[]): Entity {
    return fields.reduce((entity, field) => {
      switch (field.metadata?.type) {
        case FieldType.DateTimeField:
          entity[field.id] = this.mapDateAndDisplay(
            source[field.id] as ValueAndDisplay<string>,
          );
          break;
        case FieldType.CurrencyField:
          entity[field.id] = this.mapCurrencyAndDisplay(
            source[field.id] as CurrencyValue,
          );
          break;
        case FieldType.NumericField:
          entity[field.id] = this.mapNumberAndDisplay(
            source[field.id] as ValueAndDisplay<number>,
          );
          break;
        case FieldType.EnumFieldOfPartnerCompetitor:
          entity[field.id] = this.mapCollection(source[field.id] as Entity);
          break;
        default:
          if (isObject(source[field.id])) {
            entity[field.id] = this.mapStringAndDisplay(
              source[field.id] as ValueAndDisplay<string>,
            );
          } else {
            entity[field.id] = {
              value: source[field.id] ?? '',
              display: source[field.id] ?? '',
            } as StringAndDisplay;
          }
      }
      return entity;
    }, {} as Entity);
  }
}
