import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  Field,
  LayoutElement,
  LayoutElementType,
} from '@maximizer/core/shared/domain';
import { DisposableComponent } from '../../components/base/disposable.component';
import { LayoutFormControl } from '../classes/layout-form-control';
import { FormGroupDirective } from '@angular/forms';
import { LayoutFormGroup } from '../classes';

@Component({
  selector: 'maximizer-layout-element',
  template: `
    <ng-container [ngSwitch]="element.type">
      <ng-container *ngSwitchCase="'group'">
        <p
          *ngIf="element.name"
          class="px-4 py-2 bg-neutral-5 subtitle-2 rounded-t"
        >
          {{ element.name }}
        </p>
        <div class="flex flex-col gap-4 p-4 ml-2">
          <ng-container [ngTemplateOutlet]="elements"></ng-container>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'field'">
        <maximizer-dynamic-field
          *ngIf="field"
          [field]="field"
        ></maximizer-dynamic-field>
      </ng-container>

      <ng-container *ngSwitchCase="'separator'">
        <span *ngIf="element.name">{{ element.name }}</span>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container [ngTemplateOutlet]="elements"></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #elements>
      <ng-container *ngFor="let element of element.elements">
        <maximizer-layout-element
          [element]="element"
        ></maximizer-layout-element>
      </ng-container>
    </ng-template>
  `,
  styles: [
    `
      :host {
        @apply flex w-full;
      }

      :host ::ng-deep {
        kendo-formfield {
          @apply flex m-0;

          kendo-label + div {
            @apply flex-grow;
          }

          &.left {
            @apply flex-row items-center gap-2;

            kendo-label {
              @apply w-1/4 min-w-[6rem];

              + div {
                @apply w-3/4 max-w-[calc(100%-6rem)];
              }
            }

            &.k-form-field-error {
              @apply items-baseline;
            }

            &.narrow {
              kendo-label {
                @apply w-auto min-w-[4.75rem] max-w-1/5;

                + div {
                  @apply w-4/5 max-w-[calc(100%-4.75rem)];
                }
              }
            }
          }

          &.top {
            @apply flex-col gap-1;
          }

          &.none {
            kendo-label {
              @apply hidden;
            }
          }
        }
      }
    `,
  ],
})
export class LayoutElementComponent
  extends DisposableComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input({ required: true })
  element!: LayoutElement;

  @Output() loaded = new EventEmitter<void>();

  readonly hostClass: Record<LayoutElementType, string> = {
    column: 'flex-col gap-4',
    field: '',
    group: 'flex-col border rounded-sm border-neutral-20',
    row: 'flex-row gap-4',
    separator: 'border-b border-neutral-20 my-2 subtitle-1',
  };

  field?: Field;

  constructor(
    private host: ElementRef,
    private form: FormGroupDirective,
  ) {
    super();
  }

  ngOnChanges(): void {
    this.setHostClass();
    this.addToControl();
  }

  ngAfterViewInit(): void {
    this.loaded.emit();
    this.checkVisibility(this.element);
  }

  show(): void {
    this.host.nativeElement.classList.remove('hidden');
  }

  hide(): void {
    this.host.nativeElement.classList.add('hidden');
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.removeFromControl();
  }

  private addToControl() {
    if (!this.element.field) return;

    const control = this.form.control.get(this.element.field);

    if (control instanceof LayoutFormControl) {
      this.setUpControl(control);
    } else if (
      this.element.field.indexOf('/') > -1 &&
      !this.element.field.startsWith('Udf')
    ) {
      this.getControlFromParent(this.element.field);
    } else {
      this.hide();
    }
  }

  private getControlFromParent(field: string): void {
    const [parent, key] = field.split('/');
    const parentControl = this.form.control.get(parent);
    if (parentControl instanceof LayoutFormGroup) {
      const childControl = parentControl.getControl(key);
      if (childControl instanceof LayoutFormControl) {
        this.setUpControl(childControl);
        return;
      }
    }
    this.hide();
  }

  private setUpControl(control: LayoutFormControl) {
    this.field = control.field;
    control.addElement(this);
    if (control.hidden) {
      this.hide();
    }
  }

  private removeFromControl() {
    if (this.element.field) {
      const control = this.form.control.get(this.element.field);
      if (control instanceof LayoutFormControl) {
        control.removeElement(this);
      }
    }
  }

  private setHostClass(): void {
    const hostClass = this.hostClass[this.element.type];
    const classes = hostClass ? hostClass.split(' ') : [];

    if (this.element.properties?.cssClass) {
      classes.push(...this.element.properties.cssClass.split(' '));
    }

    if (classes.length > 0) {
      this.host.nativeElement.classList.add(...classes);
    }
  }

  private checkVisibility(element: LayoutElement): void {
    if (
      element.type === 'group' ||
      element.type === 'column' ||
      element.type === 'row'
    ) {
      if (!this.isVisible(element)) {
        this.hide();
      }
    }
  }

  private isVisible(element: LayoutElement): boolean {
    if (element.elements) {
      return element.elements.some((childElement) =>
        this.isVisible(childElement),
      );
    }
    if (element.field) {
      const control = this.form.control.get(element.field);
      if (control instanceof LayoutFormControl) {
        return !control.hidden;
      }
    }
    return false;
  }
}
