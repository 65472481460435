import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import {
  RadioButtonModule,
  SwitchModule,
  TextBoxModule,
} from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { defaultTranslateConfiguration } from '../../config';
import { KendoModule } from '../../kendo';
import { UiPipesModule } from '../../pipes';
import { UiFieldPickerModule } from '../field-picker/field-picker.module';
import { UiLoaderModule } from '../loader';
import { UiSorteableListModule } from '../sorteable-list/sorteable-list.module';
import { CardLayoutCustomizationComponent } from './card-layout-customization.component';
import { CardLayoutAddFieldComponent } from './components/card-layout-add-field/card-layout-add-field.component';
import { CardLayoutEditorComponent } from './components/card-layout-editor/card-layout-editor.component';
import { CardLayoutCustomizationStore } from './store/card-layout-customization.store';

@NgModule({
  exports: [CardLayoutCustomizationComponent],
  declarations: [
    CardLayoutCustomizationComponent,
    CardLayoutEditorComponent,
    CardLayoutAddFieldComponent,
  ],
  providers: [CardLayoutCustomizationStore],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SwitchModule,
    SortableModule,
    KendoModule,
    DialogModule,
    UiPipesModule,
    ButtonsModule,
    UiLoaderModule,
    TooltipsModule,
    ReactiveFormsModule,
    ListViewModule,
    LabelModule,
    RadioButtonModule,
    DropDownsModule,
    TextBoxModule,
    TranslateModule.forChild({
      ...defaultTranslateConfiguration,
      extend: true,
    }),
    UiSorteableListModule,
    UiFieldPickerModule
  ],
})
export class UiCardLayoutCustomizationModule {}
