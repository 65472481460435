<maximizer-outlook-menu
  [id]="id"
  [pageType]="'abentry'"
  [pageTitle]="'outlook.opportunity.add-title' | translate"
></maximizer-outlook-menu>
<div class="flex flex-col h-full scrollbar">
  <maximizer-loader *ngIf="loading" type="legacy"></maximizer-loader>
  <form
    *ngIf="form"
    class="flex flex-col p-4 text-xs space-y-2"
    [formGroup]="form.group"
  >
    <kendo-formfield>
      <kendo-label
        [for]="objective"
        [text]="'outlook.opportunity.name' | translate"
        >*
      </kendo-label>

      <kendo-textbox
        formControlName="objective"
        #objective
        [maxlength]="253"
        required
      ></kendo-textbox>
      <kendo-formerror>{{
        'outlook.forms.requiredField' | translate
      }}</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [text]="'outlook.opportunity.description' | translate"
      ></kendo-label>
      <kendo-textarea
        [rows]="6"
        resizable="vertical"
        formControlName="description"
      ></kendo-textarea>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [text]="'outlook.opportunity.process' | translate"
      ></kendo-label>
      <kendo-dropdownlist
        [data]="configuration.process"
        (valueChange)="handleProcessChange($event)"
        valueField="id"
        textField="name"
        fillMode="solid"
        formControlName="processId"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [text]="'outlook.opportunity.stage' | translate"
      ></kendo-label>
      <kendo-dropdownlist
        [data]="configuration.stages"
        valueField="id"
        textField="name"
        fillMode="solid"
        formControlName="stageId"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [for]="closeDate"
        [text]="'outlook.opportunity.estimatedCloseDate' | translate"
      >
      </kendo-label>
      <kendo-datepicker
        #closeDate
        formControlName="closeDate"
        placeholder=""
        [format]="fullFormat"
        calendarType="classic"
      >
      </kendo-datepicker>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [for]="companyName"
        [text]="'outlook.opportunity.companyIndividual' | translate"
      >
      </kendo-label>

      <kendo-textbox
        formControlName="companyName"
        #companyName
        [readonly]="true"
        class="bg-neutral-10"
      ></kendo-textbox>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [for]="primaryContact"
        [text]="'outlook.opportunity.primaryContact' | translate"
      >
      </kendo-label>

      <kendo-autocomplete
        [filterable]="true"
        (filterChange)="handlePrimaryContactFilterChange($event)"
        (valueChange)="handlePrimaryContactValueChange($event)"
        [data]="abEntryList"
        formControlName="abEntryName"
        [loading]="autocompleteLoading"
        #primaryContact
        valueField="name"
      >
      </kendo-autocomplete>
    </kendo-formfield>

    <kendo-formfield
      *ngIf="
        form.value.status === opportunityStatus.Won;
        else forecastRevenueField
      "
    >
      <kendo-label
        [for]="actualRevenue"
        [text]="'outlook.opportunity.revenue' | translate"
      ></kendo-label>

      <kendo-numerictextbox
        formControlName="actualRevenue"
        format="#.##"
        #actualRevenue
        [spinners]="false"
        [changeValueOnScroll]="false"
      ></kendo-numerictextbox>
    </kendo-formfield>
    <ng-template #forecastRevenueField>
      <kendo-formfield>
        <kendo-label
          [for]="forecastRevenue"
          [text]="'outlook.opportunity.forecastRevenue' | translate"
        ></kendo-label>

        <kendo-numerictextbox
          formControlName="forecastRevenue"
          format="#.##"
          #forecastRevenue
          [spinners]="false"
          [changeValueOnScroll]="false"
        ></kendo-numerictextbox>
      </kendo-formfield>
    </ng-template>
    <kendo-formfield>
      <kendo-label
        [for]="cost"
        [text]="'outlook.opportunity.cost' | translate"
      ></kendo-label>

      <kendo-numerictextbox
        formControlName="cost"
        format="#.##"
        #cost
        [spinners]="false"
        [changeValueOnScroll]="false"
      ></kendo-numerictextbox>
    </kendo-formfield>

    <kendo-formfield>
      <kendo-label
        [text]="'outlook.opportunity.status' | translate"
      ></kendo-label>
      <kendo-dropdownlist
        [data]="configuration.statuses"
        textField="name"
        valueField="id"
        fillMode="solid"
        formControlName="status"
        [valuePrimitive]="true"
        (valueChange)="handleStatusChange($event)"
      >
      </kendo-dropdownlist>
    </kendo-formfield>
  </form>

  <div
    class="bg-neutral-5 z-40 mt-auto flex space-x-2 p-2 w-full bottom-0 sticky"
  >
    <button
      kendoButton
      class="uppercase rounded ml-auto"
      themeColor="primary"
      [disabled]="!canSave"
      (click)="save()"
    >
      <span *ngIf="!loading; else loader"
        >{{ 'outlook.buttons.save' | translate }}
      </span>
      <ng-template #loader>
        <kendo-loader type="pulsing" themeColor="primary" size="small">
        </kendo-loader>
      </ng-template>
    </button>
    <button
      kendoButton
      class="uppercase rounded"
      themeColor="primary"
      (click)="cancel()"
      [disabled]="loading"
      fillMode="flat"
    >
      {{ 'outlook.buttons.cancel' | translate }}
    </button>
  </div>
</div>

<maximizer-outlook-notification
  #notification
  [closable]="true"
  entryId="opportunity"
>
</maximizer-outlook-notification>
