export class NoteTypes {
  static readonly MANUAL = 0;
  static readonly MAIL_OUT = 1;
  static readonly CALL_OUT = 2;
  static readonly TIMED = 3;
  static readonly TRANSFER = 4;
  static readonly TASK = 5;
  static readonly EMAIL = 7;
  static readonly OPPORTUNITY = 8;
  static readonly HISTORY = 9;
  static readonly OTHER = 11;
  static readonly CASE = 12;
  static readonly ACCOUNTING = 13;
  static readonly CALL_IN = 14;
}

export const NoteIcons: { [value: number]: string } = {
  [NoteTypes.MANUAL]: 'note-manual',
  [NoteTypes.MAIL_OUT]: 'note-mail-out',
  [NoteTypes.CALL_OUT]: 'note-call-out',
  [NoteTypes.TIMED]: 'note-timed',
  [NoteTypes.TRANSFER]: 'note-transfer',
  [NoteTypes.TASK]: 'note-task',
  [NoteTypes.EMAIL]: 'note-email',
  [NoteTypes.OPPORTUNITY]: 'note-opportunity',
  [NoteTypes.HISTORY]: 'note-history',
  [NoteTypes.OTHER]: 'note-other',
  [NoteTypes.CASE]: 'note-case',
  [NoteTypes.ACCOUNTING]: 'note-accounting',
  [NoteTypes.CALL_IN]: 'note-call-in',
};
