import { PipelineTemplate } from '@maximizer/core/shared/domain';

export const PipelineTemplateA: PipelineTemplate = {
  processName: 'Proven Sales Process',
  description:
    'Timeless approach driving consistent results and business growth',
  image: 'maximizer-knows-best.svg',
  stages: [
    {
      id: null,
      processId: null,
      name: 'Prospecting/Lead Generation',
      probability: 10,
      targetAge: 10,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Qualification',
      probability: 20,
      targetAge: 5,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Needs Analysis',
      probability: 40,
      targetAge: 7,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Solution Presentation',
      probability: 50,
      targetAge: 3,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Objection Handling',
      probability: 70,
      targetAge: 5,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Negotiation',
      probability: 90,
      targetAge: 7,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Closing',
      probability: 95,
      targetAge: 2,
      coaching: { links: [] },
    },
  ],
};

export const PipelineTemplateB: PipelineTemplate = {
  processName: 'Challenger Growth Cycle',
  description: 'Drive Growth through Challenging Thinking',
  image: 'challenger-growth-cycle.svg',
  stages: [
    {
      id: null,
      processId: null,
      name: 'Prospect',
      probability: 15,
      targetAge: 35,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Connect',
      probability: 20,
      targetAge: 10,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Understand',
      probability: 30,
      targetAge: 20,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Teach',
      probability: 40,
      targetAge: 15,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Tailor',
      probability: 50,
      targetAge: 10,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Take Control',
      probability: 60,
      targetAge: 10,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Close',
      probability: 70,
      targetAge: 10,
      coaching: { links: [] },
    },
  ],
};

export const PipelineTemplateC: PipelineTemplate = {
  processName: 'Challenger Renewal Cycle',
  description: 'Igniting Growth Through Innovation',
  image: 'challenger-renewal-cycle.svg',
  stages: [
    {
      id: null,
      processId: null,
      name: 'Assess',
      probability: 20,
      targetAge: 10,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Educate',
      probability: 30,
      targetAge: 7,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Reframe',
      probability: 40,
      targetAge: 14,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Align',
      probability: 50,
      targetAge: 7,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Negotiate',
      probability: 60,
      targetAge: 5,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Activate',
      probability: 70,
      targetAge: 3,
      coaching: { links: [] },
    },
  ],
};

export const PipelineTemplateD: PipelineTemplate = {
  processName: 'MEDDIC Sales Process',
  description: 'Effective Sales Process: MEDDIC Methodology Simplified',
  image: 'meddic-sales-process.svg',
  stages: [
    {
      id: null,
      processId: null,
      name: 'Metrics',
      probability: 10,
      targetAge: 5,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Economic Buyer',
      probability: 20,
      targetAge: 7,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Decision Criteria',
      probability: 30,
      targetAge: 10,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Decision Process',
      probability: 40,
      targetAge: 7,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Identify Pain',
      probability: 50,
      targetAge: 15,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Champion',
      probability: 60,
      targetAge: 10,
      coaching: { links: [] },
    },
  ],
};

export const PipelineTemplateE: PipelineTemplate = {
  processName: 'Solution Selling',
  description:
    'Effective Solution Selling: Boosting Sales with Tailored Strategies',
  image: 'solution-selling.svg',
  stages: [
    {
      id: null,
      processId: null,
      name: 'Prospecting',
      probability: 10,
      targetAge: 14,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Initial Contact',
      probability: 20,
      targetAge: 7,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Qualification',
      probability: 30,
      targetAge: 10,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Needs Assessment',
      probability: 40,
      targetAge: 14,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Solution Presentation',
      probability: 50,
      targetAge: 7,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Handling Objections',
      probability: 60,
      targetAge: 7,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Proposal and Negotiation',
      probability: 70,
      targetAge: 14,
      coaching: { links: [] },
    },
    {
      id: null,
      processId: null,
      name: 'Closing',
      probability: 80,
      targetAge: 7,
      coaching: { links: [] },
    },
  ],
};
