import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tabs } from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';
import { ContextService } from './context.service';

declare const LOAD_UID: string;

type TabsConfiguration = { [module: string]: Tabs[] };

@Injectable()
export class TabsService {
  tabs: Tabs[] = [];

  constructor(private http: HttpClient, private context: ContextService) {}

  public ReadTabsConfiguration(module: string): Observable<Tabs[]> {
    this.tabs = [];
    return this.GetTabsJson().pipe(
      map((response) => {
        if (response[module]) {
          const items = response[module];
          items.forEach((element: Tabs) => {
            const tab = element;
            this.tabs.push(tab);
          });
        }
        return this.tabs;
      })
    );
  }
  private GetTabsJson(): Observable<TabsConfiguration> {
    const version = this.context.version ?? LOAD_UID;

    return this.http.get<TabsConfiguration>(
      this.context.embedded
        ? `${this.context.website}/ClientScript/AngularElements/config/tabs.json?v=${version}`
        : './config/tabs.json'
    );
  }
}
