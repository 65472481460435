<div class="text-xs p-4 flex flex-col bg-white text-neutral-90 h-full w-full border-t-[1px] border-neutral-40 border-solid">
  <maximizer-loader
    *ngIf="loading"
    type="legacy"
  ></maximizer-loader>

  <div class="flex w-full" *ngIf="!isCompany">
    <span class="font-bold basis-1/3 min-w-16">
      {{ 'outlook.details.firstName' | translate }}:
    </span>
    <span class="basis-2/3 overflow-hidden break-all" >{{
      lead?.firstName ?? abentry?.firstName
    }}</span>
  </div>

  <div class="flex mt-4" *ngIf="!isCompany">
    <span class="font-bold basis-1/3 min-w-16"
      >{{ 'outlook.details.lastName' | translate }}:</span
    >
    <span class="basis-2/3 overflow-hidden break-all">{{
      lead?.lastName ?? abentry?.lastName
    }}</span>
  </div>

  <div class="flex mt-4">
    <span class="font-bold basis-1/3 min-w-16"
      >{{ 'outlook.details.company' | translate }}:</span
    >
    <span class="basis-2/3 overflow-hidden break-all">{{
      lead?.companyName ?? abentry?.companyName
    }}</span>
  </div>

  <div class="flex mt-4" *ngIf="!isCompany">
    <span class="font-bold basis-1/3 min-w-16"
      >{{ 'outlook.details.position' | translate }}:</span
    >
    <span class="basis-2/3 overflow-hidden break-all">{{
      lead?.position ?? abentry?.position
    }}</span>
  </div>

  <div class="flex mt-4">
    <span class="font-bold basis-1/3 min-w-16"
      >{{ 'outlook.details.website' | translate }}:</span
    >
    <span class="basis-2/3 overflow-hidden break-all">{{
      lead?.website ?? abentry?.website
    }}</span>
  </div>

  <div class="flex mt-4 font-bold">
    {{ 'outlook.details.phone' | translate }}:
  </div>

  <div *ngIf="lead">
    <div class="flex mt-2">
      <div class="basis-1/3 min-w-16">
        <span class="pl-4">
          {{ 'outlook.details.phoneType.phone1' | translate }}:
        </span>
      </div>
      <span class="basis-2/3 overflow-hidden break-all">{{ lead.phone1 }}</span>
    </div>

    <div class="flex mt-2" *ngIf="lead && lead.phone2 !== ''">
      <div class="basis-1/3 min-w-16">
        <span class="pl-4">
          {{ 'outlook.details.phoneType.phone2' | translate }}:
        </span>
      </div>
      <span class="basis-2/3 overflow-hidden break-all">{{ lead.phone2 }}</span>
    </div>
  </div>

  <ng-container *ngFor="let phone of abentry?.phones">
    <div class="flex mt-2">
      <div class="basis-1/3 min-w-16 overflow-hidden break-all">
        <span class="pl-4">
          {{
            phone.type !== ''
              ? phone.type
              : ('outlook.details.phone' | translate) + ' ' + phone.index
          }}:
        </span>
      </div>
      <span class="basis-2/3 overflow-hidden break-all">{{ phone.value }}</span>
    </div>
  </ng-container>

  <div class="flex mt-4 font-bold">
    {{ 'outlook.details.email' | translate }}:
  </div>

  <div class="flex mt-2" *ngIf="lead">
    <div class="basis-1/3 min-w-16">
      <span class="pl-4">
        {{ 'outlook.details.emailType.main' | translate }}:
      </span>
    </div>
    <span class="basis-2/3 overflow-hidden break-all">{{ lead.email }}</span>
  </div>

  <ng-container *ngFor="let email of abentry?.emails">
    <div class="flex mt-2">
      <div class="basis-1/3 min-w-16 overflow-hidden break-all">
        <span class="pl-4">
          {{
            email.type !== ''
              ? email.type
              : ('outlook.details.email' | translate) + ' ' + email.index
          }}:
        </span>
      </div>
      <span class="basis-2/3 overflow-hidden break-all">{{ email.value }}</span>
    </div>
  </ng-container>
  <div class="flex mt-4 whitespace-pre">
    <span class="font-bold basis-1/3 min-w-16"
      >{{ 'outlook.details.address' | translate }}:</span
    >
    <span class="basis-2/3 overflow-hidden break-all whitespace-break-spaces">{{
      getAddress()
    }}</span>
  </div>
</div>

<maximizer-outlook-notification
  #notification
  [closable]="true"
  entryId="details"
  >
</maximizer-outlook-notification>
