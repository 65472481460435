import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ContextService } from '@maximizer/core/shared/data-access';
import { Observable, catchError, map, of } from 'rxjs';
import {
  INTEGRATION_URL,
  INTEGRATION_SUBSCRIPTION_KEY,
} from '../outlook-shared-data-access.module';
import { UserSyncConfiguration } from '../models';
import {
  EmailSubscription,
  EmailSubscriptionConfiguration,
} from '../models/email';
import { insightsHeader } from '@maximizer/core/shared/insights';

export enum EntityType {
  AbEntry = 1,
  Lead = 2,
}

@Injectable()
export class OutlookSyncService {
  constructor(
    @Inject(INTEGRATION_URL) private apiUrl: string,
    @Inject(INTEGRATION_SUBSCRIPTION_KEY) private subscriptionKey: string,
    private http: HttpClient,
    private context: ContextService,
  ) {}

  getConfiguration(
    userId: string,
    workspaceId: string,
  ): Observable<UserSyncConfiguration | undefined> {
    return this.http
      .get<UserSyncConfiguration>(
        `${this.apiUrl}/Outlook/v1/sync-configurations?userUid=${userId}&workspaceId=${workspaceId}`,
        {
          headers: {
            Authorization: this.context.token,
            'Ocp-Apim-Subscription-Key': this.subscriptionKey,
          },
        },
      )
      .pipe(
        map((result) => {
          if (result) {
            return result;
          }
          return undefined;
        }),
        catchError(() => of(undefined)),
      );
  }

  saveEmail(
    configurationId: string,
    entityType: EntityType,
    key: string,
    internetId: string,
  ): Observable<boolean> {
    const request = {
      maximizerEntityType: entityType,
      maximizerKey: key,
      internetMessageId: internetId,
    };
    return this.http
      .post<HttpResponse<void>>(
        `${this.apiUrl}/Outlook/v1/sync-configurations/${configurationId}/`,
        request,
        {
          headers: {
            Authorization: this.context.token,
            'Ocp-Apim-Subscription-Key': this.subscriptionKey,
          },
        },
      )
      .pipe(map((response: HttpResponse<void>) => response.status === 202));
  }

  updateEmailAutoSaveSubscription(
    syncId: string,
    subscription: EmailSubscription,
  ): Observable<boolean> {
    return this.http
      .put<HttpResponse<void>>(
        `${this.apiUrl}/Outlook/v1/sync-configurations/${syncId}/email-subscriptions`,
        subscription,
        {
          headers: {
            Authorization: `Bearer ${this.context.token}`,
            'Ocp-Apim-Subscription-Key': this.subscriptionKey,
          },
        },
      )
      .pipe(map((response: HttpResponse<void>) => response.status === 202));
  }

  getEmailSubscriptionByKey(
    maximizerKey: string,
    syncId: string,
  ): Observable<EmailSubscriptionConfiguration | undefined> {
    return this.http
      .get<EmailSubscriptionConfiguration>(
        `${this.apiUrl}/Outlook/v1/sync-configurations/${syncId}/email-subscriptions/${maximizerKey}`,
        {
          headers: {
            Authorization: `Bearer ${this.context.token}`,
            'Ocp-Apim-Subscription-Key': this.subscriptionKey,
            [insightsHeader('ignore')]: 'true',
          },
        },
      )
      .pipe(
        map((result) => {
          return result || undefined;
        }),
      );
  }
}
