import { Inject, Injectable } from '@angular/core';
import { ContextService } from './context.service';
import { DEBUG, FEATURE_CLIENT_ID } from '../data-access.module';
import {
  LDClient,
  LDContext,
  LDOptions,
  basicLogger,
  initialize,
} from 'launchdarkly-js-client-sdk';
import { Session } from '@maximizer/core/shared/domain';

@Injectable()
export class FeatureService {
  client?: LDClient;

  constructor(
    private context: ContextService,
    @Inject(FEATURE_CLIENT_ID) private featureClientId: string,
    @Inject(DEBUG) private debug: boolean,
  ) {}

  public initialize(session: Session): Promise<void> {
    const clientId = this.context.featureClientId || this.featureClientId;

    if (!clientId) {
      console.error('Feature client id is not set');
      return Promise.resolve();
    }

    const featureContext: LDContext = this.context.isCloud
      ? {
          kind: 'user',
          key: session.database,
          name: session.displayName,
          workspaceId: session.workspace,
          platform: 'cloud',
        }
      : {
          kind: 'customer',
          key: this.context.customerNumber,
          name: session.displayName,
          database: session.database,
          platform: 'on-premise',
        };

    const options: LDOptions = {
      diagnosticOptOut: !this.debug,
      logger: this.debug ? basicLogger() : undefined,
    };

    this.client = initialize(clientId, featureContext, options);

    return this.client.waitUntilReady();
  }

  isFeatureOn(key: string, defaultValue: boolean): boolean {
    return this.client?.variation(key, defaultValue) ?? defaultValue;
  }

  getVariation<T>(key: string): T | null {
    return this.client?.variation(key) ?? null;
  }
}

@Injectable()
export class OnPremiseFeatureService extends FeatureService {
  readonly activeFlags = [
    'core-card-layout-customization-shown',
    'core-globalsearch-search-as-type',
    'core-globalsearch-interaction',
    'core-task-global-edit-shown',
  ];

  constructor(
    context: ContextService,
    @Inject(FEATURE_CLIENT_ID) featureClientId: string,
    @Inject(DEBUG) debug: boolean,
  ) {
    super(context, featureClientId, debug);
  }

  override initialize(): Promise<void> {
    return Promise.resolve();
  }

  override isFeatureOn(key: string): boolean {
    return this.activeFlags.includes(key);
  }

  override getVariation<T>(): T | null {
    return null;
  }
}
