import { Component, Input, ViewChild } from '@angular/core';
import { InsightsService } from '@maximizer/core/shared/insights';
import {
  OutlookAbEntryDetails,
  OutlookLeadSearch,
} from '@maximizer/outlook/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';

@Component({
  selector: 'maximizer-entry-summary',
  templateUrl: './entry-summary.component.html',
})
export class EntrySummaryComponent {
  @Input() abentry?: OutlookAbEntryDetails;
  @Input() lead?: OutlookLeadSearch;
  @Input() id = '';
  @Input() type = '';

  @Input() deepLink?: string;
  @Input() oppLink?: string;

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  loadingTasks = true;
  loadingNotes = true;
  loadingAppointments = true;
  loadingOpportunities = true;
  loading = true;

  constructor(private insights: InsightsService){

  }
  showNotification(event: string) {
    this.insights.trackException({ exception: new Error('Failed to load '+ event)})
    if (this.notification.lastNotification) return;
    this.notification.show('load', 'error');
  }

  setLoading(event: string) {
    switch (event) {
      case 'task':
        this.loadingTasks = false;
        break;
      case 'note':
        this.loadingNotes = false;
        break;
      case 'appointment':
        this.loadingAppointments = false;
        break;
      case 'opportunity':
        this.loadingOpportunities = false;
        break;
      default:
        break;
    }
    if (this.abentry) {
      this.loading =
        this.loadingTasks ||
        this.loadingNotes ||
        this.loadingAppointments ||
        this.loadingOpportunities;
    } else {
      this.loading =
        this.loadingTasks || this.loadingNotes || this.loadingAppointments;
    }
  }
}
