<maximizer-outlook-menu
  [pageTitle]="'outlook.lead.add-title' | translate"
  [backRoutePath]="'/home'"
></maximizer-outlook-menu>
<div class="flex flex-col h-full scrollbar">
  <maximizer-loader *ngIf="loading" type="legacy"></maximizer-loader>
  <form
    *ngIf="form"
    class="flex flex-col p-4 text-caption"
    [formGroup]="form.group"
  >
    <kendo-formfield>
      <kendo-label
        [for]="firstName"
        [text]="'outlook.lead.firstName' | translate"
      ></kendo-label>
      <kendo-textbox
        #firstName
        formControlName="firstName"
        [maxlength]="80"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.firstName.errors"
        [fieldTranslation]="'outlook.lead.firstName'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <kendo-formfield class="mt-2" showErrors="always">
      <kendo-label [for]="lastName" [text]="'outlook.lead.lastName' | translate"
        >*</kendo-label
      >
      <kendo-textbox
        #lastName
        formControlName="lastName"
        [maxlength]="80"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        *ngIf="form.controls.lastName.touched"
        [errorObject]="form.controls.lastName.errors"
        [fieldTranslation]="'outlook.lead.lastName'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="email"
        [text]="'outlook.lead.email' | translate"
      ></kendo-label>
      <kendo-textbox
        #email
        formControlName="email"
        [ngClass]="'text-neutral-70'"
        [readonly]="true"
      ></kendo-textbox>
    </kendo-formfield>

    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="phone1"
        [text]="'outlook.lead.phone1' | translate"
      ></kendo-label>
      <kendo-textbox
        #phone1
        formControlName="phone1"
        [maxlength]="22"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.phone1.errors"
        [fieldTranslation]="'outlook.lead.phone1'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <kendo-formfield class="mt-2" *ngIf="addPhone2">
      <kendo-label
        [for]="phone2"
        [text]="'outlook.lead.phone2' | translate"
      ></kendo-label>
      <div class="mt-2 flex flex-row">
        <kendo-textbox
          #phone2
          formControlName="phone2"
          [maxlength]="22"
        ></kendo-textbox>
        <button
          *ngIf="addPhone2"
          kendoButton
          title="Remove phone"
          type="button"
          rounded="large"
          class="ml-2 border-neutral-40"
          fillMode="clear"
          themeColor="secondary"
          (click)="onRemovePhone()"
        >
          <em
            class="fa-icons icon-solid icon-12 icon-trash-can font-bold text-black hover:text-neutral-70"
          ></em>
        </button>
      </div>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.phone2.errors"
        [fieldTranslation]="'outlook.lead.phone2'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <button
      kendoButton
      title="Add phone"
      type="button"
      rounded="large"
      class="ml-auto px-0"
      [ngClass]="addPhone2 ? 'text-neutral-60' : 'text-inserv-100'"
      (click)="addPhone2 = true"
      [disabled]="addPhone2"
      fillMode="clear"
    >
      <em class="fa-icons icon-solid icon-12 icon-plus font-bold"></em>
      <span class="mx-2 text-xs hover:underline">{{
        'outlook.lead.add-phone' | translate
      }}</span>
    </button>

    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="company"
        [text]="'outlook.lead.company' | translate"
      ></kendo-label>
      <kendo-textbox
        #company
        formControlName="company"
        [maxlength]="80"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.company.errors"
        [fieldTranslation]="'outlook.lead.company'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="position"
        [text]="'outlook.lead.position' | translate"
      ></kendo-label>
      <kendo-textbox
        #position
        formControlName="position"
        [maxlength]="80"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.position.errors"
        [fieldTranslation]="'outlook.lead.position'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="website"
        [text]="'outlook.lead.website' | translate"
      ></kendo-label>
      <kendo-textbox
        #website
        formControlName="website"
        [maxlength]="256"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.website.errors"
        [fieldTranslation]="'outlook.lead.website'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <kendo-formfield class="mt-4">
      <kendo-label
        [for]="address1"
        [text]="'outlook.lead.address1' | translate"
      ></kendo-label>
      <kendo-textbox
        #address1
        formControlName="address1"
        [maxlength]="80"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.address1.errors"
        [fieldTranslation]="'outlook.lead.address1'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>
    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="address2"
        [text]="'outlook.lead.address2' | translate"
      ></kendo-label>
      <kendo-textbox
        #address2
        formControlName="address2"
        [maxlength]="80"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.address2.errors"
        [fieldTranslation]="'outlook.lead.address2'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>
    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="city"
        [text]="'outlook.lead.city' | translate"
      ></kendo-label>
      <kendo-textbox
        #city
        formControlName="city"
        [maxlength]="80"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.city.errors"
        [fieldTranslation]="'outlook.lead.city'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>
    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="province"
        [text]="'outlook.lead.province' | translate"
      ></kendo-label>
      <kendo-textbox
        #province
        formControlName="province"
        [maxlength]="80"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.province.errors"
        [fieldTranslation]="'outlook.lead.province'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>
    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="postalCode"
        [text]="'outlook.lead.postalCode' | translate"
      ></kendo-label>
      <kendo-textbox
        #postalCode
        formControlName="postalCode"
        [maxlength]="80"
      ></kendo-textbox>
      <maximizer-outlook-form-error
        [errorObject]="form.controls.postalCode.errors"
        [fieldTranslation]="'outlook.lead.postalCode'"
      ></maximizer-outlook-form-error>
    </kendo-formfield>

    <kendo-formfield class="mt-2">
      <kendo-label
        [for]="country"
        [text]="'outlook.lead.country' | translate"
      ></kendo-label>

      <kendo-dropdownlist
        #country
        [data]="countries"
        formControlName="country"
        textField="name"
        valueField="name"
        [valuePrimitive]="true"
        [defaultItem]="defaultCountry"
        [virtual]="{ itemHeight: 40 }"
      >
        <ng-template kendoDropDownListValueTemplate let-data class="flex">
          <img
            *ngIf="data?.flag"
            class="h-4 mr-2 pt-1 mt-1"
            [src]="data?.flag ? cdn + '/assets/images/' + data.flag : ''"
            [alt]="data?.name + ' flag'"
            title="Country flag"
          />
          <span class="overflow-hidden whitespace-nowrap text-ellipsis">
            {{ data?.name }}
          </span>
        </ng-template>
        <ng-template kendoDropDownListItemTemplate let-data>
          <div class="flex items-center">
            <img
              class="h-4 mx-2"
              *ngIf="data?.flag"
              [src]="data?.flag ? cdn + '/assets/images/' + data.flag : ''"
              [alt]="data?.name + ' flag'"
              title="Country flag"
            />
            <div class="overflow-hidden whitespace-nowrap text-ellipsis">
              {{ data?.name }}
            </div>
          </div>
        </ng-template>
      </kendo-dropdownlist>
    </kendo-formfield>
  </form>

  <div
    class="bg-neutral-5 z-40 mt-auto flex space-x-2 p-2 w-full bottom-0 sticky"
  >
    <button
      kendoButton
      class="uppercase rounded ml-auto"
      themeColor="primary"
      [disabled]="!canSave"
      (click)="save()"
    >
      <span>{{ 'outlook.buttons.save' | translate }} </span>
      <ng-template #loader *ngIf="loading">
        <kendo-loader
          type="pulsing"
          themeColor="primary"
          size="small"
        ></kendo-loader>
      </ng-template>
    </button>
    <button
      kendoButton
      rounded="large"
      class="uppercase rounded"
      themeColor="primary"
      (click)="cancel()"
      [disabled]="loading"
      fillMode="flat"
    >
      {{ 'outlook.buttons.cancel' | translate }}
    </button>
  </div>
</div>

<maximizer-outlook-notification
  #notification
  [closable]="true"
  [entryId]="'lead'"
>
</maximizer-outlook-notification>
