import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  GlobalStore,
  OAuth2Service,
  FavoriteListService,
} from '@maximizer/core/shared/data-access';
import {
  GlobalServicesLoginDetails,
  ListItem,
} from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
} from '@maximizer/core/shared/ui';
import {
  OutlookSyncService,
  UpdateSyncConfiguration,
  UserSyncConfiguration,
} from '@maximizer/outlook/shared/data-access';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { catchError, finalize, of } from 'rxjs';

interface SyncSettingsForm {
  contact: boolean;
  task: boolean;
  appointment: boolean;
  email: boolean;
  favoriteList: string;
}

@Component({
  selector: 'maximizer-outlook-sync-settings',
  templateUrl: './settings.component.html',
  styles: `
    :host {
      .k-dialog-wrapper {
        z-index: 1000;
      }
    }
    .settings-table {
      @apply border border-neutral-10 my-4 max-h-[380px];
      & > * {
        @apply px-2 py-3 flex justify-between;
      }
      & > *:not(:last-child) {
        @apply border-b border-neutral-10;
      }
    }
  `,
  standalone: false,
})
export class SyncSettingsComponent implements OnInit {
  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @Output() openSyncSettings = new EventEmitter<boolean>();

  private readonly maximizer = 'Maximizer';

  loginDetails?: GlobalServicesLoginDetails;
  form: FormGroupWrapper<SyncSettingsForm>;
  loading = false;
  syncConfiguration?: UserSyncConfiguration;
  userId = '';
  favoriteLists: ListItem<string>[] = [];
  contactSyncFirstSetup = true;
  favoriteListEnabled = false;
  lastFavoriteList = {
    id: '',
    name: '',
  };

  constructor(
    private readonly outlookSyncService: OutlookSyncService,
    private readonly oAuthService: OAuth2Service,
    private readonly favoriteListService: FavoriteListService,
    public globalStore: GlobalStore,
  ) {
    this.form = FormWrapperBuilder.group<SyncSettingsForm>({
      contact: [false, Validators.required],
      task: [false, Validators.required],
      appointment: [false, Validators.required],
      email: [false, Validators.required],
      favoriteList: [''],
    });
  }

  ngOnInit(): void {
    this.loginDetails = this.oAuthService.getStorageLoginDetails();

    this.favoriteListService
      .getAddressBookFavoriteList()
      .pipe(catchError(() => of([])))
      .subscribe((data) => {
        this.favoriteLists = data.map((item) => ({
          id: item.key,
          name: item.name,
        }));

        this.readSyncSettings();
      });
  }

  readSyncSettings(): void {
    const session = this.globalStore.session();

    if (session) {
      this.loading = true;
      this.userId = session.user.id;

      this.outlookSyncService
        .getConfiguration(session.user.id, session.workspace)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe((data) => {
          if (!data) return;

          this.syncConfiguration = data;

          this.form.patch({
            contact: data.contactsSync.enabled,
            task: data.tasksSync.enabled,
            appointment: data.appointmentsSync.enabled,
            email: data.emailsSync.enabled,
          });

          this.checkContactSyncFirstSetup();
          this.onContactSyncChange();
        });
    }
  }

  private setFavoriteListValues(): void {
    if (!this.syncConfiguration || !this.favoriteLists.length) {
      return;
    }

    if (!this.contactSyncFirstSetup) {
      this.form.group.patchValue({
        favoriteList: this.syncConfiguration.contactsSync.favoriteListKey ?? '',
      });
    } else {
      this.form.group.patchValue({
        favoriteList: this.favoriteLists[0].id,
      });
    }
  }

  private checkContactSyncFirstSetup(): void {
    if (!this.syncConfiguration) return;

    if (
      this.syncConfiguration.contactsSync.favoriteListKey !== '' &&
      this.syncConfiguration.contactsSync.favoriteListName !== ''
    ) {
      this.contactSyncFirstSetup = false;
      this.lastFavoriteList = {
        id: this.syncConfiguration.contactsSync.favoriteListKey,
        name: this.syncConfiguration.contactsSync.favoriteListName,
      };
    }

    this.setFavoriteListValues();
  }

  onContactSyncChange(): void {
    const favoriteListControl = this.form.control.get('favoriteList');

    if (favoriteListControl) {
      if (this.favoriteListEnabled) {
        favoriteListControl.enable();
      } else {
        favoriteListControl.disable();
      }
    }
  }

  updateSyncSettings(): void {
    if (this.form.valid && this.syncConfiguration && this.userId) {
      this.loading = true;

      const settings: UpdateSyncConfiguration = {
        maximizerUserUid: this.userId,
        contactsSync: {
          enabled: this.form.value.contact,
          favoriteListKey:
            !this.contactSyncFirstSetup && !this.form.value.contact
              ? this.lastFavoriteList.id
              : (this.form.value.favoriteList ?? ''),
          favoriteListName:
            !this.contactSyncFirstSetup && !this.form.value.contact
              ? this.lastFavoriteList.name
              : this.getFavoriteListNameById(this.form.value.favoriteList),
          outlookCategory: this.maximizer,
        },
        tasksSync: { enabled: this.form.value.task },
        appointmentsSync: { enabled: this.form.value.appointment },
        emailsSync: { enabled: this.form.value.email },
      };

      this.outlookSyncService
        .updateConfiguration(settings, this.syncConfiguration.id)
        .pipe(
          catchError(() => {
            this.notification.showMessage(
              'error',
              'outlook.sync.settings.error',
              true,
            );
            return of(null);
          }),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe((result) => {
          if (result) {
            this.openSyncSettings.emit(false);
            this.notification.showMessage(
              'success',
              'outlook.sync.settings.saved',
              true,
            );
          }
        });
    }
  }

  private getFavoriteListNameById(favoriteListId: string): string {
    return (
      this.favoriteLists.find((item) => favoriteListId === item.id)?.name ?? ''
    );
  }

  openOutlookSyncHistory(): void {
    if (!this.loginDetails) {
      return;
    }

    this.outlookSyncService
      .buildAppStoreFormRequest(this.loginDetails, false)
      .pipe(
        catchError(() => {
          this.notification.showMessage(
            'error',
            'outlook.errors.appStore',
            true,
          );
          return of(null);
        }),
      )
      .subscribe();
  }
}
