import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CDN_URL } from '@maximizer/core/shared/domain';
import { EmbeddedComponent } from '@maximizer/core/shared/ui';
import { OpportunityService } from '@maximizer/outlook/opportunity/data-access';
import { OpportunitySummary } from '@maximizer/outlook/shared/domain';
import { catchError, finalize } from 'rxjs';

@Component({
  selector: 'maximizer-summary-opportunity',
  templateUrl: './summary-opportunity.component.html',
})
export class SummaryOpportunityComponent
  extends EmbeddedComponent
  implements OnInit
{
  constructor(
    @Inject(CDN_URL) public cdn: string,
    element: ElementRef,
    private opportunityService: OpportunityService,
  ) {
    super(element);
    this.configuration = {
      opportunitiesAmount: 0,
      opportunitiesWorthValue: 0,
    };
  }

  configuration: OpportunitySummary;

  @Output() loadErrorEvent: EventEmitter<string> = new EventEmitter();
  @Output() loadingEvent: EventEmitter<boolean> = new EventEmitter();
  loading = true;

  @Input() deepLink?: string;
  @Input() id = '';
  oppLink?: string;

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.opportunityService
      .getSummary(this.id)
      .pipe(
        catchError(async () => this.loadErrorEvent.emit('opportunity')),
        finalize(() => {
          this.loadingEvent.emit(false);
          this.loading = false;
        }),
      )
      .subscribe((data) => {
        if (!data) return;

        this.configuration = data;
      });
  }
}
