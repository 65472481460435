/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';
import { isObject } from '@maximizer/shared/util';
import { TranslateParser } from '@ngx-translate/core';
import { IntlService } from '@progress/kendo-angular-intl';

@Pipe({
  name: 'format',
})
export class FormatPipe implements PipeTransform {
  constructor(
    private intl: IntlService,
    private parser: TranslateParser,
  ) {}

  transform(format: string, ...args: any[]): string {
    if (!this.useFormat(format) && args && args.length > 0 && isObject(args[0])) {
      return this.parser.interpolate(format, args[0]) ?? '';
    }

    return this.intl.format(format, ...args);
  }

  private useFormat(rawString: string): boolean {
    return rawString.includes('{0}');
  }
}
