/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ContextService } from '@maximizer/core/shared/data-access';
import { ifNullOrUndefinedThenEmpty } from '@maximizer/shared/util';

export const authenticationGuard: CanActivateFn = (_route, _state) => {
  const contextService = inject(ContextService);
  const router = inject(Router);

  if (
    ifNullOrUndefinedThenEmpty(contextService.token) !== '' &&
    ifNullOrUndefinedThenEmpty(contextService.refresh_token) !== '' &&
    ifNullOrUndefinedThenEmpty(contextService.api) !== ''
  ) {
    return true;
  } else {
    router.navigate(['/home']);
    return false;
  }
};
