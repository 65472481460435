<div *ngIf="dataItem" class="flex flex-row">
  <div class="flex-none w-6 my-auto">
    <div
      class="avatar avatar-xs avatar-neutral-20"
      [ngClass]="getAvatarClass()"
    >
      <span
        *ngIf="
          dataItem.type === 'abentry' && dataItem.abentryType !== 'company'
        "
        >{{ getAvatarLetters(dataItem) | nameInitials }}</span
      >

      <img
        *ngIf="dataItem.type === undefined"
        alt="Email not in maximizer"
        [src]="cdn + '/assets/images/outlook/email-not-in-maximizer.svg'"
        class="w-3 m-auto"
      />

      <em
        *ngIf="dataItem.type === 'lead'"
        class="fa-icons icon icon-solid icon-12 icon-user-magnifying-glass m-auto"
      ></em>
      <em
        *ngIf="dataItem.abentryType === 'company'"
        class="fa-icons icon icon-regular icon-16 icon-building m-auto"
      ></em>
    </div>
  </div>

  <div
    class="bg-gray-300 overflow-hidden text-ellipsis flex flex-col space-y-0.5 ml-2 my-auto shrink"
  >
    <p
      class="text-body overflow-hidden text-ellipsis whitespace-nowrap"
      [title]="dataItem.displayName"
    >
      {{ dataItem.displayName }}
    </p>
    <p
      class="caption text-neutral-70 overflow-hidden text-ellipsis"
      [title]="dataItem.emailAddress"
    >
      {{ dataItem.emailAddress }}
    </p>
    <span
      class="caption text-neutral-70 break-all"
      *ngIf="dataItem.type !== undefined"
      >{{
        (dataItem.type === 'lead'
          ? 'outlook.entries.lead'
          : 'outlook.entries.' + dataItem.abentryType
        ) | translate
      }}</span
    >

    <span
      class="caption text-neutral-70 break-all"
      *ngIf="dataItem.type === undefined"
      >{{ 'outlook.compose.not-in-maximizer' | translate }}</span
    >
  </div>
</div>
