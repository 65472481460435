import {
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CountryListItem,
  LocaleService,
} from '@maximizer/core/shared/data-access';
import { CDN_URL } from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
} from '@maximizer/core/shared/ui';
import { LeadService } from '@maximizer/outlook/shared/data-access';
import {
  OutlookLeadForm,
  OutlookLeadSearch,
} from '@maximizer/outlook/shared/domain';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import { Validators } from '@angular/forms';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';

@Component({
  selector: 'maximizer-edit-lead',
  templateUrl: './edit-lead.component.html',
})
export class EditLeadComponent implements OnInit {
  @HostBinding('class.max-outlook') hostClass = true;
  constructor(
    @Inject(CDN_URL) public cdn: string,
    private translate: TranslateService,
    private leadService: LeadService,
    private localService: LocaleService,
    private location: Location,
  ) {}

  leadKey = '';
  canSave = false;
  loading = true;
  addPhone2 = false;
  defaultCountry?: CountryListItem;

  leadData?: OutlookLeadSearch;
  form?: FormGroupWrapper<OutlookLeadForm>;
  countries?: CountryListItem[];

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  @Input()
  set id(id: string) {
    this.leadKey = decodeURI(id);
  }

  ngOnInit(): void {
    this.defaultCountry = {
      id: '',
      name: this.translate.instant('outlook.lead.country-placeholder'),
      flag: '',
    };

    if (this.leadKey && this.leadKey != '') this.loadData();
  }

  private setForm(lead: OutlookLeadSearch): void {
    this.form = FormWrapperBuilder.group<OutlookLeadForm>(
      {
        key: null,
        firstName: [lead.firstName, [Validators.maxLength(79)]],
        lastName: [
          lead.lastName,
          [
            Validators.nullValidator,
            Validators.maxLength(79),
            Validators.required,
          ],
        ],
        email: [lead.email, []],
        address1: [lead.address?.address1, [Validators.maxLength(79)]],
        address2: [lead.address?.address2, [Validators.maxLength(79)]],
        phone1: [lead.phone1 ?? '', [Validators.maxLength(21)]],
        phone2: [lead.phone2 ?? '', [Validators.maxLength(21)]],
        company: [lead.companyName ?? '', [Validators.maxLength(79)]],
        position: [lead.position, [Validators.maxLength(79)]],
        website: [lead.website, [Validators.maxLength(255)]],
        city: [lead.address?.city, [Validators.maxLength(79)]],
        province: [lead.address?.provinceState, [Validators.maxLength(79)]],
        postalCode: [lead.address?.zipcode, [Validators.maxLength(79)]],
        country: [lead.address?.country, [Validators.maxLength(79)]],
      },

      this.save,
      () => {
        this.form?.control.setErrors(null);
        if (this.form?.valid) {
          this.canSave = true;
        } else {
          this.canSave = false;
        }
        if (this.leadData?.status == 3 || this.leadData?.status == 2) {
          this.canSave = false;
        }
      },
    );
  }

  private loadData(): void {
    forkJoin({
      countries: this.localService.getCountries(),
      lead: this.leadService.getByKey(this.leadKey),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => {
        if (!data) return;
        if (!data.lead) return;

        if (data.lead.phone2 && data.lead.phone2 != '') this.addPhone2 = true;
        if (data.countries) this.countries = data.countries;
        this.setForm(data.lead);
        this.leadData = data.lead;
      });
  }

  save() {
    if (!this.form) {
      return;
    }
    const validation = this.form.validate();
    if (!validation.valid) return;
    this.form.patch({
      key: this.leadKey,
    });
    this.loading = true;

    this.leadService
      .update(this.form.value)
      .pipe(
        catchError(async () => {
          this.notification.show('save', 'error');
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((result) => {
        if (!result) return;
        this.notification.show('save', 'success', false);
        this.location.back();
      });
  }
  onRemovePhone() {
    this.form?.patch({ phone2: '' });
    this.addPhone2 = false;
  }
  cancel(): void {
    this.location.back();
  }
}
