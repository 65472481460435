import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { KendoContainerComponent } from './kendo-container.component';

@NgModule({
  imports: [CommonModule, DialogModule],
  exports: [KendoContainerComponent],
  declarations: [KendoContainerComponent],
  providers: [],
})
export class UiContainerModule {}
