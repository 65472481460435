import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContextService } from '@maximizer/core/shared/data-access';
import {
  Octopus,
  InteractionLogFieldOption,
  InteractionLogForm,
} from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';
import { insightsHeader } from '@maximizer/core/shared/insights';
import {
  InteractionFieldOptionMapper,
  InteractionFormMapper,
} from '@maximizer/outlook/shared/domain';

@Injectable()
export class InteractionService {
  constructor(
    private http: HttpClient,
    private context: ContextService,
  ) {}

  getFieldOption(): Observable<InteractionLogFieldOption> {
    const request: Octopus.InteractionLogFieldOptionsRequest = {
      InteractionLog: {
        FieldOptions: {
          Category: {
            Scope: {
              Fields: {
                Key: 1,
                DisplayValue: 1,
              },
            },
            Criteria: {
              SearchQuery: {
                InActive: {
                  $EQ: false,
                },
              },
            },
          },
          Result: {
            Scope: {
              Fields: {
                Key: 1,
                DisplayValue: 1,
              },
            },
            Criteria: {
              SearchQuery: {
                InActive: {
                  $EQ: false,
                },
              },
            },
          },
        },
      },
    };

    return this.http
      .post<Octopus.InteractionLogFieldOptionsResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new InteractionFieldOptionMapper();
            return mapper.from(result);
          }
          return { categories: [], results: [] };
        }),
      );
  }
  save(interactionForm: InteractionLogForm): Observable<string | null> {
    const mapper = new InteractionFormMapper();
    const data = mapper.from(interactionForm);
    const type = this.getTextFromKey(interactionForm.type);
    const request: Octopus.InteractionLogFormRequest = {
      InteractionLog: {
        Data: data,
      },
    };
    return this.http
      .post<Octopus.InteractionLogFormResponse>(
        `${this.context.api}${Octopus.Action.CREATE}`,
        request,
        {
          headers: {
            [insightsHeader('interaction-type')]: type ?? interactionForm.type,
          },
        },
      )
      .pipe(
        map((result) => {
          if (result?.Code === Octopus.ResponseStatusCode.Successful) {
            return result.InteractionLog.Data.Key;
          }
          return null;
        }),
      );
  }

  getLastContact(parentKey: string, type: string): Observable<Date | null> {
    const query: Octopus.LogicalQuery<Octopus.Task> = {
      $AND: [{ StartDate: { $LE: new Date().toISOString() } }],
    };

    if (type == 'abentry') {
      query.$AND?.push({ AbEntryKey: { $EQ: parentKey } });
    }
    if (type == 'lead') {
      query.$AND?.push({ LeadKey: { $EQ: parentKey } });
    }
    const request: Octopus.InteractionLogReadRequest = {
      InteractionLog: {
        Criteria: {
          SearchQuery: query,
          Top: 1,
        },
        Scope: { Fields: { Key: 1, StartDate: 1 } },
        OrderBy: { Fields: [{ StartDate: 'DESC' }] },
      },
    };

    return this.http
      .post<Octopus.InteractionLogReadResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result?.Code === Octopus.ResponseStatusCode.Successful) {
            const data = result.InteractionLog.Data;
            if (data.length > 0) {
              return data[0].StartDate;
            }
          }
          return null;
        }),
      );
  }

  private getTextFromKey(code: string): string | undefined {
    const enumValue = Object.values(Octopus.InteractionCode).find(
      (interactionCode) => interactionCode === code,
    ) as Octopus.InteractionCode;
    if (enumValue == undefined) return enumValue;

    return Octopus.InteractionCode[enumValue];
  }
}
