import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextService } from '@maximizer/core/shared/data-access';
import { InteractionSearch, Octopus } from '@maximizer/core/shared/domain';
import { Observable, map } from 'rxjs';
import {
  GlobalSearchService,
  SearchEscapeCharacters,
} from './global-search-service';
import { escapeCharacters } from '@maximizer/shared/util';

@Injectable()
export class InteractionsSearchService
  implements GlobalSearchService<InteractionSearch>
{
  constructor(
    private readonly _http: HttpClient,
    private readonly _context: ContextService,
  ) {}

  search(
    searchPhrase: string,
    useWildcard = false,
    take = 15,
  ): Observable<InteractionSearch[]> {
    const request: Octopus.InteractionReadRequest = {
      Interaction: {
        Criteria: {
          SearchQuery: {
            $PHRASE: `${escapeCharacters(searchPhrase, SearchEscapeCharacters)}`,
          },
          Top: take,
        },
        OrderBy: {
          Fields: [{ StartDate: 'DESC' }],
        },
        Scope: {
          Columns: {
            EntryName: {
              Value:
                '{%IF {{AbEntries}} != NULL %} {{AbEntries}} {%ELSEIF {{Leads}} != NULL %} {{Leads}} {%ENDIF%}',
            },
            EntryType: {
              Value:
                '{%IF {{AbEntries}} != NULL %} addressBook {%ELSEIF {{Leads}} != NULL %} lead {%ENDIF%}',
            },
          },
          Fields: {
            Key: 1,
            Type: {
              DisplayValue: 1,
              Icon: 1,
              External: 1,
              Key: 1,
            },
            Source: 1,
            With: {
              Opportunity: {
                Key: 1,
                Objective: 1,
                LastModifyDate: {
                  DisplayValue: 1,
                  Value: 1,
                },
                CreationDate: {
                  DisplayValue: 1,
                  Value: 1,
                },
              },
              Case: {
                Key: 1,
                CaseNumber: 1,
                Subject: 1,
                LastModifyDate: {
                  DisplayValue: 1,
                  Value: 1,
                },
                CreationDate: {
                  DisplayValue: 1,
                  Value: 1,
                },
              },
            },
            StartDate: {
              DisplayValue: 1,
              Value: 1,
            },
            Subject: 1,
            Direction: 1,
            AbEntries: [{ Key: 1 }],
            Leads: [{ Key: 1 }],
          },
        },
      },
      Globalization: Octopus.DefaultGlobalization,
      Configuration: Octopus.InteractionReadDriver,
    };

    return this._http
      .post<Octopus.InteractionSearchResponse>(
        `${this._context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result && result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.InteractionSearchMapper();
            return result.Interaction.Data.map((entries) =>
              mapper.from(entries),
            );
          }
          return [];
        }),
      );
  }
}
