import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { CheckBoxModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { UiPipesModule } from '../../pipes/pipes.module';
import { UiLoaderModule } from '../loader/loader.module';
import { FieldPickerDialogComponent } from './field-picker-dialog.component';
import { FieldPickerComponent } from './field-picker.component';

@NgModule({
  imports: [
    ButtonsModule,
    CommonModule,
    CheckBoxModule,
    DialogModule,
    FormsModule,
    LabelModule,
    MultiSelectModule,
    ReactiveFormsModule,
    TextBoxModule,
    TreeViewModule,
    UiLoaderModule,
    UiPipesModule,
  ],
  exports: [FieldPickerComponent, FieldPickerDialogComponent],
  declarations: [FieldPickerComponent, FieldPickerDialogComponent],
  providers: [],
})
export class UiFieldPickerModule {}
