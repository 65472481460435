import { Mapper } from '../../../mappers';
import { SystemConfiguration as OctopusSystemConfiguration } from '../models';
import { SystemConfiguration } from '../../../models';

export class SystemConfigurationMapper extends Mapper<
  OctopusSystemConfiguration,
  SystemConfiguration
> {
  from(source: OctopusSystemConfiguration): SystemConfiguration {
    return {
      fiscalYearStartMonth: source.SystemOption.FiscalYearStartMonth - 1,
      multiCurrency: source.SystemOption.MultiCurrencyEnabled,
      corporateCurrency: source.SystemOption.CorporateCurrency ?? '',
    };
  }
}
