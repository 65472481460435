export const getMaterialIcon = (icon: string): string =>
  `material-icons icon-${icon}`;

export const getMaximizerIcon = (icon: string): string =>
  `maximizer-icons icon-${icon}`;

export const getFontAwesomeIcon = (
  icon: string,
  variant: 'solid' | 'brand' | null = null,
  size: 8 | 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | null = null,
): string =>
  `fa-icons icon-${icon}` +
  (variant ? ` icon-${variant}` : '') +
  (size ? ` icon-${size}` : '');

export const getSvgIcon = (icon: string): string => `svg-icons icon-${icon}`;
