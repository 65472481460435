import {
  InjectionToken,
  LOCALE_ID,
  NgModule,
  inject,
  provideAppInitializer,
} from '@angular/core';
import {
  provideRouter,
  RouterLink,
  RouterLinkActive,
  RouterModule,
  RouterOutlet,
  withComponentInputBinding,
} from '@angular/router';
import { CoreGlobalSearchDataAccessModule } from '@maximizer/core/global-search/data-access';
import {
  DataAccessModule,
  GlobalStore,
} from '@maximizer/core/shared/data-access';
import { CDN_URL, DomainModule } from '@maximizer/core/shared/domain';
import { InsightsModule } from '@maximizer/core/shared/insights';
import {
  UiModule,
  defaultTranslateConfiguration,
} from '@maximizer/core/shared/ui';
import { OutlookAppointmentDataAccessModule } from '@maximizer/outlook/appointment/data-access';
import { OutlookAppointmentUiModule } from '@maximizer/outlook/appointment/ui';
import { OutlookInteractionLogDataAccessModule } from '@maximizer/outlook/interaction-log/data-access';
import { OutlookInteractionLogUiModule } from '@maximizer/outlook/interaction-log/ui';
import { OutlookTimelineUiModule } from '@maximizer/outlook/timeline/ui';
import { OutlookNoteDataAccessModule } from '@maximizer/outlook/note/data-access';
import { OutlookNoteUiModule } from '@maximizer/outlook/note/ui';
import { OutlookOpportunityDataAccessModule } from '@maximizer/outlook/opportunity/data-access';
import { OutlookOpportunityUiModule } from '@maximizer/outlook/opportunity/ui';
import { OutlookSharedUiModule } from '@maximizer/outlook/shared/ui';
import { OutlookTaskDataAccessModule } from '@maximizer/outlook/task/data-access';
import { OutlookTaskUiModule } from '@maximizer/outlook/task/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { routes } from './app.routes';
import { OutlookTimelineDataAccessModule } from '@maximizer/outlook/timeline/data-access';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { OutlookLeadUiModule } from '@maximizer/outlook/lead/ui';
import { OutlookAbEntryUiModule } from '@maximizer/outlook/abentry/ui';
import { OutlookEntryUiModule } from '@maximizer/outlook/entry/ui';
import { OutlookSharedDataAccessModule } from '@maximizer/outlook/shared/data-access';
import { OutlookSharedDomainModule } from '@maximizer/outlook/shared/domain';
import { OutlookMenuUiModule } from '@maximizer/outlook/menu/ui';
import { OutlookMainUiModule } from '@maximizer/outlook/main/ui';
import { OutlookAuthUiModule } from '@maximizer/outlook/auth/ui';
import { OutlookOnboardingUiModule } from '@maximizer/outlook/onboarding/ui';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { OutlookAuthService } from './auth/auth.service';
import { ADDIN_VERSION } from './app.version';
import { OutlookCaseDataAccessModule } from '@maximizer/outlook/case/data-access';
import { OutlookCaseUiModule } from '@maximizer/outlook/case/ui';
import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalModule,
  MsalService,
  MSAL_INSTANCE,
  MsalRedirectComponent,
} from '@azure/msal-angular';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(`${logLevel}: ${message}`);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: environment.msalConfig.auth.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true,
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error, // Change log level to debug msal events
        piiLoggingEnabled: false,
      },
    },
  });
}

export const INSTRUMENTATION_KEY = new InjectionToken<string>(
  'instrumentationKey',
);
export const APP_STORE_OAUTH_URL = new InjectionToken<string>(
  'appStoreOAthUrl',
);
export const APP_STORE_CONFIGURATION_URL = new InjectionToken<string>(
  'appStoreConfigurationUrl',
);
export const INTEGRATION_WEBHOOK_URL = new InjectionToken<string>(
  'integrationWebhook',
);
export const WEBHOOK_CREATE_EARLY_ACCESS = new InjectionToken<string>(
  'webhookEarlyAccess',
);
export const WEBHOOK_READ_EARLY_ACCESS = new InjectionToken<string>(
  'webhookEarlyAccess',
);
export const GRAPH_API_SCOPES = new InjectionToken<string>('graphApiScopes');
export const GRAPH_API_URI = new InjectionToken<string>('graphApiUri');

@NgModule({
  declarations: [AppComponent, HomeComponent],
  exports: [RouterModule],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    ButtonsModule,
    InputsModule,
    DropDownsModule,
    PopupModule,
    DialogsModule,
    LayoutModule,
    TooltipsModule,
    IndicatorsModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    UiModule,
    DataAccessModule,
    CoreGlobalSearchDataAccessModule,
    OutlookAbEntryUiModule,
    OutlookAppointmentUiModule,
    OutlookAppointmentDataAccessModule,
    OutlookEntryUiModule,
    OutlookInteractionLogDataAccessModule,
    OutlookInteractionLogUiModule,
    OutlookLeadUiModule,
    OutlookInteractionLogUiModule,
    OutlookLeadUiModule,
    OutlookMainUiModule,
    OutlookNoteUiModule,
    OutlookNoteDataAccessModule,
    OutlookOpportunityDataAccessModule,
    OutlookOpportunityUiModule,
    OutlookTaskUiModule,
    OutlookTaskDataAccessModule,
    OutlookTimelineUiModule,
    OutlookTimelineDataAccessModule,
    OutlookSharedUiModule,
    OutlookSharedDataAccessModule.forRoot({
      url: environment.integration.url,
      subscriptionKey: environment.integration.subscriptionKey,
      appStoreOAuthUrl: environment.appStoreOAuthUrl,
      appStoreConfigurationUrl: environment.appStoreConfigurationUrl,
      integrationWebhookUrl: environment.integration.webhookUrl,
      createEarlyAccess: environment.integration.webhookId.createEarlyAccess,
      readEarlyAccess: environment.integration.webhookId.readEarlyAccess,
      graphApiScopes: environment.graphApiConfig.scopes,
      graphApiUri: environment.graphApiConfig.uri,
    }),
    OutlookSharedDomainModule,
    OutlookMenuUiModule,
    OutlookAuthUiModule,
    OutlookOnboardingUiModule,
    OutlookCaseDataAccessModule,
    OutlookCaseUiModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    TranslateModule.forRoot(defaultTranslateConfiguration),
    DomainModule,
    DataAccessModule.forRoot(
      !environment.production,
      environment.launchDarklyClientId,
      environment.smartFill,
      undefined,
      undefined,
      ADDIN_VERSION,
      environment.product,
      environment.oauth,
    ),
    InsightsModule,
    MsalModule,
  ],
  providers: [
    { provide: CDN_URL, useValue: environment.cdn },
    { provide: INSTRUMENTATION_KEY, useValue: environment.instrumentationKey },
    { provide: APP_STORE_OAUTH_URL, useValue: environment.appStoreOAuthUrl },
    {
      provide: APP_STORE_CONFIGURATION_URL,
      useValue: environment.appStoreConfigurationUrl,
    },
    {
      provide: INTEGRATION_WEBHOOK_URL,
      useValue: environment.integration.webhookUrl,
    },
    {
      provide: WEBHOOK_CREATE_EARLY_ACCESS,
      useValue: environment.integration.webhookId.createEarlyAccess,
    },
    {
      provide: WEBHOOK_READ_EARLY_ACCESS,
      useValue: environment.integration.webhookId.readEarlyAccess,
    },
    {
      provide: GRAPH_API_SCOPES,
      useValue: environment.graphApiConfig.scopes,
    },
    {
      provide: GRAPH_API_URI,
      useValue: environment.graphApiConfig.uri,
    },
    provideAppInitializer(() => {
      const initializerFn = (
        (global: GlobalStore) => () =>
          global.setInsightsConfiguration({
            default: {
              applicationInsights: {
                instrumentationKey: environment.instrumentationKey,
              },
              eventLevel: SeverityLevel.Information,
              trackPageView: true,
            },
          })
      )(inject(GlobalStore));
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = (
        (authService: OutlookAuthService) => async () =>
          authService.initialize()
      )(inject(OutlookAuthService));
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = (
        (translate: TranslateService) => () =>
          translate.use(translate.defaultLang)
      )(inject(TranslateService));
      return initializerFn();
    }),
    {
      provide: LOCALE_ID,
      useValue: 'en-US',
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    MsalService,
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
