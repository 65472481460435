import { Mapper } from '../../../mappers';
import { AbEntryRecent, AbEntrySearch } from '../../../models';
import {
  AbRecentItems as OctopusAbRecentItems,
  AbEntry as OctopusAbEntry,
} from '../models/ab-entry';

export class AbEntrySearchMapper extends Mapper<OctopusAbEntry, AbEntrySearch> {
  from(source: OctopusAbEntry): AbEntrySearch {
    return {
      key: source.Key,
      parentKey: source.ParentKey?.Value ?? null,
      parent: source.Parent
        ? {
            type: source.Parent.Type ?? null,
            displayValue: source.Parent.DisplayValue ?? null,
          }
        : null,
      displayValue: source.DisplayValue,
      type: source.Type,
      name: `${source.FirstName} ${source.LastName}`.trim(),
      companyName: source.CompanyName,
      email: source.Email.Address,
      lastContactDateDisplayValue: source.LastContactDate?.DisplayValue,
      lastContactDateValue: source.LastContactDate?.Value,
      phone: source.Phone?.Number,
      phone1: source.Phone1?.Number,
      phone2: source.Phone2?.Number,
      phone3: source.Phone3?.Number,
    };
  }
}

export class AbEntryRecentMapper extends Mapper<
  OctopusAbRecentItems,
  AbEntryRecent
> {
  from(source: OctopusAbRecentItems): AbEntryRecent {
    const mapper = new AbEntrySearchMapper();

    return {
      key: source.Key,
      length: source.Length,
      items: source.Items.map((item) => mapper.from(item.AbEntry)),
    } as AbEntryRecent;
  }
}
