import {
  Case,
  KeyAndDisplay,
  Opportunity,
  ComparisonValue,
  SecurityStatus,
} from '../api/octopus';
import { ActivityTimeline } from './activity-timeline';
import { EntityType } from './entity';
import { EntryType } from './entry-type';
import { ListItem } from './list-item';
import { Session } from './session';
import { User } from './user';

export enum DialogType {
  confirm = 0,
  alert = 1,
}

export enum ActionType {
  completed = 0,
  delete = 1,
}

export enum ActivityType {
  regular = 0,
  actionPlan = 1,
  recurring = 2,
}

export interface Activity {
  [name: string]: unknown;
  key: string | null;
  type: number;
  secStatus: SecurityStatus;
  completed: boolean;
  creator: string;
  startDate: string;
  endDate: string;
  userKeys: string[];
  abEntries: KeyAndDisplay[];
  leads: KeyAndDisplay[];
  withKey: string | null;
  regarding?: {
    opportunity?: Opportunity;
    case?: Case;
  };
  recurrence: string | null;
  isActionPlan: boolean;
  isLastActivity: boolean;
}

export interface ActivityConfirmDialog {
  dialogType: DialogType;
  actionType: ActionType;
  activityType: ActivityType;
}

export interface ActivityQueryParam {
  key?: string | string[] | null;
  entryType?: EntryType | null;
  users?: string[];
  creators?: string[];
  duedate?: ComparisonValue;
  activityTypes?: number[];
  recurrence?: string | null;
  showCompleted?: boolean;
  showDisabledUsers?: boolean;
}

export interface ActivityManagerFieldOption {
  types: ListItem<number>[];
}

export interface ActivityManagerModuleConfiguration {
  session: Session;
  currentUser: User;
  activityTypes: ActivityManagerFieldOption;
  users: User[];
}

export interface ActivityManagerFilters {
  basicFilter: ActivityManagerBasicFilters;
  customDateFilter: ActivityManagerCustomDateFilters;
}

export interface ActivityManagerBasicFilters {
  users: string[] | 'all';
  duedate: string;
  activityTypes: number[];
  showCompleted: boolean;
}

export interface ActivityManagerCustomDateFilters {
  dateRange: {
    start: Date | null;
    end: Date | null;
  };
}

export interface ToUpdateActivity {
  isNew: boolean;
  type?: EntityType | EntryType;
  key?: string | null;
  newKey?: string | null;
}

export interface ActivityGridColumn {
  type: EntryType;
  clickable: boolean;
}

export interface ActivityTimelineUpcomingOverdue {
  overdue: Array<ActivityTimeline>;
  upcoming: Array<ActivityTimeline>;
}

export enum ActivityColumnField {
  Name = 'entry',
  Subject = 'subject',
  Regarding = 'with',
}
