/* eslint-disable @typescript-eslint/no-extra-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfigurationSetting,
  ConfigurationSettingId,
  Octopus,
  Session,
  User,
} from '@maximizer/core/shared/domain';
import {
  Observable,
  forkJoin,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { GlobalStore } from '../state/global.store';
import { ConfigurationService } from './configuration.service';
import { ContextService } from './context.service';
import { FeatureService } from './feature.service';
import { LocaleService } from './locale.service';
import { UserService } from './user.service';

@Injectable()
export class SessionService {
  private get endpoint(): string {
    return `${this.context.api}${Octopus.Action.SESSION_INFO}`;
  }
  private sessionRequest?: Observable<Session>;

  readonly request: Octopus.SessionReadRequest = {
    AddressBook: {
      Alias: 1,
      Database: 1,
      DisplayValue: 1,
      WorkSpaceId: 1,
    },
    User: {
      Key: {
        Value: 1,
        Uid: 1,
      },
    },
  };

  constructor(
    private readonly http: HttpClient,
    private readonly users: UserService,
    private readonly context: ContextService,
    private readonly configuration: ConfigurationService,
    private readonly feature: FeatureService,
    private readonly locale: LocaleService,
    private readonly global: GlobalStore,
  ) {}

  getInfo(): Observable<Session> {
    if (!this.sessionRequest) {
      this.sessionRequest = this.http
        .post<Octopus.SessionResponse>(this.endpoint, this.request)
        .pipe(
          switchMap((result) => {
            const mapper = new Octopus.SessionMapper();
            const session = mapper.from(result.Data);

            return forkJoin({
              session: of(session),
              user: this.users.getByKey(result.Data.User.Key.Value!),
              settings: this.getSetting(result.Data.User.Key.Uid),
              configuration: this.configuration.getSystemConfiguration(),
            });
          }),
          map((result) => {
            result.session.systemConfiguration = result.configuration;
            result.session.user = result.user!!;
            result.session.showDisabledUsers =
              result.settings?.find(
                (setting) =>
                  setting.code3 === ConfigurationSettingId.showDisabledUsers,
              )?.textValue === 'yes' ?? false;
            result.session.culture =
              result.settings?.find(
                (setting) => setting.code4 === ConfigurationSettingId.culture,
              )?.textValue ?? this.locale.defaultLocale;

            return result.session;
          }),
          switchMap((session) => {
            return forkJoin({
              session: of(session),
              feature: this.feature.initialize(session),
              locale: this.locale.set(session.culture),
            });
          }),
          map((result) => result.session),
          tap((session) => this.global.setSession(session)),
          shareReplay(1),
        );
    }

    return this.sessionRequest;
  }

  getCurrentUser(): Observable<User> {
    return this.getInfo().pipe(map((session) => session.user));
  }

  getUsersByPreference(): Observable<User[]> {
    return forkJoin({
      session: this.getInfo(),
      users: this.users.getUsers(),
    }).pipe(
      map(({ users, session }) => {
        return users.filter(
          (user) => session?.showDisabledUsers || user.webAccess,
        );
      }),
    );
  }

  clearSessionCache(): void {
    this.sessionRequest = undefined;
  }

  private getSetting(user: string): Observable<ConfigurationSetting[] | null> {
    return this.configuration.getSettings([
      {
        code1: 'AMGR',
        code2: user,
        code3: ConfigurationSettingId.showDisabledUsers,
      },
      {
        code2: user,
        code3: 'Preferences',
        code4: ConfigurationSettingId.culture,
      },
    ]);
  }
}
