/* eslint-disable @typescript-eslint/no-explicit-any */

export function isObject(value: unknown) {
  const type = typeof value;
  return type === 'function' || (type === 'object' && !!value);
}

export function cloneObject<T>(originalObj: T): T {
  return JSON.parse(JSON.stringify(originalObj));
}

export function isNullOrUndefinedOrEmpty(
  value: unknown,
): value is null | undefined {
  return (
    value === null ||
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  );
}

export function replaceObjectValues<T>(origin: T, replace: T): T {
  if (origin && replace) {
    for (const key in origin) {
      if (typeof origin[key] === 'object' && typeof replace[key] === 'object') {
        origin[key] = replaceObjectValues(origin[key], replace[key]);
      } else {
        origin[key] = replace[key];
      }
    }
  }

  return origin;
}

export function getPropertyValue<T>(value: any, property: string): T {
  if (!value || !property) {
    return value;
  }

  const nestedProperties = property.split('.');
  return nestedProperties.reduce((result, prop) => result?.[prop], value);
}
